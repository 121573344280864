<template>
  <div
    class="admin"
    v-loading="loading"
    element-loading-text="拼命上传中..."
    element-loading-background="rgba(0, 0, 0, 0.8)"
    customClass="admins"
  >
    <p class="tabbar">
      <span
        class="wei"
        :class="{ cur: tabbar === 0 }"
        @click="(tabbar = 0), getData()"
        >未审核</span
      ><span
        class="yi"
        :class="{ cur: tabbar === 1 }"
        @click="(tabbar = 1), getData()"
        >已审核</span
      >
    </p>
    <div class="tabelBox">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="date" label="序列" width="180">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="menuName" label="资源名称" width="180">
        </el-table-column>
        <el-table-column prop="rescourseType" label="资源类型">
        </el-table-column>
        <el-table-column prop="realname" label="老师用户名"> </el-table-column>
        <el-table-column prop="username" label="老师真实姓名">
        </el-table-column>
        <el-table-column prop="createTime" label="提交时间"></el-table-column>
        <el-table-column v-if="tabbar === 0" prop="address" label="操作">
          <template slot-scope="scope"
            ><el-button type="info" @click="lookOne(scope.row)">查看</el-button>
            <el-button
              type="primary"
              @click="(dialogVisible = true), openBox(scope.row)"
              >通过</el-button
            ><el-button
              type="danger"
              @click="(unpass = true), openBox(scope.row)"
              >不通过</el-button
            ></template
          >
        </el-table-column>
        <el-table-column v-else prop="address" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isUp === 1" effect="plain">已通过</el-tag>
            <el-tag v-if="scope.row.isUp === 2" effect="plain" type="danger"
              >已删除</el-tag
            >

            <el-tag v-if="scope.row.isUp === 0" type="warning" effect="dark"
              >未通过</el-tag
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="资源分组"
      :visible.sync="dialogVisible"
      center
      @open="openPassBox"
      v-loading="loadings"
      element-loading-text="拼命上传中..."
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="xiala">
        <div class="block">
          <el-cascader
            :props="{ label: 'name', value: 'id', children: '' }"
            v-model="value"
            :options="options"
            @change="handleChange"
          ></el-cascader>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="passOne">提 交</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核未通过" :visible.sync="unpass" center>
      <div class="xiala">确定不通过此资源吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="unpassOne">确 定</el-button>
        <el-button @click="unpass = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentPage3: 1,
      pageSize: 10,
      total: 10,
      cerificateId: '',
      tabbar: 0,
      dialogVisible: false,
      value: '',
      unpass: false,
      options: [],
      clickId: '',
      clickMenuId: '',
      clickType: '',
      silderId: null,
      loading: false,
      loadings: false,
      userId: '',
      resName: '',
      nowId: '',
    };
  },
  created() {
    this.silderId = localStorage.getItem('silderId');
    this.cerificateId = localStorage.getItem('silderId');
    // console.log(this.cerificateId);
    this.getData();
  },
  methods: {
    // 不通过一个
    unpassOne() {
      this.$axios
        .post(
          `/teach/cerificate/upNoConversion?conversionId=${this.clickId}&menuId=${this.clickMenuId}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.value = '';
            this.sendMessage();
            this.getData();
            this.unpass = false;
          } else {
            this.$message({
              type: 'error',
              message: '操作失败',
            });
          }
        });
    },
    handleChange() {
      // console.log(this.value[0]);
    },
    // 打开通过对话框获取到该类型的公共资源目录
    openPassBox() {
      // console.log(this.clickType);
      this.$axios
        .get(
          `/teach/courseware/findMenuWare?cerificateId=${this.silderId}&type=${this.clickType}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data.code === 200) {
            this.options = res.data.data[0].children;
          }
        });
      // this.$axios.
    },
    openBox(item) {
      // console.log(item);
      this.clickMenuId = item.menuId;
      this.clickId = item.id;
      this.clickType = item.rescourseType;
      this.nowId = item.id;
      this.userId = item.uploadId;
      if (this.dialogVisible === true) {
        this.resName =
          '您提交的' +
          item.menuName +
          item.rescourseType +
          '审核成功！已经转为公共资源了！';
      } else {
        this.resName =
          '您提交的' + item.menuName + item.rescourseType + '审核失败！';
      }
    },
    // 发送审核结果
    sendMessage() {
      const data = {
        comvisionId: this.nowId,
        message: this.resName,
        subId: this.userId,
        type: 0,
      };
      this.$axios.post('/teach/mess/sendCommonMessage?', data).then((res) => {
        // console.log(res.data.data);
        if (res.data.code === 200) {
          // console.log('通过消息发送成功！！！');
        } else {
          // console.log('消息发送失败');
        }
      });
    },
    // 通过一个
    passOne() {
      if (!this.value[0]) {
        this.$message({
          type: 'error',
          message: '请选择课件分组',
        });
        return;
      }
      this.loadings = true;
      this.$axios
        .post(
          `/teach/cerificate/updaeConversion?conversionId=${this.clickId}&menuId=${this.value[0]}`
        )
        .then((res) => {
          if (res.data.code === 200) {
            // console.log(res.data.data);
            this.$message({
              type: 'success',
              message: '审核通过成功',
            });
            // this.$axios.post(`/teach/mess/sendMessage?id=${}&message=${}&ti=${}`)
            this.loadings = false;
            this.value = '';
            this.dialogVisible = false;
            this.sendMessage();
            this.getData();
          } else {
            this.$message({
              type: 'error',
              message: '审核失败',
            });
          }
        });
    },
    // 查看一个
    lookOne(item) {
      // console.log(item);
      //   this.$axios.
      if (item.rescourseType === '课件资源') {
        this.$router.push({
          path: '/previewCourseware',
          query: {
            menuId: item.menuId,
          },
        });
      } else if (item.rescourseType === '题库资源') {
        this.$router.push({
          path: '/previewTopic',
          query: {
            menuId: item.menuId,
            type: item.questiontype,
          },
        });
      } else if (item.rescourseType === '数字资源') {
        this.$router.push({
          path: '/previewNumber',
          query: {
            menuId: item.menuId,
          },
        });
      } else if (item.rescourseType === '试卷资源') {
        this.$router.push({
          path: '/previewExamination',
          query: {
            menuId: item.menuId,
          },
        });
      }
    },
    // 获取审核页面数据
    getData() {
      this.loading = true;
      this.tableData = [];
      this.$axios
        .get(
          `/teach/cerificate/findConversionList?cerificateId=${this.cerificateId}&status=${this.tabbar}&page=${this.currentPage3}&rows=${this.pageSize}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.tableData = res.data.data.items;
            this.total = res.data.data.total;
            this.loading = false;
          }
        });
    },
    handleSizeChange() {
      // console.log(v);
    },
    handleCurrentChange(v) {
      // console.log(v);
      this.currentPage3 = v;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.admin {
  width: 100%;
  height: 100vh;
  background: #fff;
  border-radius: 4px;
}
.admins {
  z-index: 10000 !important;
}
.tabbar {
  text-align: center;
  margin: 18px auto;
  span {
    border: 1px solid #666666;
    padding: 8px 20px;
    border-radius: 4px;
  }
  span:nth-child(1) {
    border-radius: 4px 0 0 4px;
  }
  span:nth-child(2) {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }
  .cur {
    background: #409eff;
    color: #ffffff;
    border: #409eff 1px solid;
  }
  span:hover {
    cursor: pointer;
  }
}
.tabelBox {
  margin-top: 16px;
}
.block {
  margin-top: 16px;
  text-align: center;
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
.xiala {
  width: 686px;
  height: 40px;
  // background: red;
  margin: 0 auto;
}
.el-cascader {
  width: 100%;
}
// .el-cascader-menu {
//   width: 300px;
// }
</style>
<style lang="less">
/deep/.el-cascader__dropdown {
  width: 686px !important;
}
.el-cascader-menu {
  width: 686px !important;
}
/deep/.el-cascader-panel {
  width: 686px !important ;
}
/deep/.el-scrollbar__view {
  width: 500px !important;
}
// .el-cascader-menu__list {
//   width: 800px;
// }
</style>
