<template>
  <div class="note">
    <div class="header">
      <icon-svg icon-class="biji" class="icon"></icon-svg>
      <h2>我的笔记</h2>
    </div>
    <div class="form-box">
      <div>
        <el-input
          placeholder="请输入笔记名称关键词"
          v-model="key"
          class="input-with-select"
          style="width: 270px"
          @keyup.enter.native="getNoteList"
        >
          <!-- <div slot="append" icon="el-icon-search"></div> -->
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getNoteList"
          ></el-button>
        </el-input>
      </div>

      <el-button type="primary" @click="openNoteDialog('add')"
        >新增笔记</el-button
      >
    </div>
    <el-empty
      description="怎么回事？你竟然没有笔记"
      v-if="tableData.length == 0"
    ></el-empty>
    <div class="note-box" v-else>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="50px"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="笔记名称"
          width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="type" label="笔记类型" align="center">
          <template slot-scope="scoped">
            <el-tag v-if="scoped.row.type == 1" effect="dark" type="success"
              >课堂笔记</el-tag
            >
            <el-tag v-if="scoped.row.type == 2" effect="dark">个人笔记</el-tag>
            <el-tag v-if="scoped.row.type == 3" effect="dark" type="info"
              >备课笔记</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间"></el-table-column>
        <el-table-column label="操作" width="240px" align="center">
          <template slot-scope="scope">
            <div>
              <el-button size="mini" @click="openNoteDialog('edit', scope.row)"
                >编辑</el-button
              >
              <el-button
                type="info"
                size="mini"
                @click="openLookDialog(scope.row)"
                >查看</el-button
              >
              <el-button
                type="warning"
                size="mini"
                @click="masterNote(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="zksc-pagination"
        layout="prev, pager, next, jumper"
        :total="totalPage"
        :page-size="pageSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </div>

    <!-- 笔记dialog -->
    <el-dialog
      class="zksc-dialog"
      title="新增笔记"
      :visible.sync="isNoteDialog"
      :before-close="closeNoteDialog"
    >
      <el-form
        label-width="80px"
        :model="noteForm"
        :rules="noteFormRules"
        ref="noteRef"
      >
        <el-form-item label="笔记类型" prop="type">
          <el-select v-model="noteForm.type" placeholder="请选择笔记类型">
            <el-option
              v-for="item in noteType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="笔记名称" prop="name">
          <el-input
            placeholder="请输入笔记名称"
            v-model="noteForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label prop="text">
          <el-input
            v-if="false"
            placeholder="例:金牌动画师"
            v-model="noteForm.text"
          ></el-input>
          <rich-text
            ref="richRef"
            @change="richTextChange"
            :detail="noteForm.text"
          ></rich-text>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getUploadToken">确 定</el-button>
          <el-button @click="closeNoteDialog">取 消</el-button>
        </el-form-item>
      </el-form>
      <!-- TODO -->
    </el-dialog>

    <!-- 笔记查看 -->
    <el-dialog
      class="zksc-dialog"
      title="笔记"
      :visible.sync="isLookDialog"
      :before-close="closeLookDialog"
    >
      <div class="dialog-content">
        <h4>{{ noteName ? noteName : '笔记名字' }}</h4>
        <div
          class="note-content"
          v-html="noteContent ? noteContent : '笔记呀笔记'"
        ></div>
        <div class="btn-group">
          <el-button type="primary" @click="closeLookDialog">确定</el-button>
          <el-button @click="closeLookDialog">取消</el-button>
        </div>
      </div>
      <!-- TODO -->
    </el-dialog>
  </div>
</template>
<script>
import RichText from '../../common/richText.vue';
import fileUtil from '../../../utils/index';
export default {
  components: {
    RichText,
  },
  data() {
    return {
      /* 笔记类型 */
      noteType: [
        {
          id: 1,
          name: '课堂笔记',
        },
        {
          id: 2,
          name: '个人笔记',
        },
        {
          id: 3,
          name: '备课笔记',
        },
      ],
      tableData: [],
      totalPage: 0,
      pageSize: 10,
      current: 1,
      query: '',
      /* 笔记编辑 */
      isNoteDialog: false,
      noteForm: {
        name: '',
        type: '',
        text: '',
        annexDTO: {
          objectname: '',
          fileSize: '',
          fileName: '',
        },
      },
      noteFormRules: {
        name: [{ required: true, message: '请选择笔记类型', trigger: 'blur' }],
        type: [
          { required: true, message: '请输入笔记名称', trigger: 'change' },
        ],
        text: [{ required: true, message: '请输入笔记内容', trigger: 'blur' }],
      },
      /* 笔记查看 */
      isLookDialog: false,
      key: '',
      noteName: '',
      noteContent: '',
      /* 编辑 */
      isEdit: '',
    };
  },
  mounted() {
    this.getNoteList();
  },
  methods: {
    handleChangePage() {},
    richTextChange(str) {
      this.noteForm.text = str;
    },
    /* 笔记 */
    async openNoteDialog(str, row) {
      // console.log('str: ', str);
      let url = '/teach/student/findOneNote';
      if (row) {
        url = `/teach/student/findOneNote?notesId=${row.id}`;
      }
      this.isNoteDialog = true;
      if (str == 'edit') {
        this.isEdit = 'edit';
        const { data } = await this.$axios.get(url);
        if (data.code !== 200) {
          return this.$message.error(data.message);
        }
        const res = await this.$axios.get(data.data.url);
        if (res.status !== 200) {
          return this.$message.error('获取内容失败');
        }
        this.noteForm.type = data.data.type;
        this.noteForm.name = data.data.name;
        this.noteForm.text = res.data;
        this.noteForm.id = data.data.id;
        // console.log('res.data: ', res.data);
        this.$nextTick(() => {
          this.$refs.richRef.updateEditorData();
        });
        // console.log('this.$refs.rechid: ', this.$refs.richRef);
      }

      /* 结合：detail进行回显 */
      // this.refs.rechid.updateEditorData();
    },
    closeNoteDialog() {
      this.isNoteDialog = false;
      this.$refs.noteRef.resetFields();
      this.$refs.richRef.clearTxt();
    },
    /* 获取上传凭证 */
    /* 获取上传凭证 */
    getUploadToken() {
      this.$axios.post('/teach/courseware/ossSignature').then((res) => {
        if (res.data.code !== 200) {
          return this.$message.error('获取签名失败');
        }
        // console.log('res.data: ', res.data);
        /* 2.获取响应数据 */
        this.editNote(res.data.data);
      });
    },
    /* 编辑笔记 */
    async editNote(list) {
      let txt = this.noteForm.text;
      let file = new Blob([txt], { type: 'text/plain;charset=utf-8' });
      let fileName = 'noteRichText.txt';
      fileName = fileUtil.getFileName(fileName, list.dir);
      const fd = new FormData();
      fd.append('key', fileName);
      fd.append('policy', list.policy);
      fd.append('OSSAccessKeyId', list.accessId);
      fd.append('success_action_status', '200');
      fd.append('signature', list.signature);
      fd.append('file', file, `noteRichText.txt`);
      const config = {
        headers: {
          'Content-Type': 'multipart/from-data',
          x: 'y',
        },
      };

      const { status } = await this.$axios.post(list.host, fd, config);
      if (status !== 200) {
        return this.$message.error('新建笔记失败');
      }
      /* 发送请求 */
      this.noteForm.annexDTO.objectname = fileName;
      this.noteForm.annexDTO.fileSize = file.size;
      this.noteForm.annexDTO.fileName = 'noteRichText.txt';
      // console.log('this.noteForm: ', this.noteForm);
      let url = '/teach/student/addNotes';
      if (this.isEdit == 'edit') {
        url = '/teach/student/editNotes';
      }
      const { data } = await this.$axios.post(url, this.noteForm);
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.$message.success(data.message);
      this.getNoteList();
      this.closeNoteDialog();
    },
    async openLookDialog({ id }) {
      this.isLookDialog = true;
      const { data } = await this.$axios.get(
        `/teach/student/findOneNote?notesId=${id}`
      );
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      const res = await this.$axios.get(data.data.url);
      if (res.status !== 200) {
        return this.$message.error('获取内容失败');
      }

      this.noteName = data.data.name;
      this.noteContent = res.data;
    },
    closeLookDialog() {
      this.isLookDialog = false;
    },
    /* 获取笔记 */
    async getNoteList() {
      const query = {
        page: this.current,
        rows: this.pageSize,
      };

      this.key == '' ? (query.key = '') : (query.key = this.key);
      // console.log('query.key: ', query);

      const { data } = await this.$axios.get('/teach/student/findNotes', {
        params: query,
      });
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.tableData = data.data.items;
    },

    /* 我已掌握 */
    masterNote({ id }) {
      this.$confirm('是否删除该笔记？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const { data } = await this.$axios.post(
            `/teach/student/delNotes?NoteId=${id}`
          );
          if (data.code !== 200) {
            return this.$message.error(data.message);
          }
          this.$message.success(data.message);
          this.getNoteList();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除',
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.note {
  margin: 16px auto;
  padding-bottom: 16px;
  width: 1080px;
  border-radius: 4px;
  background: #ffffff;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #cccccc;
    .icon {
      margin-right: 8px;
      margin-top: 4px;
      color: #409eff;
    }
    h2 {
      font-size: 24px;
      color: #333333;
    }
  }
  .form-box {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px;
  }
  // .note-box {

  // }
}
.el-pagination {
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
/deep/.zksc-dialog {
  .el-dialog {
    min-width: 600px;
  }
  .el-dialog__header {
    height: 70px !important;
    background: #409eff !important;
    padding: 0 !important;
    line-height: 70px !important;
    text-align: center !important;
    .el-dialog__title {
      color: #ffffff !important;
      font-size: 24px !important;
    }
    .el-dialog__headerbtn {
      top: 30px;
      right: 20px;
    }
    .el-dialog__close {
      color: #fff !important;
    }
  }
  // .el-form {
  //   width: 550px;
  //   margin: 0 auto;
  //   .el-select {
  //     width: 470px;
  //   }
  // }

  // .btn {
  //   margin-top: 30px;
  //   display: flex;
  //   justify-content: center;
  // }
}
.dialog-content {
  h4 {
    text-align: center;
    color: #333333;
    font-size: 16px;
  }
  .note-content {
    margin-top: 16px;
    padding: 16px;
    min-height: 260px;
    border-radius: 4px;
    border: 1px solid #666666;
  }
  .btn-group {
    margin-top: 30px;
  }
}
.el-select {
  width: 100%;
}
</style>
