<template>
  <div class="zksc-courseManage">
    <!-- <SideMenu></SideMenu> -->
    <div class="zksc-courseManage-content">
      <div class="zksc-courseManage-title">
        <h5>课程信息</h5>
        <el-button type="primary" @click="toCreateCourse">新建课程</el-button>
      </div>
      <!-- <el-empty
        description="暂时没有课程"
        v-if="courseTable.length == 0"
      ></el-empty> -->
      <div>
        <el-table :data="courseTable" style="width: 100%" stripe>
          <!-- <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>-->
          <el-table-column
            label="序号"
            type="index"
            width="50px"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="课程名称"
            width="150"
          ></el-table-column>
          <el-table-column prop="text" label="课程介绍" show-overflow-tooltip>
            <!-- <template slot-scope="scope">
              <div v-html="scope.row.text"></div>
            </template> -->
          </el-table-column>
          <el-table-column label="操作" width="260" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="editCourse(scope.row)"
                >编辑</el-button
              >
              <el-button type="info" size="mini" @click="lookCourse(scope.row)"
                >查看</el-button
              >
              <el-button
                type="warning"
                size="mini"
                @click="openDeleteDialog(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          border
          layout="prev, pager, next, jumper"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="currentPageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import SideMenu from '../../sideMenu.vue';
export default {
  components: {
    // SideMenu,
  },
  data() {
    return {
      courseTable: [],
      currentPage: 1,
      pageSize: 10,
      total: 100,
    };
  },
  mounted() {
    this.getCourseTable();
  },
  methods: {
    /**
     *  获取课程列表数据
     */
    getCourseTable() {
      this.courseTable = [];
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$axios
        .get(`/teach/course/courseList`, {
          params: { num: this.currentPage, sum: this.pageSize },
        })
        .then((res) => {
          let { data } = res;
          if (data.code !== 200) {
            setTimeout(() => {
              loading.close();
            }, 500);
            return this.$message.error(data.message);
          }
          if (data.data.records.length == 0) {
            setTimeout(() => {
              loading.close();
            }, 500);
            if (this.currentPage !== 1) {
              this.currentPage = this.currentPage - 1;
              this.getCourseTable();
            } else {
              this.courseTable = data.data.records;
              this.total = data.data.total;
              return this.$message.warning('暂时没有课程');
            }
          }

          this.courseTable = data.data.records;
          this.total = data.data.total;

          // this.courseTable.map((v) => {
          //   if (v.text) {
          //     this.$axios.get(v.text).then((res) => {
          //       if (res.status !== 200) {
          //         return this.$message.error('获取内容失败');
          //       }
          //       this.$set(v, 'text', res.data);
          //     });
          //   }
          // });
          setTimeout(() => {
            loading.close();
          }, 500);
        });
    },
    /**
     * 新建课程
     */
    // TODO
    toCreateCourse() {
      sessionStorage.setItem('choose', 'courseDesign');
      this.$router.push({ path: '/courseDesign' });
    },
    /**
     * 查看课程
     */
    lookCourse(item) {
      // sessionStorage.setItem('couresname', item.name);
      // sessionStorage.setItem('courescontent', item.text);
      // 携带课程id
      this.$router.push({
        path: '/lookCourse',
        query: {
          courseId: item.id,
          name: item.name,
        },
      });
    },
    /**
     * 编辑课程
     */
    editCourse(courseId) {
      this.$router.push({
        path: '/courseDesign',
        query: {
          status: 'edit',
          courseId: courseId.id,
        },
      });
    },
    /**
     * 删除课程
     */
    openDeleteDialog(courseId) {
      this.$confirm('删除后不可恢复，确定删除此课程么？', '删除课程', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // type: 'warning',
      })
        .then(() => {
          this.$axios
            .delete('/teach/course/delete', {
              params: { courseId: courseId.id },
            })
            .then((res) => {
              let { data } = res;
              if (data.code !== 200) {
                return this.$message.error('删除课程失败' + data.message);
              }

              this.$message.success('删除成功');
              this.getCourseTable();
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    /**
     * 当前页改变获取数据
     */
    currentPageChange(currentPage) {
      this.currentPage = currentPage;
      this.getCourseTable();
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-courseManage {
  min-height: calc(100vh - 56px - 56px - 30px);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  background: #f0f2f5;
  padding: 16px 22px;
  .zksc-courseManage-content {
    width: calc(100% - 300px - 22px);
    background-color: #fff;
    border-radius: 4px;
    flex: 1;
    .zksc-courseManage-title {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h5 {
        font-size: 16px;
        font-weight: normal;
        padding-left: 16px;
        font-weight: 600;
      }
      .el-button {
        margin-right: 16px;
      }
    }
  }
}
.el-table {
  padding: 16px;
}

.el-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
<style lang="less">
/* 此部分内容将修改全局组件样式 */
.el-message-box {
  border: 0 !important;
  border-radius: 0 !important;
  width: 50% !important;
  .el-message-box__header {
    height: 70px;
    background-color: #409eff;
    padding: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #fff;
    }
  }
  .el-message-box__message {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }
  .el-message-box__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    .el-button {
      margin-right: 10px;
    }
  }
}
</style>
