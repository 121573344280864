<template>
  <div class="workspace">
    <div class="top">
      <div class="student-msg">
        <img
          @click="goshequ"
          :src="
            userMsg.headPicture === 'null'
              ? '/img/avatar.4e4aa2c3.jpg'
              : userMsg.headPicture
          "
          alt
          class="avatar"
        />
        <!-- <img
          v-else
          class="avatar"
          src="../../../assets/images/avatar.jpg"
          alt
        /> -->
        <div class="msg">
          <p>{{ userMsg.realname }}</p>
          <p>
            <icon-svg
              iconClass="nan"
              v-if="userMsg.sex"
              style="font-size: 10px; margin-left: 6px; color: #409eff"
            ></icon-svg>
            <icon-svg
              iconClass="nv"
              v-else
              style="font-size: 10px; margin-left: 6px; color: #ff9ed1"
            ></icon-svg>
            <!-- <span>{{userMsg.sex}}</span> -->
          </p>
          <p v-if="userMsg.role == 'student'">学生</p>
          <p>{{ userMsg.className }}</p>
        </div>
      </div>
      <div class="goStuday" @click="goStuday">
        <div class="divv">开始</div>
        <div>学习</div>
        <i class="light"></i>
      </div>

      <div class="item" @click="toclass">
        <ModulsItem
          class="items"
          title1="数字创意建模"
          title2="课程完成率"
          :value="
            userMsg.accomplishment ? userMsg.accomplishment + '%' : 0 + '%'
          "
          :progress="userMsg.accomplishment"
        ></ModulsItem>
      </div>
      <div class="item" @click="toshoucang">
        <ModulsItem
          class="items"
          bgi="1"
          title1="我的收藏"
          title2="当前总数"
          :value="userMsg.enshrine ? userMsg.enshrine + '项' : 0 + '项'"
        ></ModulsItem>
      </div>
      <div class="item" @click="tocuotiji">
        <ModulsItem
          class="items"
          bgi="2"
          title1="历史错题"
          title2="总错题数"
          :value="
            userMsg.questionCount ? userMsg.questionCount + '项' : 0 + '项'
          "
        ></ModulsItem>
      </div>
      <div class="item" @click="tomonikao">
        <ModulsItem
          class="items"
          bgi="3"
          title1="我的成绩"
          :title2="userMsg.examName === 'null' ? '暂无' : userMsg.examName"
          :value="userMsg.grade === -1 ? '暂无' : userMsg.grade + '分'"
        ></ModulsItem>
      </div>
    </div>
    <div class="bottom">
      <Part class="b-item pmin-width" title="历史错题">
        <ul class="bottom-ul">
          <li
            class="none"
            v-if="
              userMsg.hisquestionDTOS && userMsg.hisquestionDTOS.length == 0
            "
          >
            暂无记录
          </li>
          <li
            v-else
            :class="{ 'li-active': isError == i }"
            v-for="(item, i) in userMsg.hisquestionDTOS"
            :key="item.id"
            @click="toErrorQuestion(item)"
          >
            {{ i + 1 }}. {{ item.questionName }}
          </li>
        </ul>
      </Part>
      <Part class="b-item emin-width" title="综合能力">
        <div class="echart-box">
          <div style="width: auto; height: 300px" id="echart"></div>

          <div class="pos pos1"><span class="note-icon1"></span>软件技能</div>

          <div class="pos pos2">
            <span class="note-icon1 c2"></span>考核成绩
          </div>
          <div class="pos pos3">
            <span class="note-icon1 c5"></span>实践技能
          </div>
          <div class="pos pos4">
            <span class="note-icon1 c3"></span>学习能力
          </div>
          <div class="pos pos5">
            <span class="note-icon1 c4"></span>理论知识
          </div>

          <ul class="notes">
            <li v-for="(item, i) in tags" :key="i">
              <span :class="`c${i + 1}`"></span>
              {{ item.name }} {{ item.grade }}
            </li>
            <!-- <li>
              <span class="c2"></span>实操技能
            </li>
            <li>
              <span class="c3"></span>理论知识
            </li>
            <li>
              <span class="c4"></span>学习能力
            </li>
            <li>
              <span class="c5"></span>考核成绩
            </li>-->
          </ul>
        </div>
      </Part>
      <div class="right">
        <Part class="b-item" title="每周学霸排行榜" style="min-height: 166px">
          <div class="soft">
            <!-- v-if="userMsg.resUsers && userMsg.resUsers.length==0" -->
            <p
              class="none"
              v-if="userMsg.resUsers && userMsg.resUsers.length == 0"
            >
              暂无排行
            </p>
            <a
              v-else
              v-for="item in userMsg.resUsers"
              :key="item.id"
              :href="item.url === 'null' ? 'http://www.suczone.com' : item.url"
              target="_blank"
            >
              <img
                :src="
                  item.heading === 'null'
                    ? '/img/avatar.4e4aa2c3.jpg'
                    : item.heading
                "
                class="avatar"
                alt
              />
              <p>{{ item.realname }}</p>
            </a>
          </div>
        </Part>
        <Part class="b-item btm" title="我的足迹">
          <ul class="bottom-ul hg">
            <li
              class="none"
              v-if="userMsg.visitDTOS && userMsg.visitDTOS.length == 0"
            >
              暂无记录
            </li>
            <li
              v-else
              :class="{ 'li-active': isChoice == i }"
              v-for="(item, i) in userMsg.visitDTOS"
              :key="item.id"
              @click="handleLi(i)"
            >
              <span>{{ i + 1 }}. {{ item.name }}</span>
              <span class="play" @click="toPlay(item)">
                <icon-svg icon-class="bofang2" class="icon"></icon-svg>立即播放
              </span>
            </li>
          </ul>
        </Part>
      </div>
    </div>
  </div>
</template>
<script>
import IconSvg from '../../icon/iconSvg.vue';
import ModulsItem from './modulesItem/modulesItem.vue';
import Part from './part/part.vue';
export default {
  components: {
    ModulsItem,
    Part,
    IconSvg,
  },
  data() {
    return {
      userMsg: {},
      echartData: [],
      tags: [],
      isError: -1,
      isChoice: -1,
      myUrl: null,
    };
  },
  mounted() {
    this.getUsersMsg();
  },
  methods: {
    // 去学习
    goStuday() {
      this.$router.push({
        path: '/ClassCourse',
      });
    },
    // 去社区
    goshequ() {
      if (this.myUrl === 'null') {
        window.open('http://www.suczone.com');
      } else {
        window.open(this.myUrl);
      }
    },
    toclass() {
      this.$router.push({
        path: '/ClassCourse',
      });
    },
    toshoucang() {
      this.$router.push({
        path: '/collection',
      });
    },
    tocuotiji() {
      this.$router.push({
        path: '/errorCollection',
      });
    },
    tomonikao() {
      this.$router.push({
        path: '/exam',
      });
    },
    echartsInit() {
      const myChart = this.$echarts.init(document.getElementById('echart'));

      // 指定图表的配置项和数据
      const option = {
        grid: {
          width: '200px',
          height: '200px',
        },
        color: ['#FCD66F', '#FFCCC7', '#FFBB96', '#D3ADF7', '#91D5FF'],
        title: {
          // text: '基础雷达图',
        },
        legend: {
          orient: 'vertical',
          x: 'right', //可设定图例在左、右、居中
          y: 'center', //可设定图例在上、下、居中
          padding: [0, 50, 0, 0], //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]

          // icon: 'rect',
          // data: ['软件技能', '实践技能', '理论知识', '学习能力', '考核成绩'],
          // data: [
          //   { /* icon: 'rect',  */ name: '软件技能', color: '#FCD66F' },
          //   { /* icon: 'rect', */ name: '实践技能', color: '#FFCCC7' },
          //   { /* icon: 'rect', */ name: '理论知识', color: '#FFBB96' },
          //   { /* icon: 'rect', */ name: '学习能力', color: '#D3ADF7' },
          //   { /* icon: 'rect', */ name: '考核成绩', color: '#91D5FF' },
          // ],
        },
        radar: {
          // shape: 'circle',
          center: ['50%', '50%'],
          radius: 100,
          indicator: [
            { name: '', max: 100 },
            { name: '', max: 100 },
            { name: '', max: 100 },
            { name: '', max: 100 },
            { name: '', max: 100 },
            // { name: '软件技能', max: 100 },
            // { name: '考核成绩', max: 100 },
            // { name: '学习能力', max: 100 },
            // { name: '理论知识', max: 100 },
            // { name: '实践技能', max: 100 },
          ],
          splitNumber: 3, // 雷达图圈数设置
          name: {
            textStyle: {
              color: ' #000000',
            },
          },
          /* 区域颜色 */
          splitArea: {
            areaStyle: {
              color: 'rgb(233,249,255)',
            },
          },
          /* 中间射线颜色 */
          axisLine: {
            lineStyle: {
              color: 'rgba(131,141,158,.1)',
            },
          },
          /* 网格线颜色 */
          splitLine: {
            show: true,
            lineStyle: {
              width: 1,
              color: 'rgba(131,141,158,.1)', // 设置网格的颜色
            },
          },
        },
        series: [
          {
            type: 'radar',
            axisLabel: {
              show: false,
            },
            data: [
              {
                // value: [10, 20, 30, 40, 50],
                value: this.echartData,

                /* 区域颜色 */
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: 'rgb(185,251,231)', // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: 'rgb(152,178,251)', // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
                /* 边框颜色 */
                itemStyle: {
                  normal: {
                    color: 'rgba(255,225,255,0)',
                    lineStyle: {
                      color: 'rgba(255,225,255,0)',
                    },
                  },
                },
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。

      this.$nextTick(() => {
        myChart.setOption(option);
      });
      setTimeout(function () {
        window.onresize = function () {
          myChart.resize();
        };
      }, 200);
    },
    /* 获取用户信息 */
    async getUsersMsg() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let { data } = await this.$axios.get('/teach/student/mydetail');
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.userMsg = data.data;
      this.myUrl = data.data.scUrl;
      // console.log('this.userMsg: ', this.userMsg);
      if (data.data.fivenergy) {
        /* software 软件 */
        this.echartData.push(data.data.fivenergy.software);
        /* 考核 */
        this.echartData.push(data.data.fivenergy.exampow);
        /* learning 学习能力 */
        this.echartData.push(data.data.fivenergy.learning);
        /* theoretical 理论*/
        this.echartData.push(data.data.fivenergy.theoretical);
        /* practical 实操 */
        this.echartData.push(data.data.fivenergy.practical);
      } else {
        this.echartData = [60, 60, 60, 60, 60];
      }

      // console.log('this.echartData: ', this.echartData);

      let tags = ['软件技能', '考核成绩', '学习能力', '理论知识', '实践技能'];
      tags.map((v, i) => {
        this.tags.push({
          name: v,
          grade: this.echartData[i],
        });
      });
      setTimeout(() => {
        loading.close();
      }, 100);
      this.echartsInit();
      // console.log('this.echartData: ', this.echartData);
      // console.log('this.tags', this.tags);
    },
    /* 去播放 */
    toPlay(item, i) {
      this.isChoice = i;
      // this.$router.push({
      //   path: `/resourcedetail?id=${item.rescourseId}`,
      // });
      this.$router.push({
        path: '/resourcedetail',
        query: {
          id: item.rescourseId,
          menuId: item.menuId,
          tabNum: 1,
        },
      });
    },
    handleLi(i) {
      this.isChoice = i;
    },
    hadleError(i) {
      this.isError = i;
    },
    toErrorQuestion({ id }) {
      this.$router.push({
        path: '/errorCollection',
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.workspace {
  box-sizing: border-box;
  padding: 16px 32px;
  .top {
    display: flex;
    .student-msg {
      display: flex;
      align-items: center;
      margin-right: 16px;
      width: 20%;
      min-width: 312px;
      height: 220px;
      border-radius: 4px;
      background: #ffffff;
      p {
        margin: 4px 0;
      }
      .avatar {
        margin: 10px 20px 10px 10px;
        width: 128px;
        height: 128px;
        border-radius: 128px;
      }
      .msg {
        display: flex;
        flex-direction: column;
        justify-content: center;

        color: #666666;
        font-size: 14px;
      }
    }
    .goStuday {
      overflow: hidden;
      cursor: pointer;
      width: 220px;
      height: 220px;
      // background: rgb(91, 93, 255);
      background-image: linear-gradient(to bottom right, #5b5dff, #1c89ee);
      margin-right: 16px;
      border-radius: 4px;
      position: relative;
      display: block;
      font-size: 48px;
      text-align: center;
      color: #fff;
      .divv {
        margin-top: 40px;
      }
    }
    .light {
      // background: red;
      // opacity: 0;
      cursor: pointer;
      position: absolute;
      left: -220px;
      top: 0;
      width: 200px;
      height: 220px;
      background-image: -moz-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0)
      );
      background-image: -webkit-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0)
      );
      transform: skewx(-25deg);
      -o-transform: skewx(-25deg);
      -moz-transform: skewx(-25deg);
      -webkit-transform: skewx(-25deg);
      -webkit-animation: example 1.2s both infinite;
      -moz-animation: example 1.2s both infinite;
      -ms-animation: example 1.2s both infinite;
      animation: example 1.2s both infinite;
    }
    @keyframes example {
      0% {
        left: -220px;
      }
      25% {
        left: -220px;
      }
      50% {
        left: -220px;
      }
      75% {
        left: -220px;
      }
      100% {
        left: 220px;
      }
    }
    // .goStuday:hover .light {
    //   opacity: 1;
    //   left: 204px;
    //   -moz-transition: 0.5s;
    //   -o-transition: 0.5s;
    //   -webkit-transition: 0.5s;
    //   transition: 0.5s;
    // }

    .item {
      margin-right: 16px;
      min-width: 180px;
      width: 16%;
      &:last-child {
        margin: 0;
      }
    }
    .item:hover {
      cursor: pointer;
    }
    .items {
      margin-right: 16px;
      width: 100%;
      &:last-child {
        margin: 0;
      }
    }
  }
  .bottom {
    margin-top: 16px;
    display: flex;
    height: calc(100vh - 30px - 56px - 56px - 220px - 32px - 16px);
    // justify-content: space-around;
    .b-item {
      // min-width: 610px;
      width: 33%;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .bottom-ul {
        margin-top: 16px;
        height: calc(100% - 50px - 32px);

        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
        overflow: auto;
        .none {
          justify-content: center;
          text-align: center;
        }
        li {
          margin: 0 10px;
          padding: 0 10px;
          height: 56px;
          line-height: 56px;
          color: #333333;
          font-size: 14px;
          border-radius: 4px;
          cursor: pointer;
        }
        li:hover {
          background-color: #f1f3f5;
        }
        .li-active {
          background-color: #f1f3f5;
        }
      }
    }
    .right {
      width: 33.33%;
      min-width: 300px;
      display: flex;
      flex-direction: column;

      .b-item {
        flex-shrink: 1;
        width: 100%;
      }
      .avatar {
        margin: 10px 20px 10px 10px;
        width: 64px;
        height: 64px;
        border-radius: 130px;
      }
      p {
        margin: 0 0 10px 0;
      }
      .soft {
        display: flex;
        justify-content: space-around;
        height: 116px;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #333;
          cursor: pointer;
        }
        .none {
          text-align: center;
          line-height: 116px;
          font-size: 14px;
        }
      }
      .btm {
        margin-top: 16px;
        min-height: calc(100% - 166px - 16px);
      }
      .hg {
        height: calc(100% - 50px - 32px);
        overflow: auto;
        li {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .play {
            display: flex;
            align-items: center;
            color: #409eff;
            .icon {
              font-size: 10px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    .pmin-width {
      min-width: 312px;
    }
    .emin-width {
      min-width: 460px;
    }
    .echart-box {
      // display: flex;
      position: relative;
      overflow: hidden;
      min-width: 460px;

      .notes {
        // position: absolute;
        // right: 10px;
        // top: 110px;
        display: flex;
        flex-wrap: wrap;

        li {
          display: flex;
          align-items: center;
          margin: 6px 0 0 20px;
          color: #000000;
          font-size: 14px;
          span {
            display: inline-block;
            margin-right: 6px;
            width: 16px;
            height: 16px;
          }
        }
      }

      .pos {
        position: absolute;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #000000;
        white-space: nowrap;
      }
      .pos1 {
        top: 16px;
        left: 42%;
      }
      .note-icon1 {
        display: inline-block;
        margin-right: 6px;
        width: 16px;
        height: 16px;
        background: #fcd66f;
      }
      .pos2 {
        top: 105px;
        left: 12%;
      }
      .pos3 {
        top: 105px;
        left: 72%;
      }
      .pos4 {
        top: 240px;
        left: 20%;
      }
      .pos5 {
        top: 240px;
        left: 60%;
      }
      .c1 {
        background: #fcd66f;
      }
      .c2 {
        background: #91d5ff;
      }
      .c3 {
        background: #ffccc7;
      }
      .c4 {
        background: #d3adf7;
      }
      .c5 {
        background: #ffbb96;
      }
    }
  }
}
</style>
