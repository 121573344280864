<template>
  <div class="my-message">
    <div class="header">
      <img
        class="avatar"
        :src="
          studentMsg.url === 'null'
            ? '/img/avatar.4e4aa2c3.jpg'
            : studentMsg.url
        "
        alt
      />
      <!-- <img v-else class="avatar" src="../../../assets/images/avatar.jpg" alt /> -->
      <div class="msg-box">
        <div class="msg-item">
          <span class="name">{{ studentMsg.name }}</span>
          <icon-svg
            v-if="studentMsg.sex == '0'"
            icon-class="nv"
            class="icon"
            style="color: red"
          ></icon-svg>
          <icon-svg
            v-else
            icon-class="nan"
            class="icon"
            style="color: #409eff"
          ></icon-svg>
          <span class="logintime">{{ studentMsg.time }}</span>
        </div>
        <div class="msg-item">
          <icon-svg icon-class="geren" class="icon"></icon-svg>
          <span>学生</span>
        </div>
        <div class="msg-item">
          <icon-svg icon-class="boda" class="icon"></icon-svg>
          <span>{{ studentMsg.phone }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 软件技能 -->
      <div class="content-part">
        <h3 class="part-title">职业技能</h3>
        <div class="part2">
          <div class="part2-title">
            <div>
              <icon-svg icon-class="weixiu" class="icon"></icon-svg>
              <span>软件技能</span>
            </div>
            <div class="addbtn" @click="openSoftWareDialog">
              <icon-svg icon-class="jiahaocu" class="iconadd"></icon-svg>
            </div>
          </div>
          <div class="skill-box">
            <!-- {{studentMsg.soft.length == 0}} -->
            <div
              class="none"
              v-if="studentMsg.soft && studentMsg.soft.length == 0"
            >
              暂无
            </div>
            <template v-else>
              <div
                class="skill-item"
                v-for="item in studentMsg.soft"
                :key="item.id"
              >
                <span>{{ item.softwareType }}</span>
                <el-progress :percentage="item.softwareAbility"></el-progress>
                <span @click="deleteItem(item.id)">
                  <icon-svg class="del-icon" icon-class="shanchu"></icon-svg>
                </span>
              </div>
            </template>
          </div>
          <div class="part2-title">
            <div>
              <icon-svg icon-class="jiangbei" class="icon"></icon-svg>
              <span>擅长领域</span>
            </div>
            <div class="addbtn" @click="openFieldDialog">
              <icon-svg icon-class="jiahaocu" class="iconadd"></icon-svg>
            </div>
          </div>
          <div class="field-box">
            <div
              class="none"
              v-if="studentMsg.field && studentMsg.field.length == 0"
            >
              暂无
            </div>
            <template v-else>
              <div
                class="field-box-item"
                v-for="(item, i) in studentMsg.field"
                :key="i"
              >
                <ul class="field-tags">
                  <li class="tag" v-for="(item2, i2) in item.field" :key="i2">
                    {{ item2 }}
                  </li>
                </ul>
                <div class="field-content">{{ item.introduction }}</div>
                <div class="btn-group">
                  <div class="btn" @click="openFieldDialog('edit', item)">
                    <icon-svg
                      icon-class="bianji_2"
                      style="font-size: 10px; color: #333333"
                    ></icon-svg>
                  </div>
                  <div class="btn" @click="deleteItem(item.id)">
                    <icon-svg
                      icon-class="shanchu"
                      style="font-size: 10px; color: #d76780"
                    ></icon-svg>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- 工作经验 -->
      <div class="content-part">
        <h3 class="part-title">工作经验</h3>
        <div class="addbtn" @click="openExpDialog">
          <icon-svg icon-class="jiahaocu" class="iconadd"></icon-svg>
        </div>
        <div class="part2">
          <div class="field-box">
            <div
              class="none"
              v-if="studentMsg.job && studentMsg.job.length == 0"
            >
              暂无
            </div>
            <template v-else>
              <div
                class="field-box-item"
                v-for="(item, i) in studentMsg.job"
                :key="i"
              >
                <div class="work-title">
                  <div>
                    <span class="work-pos">{{ item.position }}</span>
                    <span class="work-company">{{ item.unit }}</span>
                    <span class="work-time"
                      >{{ item.postTime | formatDate(0) }} ～
                      {{ item.endTime | formatDate(0) }}</span
                    >
                  </div>
                </div>
                <div class="field-content">{{ item.job }}</div>
                <div class="btn-group">
                  <div class="btn" @click="openExpDialog('edit', item)">
                    <icon-svg
                      icon-class="bianji_2"
                      style="font-size: 10px; color: #333333"
                    ></icon-svg>
                  </div>
                  <div class="btn" @click="deleteItem(item.id)">
                    <icon-svg
                      icon-class="shanchu"
                      style="font-size: 10px; color: #d76780"
                    ></icon-svg>
                  </div>
                </div>
              </div>
            </template>
            <!-- <div class="field-box-item">
              <div class="work-title">
                <div>
                  <span class="work-pos">UI设计师（兼交互）</span>
                  <span class="work-company">杭州追梦网络科技有限公司</span>
                  <span class="work-time">2020-01 ～ 2021-05</span>
                </div>
              </div>
              <div class="field-content">想当年，我一个人在铜锣湾</div>
              <div class="btn-group">
                <div class="btn">
                  <icon-svg icon-class="bianji_2" style="font-size:10px;color:#333333;"></icon-svg>
                </div>
                <div class="btn">
                  <icon-svg icon-class="shanchu" style="font-size:10px;color:#D76780;"></icon-svg>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <!-- 教育背景 -->
      <div class="content-part">
        <h3 class="part-title">教育背景</h3>
        <div class="addbtn" @click="openEducationDialog">
          <icon-svg icon-class="jiahaocu" class="iconadd"></icon-svg>
        </div>
        <div class="part2">
          <div class="field-box">
            <div
              class="none"
              v-if="studentMsg.school && studentMsg.school.length == 0"
            >
              暂无
            </div>
            <template v-else>
              <div
                class="field-box-item"
                v-for="item in studentMsg.school"
                :key="item.id"
              >
                <div class="education-title">
                  <div>
                    <span class="education-major">{{ item.profession }}</span>
                    <span class="education-">{{ item.education }}</span>
                  </div>
                </div>
                <div class="education-title2">
                  <span class="school">{{ item.school }}</span>
                  <span
                    >{{ item.educationTime | formatDate(0) }} ～
                    {{ item.goTime | formatDate(0) }}</span
                  >
                </div>
                <div class="field-content">{{ item.learningIntroduction }}</div>
                <div class="btn-group">
                  <div class="btn" @click="openEducationDialog('edit', item)">
                    <icon-svg
                      icon-class="bianji_2"
                      style="font-size: 10px; color: #333333"
                    ></icon-svg>
                  </div>
                  <div class="btn" @click="deleteItem(item.id)">
                    <icon-svg
                      icon-class="shanchu"
                      style="font-size: 10px; color: #d76780"
                    ></icon-svg>
                  </div>
                </div>
              </div>
            </template>
            <!-- <div class="field-box-item">
              <div class="work-title">
                <div>
                  <span class="work-pos">UI设计师（兼交互）</span>
                  <span class="work-company">杭州追梦网络科技有限公司</span>
                  <span class="work-time">2020-01 ～ 2021-05</span>
                </div>
              </div>
              <div class="field-content">想当年，我一个人在铜锣湾</div>
              <div class="btn-group">
                <div class="btn">
                  <icon-svg icon-class="bianji_2" style="font-size:10px;color:#333333;"></icon-svg>
                </div>
                <div class="btn">
                  <icon-svg icon-class="shanchu" style="font-size:10px;color:#D76780;"></icon-svg>
                </div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <!-- 个人荣誉 -->
      <div class="content-part">
        <h3 class="part-title">个人荣誉</h3>
        <div class="addbtn" @click="openHonorDialog">
          <icon-svg icon-class="jiahaocu" class="iconadd"></icon-svg>
        </div>
        <ul class="list-box">
          <div
            class="none"
            v-if="studentMsg.award && studentMsg.award.length == 0"
          >
            暂无
          </div>
          <template v-else>
            <li
              class="list-item"
              v-for="(item, i) in studentMsg.award"
              :key="i"
            >
              <span class="name">{{ item.matchName }}</span>
              <span class="time">{{ item.awardedTime | formatDate(0) }}</span>
              <span class="prize">{{ item.awards }}</span>
              <div class="deletebtn" @click="deleteItem(item.id)">
                <icon-svg icon-class="shanchu"></icon-svg>
              </div>
            </li>
          </template>
          <!-- <li class="list-item">
            <span class="name">湖南省吐槽大赛</span>
            <span class="time">2020-01 ～ 2021-05</span>
            <span class="prize">湖南省吐槽大赛金奖</span>
            <div class="deletebtn">
              <icon-svg icon-class="shanchu"></icon-svg>
            </div>
          </li>-->
        </ul>
      </div>
      <!-- 作品链接 -->
      <div class="content-part">
        <h3 class="part-title">作品链接</h3>
        <div class="addbtn" @click="openWorksDialog">
          <icon-svg icon-class="jiahaocu" class="iconadd"></icon-svg>
        </div>

        <ul class="list-box">
          <div
            class="none"
            v-if="studentMsg.produce && studentMsg.produce.length == 0"
          >
            暂无
          </div>
          <template v-else>
            <li
              class="list-item"
              v-for="(item, i) in studentMsg.produce"
              :key="i"
            >
              <span class="name">{{ item.produce }}</span>
              <a class="link" target="_blank" :href="item.produceUrl">{{
                item.produceUrl
              }}</a>
              <div class="deletebtn" @click="deleteItem(item.id)">
                <icon-svg icon-class="shanchu"></icon-svg>
              </div>
            </li>
          </template>
          <!-- <li class="list-item">
            <span class="name">手绘四不像</span>
            <span class="link">www.abcdefg.com</span>
            <div class="deletebtn">
              <icon-svg icon-class="shanchu"></icon-svg>
            </div>
          </li>-->
        </ul>
      </div>
    </div>
    <!-- 软件能力 -->
    <el-dialog
      class="zksc-dialog"
      title="添加软件能力"
      :visible.sync="softSkill"
      :before-close="closeSoftWareDialog"
    >
      <el-form
        label-width="80px"
        :model="skillForm"
        :rules="skillFormRules"
        ref="skillRef"
      >
        <el-form-item label="软件类型" prop="softwareType">
          <el-select
            v-model="skillForm.softwareType"
            placeholder="请选择软件能力"
          >
            <el-option
              :label="item"
              :value="item"
              v-for="(item, i) in skillList"
              :key="i"
            ></el-option>
            <!-- <el-option label="ps" value="ps"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="软件能力">
          <el-slider v-model="skillForm.softwareAbility"></el-slider>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editSkill">确 定</el-button>
          <el-button @click="closeSoftWareDialog">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 擅长领域 -->
    <el-dialog
      class="zksc-dialog"
      :title="title"
      :visible.sync="isFiledDialog"
      :before-close="closeFieldDialog"
    >
      <el-form
        label-width="80px"
        :model="filedForm"
        :rules="filedFormRules"
        ref="filedRef"
      >
        <el-form-item label="擅长领域" prop="field">
          <el-input
            v-model="filedForm.tag"
            placeholder="请输入领域名称"
            @keyup.enter.native="keydown"
          ></el-input>
          <el-input
            v-if="false"
            v-model="filedForm.field"
            placeholder="请输入领域名称"
          ></el-input>
        </el-form-item>
        <el-tag
          effect="dark"
          v-for="(item, i) in tags"
          :key="i"
          closable
          @close="deleteFieldItem(i)"
          >{{ item }}</el-tag
        >
        <el-form-item label="个人简介">
          <el-input
            v-model="filedForm.introduction"
            placeholder="请输入个人简介"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="eidtField">确 定</el-button>
          <el-button @click="closeFieldDialog">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 工作经验 -->
    <el-dialog
      class="zksc-dialog"
      title="添加工作经验"
      :visible.sync="isExpDialog"
      :before-close="closeExpDialog"
    >
      <el-form
        label-width="80px"
        :model="expForm"
        :rules="expFormRules"
        ref="expRef"
      >
        <el-form-item label="单位名称" prop="unit">
          <el-input
            placeholder="例:浙江xxx网络科技有限公司"
            v-model="expForm.unit"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="department">
          <el-input
            placeholder="例:设计部"
            v-model="expForm.department"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-input
            placeholder="例:动画设计师"
            v-model="expForm.position"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="postTime">
          <el-date-picker
            v-model="expForm.postTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择开始日期"
          ></el-date-picker>
          <!-- <el-input placeholder="例:2020年2月-2023年1月" v-model="expForm.postTime"></el-input> -->
        </el-form-item>
        <el-form-item label="结束时间" prop="endTime">
          <el-date-picker
            v-model="expForm.endTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择结束日期"
          ></el-date-picker>
          <!-- <el-input placeholder="例:2020年2月-2023年1月" v-model="expForm.endTime"></el-input> -->
        </el-form-item>
        <el-form-item label="工作简介" prop="job">
          <el-input placeholder="请输入简介" v-model="expForm.job"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editExp">确 定</el-button>
          <el-button @click="closeExpDialog">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 教育经历 -->
    <el-dialog
      class="zksc-dialog"
      title="添加教育经历"
      :visible.sync="isEducationDialog"
      :before-close="closeEducationDialog"
    >
      <el-form
        label-width="80px"
        :model="educationForm"
        :rules="educationFormRules"
        ref="educationRef"
      >
        <el-form-item label="学校名称" prop="school">
          <el-input
            placeholder="例:浙江大学"
            v-model="educationForm.school"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业" prop="profession">
          <el-input
            placeholder="例:视觉传达"
            v-model="educationForm.profession"
          ></el-input>
        </el-form-item>
        <el-form-item label="学历" prop="education">
          <el-input
            placeholder="例:本科"
            v-model="educationForm.education"
          ></el-input>
        </el-form-item>
        <el-form-item label="开始时间" prop="educationTime">
          <el-date-picker
            v-model="educationForm.educationTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择开始日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="goTime">
          <el-date-picker
            v-model="educationForm.goTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            placeholder="选择结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="简介" prop="learningIntroduction">
          <el-input
            placeholder="请输入简介"
            v-model="educationForm.learningIntroduction"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editEducation">确 定</el-button>
          <el-button @click="closeEducationDialog">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 个人荣誉 -->
    <el-dialog
      class="zksc-dialog"
      title="添加个人荣誉"
      :visible.sync="isHonorDialog"
      :before-close="closeHonorDialog"
    >
      <el-form
        label-width="80px"
        :model="honorForm"
        :rules="honorFormRules"
        ref="honorRef"
      >
        <el-form-item label="赛事名称" prop="matchName">
          <el-input
            placeholder="例:xxx动画设计大赛"
            v-model="honorForm.matchName"
          ></el-input>
        </el-form-item>
        <el-form-item label="获奖时间" prop="awardedTime">
          <el-date-picker
            v-model="honorForm.awardedTime"
            type="date"
            placeholder="请选择获奖日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="奖项" prop="awards">
          <el-input
            placeholder="例:金牌动画师"
            v-model="honorForm.awards"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editHonor">确 定</el-button>
          <el-button @click="closeHonorDialog">取 消</el-button>
        </el-form-item>
      </el-form>
      <!-- TODO -->
    </el-dialog>

    <!-- 作品 -->
    <el-dialog
      class="zksc-dialog"
      title="添加作品链接"
      :visible.sync="isWorksDialog"
      :before-close="closeWorksDialog"
    >
      <el-form
        label-width="80px"
        :model="worksForm"
        :rules="worksFormRules"
        ref="worksRef"
      >
        <el-form-item label="作品名称" prop="produce">
          <el-input
            placeholder="例:任务模型"
            v-model="worksForm.produce"
          ></el-input>
        </el-form-item>
        <el-form-item label="作品链接" prop="produceUrl">
          <el-input
            placeholder="例:https://www.xxx.com/123123"
            v-model="worksForm.produceUrl"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editWorks">确 定</el-button>
          <el-button @click="closeWorksDialog">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '',
      studentMsg: {},
      /* 软件能力部分 */
      softSkill: false,
      skillList: [
        '3D Max',
        'KeyShot',
        'Maya',
        'C4D',
        'Rhino',
        'Marmoset Toolbag',
        'Subtance Painter',
        'Zbrush',
        'Sketch Up',
      ],
      skillForm: {
        softwareType: '',
        softwareAbility: 0,
      },
      skillFormRules: {
        softwareType: [
          { required: true, message: '请选择软件能力', trigger: 'change' },
        ],
      },
      /* 擅长领域 */
      isFiledDialog: false,
      tags: [],
      filedForm: {
        field: '',
        introduction: '',
      },
      filedFormRules: {
        field: [{ required: true, message: '请输入领域名称', trigger: 'blur' }],
      },
      /* 工作经验 */
      isExpDialog: false,
      expForm: {
        unit: '',
        department: '',
        position: '',
        postTime: '',
        endTime: '',
      },
      expFormRules: {
        unit: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
        department: [
          { required: true, message: '请输入部门名称', trigger: 'blur' },
        ],
        position: [{ required: true, message: '请输入职位', trigger: 'blur' }],
        postTime: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        endTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' },
        ],
        disable: [
          { required: true, message: '请输入工作简介', trigger: 'blur' },
        ],
      },
      /* 教育经历 */
      isEducationDialog: false,
      educationForm: {
        school: '',
        profession: '',
        education: '',
        educationTime: '',
        goTime: '',
        learningIntroduction: '',
      },
      educationFormRules: {
        school: [
          { required: true, message: '请输入学校名称', trigger: 'blur' },
        ],
        profession: [
          { required: true, message: '请输入专业名称', trigger: 'blur' },
        ],
        education: [{ required: true, message: '请输入学历', trigger: 'blur' }],
        educationTime: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        goTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' },
        ],
        learningIntroduction: [
          { required: true, message: '请输入简介', trigger: 'blur' },
        ],
      },
      /* 个人荣誉 */
      isHonorDialog: false,
      honorForm: {
        matchName: '',
        awardedTime: '',
        awards: '',
      },
      honorFormRules: {
        matchName: [
          { required: true, message: '请输入赛事名称', trigger: 'blur' },
        ],
        awardedTime: [
          { required: true, message: '请选择比赛时间', trigger: 'change' },
        ],
        awards: [
          { required: true, message: '请输入获得奖项', trigger: 'blur' },
        ],
      },
      /* 作品 */
      isWorksDialog: false,
      worksForm: {
        produce: '',
        produceUrl: '',
      },
      worksFormRules: {
        produce: [
          { required: true, message: '请输入作品名称', trigger: 'blur' },
        ],
        produceUrl: [
          { required: true, message: '请输入作品链接', trigger: 'blur' },
        ],
      },
    };
  },
  mounted() {
    this.getStudentMsg();
  },
  filters: {
    /* 格式化时间 */
    formatDate(time, format) {
      let date = new Date(time);

      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : (date.getMonth() + 1 + '').padStart(2, 0); //月份是从0开始的
      let day =
        date.getDate() > 9
          ? date.getDate()
          : (date.getDate() + '').padStart(2, 0);
      let hour =
        date.getHours() > 9
          ? date.getHours()
          : (date.getHours() + '').padStart(2, 0);
      let min = date.getMinutes()
        ? date.getMinutes()
        : (date.getMinutes() + '').padStart(2, 0);
      let sec = date.getSeconds()
        ? date.getSeconds()
        : (date.getSeconds() + '').padStart(2, 0);
      let newTime = '';
      if (format == 1) {
        newTime =
          year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
        return newTime;
      } else if (format == 0) {
        newTime = year + '-' + month + '-' + day;
        return newTime;
      }
    },
  },
  methods: {
    /* 获取学生信息 */
    // /teach/student/findIntroduction
    async getStudentMsg() {
      const { data } = await this.$axios.get('/teach/student/findIntroduction');
      if (data.code !== 200) return this.$message.error(data.message);

      this.studentMsg = data.data;
      // console.log('this.studentMsg: ', this.studentMsg);
    },
    /* 统一发送请求 */
    async editPost(con) {
      //  /teach/student/addIntroduction

      const { data } = await this.$axios.post(
        '/teach/student/addIntroduction',
        con
      );
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.getStudentMsg();
      if (this.isEdit == 'edit') {
        return this.$message.success('编辑成功');
      } else if (this.isEdit == 'del') {
        return this.$message.success('删除成功');
      } else if (this.isEdit == 'add') {
        return this.$message.success('添加成功');
      }
    },
    /* 软件技能 */
    openSoftWareDialog() {
      this.softSkill = true;
      this.skillForm.softwareAbility = 0;
    },
    closeSoftWareDialog() {
      this.softSkill = false;
      this.$refs.skillRef.resetFields();
    },
    editSkill() {
      this.$refs.skillRef.validate(async (valid) => {
        if (!valid) return;
        await this.editPost(this.skillForm);
        this.closeSoftWareDialog();
      });
    },
    /* 全局删除 */
    deleteItem(id) {
      // console.log('delete');
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          this.isEdit = 'del';
          await this.editPost({
            softId: id,
            deleted: 123,
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    /* 领域 */
    openFieldDialog(str, data) {
      this.isFiledDialog = true;
      if (str == 'edit') {
        this.title = '编辑擅长领域';
        this.isEdit = str;
        this.filedForm.softId = data.id;
        this.filedForm.introduction = data.introduction;
        this.tags = data.field;
        this.tags.map((v) => {
          this.filedForm.field = v + ',' + this.filedForm.field;
        });
      } else {
        this.title = '添加擅长领域';
      }
    },
    closeFieldDialog() {
      this.isFiledDialog = false;
      this.$refs.filedRef.resetFields();
      this.filedForm.tag = '';
      this.filedForm.field = '';
      this.filedForm.introduction = '';
      this.tags = [];
    },
    /* 回车生产标签 */
    keydown() {
      if (this.tags.length > 4) return this.$message.warning('最多五个标签');
      if (this.filedForm.tag.trim()) {
        this.tags.push(this.filedForm.tag);
        this.filedForm.field = this.filedForm.tag + ',' + this.filedForm.field;
        this.filedForm.tag = '';
      }
    },
    /* 删除标签 */
    deleteFieldItem(i) {
      this.tags.splice(i, 1);
    },
    eidtField() {
      this.filedForm.field = '';
      this.tags.map((v) => {
        if (this.filedForm.field == '') {
          this.filedForm.field = v;
        } else {
          this.filedForm.field = this.filedForm.field + ',' + v;
        }
      });
      this.$refs.filedRef.validate(async (valid) => {
        if (!valid) return;
        await this.editPost(this.filedForm);
        this.closeFieldDialog();
      });
    },
    /* 工作经验 */
    openExpDialog(str, data) {
      this.isExpDialog = true;
      if (str == 'edit') {
        this.isEdit = str;
        this.expForm = JSON.parse(JSON.stringify(data));
        this.expForm.endTime = new Date(this.expForm.endTime);
        this.expForm.postTime = new Date(this.expForm.postTime);
        delete this.expForm.id;
        this.expForm.softId = data.id;
        // console.log(this.expForm);
      } else {
        this.isEdit = 'add';
      }
    },
    closeExpDialog() {
      this.isExpDialog = false;
      this.$refs.expRef.resetFields();
    },
    editExp() {
      this.$refs.expRef.validate(async (valid) => {
        if (!valid) return;
        await this.editPost(this.expForm);
        this.closeExpDialog();
      });
    },
    /* 教育经历 */
    openEducationDialog(str, data) {
      this.isEducationDialog = true;
      if (str == 'edit') {
        this.isEdit = str;
        this.educationForm = JSON.parse(JSON.stringify(data));
        this.educationForm.softId = data.id;
        delete this.educationForm.id;
        this.educationForm.educationTime = new Date(
          this.educationForm.educationTime
        );
        this.educationForm.goTime = new Date(this.educationForm.goTime);
      } else {
        this.isEdit = 'add';
      }
    },
    closeEducationDialog() {
      this.isEducationDialog = false;
      this.$refs.educationRef.resetFields();
    },
    editEducation() {
      // console.log('edit', this.educationForm);
      this.$refs.educationRef.validate(async (valid) => {
        if (!valid) return;
        await this.editPost(this.educationForm);
        this.closeEducationDialog();
      });
    },
    /* 个人荣誉 */
    openHonorDialog() {
      this.isHonorDialog = true;
    },
    closeHonorDialog() {
      this.isHonorDialog = false;
      this.$refs.honorRef.resetFields();
    },
    editHonor() {
      // console.log('edit');
      this.$refs.honorRef.validate(async (valid) => {
        if (!valid) return;
        await this.editPost(this.honorForm);
        this.closeHonorDialog();
      });
    },

    /* 作品 */
    openWorksDialog() {
      this.isWorksDialog = true;
    },
    closeWorksDialog() {
      this.isWorksDialog = false;
      this.$refs.worksRef.resetFields();
    },
    editWorks() {
      // console.log('edit');
      this.$refs.worksRef.validate(async (valid) => {
        if (!valid) return;
        await this.editPost(this.worksForm);
        this.closeWorksDialog();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.my-message {
  min-height: 100vh;
  background-color: #f0f3f5;
  .header {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    padding: 16px;
    margin: 16px auto;
    width: 1080px;
    height: 160px;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    .avatar {
      margin: 10px 20px 10px 10px;
      width: 130px;
      height: 130px;
      border-radius: 130px;
    }
    .msg-box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      // align-items: center;
      .msg-item {
        display: flex;
        align-items: center;
        height: 40px;

        font-size: 14px;
        .name {
          margin-right: 6px;
        }
        .logintime {
          color: #666666;
        }
        .icon {
          font-size: 10px;
          margin-right: 10px;
        }
      }
    }
  }
  .content {
    margin: 0 auto;
    width: 1080px;

    border-radius: 4px;
    .content-part {
      position: relative;
      padding: 16px;
      margin-bottom: 16px;
      width: 1080px;
      min-height: 110px;
      background-color: #fff;
      box-sizing: border-box;
      .part-title {
        padding-left: 10px;
        border-left: 4px solid #409eff;
        color: #333333;
        font-size: 16px;
      }
      .addbtn {
        position: absolute;
        top: 40px;
        right: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: #409eff;
        width: 40px;
        height: 40px;
        cursor: pointer;
        .iconadd {
          font-size: 14px;
          color: #fff;
        }
      }
      .part2 {
        margin: 0 16px;
        .part2-title {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 30px 0;
          .addbtn {
            position: absolute;
            top: 0px;
            right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background: #409eff;
            width: 40px;
            height: 40px;
            cursor: pointer;
            .iconadd {
              font-size: 14px;
              color: #fff;
            }
          }
          .icon {
            font-size: 10px;
            margin-right: 10px;
            color: #409eff;
          }
          span {
            font-size: 14px;
            color: #333333;
          }
        }

        .skill-box {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          // justify-content: space-between;

          .skill-item {
            display: flex;
            align-items: center;
            margin: 10px 20px 10px 20px;
            // width: 33%;
            .el-progress {
              margin: 0 10px;
              width: 180px;
            }
            .del-icon {
              font-size: 10px;
              color: #f56c6c;
              margin-bottom: 2px;
              cursor: pointer;
            }
          }
        }
        .field-box {
          .field-box-item {
            margin-top: 20px;
            padding-bottom: 30px;
            border-bottom: 1px solid #cccccc;
            &:last-child {
              border-bottom: none;
            }

            .field-tags {
              display: flex;
              flex-wrap: wrap;
              margin: 0;
              .tag {
                padding: 4px 8px;
                margin: 6px;
                background-color: #409eff;
                border-radius: 4px;
                color: #fff;
                font-size: 14px;
                cursor: pointer;
                &:first-child {
                  margin-left: 0;
                }
                &:last-child {
                  margin-right: 0;
                }
              }
            }
            .field-content {
              box-sizing: border-box;
              margin-top: 16px;
              padding: 8px 12px;
              width: 100%;
              height: 160px;
              border-radius: 4px;
              border: 1px solid #666666;
            }
            .btn-group {
              display: flex;
              margin-top: 30px;
              .btn {
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 4px;
                border: 1px solid #666666;
                cursor: pointer;
              }
              .btn:hover {
                border-color: #409eff;
                background-color: #d9ecff;
              }
            }
            .work-title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              color: #333333;
              .work-pos {
                margin-right: 30px;
                font-weight: 600;
              }
              .work-company {
                margin-right: 30px;
              }
            }
            .education-title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              color: #333333;
              .education-major {
                margin-right: 30px;
                font-weight: 600;
              }
            }
            .education-title2 {
              font-size: 14px;
              color: #333333;
              .school {
                margin-right: 30px;
              }
            }
            // .addbtn {
            //   display: flex;
            //   align-items: center;
            //   justify-content: center;
            //   border-radius: 4px;
            //   background: #409eff;
            //   width: 40px;
            //   height: 40px;
            //   cursor: pointer;
            //   .iconadd {
            //     font-size: 14px;
            //     color: #fff;
            //   }
            // }
          }
        }
      }
      .list-box {
        .list-item {
          display: flex;
          align-items: center;
          margin: 10px;
          font-size: 14px;
          color: #333333;

          .name {
            margin-right: 30px;
            font-weight: 600;
          }
          .time {
            margin-right: 30px;
          }
          .link {
            margin-right: 30px;

            max-width: 750px;
            color: #333;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
            &:hover {
              border-bottom: 1px solid #ccc;
            }
          }
          .prize {
            display: inline-block;
            margin-right: 30px;
            padding: 4px 8px;
            border-radius: 4px;
            background-color: #409eff;
            color: #fff;
          }
          .deletebtn {
            cursor: pointer;
            font-size: 12px;
            color: #f56c6c;
          }
        }
      }
      .none {
        margin: 0 auto;
        // height: 60px;
        font-size: 14px;
        color: #333333;
        text-align: center;
      }
    }
  }
}

/deep/.zksc-dialog {
  .el-dialog {
    min-width: 600px;
  }
  .el-dialog__header {
    height: 70px !important;
    background: #409eff !important;
    padding: 0 !important;
    line-height: 70px !important;
    text-align: center !important;
    .el-dialog__title {
      color: #ffffff !important;
      font-size: 24px !important;
    }
    .el-dialog__headerbtn {
      top: 30px;
      right: 20px;
    }
    .el-dialog__close {
      color: #fff !important;
    }
  }
  .el-form {
    width: 550px;
    margin: 0 auto;
    .el-select {
      width: 470px;
    }
  }

  .btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
.el-tag {
  margin: 10px 8px 10px 0;
}
</style>
<style lang="less">
.el-date-editor {
  width: 100% !important;
}
</style>
