<template>
  <div class="detail">
    <div class="content">
      <div class="video_box">
        <h2>{{ detailData.name }}</h2>
        <!-- <span @click="dialogpdf = true"
          ><icon-svg icon-class="biji"></icon-svg
        ></span> -->
        <p v-if="detailData">
          <icon-svg
            style="font-size: 12px; margin-right: 4px"
            icon-class="shizhong"
          ></icon-svg>
          {{ detailData.createTime
          }}<span>
            <icon-svg
              style="font-size: 14px; margin-right: 4px"
              icon-class="bofang"
            ></icon-svg
            >{{ detailData.visit }}
          </span>
        </p>
        <div
          class="video_content"
          v-if="fileFlag"
          v-show="detailData"
          v-html="videourl"
        >
          <!-- <video
            width="100%"
            v-show="detailData"
            height="100%"
            controls
          ></video> -->
        </div>
        <div class="video_contents" v-else>
          <!-- <pdf
            :src="src"
            :page="currentPage"
            @num-pages="pageCount = $event"
            @page-loaded="currentPage = $event"
            @loaded="loadPdfHandler"
          >
          </pdf> -->
          <iframe
            ref="pdf"
            style="width: 100%; height: 100%"
            :src="url"
          ></iframe>
          <span class="box" @click="openPDF">
            <icon-svg icon-class="fangda"></icon-svg>
          </span>
        </div>
        <div class="user_box" v-if="detailData.map">
          <img
            :src="
              detailData.map.heading === 'null'
                ? '/img/avatar.4e4aa2c3.jpg'
                : detailData.map.heading
            "
            alt=""
          />
          <p>{{ detailData.map.username }}</p>
          <p class="dianzan">
            <span
              v-if="favorite === false"
              @click="clickLike(likefavorite.favorite)"
            >
              <icon-svg style="margin-right: 4px" icon-class="xing"></icon-svg
              >收藏({{ facCount }})
            </span>
            <span
              class="unshoucang"
              v-else
              @click="unclickLike(likefavorite.favorite)"
            >
              <icon-svg
                style="margin-right: 4px"
                icon-class="xingshixin"
              ></icon-svg
              >取消收藏({{ facCount }})
            </span>
            <span v-if="like === false" @click="clickLike(likefavorite.like)">
              <icon-svg
                style="margin-right: 4px"
                icon-class="dianzan"
              ></icon-svg
              >点赞({{ likeCount }})
            </span>

            <span class="unlike" v-else @click="unclickLike(likefavorite.like)">
              <icon-svg
                style="margin-right: 4px"
                icon-class="dianzanshixin"
              ></icon-svg
              >取消点赞({{ likeCount }})
            </span>
            <!-- <a :href="links"
              ><el-button type="primary" :disabled="btnFlag"
                >资源下载</el-button
              ></a
            > -->
          </p>
          <div class="xingxing">
            <el-rate
              v-model="socore"
              disabled
              show-score
              disabled-void-color="#fff"
              text-color="#ff9900"
            >
            </el-rate>
            <span v-if="messageList.map.myscore == -1" class="myscore"
              >暂未评分</span
            >
            <span v-else class="myscore"
              >您的评分：{{ messageList.map.myscore }}</span
            >
          </div>
        </div>
      </div>
      <div class="video_list">
        <div class="list_title">
          <p>
            <icon-svg
              icon-class="ziyuan"
              style="margin-right: 8px; font-weight: 600"
            ></icon-svg
            >课件列表共（{{ silderList.length }}）课
          </p>
        </div>
        <ul class="list">
          <li
            v-for="(item, index) in silderList"
            :key="index"
            @click="goDetali(item.id)"
            :class="{ nowItem: item.id == detailId }"
          >
            <p>
              <span class="name">
                {{ index > 8 ? index + 1 : '0' + (index + 1) }}
                {{ item.name }}</span
              >
              <span class="time">{{
                item.duration[0] === '0'
                  ? item.duration.slice(1)
                  : item.duration
              }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="message_box">
      <div class="title">
        <div class="introduce" @click="spanFlag = '1'">
          课件介绍<br /><span
            v-show="spanFlag === '1'"
            class="introduce_span"
          ></span>
        </div>
        <div class="material" @click="spanFlag = '2'">
          课件素材<span v-show="spanFlag === '2'" class="material_span"></span>
        </div>
        <div class="team" @click="spanFlag = '3'">
          课件团队<span v-show="spanFlag === '3'" class="material_span"></span>
        </div>
        <div class="notice" @click="spanFlag = '4'">
          课件公告<span v-show="spanFlag === '4'" class="material_span"></span>
        </div>
        <div class="comment" @click="spanFlag = '5'">
          课件评论<span v-show="spanFlag === '5'" class="material_span"></span>
        </div>
      </div>
      <div
        class="introduce_content"
        v-html="text"
        v-show="spanFlag === '1'"
      ></div>
      <div class="material_content" v-show="spanFlag === '2'">
        <div class="zip" v-if="upload === false">
          <div v-show="messageList.map.materialob">
            {{ messageList.map.materialob }}
            <a class="downZip" :href="messageList.map.material">下载</a>
          </div>
          <div v-show="!messageList.map.materialob">暂无</div>
        </div>
        <div v-else>
          <div class="lead_box">
            <div>
              <span>
                <icon-svg
                  style="margin-right: 4px"
                  icon-class="kaisuo"
                ></icon-svg
                >提取密码
              </span>
              <el-tag>{{ messageList.map.materialupcode }}</el-tag>
              <div class="copy" @click="copy(messageList.map.materialupcode)">
                <icon-svg
                  style="margin-right: 4px"
                  icon-class="fuzhi"
                ></icon-svg>
              </div>
            </div>
            <div>
              <span>
                <icon-svg
                  style="margin-right: 4px"
                  icon-class="iconset0191"
                ></icon-svg
                >解压密码
              </span>
              <el-tag>{{ messageList.map.materialzipcode }}</el-tag>
              <div class="copy1" @click="copy(messageList.map.materialzipcode)">
                <icon-svg
                  style="margin-right: 4px"
                  icon-class="fuzhi"
                ></icon-svg>
              </div>
            </div>
            <div>
              <a
                :href="'https://' + messageList.map.materialurl"
                target="_blank"
              >
                <el-button type="primary" plaintype="primary" plain>
                  <icon-svg
                    style="margin-right: 4px"
                    icon-class="download"
                  ></icon-svg>
                  下载
                </el-button>
              </a>
              <div class="copy11" @click="copy(messageList.map.materialurl)">
                <icon-svg
                  style="margin-right: 4px"
                  icon-class="fuzhi"
                ></icon-svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="material_team"
        v-if="spanFlag === '3' && messageList.groupDTOS"
      >
        <ul v-if="teamFlag">
          <li v-for="(item, index) in messageList.groupDTOS" :key="index">
            <img
              :src="
                item.heading === 'null'
                  ? '/img/avatar.4e4aa2c3.jpg'
                  : item.heading
              "
              alt=""
            />
            <div class="textbox">
              <span class="name">{{ item.name }}</span>
              <span class="list">
                <span v-for="(items, indexs) in item.teachrole" :key="indexs">{{
                  indexs >= 1 ? ' / ' + items : items
                }}</span>
              </span>
              <br />
              <span class="school">{{ item.school }}</span>
              <span class="type">{{ item.jobTitle }}</span>
            </div>
            <span class="time">{{ item.createTime }}</span>
            <div class="teamTxt">
              <p>{{ item.content }}</p>
            </div>
            <span class="btn">
              <span class="alter" @click="alterTeacher(index)">编辑</span>
              <span @click="delTeacher(index)">删除</span>
            </span>
          </li>
        </ul>
        <div class="zanwu" v-else>暂无</div>
      </div>
      <div class="material_notice" v-if="spanFlag === '4' && detailData.map">
        <ul v-if="gaoFlag">
          <li
            class="item"
            v-for="(item, index) in messageList.gaoDTOS"
            :key="index"
          >
            <div class="box_left">
              <img
                :src="
                  detailData.map.heading === 'null'
                    ? '/img/avatar.4e4aa2c3.jpg'
                    : detailData.map.heading
                "
                alt=""
              />
              <span>{{ detailData.map.username }}</span>
            </div>
            <div class="box_right">{{ item.createTime }}</div>
            <div class="contents">
              {{ item.content }}
            </div>
          </li>
        </ul>
        <div class="zanwu" v-else>暂无</div>
      </div>
      <div class="comment_box" v-if="spanFlag === '5'">
        <Comment
          :menuIds="menuId"
          :courseId="detailId"
          :messageData="messageList.map"
          :getMessageDatas="getMessageData"
        ></Comment>
      </div>
    </div>
    <!-- pdf全屏 -->
    <!-- <el-dialog
      :title="usrlname"
      :visible.sync="dialogpdf"
      width="90%"
      height="1000px"
      center
    >
      <iframe ref="pdf" style="width: 100%; height: 100%" :src="url"></iframe>
    </el-dialog> -->
  </div>
</template>

<script>
import iconSvg from '../icon/iconSvg.vue';
import Comment from './detailModule/comment.vue';
// import pdf from 'vue-pdf';
export default {
  components: {
    iconSvg,
    Comment,
  },
  data() {
    return {
      spanFlag: '1',
      like: true,
      favorite: true,
      likefavorite: {
        like: 'like',
        favorite: 'favorite',
      },
      likeCount: '',
      facCount: '',
      detailId: '',
      detailData: {},
      menuId: '',
      links: '',
      btnFlag: false,
      teamFlag: true,
      gaoFlag: false,
      upload: false,
      silderList: [],
      messageList: [],
      text: '',
      // currentPage: 0, // pdf文件页码
      // pageCount: null, // pdf文件总页数
      // fileType: 'pdf', // 文件类型
      url: '', // pdf文件地址
      fileFlag: true,
      numPages: null,
      usrlname: '',
      socore: null, //评分
    };
  },
  created() {
    this.detailId = this.$route.query.id;
    this.menuId = this.$route.query.menuId;
    this.getDetailIdData(this.$route.query.id);
    this.getList(this.$route.query.menuId);
    this.getLikeStatus();
    this.getMessageData();
    this.spanFlag = this.$route.query.tabNum;
    // console.log(this.detailData);
  },
  computed: {
    videourl: function () {
      if (this.fileFlag) {
        // console.log(this.fileFlag);
        // console.log(this.detailData.map);
        if (this.detailData.map == undefined) {
          // console.log('111111111111');
          return '';
        }
        return `<video
            width="100%"
            v-if="this.detailData.map.content"
            height="100%"
            controls
          ><source src="${
            !this.detailData.map.content ? '' : this.detailData.map.content
          }" type="video/mp4" /></video>`;
      } else {
        return '';
      }
    },
  },
  watch: {
    $route() {
      console.log(this.getDetailIdData(this.$route.query.id));
    },
    //  或者
    // $route: this.getDetailIdData(this.$route.query.id), //路由变化触发下列方法
  },
  methods: {
    openPDF() {
      window.open(this.url);
    },
    // getNumPages() {
    //   let loadingTask = pdf.createLoadingTask(this.url);
    //   loadingTask.promise
    //     .then((pdf) => {
    //       this.numPages = pdf.numPages;
    //     })
    //     .catch((err) => {
    //       console.error('pdf 加载失败', err);
    //     });
    // },
    // 复制
    copy(item) {
      let txt = item;
      let oinput = document.createElement('input');
      oinput.value = '';
      oinput.value = txt;
      document.body.appendChild(oinput);
      oinput.select();
      // console.log(oinput.value);
      document.execCommand('Copy');
      this.$message({
        type: 'success',
        message: '复制成功',
      });
      oinput.remove();
    },
    // 获取消息数据
    getMessageData() {
      const that = this;
      this.$axios
        .get(
          `/teach/courseware/findCourseWareCommon?menuId=${this.$route.query.menuId}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            // console.log(res.data.data.groupDTOS);
            this.messageList = res.data.data;
            this.socore = Number(res.data.data.map.socore);
            // console.log(this.socore);
            if (res.data.data.groupDTOS) {
              this.teamFlag = true;
            } else {
              this.teamFlag = false;
            }
            if (res.data.data.gaoDTOS) {
              this.gaoFlag = true;
            } else {
              this.gaoFlag = false;
            }
            if (res.data.data.map.material === '') {
              that.btnFlag = true;
            } else {
              that.btnFlag = false;
              that.links = res.data.data.map.material;
            }
            if (res.data.data.map.materialurl) {
              this.upload = true;
            } else {
              this.upload = false;
            }
            if (res.data.data.map.text) {
              this.$axios.get(res.data.data.map.text).then((res) => {
                // console.log(res.status);
                if (res.status === 200) {
                  that.text = res.data;
                }
              });
            } else {
              that.text = '暂无';
            }
          }
        });
    },
    //取消点赞
    unclickLike(e) {
      this.$axios
        .post(
          `/teach/courseware/Unlikecourseware?coursewareId=${this.$route.query.id}&status=${e}`
        )
        .then(() => {
          // console.log(res);
          this.getLikeStatus();
        });
    },
    // 点赞
    clickLike(e) {
      this.$axios
        .post(
          `/teach/courseware/likecourseware?coursewareId=${this.$route.query.id}&status=${e}`
        )
        .then(() => {
          // console.log(res);
          this.getLikeStatus();
        });
    },
    //获取点赞状态
    getLikeStatus() {
      const that = this;
      this.$axios
        .get(
          `/teach/courseware/findlikecourseware?coursewareId=${this.$route.query.id}`
        )
        .then((res) => {
          // console.log(res);
          this.facCount = res.data.data.faccount;
          this.likeCount = res.data.data.likecount;
          if (res.data.data.like === '0') {
            that.like = false;
          } else {
            that.like = true;
          }
          if (res.data.data.favorite === '0') {
            that.favorite = false;
          } else {
            that.favorite = true;
          }
        });
    },
    // 获取详情id数据
    getDetailIdData(id) {
      const that = this;
      this.$axios
        .get(`/teach/courseware/findCourseWare?coursewareId=${id}`)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.detailData = res.data.data;
            // if (that.detailData.groupDTOS === null) {
            //   that.teamFlag = false;
            // } else {
            //   that.teamFlag = true;
            // }
            let suffix = res.data.data.map.contentob.split('.');
            // console.log(suffix[1]);
            if (suffix[1] === 'pdf' || suffix[1] === 'PDF') {
              this.fileFlag = false;
              this.url = res.data.data.map.content;
              this.usrlname = res.data.data.map.contentob;
              // this.fileName = res.data.data.map.contentob;
            } else {
              this.fileFlag = true;
            }
            // this.initFile();
            if (res.data.data.map.material === '') {
              that.btnFlag = true;
            } else {
              that.btnFlag = false;
              that.links = res.data.data.map.material;
            }
            // this.$axios.get(res.data.data.map.text).then((res) => {
            //   console.log(res.status);
            //   if (res.status === 200) {
            //     that.text = res.data;
            //   }
            // });
          }
        });
    },
    // 获取详情页右侧列表数据
    getList(id) {
      this.$axios
        .get(`/teach/courseware/findMenueAnnex?menuId=${id}`)
        .then((res) => {
          console.log(res.data);
          if (res.data.code === 200) {
            // res.data.data.map((v) => {
            //   console.log(v);
            //   // let data = res.data.data.duration.slice
            // });
            this.silderList = res.data.data;
          }
        });
    },
    // 详情列表切换
    goDetali(id) {
      // const that = this;
      if (this.detailId === id) {
        return;
      }
      this.$router.push({
        path: '/resourcedetail',
        query: {
          id: id,
          menuId: this.menuId,
          tabNum: 1,
        },
      });
      this.detailId = this.$route.query.id;
      this.menuId = this.$route.query.menuId;
      this.getLikeStatus();
      this.$axios
        .get(`/teach/courseware/findCourseWare?coursewareId=${this.detailId}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.detailData = [];
            this.detailData = res.data.data;
            this.detailId = res.data.data.id;
            // this.$axios.get(res.data.data.map.text).then((res) => {
            //   if (res.status === 200) {
            //     that.text = res.data;
            //   }
            // });
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
    width: 100%;
    min-height: 100vh;
    background: #f0f3f5;
    .content {
        width: 1232px;
        height: 716px;
        background: #f0f3f5;
        margin: 16px auto;
        .video_box {
            width: 920px;
            height: 100%;
            background: #fff;
            float: left;
            border-radius: 4px;
            h2 {
                margin: 16px 0 0 16px;
            }
            p {
                text-indent: 16px;
                font-size: 14px;
                color: #333333;
                span {
                    display: inline-block;
                    margin-left: 16px;
                    font-size: 14px;
                    color: #333333;
                }
            }
            .video_content {
                width: 888px;
                height: 500px;
                - background: #ccc;
                margin: 0 0 16px 16px;
                border-radius: 4px;
            }
            .video_contents {
                width: 888px;
                height: 500px;
                background: #ccc;
                margin: 0 0 16px 16px;
                border-radius: 4px;
                position: relative;
                .box {
                    color: #fff;
                    position: absolute;
                    font-size: 12px;
                    top: 22px;
                    right: 140px;
                }
                .box:hover {
                    cursor: pointer;
                }
            }
            .user_box {
                width: 888px;
                height: 82px;
                background: #f1f3f5;
                margin: 0 0 16px 16px;
                border-radius: 4px;
                position: relative;
                img {
                    width: 50px;
                    height: 50px;
                    background: #ccc;
                    border-radius: 50%;
                    float: left;
                    margin: 16px 0 16px 16px;
                }
                p:nth-child(2) {
                    margin: 0;
                    float: left;
                    line-height: 82px;
                    font-size: 18px;
                }
                p:nth-child(3) {
                    float: right;
                    margin: 0;
                    line-height: 82px;
                    span {
                        color: #333;
                        font-size: 14px;
                        margin-right: 16px;
                    }
                }
                .el-button {
                    margin-right: 16px;
                }
                .dianzan {
                    .unshoucang {
                        color: #409eff;
                    }
                    .unlike {
                        color: #409eff;
                    }
                    span:hover {
                        cursor: pointer;
                    }
                }
                .xingxing {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    .myscore {
                        display: none;
                        position: absolute;
                        left: 25px;
                    }
                }
                .xingxing:hover .myscore {
                    width: 150px;
                    display: inline-block;
                }
            }
        }
        .video_list {
            width: 296px;
            height: 100%;
            background: #fff;
            float: left;
            margin-left: 16px;
            border-radius: 4px;
            .list_title {
                width: 100%;
                background: #fff;
                border-radius: 4px 4px 0 0;
                border-bottom: 1px solid #ccc;
                margin-bottom: 16px;
                p {
                    margin: 0;
                    padding: 16px 0 16px 16px;
                    font-weight: 600;
                    color: #409eff;
                }
            }
            .list::-webkit-scrollbar {
                width: 4px;
            }
            .list::-webkit-scrollbar-track-piece {
                background-color: none;
            }
            /* 滚动条的内层滑轨背景颜色 */
            .list::-webkit-scrollbar-track {
                background-color: none;
            }
            /* 滚动条的外层滑轨背景颜色 */
            .list::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.25);
                border-radius: 3px;
            }
            /* 滚动条的内层滑块颜色 */
            .list::-webkit-scrollbar-button {
                background-color: #fff;
                display: none;
            }
            .list {
                height: 645px;
                overflow: hidden;
                overflow-y: auto;
                margin: 0;
                li {
                    width: 280px;
                    height: 40px;
                    line-height: 40px;
                    margin: 0 auto;
                    border-radius: 4px;
                    overflow: hidden;
                    p {
                        margin: 0;
                    }
                    .name {
                        display: inline-block;
                        width: 220px;
                        margin-left: 8px;
                        line-height: 40px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .time {
                        display: inline-block;
                        float: right;
                        line-height: 40px;
                    }
                }
                li:hover {
                    cursor: pointer;
                    background: #f0f3f5;
                }
            }
        }
    }
    .message_box {
        width: 1232px;
        min-height: 450px;
        background: #fff;
        border-radius: 4px;
        margin: 16px auto;
        overflow: hidden;
        padding-bottom: 20px;
        .title {
            width: 1200px;
            height: 40px;
            background: #fff;
            border-radius: 4px 4px 0 0;
            border-bottom: 2px #cccccc solid;
            margin: 16px auto;
            position: relative;
            div:hover {
                cursor: pointer;
            }
            div {
                float: left;
                line-height: 40px;
                font-size: 14px;
                span {
                    display: inline-block;
                    width: 28px;
                    height: 2px;
                    background: #409eff;
                    position: absolute;
                    right: 42px;
                    top: 17px;
                }
            }
            .introduce {
                // margin: 0 16px;
                margin-left: 16px;
                height: 40px;
                span {
                    top: 40px;
                    left: 30px;
                }
            }
            .material {
                margin: 0 16px;
                height: 40px;
                span {
                    top: 40px;
                    left: 100px;
                }
            }
            .team {
                margin: 0 16px;
                height: 40px;
                span {
                    top: 40px;
                    left: 190px;
                }
            }
            .notice {
                margin: 0 16px;
                height: 40px;
                span {
                    top: 40px;
                    left: 278px;
                }
            }
            .comment {
                margin: 0 16px;
                height: 40px;
                span {
                    top: 40px;
                    left: 366px;
                }
            }
        }
        .introduce_content {
            font-size: 14px;
            margin: 16px 16px;
        }
        .material_content {
            font-size: 14px;
            margin: 16px 16px;
            .zip {
                .downZip {
                    color: #ffffff;
                    padding: 8px 40px;
                    background: #409eff;
                    border-radius: 4px;
                }
            }
        }
        .material_team {
            width: 100%;
            min-height: 100px;
            margin-top: 16px;
            li {
                border-bottom: 2px solid #cccccc;
                width: 1200px;
                // padding: 16px;
                position: relative;
                margin: 16px 16px;
                img {
                    width: 70px;
                    height: 70px;
                    background: #000000;
                    border-radius: 50%;
                }
                .textbox {
                    width: 550px;
                    height: 60px;
                    left: 10px;
                    position: relative;
                    top: -73px;
                    left: 87px;
                    span {
                        display: inline-block;
                    }
                    .name {
                        font-size: 14px;
                        margin-right: 16px;
                    }
                    .list {
                        font-size: 14px;
                    }
                    .school {
                        color: #666;
                        font-size: 14px;
                        margin-top: 16px;
                        margin-right: 16px;
                    }
                    .type {
                        color: #666;
                        font-size: 14px;
                    }
                }
                .teamTxt {
                    width: 94%;
                    margin-top: -46px;
                    margin-bottom: 16px;
                    border-radius: 4px;
                    p {
                        display: inline-block;
                        word-break: break-all;
                        width: 79%;
                        margin: 10px 16px 16px 16px;
                    }
                }
                .btn {
                    display: none;
                    position: absolute;
                    right: 10px;
                    top: 20px;
                    span {
                        display: inline;
                        cursor: pointer;
                    }
                    .alter {
                        margin-right: 16px;
                    }
                }
                .time {
                    position: absolute;
                    right: 10px;
                    top: 00px;
                }
            }
        }
        .material_notice {
            width: 1200px;
            min-height: 100px;
            padding-bottom: 16px;
            .item {
                width: 100%;
                height: 150px;
                position: relative;
                border-bottom: 2px #cccccc solid;
                margin-top: 16px;
                margin-left: 16px;
                .box_left {
                    position: absolute;
                    // left: 16px;
                    img {
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                    }
                    span {
                        display: inline-block;
                        position: relative;
                        color: #333333;
                        left: 16px;
                        top: -30px;
                    }
                }
                .box_right {
                    position: absolute;
                    right: 16px;
                    color: #666666;
                    top: 30px;
                }
                .contents {
                    width: 95%;
                    // margin-left: 16px;
                    border-radius: 4px;
                    color: #333333;
                    // padding: 10px 10px;
                    position: absolute;
                    top: 80px;
                }
            }
        }
    }
}

.lead_box {
    width: 556px;
    min-height: 30px;
    margin: 16px 0 0 16px;
    div {
        width: 100%;
        margin-bottom: 16px;
        position: relative;
        span {
            margin-right: 10px;
            font-size: 14px;
        }
        .el-tag {
            color: #333333;
            width: 82%;
            height: 40px;
            line-height: 40px;
        }
        .el-button {
            width: 540px;
        }
        .copy,
        .copy1,
        .copy11 {
            width: 40px;
            height: 40px;
            font-size: 16px;
            text-align: center;
            line-height: 40px;
            position: absolute;
            border-radius: 0 4px 4px 0;
            color: #409eff;
        }
        .copy {
            top: 0px;
            left: 504px;
        }
        .copy1 {
            top: 0px;
            left: 504px;
        }
        .copy11 {
            top: 0px;
            left: 500px;
        }
        .copy:hover {
            background: #409eff;
            color: #fff;
        }
        .copy1:hover {
            background: #409eff;
            color: #fff;
        }
        .copy11:hover {
            background: #409eff;
            color: #fff;
        }
    }
}

.nowItem {
    background: #f0f3f5;
}

.zanwu {
    margin-left: 16px;
}

/deep/.el-dialog__body {
    height: 800px;
}

/deep/.el-dialog--center {
    margin-top: 5vh !important;
}
</style>
