<template>
  <div class="zksc-homework"
       :class="{ 'is-student': role == 'student' }">
    <ul class="side-menu">
      <li v-for="(v, i) in classMenu"
          :key="v.id"
          :class="{ 'li-active': i == choice }"
          @click="choieClass(i, v)">
        <el-tooltip class="item"
                    effect="dark"
                    :content="v.name"
                    placement="top"
                    :open-delay="time">
          <span>
            {{ v.name }}
          </span>
        </el-tooltip>
      </li>
    </ul>
    <div class="zksc-homework-c">
      <div class="zksc-homework-title">
        <h5>{{ className }}</h5>
        <el-button v-if="role == 'teacher'"
                   type="primary"
                   @click="openHomeworkDialog">发布作业</el-button>
      </div>
      <!-- 作业 -->
      <el-empty description="暂时没有作业"
                v-if="tableData.length == 0"></el-empty>
      <template v-else>
        <el-table :data="tableData"
                  style="width: 100%"
                  stripe>
          <el-table-column type="index"
                           width="50"
                           label="序号"
                           align="center"></el-table-column>
          <el-table-column property="name"
                           label="作业名称"
                           width="120"
                           show-overflow-tooltip></el-table-column>
          <el-table-column property="content"
                           label="作业要求"
                           show-overflow-tooltip></el-table-column>
          <el-table-column property="createTime"
                           label="发布时间"
                           align="center"
                           width="180px">
            <template slot-scope="scope">
              <span class="homework-time">{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column property="url"
                           label="附件下载"
                           align="center">
            <template slot-scope="scope">
              <el-link :href="scope.row.url"
                       type="primary">下载</el-link>
            </template>
          </el-table-column>

          <el-table-column label="操作"
                           width="220"
                           align="center">
            <template slot-scope="scope">
              <el-button size="mini"
                         type="info"
                         @click="openCheckWork(scope.row.id)">查看</el-button>
              <el-button v-if="role == 'teacher'"
                         size="mini"
                         @click="deleteHomework(scope.row.id)"
                         type="warning">删除</el-button>
              <el-button v-if="role == 'student' && scope.row.type == 0"
                         type="primary"
                         size="mini"
                         @click="openUplodHomework(scope.row, 1)">上传作业</el-button>

              <el-button v-if="role == 'student' && scope.row.type == 1"
                         size="mini"
                         @click="openUplodHomework(scope.row, 2)">重新上传</el-button>
              <el-tooltip v-if="scope.row.type == 2"
                          class="item"
                          effect="dark"
                          content="作业已评分不能再次上交"
                          placement="top">
                <el-button size="mini">上传作业</el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="zksc-pagination"
                       layout="prev, pager, next, jumper"
                       :total="totalPage"
                       :page-size="pageSize"
                       :current-page.sync="currentPage"
                       @current-change="handleChangePage"></el-pagination>
      </template>

      <!-- 发布作业 -->
      <el-dialog class="zksc-dialog"
                 title="发布新作业"
                 :visible.sync="homeworkDialog"
                 :before-close="handleClose"
                 center>
        <div class="addHomeworkBox">
          <el-form label-width="80px"
                   :rules="rules"
                   :model="uploadHomework"
                   ref="formRef">
            <el-form-item label="作业名称"
                          prop="name">
              <el-input placeholder="请输入"
                        v-model="uploadHomework.name"></el-input>
            </el-form-item>
            <el-form-item label="作业要求"
                          prop="content">
              <el-input placeholder="请输入"
                        v-model="uploadHomework.content"></el-input>
            </el-form-item>
            <el-form-item label="上传附件"
                          prop="file">
              <el-input v-if="false"
                        v-model="uploadHomework.file"></el-input>
              <el-upload class="upload-demo zksc-upload"
                         drag
                         action="https://jsonplaceholder.typicode.com/posts/"
                         :http-request="uploadSectionFile"
                         :multiple="false"
                         :limit="1"
                         type="file"
                         accept=".rar, .zip"
                         ref="upload">
                <icon-svg icon-class="shangchuan1"></icon-svg>
                <div class="el-upload__text">
                  <p>点击上传，或拖到此处</p>
                  <p class="file-type-size">
                    <span>支持zip，rar文件</span>
                    <span>大小不超过20M</span>
                  </p>
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>

        <span class="btn">
          <el-button @click="getUploadToken"
                     type="primary">保存</el-button>
          <el-button @click="homeworkDialog = false">取消</el-button>
        </span>
      </el-dialog>

      <!-- 检查作业 -->
      <el-dialog v-loading="loading"
                 class="zksc-dialog"
                 title="学生作业提交情况"
                 :visible.sync="checkWork"
                 :before-close="closeCheckWork"
                 center>
        <el-table :data="homeworkTable"
                  style="width: 100%"
                  stripe>
          <el-table-column type="index"
                           width="50"
                           label="序号"></el-table-column>
          <el-table-column property="imgurl"
                           label="头像"
                           width="120"
                           align="center">
            <template slot-scope="scope">
              <!-- {{scope.row.imgurl}} -->
              <img class="zksc-avatar"
                   :src="
                  scope.row.headPicture == 'null'
                    ? '/img/avatar.4e4aa2c3.jpg'
                    : scope.row.headPicture
                "
                   alt="头像"
                   title="头像" />
            </template>
          </el-table-column>
          <el-table-column property="realname"
                           label="真实姓名"
                           width="120"></el-table-column>
          <el-table-column property="website"
                           label="作业链接">
            <template slot-scope="scope">
              <!-- <el-link :href="scope.row.website" type="primary">点击查看</el-link> -->
              <a class="zksc-work-link"
                 v-if="scope.row.website != 'null'"
                 target="_blank"
                 :href="'http://' + scope.row.website">点击查看</a>
              <span v-else>暂未提交</span>
              <!-- <el-link :herf="scope.row.website">点击查看</el-link> -->
              <!-- <span class="zksc-work-link" @click="checkHoemwork(scope.row.website)">点击查看</span> -->
            </template>
          </el-table-column>
          <el-table-column property="createTime"
                           label="提交时间">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.createTime == null
                    ? '暂未提交'
                    : scope.row.createTime
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="role === 'teacher' ? '评分' : '得分'"
                           align="center">
            <template slot-scope="scope">
              <div v-if="role == 'teacher'">
                <span v-if="scope.row.grade === -1 && scope.row.createTime"
                      @click="openScore(scope.row)">
                  打分</span>
                <span v-if="scope.row.grade === -1 && !scope.row.createTime">暂未提交</span>
                <span v-if="scope.row.grade > 0"
                      @click="openScore(scope.row)">{{ scope.row.grade }}&nbsp;<span style="color: rgb(64, 158, 255); cursor: pointer">修改成绩</span></span>
              </div>
              <div v-if="role == 'student'">
                <span v-if="scope.row.grade === -1 && scope.row.createTime">
                  暂未评分</span>
                <span v-if="scope.row.grade === -1 && !scope.row.createTime">暂未提交</span>
                <span v-if="scope.row.grade > 0">{{ scope.row.grade }}</span>
              </div>
              <!-- <div v-if="scope.row.grade != -1">
                <span>{{
                  scope.row.grade === -1 ? '未提交' : scope.row.grade
                }}</span>
                <span
                  v-if="role == 'teacher' && scope.row.grade"
                  class="modify"
                  @click="openScore(scope.row)"
                  >修改成绩</span
                >
              </div>
              <div v-else>
                <span v-if="scope.row.grade  && role == 'teacher'"
                  >暂未提交</span
                >
                <span v-else>暂未评分</span>
                <span
                  v-if="scope.row.grade == -1 && role == 'teacher'"
                  class="zksc-work-score"
                  @click="openScore(scope.row)"
                  >打分</span
                >
              </div> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination class="zksc-pagination"
                       :page-size="pageSize1"
                       layout="prev, pager, next, jumper"
                       :total="totalPage1"
                       @current-change="checkCurrentChange"></el-pagination>
      </el-dialog>

      <!-- 评分 -->
      <el-dialog class="zksc-dialog"
                 title="评分"
                 :visible.sync="isScore"
                 :before-close="closeScore"
                 center>
        <el-input oninput="value=value.replace(/[^\d]/g,'')"
                  v-model="scoreForm.grade"
                  placeholder="请输入考试分数"></el-input>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="isScore = false">取 消</el-button>
          <el-button type="primary"
                     @click="subScore">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 提交作业 -->
      <el-dialog class="zksc-dialog"
                 title="提交作业"
                 :visible.sync="isUploadWork"
                 :before-close="closeUploadHomework"
                 center>
        <el-form label-width="80px"
                 :model="uploadWorkForm"
                 :rules="uploadWorkRules"
                 ref="uploadWorkRef">
          <el-form-item label="作业地址"
                        prop="website">
            <el-input v-model="uploadWorkForm.website"
                      placeholder="例如：www.suczone.com"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="closeUploadHomework">取 消</el-button>
          <el-button type="primary"
                     @click="uploadWork">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import fileUtil from '../../../utils/index';
export default {
  components: {},
  data () {
    return {
      loading: false,
      classId: '',
      choice: 0,
      classMenu: [],
      className: '',
      homeworkDialog: false,
      checkWork: false,
      currentPage: 1,
      pageSize: 10,
      totalPage: 0,
      rules: {
        name: [{ required: true, message: '请输入作业名称', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入作业要求', trigger: 'blur' },
        ],
        file: [{ required: true, message: '请上传文件', trigger: 'blur' }],
      },
      tableData: [
        // {
        //   name: '数字媒体',
        //   request:
        //     '赶紧做赶紧做赶紧做赶紧做赶紧做赶紧做赶紧做赶紧做赶紧做赶紧做',
        //   time: '2021-01-01 10:32:01',
        //   download: '#',
        // },
      ],
      uploadHomework: {
        annexDTO: {
          fileName: '',
          fileSize: 0,
          fileSuffix: '',
          fileType: 'homework' /* 对应文件类型 */,
          objectname: '',
          resourceType: 'class' /* 对应资源大类型 */,
        },
        content: '',
        name: '',
        classId: '',
        file: '',
      },
      assignmentScore: {
        markType: 'homework',
        currents: 1,
        size: 1,
        file: '',
      },
      /* 查看作业提交详情 */
      homeworkTable: [
        // {
        //   avatar:
        //     'https://portrait.gitee.com/uploads/avatars/user/1824/5472531_yun_liu_1578987242.png!avatar60',
        //   name: '团子',
        //   homeworkLink: 'src',
        //   subTime: '2021-05-21 14:09:09',
        // },
      ],
      pageSize1: 5,
      totalPage1: 0,
      /* 评分 */
      isScore: false,
      scoreForm: {
        userId: '',
        markType: 'homework',
        markId: '',
        grade: '',
      },
      role: '',

      /* 学生端 */
      isUploadWork: false,
      uploadWorkForm: {
        homeId: '',
        website: '',
      },
      uploadWorkRules: {
        website: [
          { required: true, message: '请填写作业地址', trigger: 'blur' },
        ],
      },
      time: 800,
    };
  },
  mounted () {
    this.classId = sessionStorage.getItem('classId');
    this.choice = sessionStorage.getItem('menuIndex');
    this.className = sessionStorage.getItem('className');
    this.role = sessionStorage.getItem('role');
    if (this.role == 'teacher') {
      this.getClassManageMenu();
    } else if (this.role == 'admin') {
      // console.log('证书管理员');
      this.getClassListMenu();
    } else if (this.role == 'student') {
      this.getHomeWork(this.classId);
      this.$store.commit('delWork', 0);
    }
  },
  methods: {
    /**
     * 获取班级菜单数据
     */
    async getClassManageMenu () {
      const { data } = await this.$axios.get('/teach/class/findClass');
      if (data.code !== 200) {
        return this.$message.error(data.message);
      } else {
        if (data.data.length == 0)
          return this.$message.warning('暂时没有班级呀');
      }
      this.classMenu = data.data;
      if (sessionStorage.getItem('classId') == 'null') {
        sessionStorage.setItem('classId', data.data[0].id);
        sessionStorage.setItem('menuIndex', 0);
        sessionStorage.setItem('className', data.data[0].name);
        this.classId = data.data[0].id;
        this.className = this.classMenu[0].name;
        this.choice = 0;
      }
      /* TODO 获取作业列表 */
      this.getHomeWork(this.classMenu[0].id);
    },
    choieClass (i, v) {
      this.choice = i;
      this.className = v.name;
      this.classId = v.id;
      this.currentPage = 1;
      sessionStorage.setItem('classId', v.id);
      sessionStorage.setItem('menuIndex', i);
      sessionStorage.setItem('className', v.name);
      /* TODO 获取作业列表 */
      this.getHomeWork(v.id);
    },
    /* 打开发布作业对话框 */
    openHomeworkDialog () {
      this.homeworkDialog = true;
    },
    handleClose () {
      this.homeworkDialog = false;
      /* 数据重置 */
      this.$refs.upload.clearFiles();
      this.$refs.formRef.resetFields();
      this.uploadHomework = {
        annexDTO: {
          fileName: '',
          fileSize: 0,
          fileSuffix: '',
          fileType: 'homework' /* 对应文件类型 */,
          objectname: '',
          resourceType: 'class' /* 对应资源大类型 */,
        },
        content: '',
        name: '',
        classId: '',
        file: '',
      };
    },
    /* 获取上传凭证 */
    async getUploadToken () {
      const { data } = await this.$axios.post('/teach/courseware/ossSignature');
      if (data.code !== 200) {
        return this.$message.error('获取签名失败');
      }
      /* 2.获取响应数据 */
      this.createHomework(data.data);
    },
    /* 上传文件 */
    uploadSectionFile (file) {
      this.file = file;
      this.uploadHomework.file = this.file.file.name;

      let size = this.file.file.size / 1024 / 1024;

      /* 大于20mb禁止上传 */
      if (size > 20) {
        this.assignmentScore.file = '';
        this.$refs.upload.clearFiles();
        this.$message.error('文件大小不能超过20MB');
      }
    },

    /* 发布作业 */
    createHomework (list) {
      /* 文件信息整理 */
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) return;
        // console.log(this.file.file.name);
        let fileName = this.file.file.name;
        let upName = fileUtil.getFileName(fileName, list.dir);
        let fd = new FormData();

        fd.append('key', upName);
        fd.append('policy', list.policy);
        fd.append('OSSAccessKeyId', list.accessId);
        fd.append('success_action_status', '200');
        fd.append('signature', list.signature);
        fd.append('file', this.file.file, fileName);

        let config = {
          headers: {
            'Content-Type': 'multipart/from-data',
            x: 'y',
          },
        };
        /* 上传文件 */
        const { status } = await this.$axios.post(list.host, fd, config);

        if (status !== 200) {
          return this.$message.error('上传作业失败');
        }
        /* 处理后台所需数据 */
        this.uploadHomework.classId = this.classId;
        this.uploadHomework.annexDTO.fileName = this.file.file.name;
        this.uploadHomework.annexDTO.fileSize = this.file.file.size;
        this.uploadHomework.annexDTO.fileSuffix =
          this.file.file.name.split('.')[1];
        this.uploadHomework.annexDTO.objectname = upName;

        /* 上传到服务器 */
        const { data } = await this.$axios.post(
          '/teach/class/uphomework',
          this.uploadHomework
        );
        if (data.code !== 200) {
          return this.$message.error(data.message);
        }
        this.$message.success(data.message);
        this.getHomeWork(this.classId);
        this.handleClose();
        this.homeworkDialog = false;
      });
    },
    /* 打开查看作业详情对话框 */
    async openCheckWork (id) {
      // this.homeworkTable = [];
      this.loading = true;
      this.checkWork = true;
      this.assignmentScore.markedId = id;
      this.assignmentScore.markId = id;
      const { data } = await this.$axios.get(`/teach/class/findhomedetile`, {
        params: this.assignmentScore,
      });
      if (data.code !== 200) {
        this.loading = false;
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        this.loading = false;
        return this.$message.warning('暂时没有数据');
      }
      this.loading = false;
      this.homeworkTable = data.data.items;
      this.totalPage1 = data.data.total;
    },
    /* 作业提交详情分页 */
    checkCurrentChange (current) {
      console.log(current);
      this.assignmentScore.currents = current;
      this.openCheckWork(this.assignmentScore.markId);
    },
    closeCheckWork () {
      this.checkWork = false;
      this.homeworkTable = [];
      this.assignmentScore.currents = 1;
    },
    /* 查看班级作业 */
    async getHomeWork (classId) {
      this.tableData = [];
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const { data } = await this.$axios.get(
        `/teach/class/findhomework?classId=${classId}&page=${this.currentPage}&rows=${this.pageSize}`
      );

      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      } else {
        if (data.data.items.length == 0) {
          setTimeout(() => {
            loading.close();
          }, 500);
          return this.$message.warning('暂时没有作业');
        }
      }
      this.tableData = data.data.items;
      this.totalPage = data.data.total;
      setTimeout(() => {
        loading.close();
      }, 500);
    },
    /* 班级作业分页 */
    handleChangePage (current) {
      console.log(current);
      this.currentPage = current;
      this.getHomeWork(this.classId);
    },

    /* 删除作业 */
    deleteHomework (homeworkId) {
      this.$confirm('是否删除该作业?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          // /teach/class/delhomework?homeworkId
          const { data } = await this.$axios.delete(
            `/teach/class/delhomework?homeworkId=${homeworkId}`
          );
          if (data.code !== 200) {
            return this.$message.error('删除作业失败');
          }
          this.$message.success('删除作业成功');
          this.getHomeWork(this.classId);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    /* 打开评分对话框 */
    openScore (row) {
      this.scoreForm.userId = row.userId;
      this.scoreForm.markId = row.markId;
      this.isScore = true;
      if (row.grade !== -1) {
        this.scoreForm.grade = row.grade;
      }
    },
    /* 修改评分 */
    async subScore () {
      if (this.scoreForm.grade > 100 || this.scoreForm.grade < 0) {
        this.$message({
          type: 'error',
          message: '评分在0到100之间',
        });
        return;
      }
      const { data } = await this.$axios.post(
        `/teach/class/markHomework?markType=homework&markId=${this.scoreForm.markId}&userId=${this.scoreForm.userId}&grade=${this.scoreForm.grade}`
      );

      if (data.code !== 200) {
        return this.$message.error('评分失败');
      }
      this.$message.success('评分成功');
      this.scoreForm.grade = '';
      this.isScore = false;
      /* 获取最新数据 */
      this.openCheckWork(this.scoreForm.markId);
    },
    /* 关闭评分 */
    closeScore () {
      this.isScore = false;
    },

    /* 证书管理员获取班级菜单 */
    async getClassListMenu () {
      this.tableData = [];
      /* 加载动画 */
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      /* 获取证书id  saveSilderId*/
      let id = localStorage.getItem('silderId');

      let { data } = await this.$axios.get(
        `/teach/cerificate/findCerClass?cerificateId=${id}&page=${1}&rows=${100}`
      );
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.warning('暂时没有班级信息');
      }
      this.classMenu = data.data.items;
      /* 初始默认是第一个 */
      if (sessionStorage.getItem('classId') == 'null') {
        sessionStorage.setItem('classId', data.data.items[0].id);
        sessionStorage.setItem('menuIndex', 0);
        sessionStorage.setItem('className', data.data.items[0].name);
        this.classId = data.data.items[0].id;
        this.choice = 0;
      }
      this.getHomeWork(this.classId);
      setTimeout(() => {
        loading.close();
      }, 500);
    },

    /* 学生端上传作业 */
    openUplodHomework (item, i) {
      this.uploadWorkForm.homeId = item.id;
      this.isUploadWork = true;
      if (i === 2) {
        this.uploadWorkForm.website = item.url;
      } else {
        this.uploadWorkForm.website = '';
      }
    },
    closeUploadHomework () {
      this.isUploadWork = false;
      this.$refs.uploadWorkRef.resetFields();
      this.uploadWorkForm.homeId = '';
    },
    uploadWork () {
      // console.log(this.uploadWorkForm, 'this.uploadWorkForm');
      this.$refs.uploadWorkRef.validate(async (valid) => {
        if (!valid) return;
        // ?homeId=${this.uploadWorkForm.homeId}&website='${this.uploadWorkForm.website}'
        const { data } = await this.$axios.post(
          `/teach/student/addhomeword`,
          this.uploadWorkForm
        );
        if (data.code !== 200) {
          return this.$message.error(data.message);
        }
        this.$message.success(data.message);
        this.closeUploadHomework();
        this.getHomeWork(this.classId);
      });
      // console.log('提交作业');
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-homework {
  padding: 16px 22px;
  display: flex;
  justify-content: space-between;
  .side-menu {
    width: 300px;
    margin: 0 16px 0 0;
    background-color: #fff;
    border-radius: 4px;
    li {
      padding: 0 20px;
      height: 40px;
      margin: 6px;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    li:hover {
      border-radius: 4px;
      background: #f1f3f5;
    }
    .li-active {
      border-radius: 4px;
      background: #f1f3f5;
    }
  }
}
.is-student {
  display: block;
  .zksc-homework-c {
    width: 100%;
  }
}
.zksc-homework-c {
  width: calc(100% - 300px - 22px);
  padding-bottom: 30px;
  min-height: calc(100vh - 30px - 56px - 56px - 32px);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  .zksc-homework-title {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 14px;
      font-weight: normal;
      padding-left: 16px;
    }
    .el-button {
      margin-right: 16px;
    }
  }
  .el-table {
    padding: 0 16px 16px;
    .homework-time {
      color: #2881f6;
    }
  }
  /deep/.el-dialog {
    min-width: 660px;
  }
}

// /deep/.zksc-dialog {
//   .el-dialog__header {
//     height: 70px !important;
//     background: #409eff !important;
//     padding: 0 !important;

//     line-height: 70px;
//     text-align: center;
//     .el-dialog__title {
//       color: #ffffff !important;
//       font-size: 24px !important;
//     }
//     .el-dialog__headerbtn {
//       top: 30px;
//       right: 20px;
//     }
//     .el-dialog__close {
//       color: #fff !important;
//     }
//   }
//   .el-form {
//     width: 500px;
//     margin: 0 auto;
//   }
//   .el-button {
//     width: 140px;
//     margin-right: 30px;
//   }
// }

/deep/.zksc-upload {
  .el-upload-dragger {
    background-color: #f1f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .svg-icon {
      font-size: 30px;
      color: #409eff;
    }
  }
  .el-upload__text {
    p {
      margin: 14px 0;
      padding: 0;
      line-height: 1em;
    }
    .file-type-size {
      display: flex;
      flex-direction: column;
      color: #ccc;
      font-size: 12px;
      span {
        margin-bottom: 4px;
      }
    }
  }
}
.addHomeworkBox {
  width: 552px;
  margin: 0 auto;
}
.zksc-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.zksc-work-link {
  cursor: pointer;
  color: #409eff;

  font-size: 14px;
}
.zksc-work-score {
  cursor: pointer;
}
.el-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}

.zksc-dialog {
  .btn {
    width: 100%;
    display: inline-block;
    text-align: center;
    button {
      width: 140px;
    }
  }
  .modify {
    margin-left: 10px;
    color: #409eff;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
