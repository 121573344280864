<template>
  <div class="box">
    <div class="sidemenu">
      <SideMenu
        :menuLists="menuList"
        v-on:getIds="getMenuId"
        :type="types"
        v-if="list"
      ></SideMenu>
    </div>
    <div class="topic">
      <div class="topic_title" v-show="allFlag">
        <p>
          <span v-for="(item, index) in silderData" :key="index">{{
            index >= 1 ? ' / ' + item.name : item.name
          }}</span>
        </p>
        <p class="topic_class" v-show="menuFlag === false">
          题目类型:
          <span
            @click="(btnFlag = !btnFlag), typeHandle()"
            :class="{ cur: btnFlag === true }"
            >单选题</span
          >
          <span
            @click="(btnFlag = !btnFlag), typeHandle()"
            :class="{ cur: btnFlag === false }"
            >判断题</span
          >
        </p>
        <!-- <div v-show="buttonFlag"> -->
        <div v-show="role !== 'student'">
          <div class="btn_box" v-show="role !== 'admin'">
            <span v-show="buttonFlag" type="primary" @click="menuFlags()">{{
              menuFlag === false ? '新建理论题' : '新建实操题'
            }}</span>
            <span
              class="info"
              @click="dialogUpload = true"
              v-if="menuFlag === false"
              v-show="buttonFlag"
              >下载模板</span
            >
            <span
              class="info"
              @click="dialogLead = true"
              v-if="menuFlag === false"
              v-show="buttonFlag"
              >导入理论题</span
            >
            <span
              type="info"
              class="yellowBtn"
              @click="dialogVisible = true"
              v-show="buttonFlag"
              >转化为公共资源</span
            >
          </div>
        </div>
        <div
          v-show="role === 'admin' || role === 'student'"
          class="zhanweikuang"
        ></div>
      </div>
      <div class="form_table" v-show="allFlag">
        <div v-if="menuFlag === true">
          <el-table
            :data="tableDatas"
            stripe
            v-if="tableDatas"
            style="width: 100%"
            :header-cell-style="{ color: '#333333' }"
          >
            <el-table-column prop="date" label="序号" width="86">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="试题名称"
              width="191"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="required"
              label="实操题要求"
              width="508"
              show-overflow-tooltip
            >
            </el-table-column>
            <!-- <el-table-column prop="type" label="题型" width="78">
          </el-table-column>
          <el-table-column prop="grade" label="分值" width="78">
          </el-table-column>
          <el-table-column prop="people" label="创建人" width="78">
          </el-table-column> -->
            <el-table-column prop="createTime" label="发布时间" width="186">
            </el-table-column>
            <el-table-column prop="time" label="附件下载" width="256">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  :href="scope.row.quesionurl"
                  @click="onLoad(scope.row)"
                  ><icon-svg icon-class="download"></icon-svg>下载</el-link
                >
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" width="283">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  v-if="menuFlag === false"
                  v-show="buttonFlag"
                  style="border: 1px solid #666666"
                  >编辑</el-button
                >
                <el-button size="small" type="info" @click="previews(scope.row)"
                  >预览</el-button
                >
                <el-button
                  size="small"
                  type="warning"
                  @click="delitem(scope.row)"
                  v-show="buttonFlag"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-empty v-else description="暂无内容"></el-empty>
        </div>
        <div v-else>
          <el-table
            :data="tableData"
            stripe
            v-if="tableData"
            style="width: 100%"
            :header-cell-style="{ color: '#333333' }"
          >
            <el-table-column label="序号" width="86">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="name" label="试题名称" width="691">
            </el-table-column>
            <!-- <el-table-column prop="address" label="实操题要求" width="98">
          </el-table-column> -->
            <el-table-column v-if="menuFlag === false" label="题型" width="78">
              <template slot-scope="scope">
                {{ scope.row.type === 'choice' ? '单选题' : '判断题' }}
              </template>
            </el-table-column>
            <el-table-column prop="fraction" label="分值" width="78">
            </el-table-column>
            <!-- <el-table-column prop="people" label="创建人" width="78">
          </el-table-column> -->
            <el-table-column prop="updateTime" label="发布时间" width="236">
            </el-table-column>
            <!-- <el-table-column prop="time" label="附件下载" width="156">
            <template slot-scope="scope"> 
              <el-link type="primary" @click="onLoad(scope.row)"
                ><icon-svg icon-class="download"></icon-svg>下载</el-link
              >
            </template>
          </el-table-column>-->
            <el-table-column prop="address" label="操作" width="283">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  v-if="menuFlag === false"
                  v-show="buttonFlag"
                  style="border: 1px solid #666666"
                  @click="compile(scope.row)"
                  >编辑</el-button
                >
                <el-button size="small" type="info" @click="previews(scope.row)"
                  >预览</el-button
                >
                <el-button
                  size="small"
                  type="warning"
                  @click="delitem(scope.row)"
                  v-show="buttonFlag"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-empty v-else description="暂无内容"></el-empty>
        </div>
      </div>
      <div class="block" v-show="allFlag">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 预览某一项 -->
    <el-dialog
      :title="menuFlag === false ? '题目详情' : previewData.name"
      :visible.sync="preview"
      @close="closeBoxs()"
      class="preview"
      center
    >
      <div class="topicContent" v-if="menuFlag === false">
        <div class="header">
          <span class="spans"
            >题目类型：{{
              previewData.type === 'choice' ? '单选题' : '判断题'
            }}</span
          >
          <span class="spans">分值：{{ previewData.fraction }}</span>
          <el-button
            @click="showAnswer = !showAnswer"
            :type="showAnswer === false ? 'primary' : 'null'"
            >{{ showAnswer === false ? '显示答案' : '隐藏答案' }}</el-button
          >
          <span class="daan" v-show="showAnswer"
            >正确答案：{{ previewData.answer }}</span
          >
        </div>
        <div class="centers">
          <p>题目：{{ previewData.name }}</p>
          <ul>
            <li>A：{{ previewData.optionsA }}</li>
            <li>B：{{ previewData.optionsB }}</li>
            <li v-show="previewData.type !== 'judge'">
              C：{{ previewData.optionsC }}
            </li>
            <li v-show="previewData.type !== 'judge'">
              D：{{ previewData.optionsD }}
            </li>
          </ul>
          <span v-show="showAnswer">解析：{{ previewData.parsing }}</span>
          <br />
          <button @click="switchoverTopic(previewData)">上一题</button>
          <button @click="switchoverTopics(previewData)">下一题</button>
        </div>
      </div>
      <div class="previewPractical" v-else>
        <div class="content">{{ previewData.required }}</div>
        <a :href="previewData.quesionurl">
          <el-button type="primary">
            <icon-svg icon-class="download"></icon-svg>下载
          </el-button>
        </a>
      </div>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog
      :title="menuFlag === false ? '删除理论题' : '删除实操题'"
      :visible.sync="delTabItem"
      center
    >
      <p class="title_p">
        删除后不可恢复，确定删除此{{
          menuFlag === false ? '删除理论题' : '删除实操题'
        }}吗？
      </p>
      <!-- <span class="title_span"
        >建议提交整套课件资源，这样审核更加容易通过哦！</span
      > -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="delOne">提 交</el-button>
        <el-button @click="delTabItem = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 提交 -->
    <el-dialog title="转化为公共资源" :visible.sync="dialogVisible" center>
      <p class="title_p">审核通过后，将出现在公共课件资源区</p>
      <span class="title_span">建议提交整套资源，这样审核更加容易通过哦！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addPublic">提 交</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog
      title="导入试题"
      @close="closeAdd"
      :visible.sync="dialogLead"
      center
      v-loading="loading"
      element-loading-text="拼命上传中..."
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="lead_box">
        <span><em class="hongdian"></em> 题目类型 </span>
        <el-radio v-model="uploadType" label="choice">单选题</el-radio>
        <el-radio v-model="uploadType" label="judge">判断题</el-radio>
      </div>
      <el-upload
        accept=".xls"
        class="upload-demo"
        drag
        ref="uploadLilun"
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
        :on-preview="handlePictureCardPreview"
        :auto-upload="false"
        :on-remove="handleRemove"
        :limit="1"
        :on-change="handleChansge"
      >
        <icon-svg class="shangchaun" icon-class="shangchuan1"></icon-svg>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">请按照模板上传</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFile" :disabled="loading"
          >提 交</el-button
        >
        <el-button @click="dialogLead = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 下载 -->
    <el-dialog title="下载模板" :visible.sync="dialogUpload" center>
      <div class="lead_box">
        <span><em class="hongdian"></em> 题目类型 </span>
        <el-radio v-model="upload" label="1">单选题</el-radio>
        <el-radio v-model="upload" label="2">判断题</el-radio>
      </div>
      <div class="clickXiazai" @click="uploaddanxuan">
        <span class="icons">
          <icon-svg icon-class="xiazai"></icon-svg>
        </span>
        <p>点击此处，下载Excel模板</p>
      </div>
    </el-dialog>
    <!-- 添加理论题 -->
    <el-dialog
      :title="alterTopic === true ? '编辑试题' : '添加试题'"
      @close="closeDialog()"
      :visible.sync="dialogAddlilun"
      center
    >
      <div class="lead_box_input">
        <!-- <div>
          <span><em class="hongdian"></em> 题目类型 </span>
          <el-radio v-model="radio" label="1">单选题</el-radio>
          <el-radio v-model="radio" label="2">多选题</el-radio>
        </div> -->
        <div>
          <span><em class="hongdian"></em> 分值 </span>
          <el-input-number
            v-model="num"
            controls-position="right"
            @change="handleChange"
            :min="1"
            :max="10"
          ></el-input-number>
        </div>
        <div>
          <span><em class="hongdian"></em> 题目名称 </span>
          <el-input v-model="addFrom.name" placeholder="请输入内容"></el-input>
        </div>
        <div>
          <span><em class="hongdian"></em> 选项A </span>
          <el-input
            v-model="addFrom.A"
            :disabled="!btnFlag"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div>
          <span><em class="hongdian"></em> 选项B </span>
          <el-input
            v-model="addFrom.B"
            :disabled="!btnFlag"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div v-show="btnFlag">
          <span><em class="hongdian"></em> 选项C </span>
          <el-input v-model="addFrom.C" placeholder="请输入内容"></el-input>
        </div>
        <div v-show="btnFlag">
          <span><em class="hongdian"></em> 选项D </span>
          <el-input v-model="addFrom.D" placeholder="请输入内容"></el-input>
        </div>
        <div>
          <span><em class="hongdian"></em> 答案 </span>
          <el-radio v-model="radio" label="A">A</el-radio>
          <el-radio v-model="radio" label="B">B</el-radio>
          <el-radio v-show="btnFlag" v-model="radio" label="C">C</el-radio>
          <el-radio v-show="btnFlag" v-model="radio" label="D">D</el-radio>
        </div>
        <div class="jeixi">
          <span><em class="hongdian" style="opacity: 0"></em> 解析 </span>
          <el-input
            v-model="addFrom.analysis"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          :type="alterTopic === true ? 'warning' : 'primary'"
          @click="submitadd()"
          >{{ alterTopic === true ? '修 改' : '提 交' }}</el-button
        >
        <el-button @click="dialogAddlilun = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 添加实操题 -->
    <el-dialog
      title="新增实操题"
      class="addshicao"
      @close="closeBox"
      :visible.sync="dialogAddshicao"
      center
      v-loading="loading"
      element-loading-text="拼命上传中..."
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="lead_box_input">
        <div>
          <span><em class="hongdian"></em> 实操题名称 </span>
          <el-input
            v-model="addPractical.name"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div>
          <span> 实操题要求 </span>
          <el-input
            v-model="addPractical.demand"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div>
          <span>上传作业附件</span>
          <el-upload
            class="upload-demo"
            ref="uploadShicao"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple
            :on-preview="handlePictureCardPreviews"
            :auto-upload="false"
            :on-remove="handleRemoves"
            :limit="1"
            :on-change="handleChansges"
          >
            <icon-svg class="shangchaun" icon-class="shangchuan1"></icon-svg>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              <!-- 只能上传jpg/png文件，且不超过500kb -->
            </div>
          </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="postOss()">提 交</el-button>
        <el-button @click="dialogAddshicao = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import IconSvg from '../../icon/iconSvg.vue';
import SideMenu from '../../sideMenus';
import fileUtil from '../../../utils/index';
export default {
  components: {
    SideMenu,
    IconSvg,
  },
  data() {
    return {
      loading: false,
      currentPage3: 1,
      pageCount: 10,
      total: 0,
      pageSize: 10,
      btnFlag: true,
      radio: '',
      uploadType: '',
      delTabItem: false,
      preview: false,
      clickMenuId: '',
      addFrom: {
        name: '',
        A: '',
        B: '',
        C: '',
        D: '',
        analysis: '',
      },
      addPractical: {
        name: '',
        demand: '',
      },
      dialogVisible: false,
      dialogLead: false,
      dialogUpload: false,
      dialogAddlilun: false,
      dialogAdd: false,
      dialogAddshicao: false,
      menuFlag: true,
      alterTopic: false,
      showAnswer: false,
      topicType: 'choice',
      num: 1,
      upload: '',
      input: '',
      // 上传理论题文件
      file: '',
      files: { file: '' },
      // 上传实操题文件
      practicals: {
        file: '',
        status: '',
      },
      practical: '',
      labels: 'A',
      list: false,
      clickItemId: '',
      clickItem: {},
      previewData: {},
      tableData: [],
      tableDatas: [],
      types: '',
      silderData: [],
      allFlag: false,
      buttonFlag: true,
      silderId: null,
      role: null,
    };
  },
  created() {
    this.silderId = localStorage.getItem('silderId');
    this.role = sessionStorage.getItem('role');
    this.getList();
  },
  methods: {
    //关闭新增理论题对话框的时候
    closeBoxs() {
      this.num = 1;
      this.addFrom.name = '';
      this.addFrom.A = '';
      this.addFrom.B = '';
      this.addFrom.C = '';
      this.addFrom.D = '';
      this.radio = '';
      this.addFrom.analysis = '';
    },
    // 提交到公共资源库
    addPublic() {
      if (this.menuFlag === true) {
        if (this.tableDatas.length < 1) {
          this.$message({
            type: 'error',
            message: '请上传内容',
          });
          return;
        }
      } else {
        if (this.tableData.length < 1) {
          this.$message({
            type: 'error',
            message: '请上传内容',
          });
          return;
        }
      }
      let data = {
        menuId: this.clickMenuId,
        rescourseType: '题库资源',
      };
      this.$axios.post('/teach/courseware/toConversion', data).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: 'success',
            message: res.data.message,
          });
          this.dialogVisible = false;
        } else {
          this.$message({
            type: 'error',
            message: res.data.message,
          });
        }
      });
    },
    closeAdd() {
      this.$refs.uploadLilun.clearFiles();
      this.uploadType = '';
    },
    // 关闭新增实操题对话框
    closeBox() {
      this.practical = '';
      this.addPractical.name = '';
      this.addPractical.demand = '';
      this.$refs.uploadShicao.clearFiles();
    },
    // 提交
    postOss() {
      // (dialogAdd = false)
      // let that = this;
      const that = this;
      this.loading = true;
      this.$axios.post('/teach/courseware/ossSignature').then((res) => {
        if (res.data.code === 200) {
          // this.ossData = res.data.data;
          // console.log(res.data);
          Promise.all([this.postPractical(res.data.data)]).then((res) => {
            // console.log(res);
            if (res.length !== 0) {
              // const zip = {
              //   unzipCode: that.decompressionPsw,
              //   uploadCode: that.extractPsw,
              //   uploadUrl: that.links,
              // };
              const data = {
                // deleted: null,
                // id: null,
                // likes: null,
                // downloadDTO: zip,
                annexDTO: res[0],
                menuId: that.clickMenuId,
                name: that.addPractical.name,
                required: that.addPractical.demand,
                type: 'pricate',
              };
              that.$axios
                .post('/teach/exam/insertShiQuestion', data)
                .then((res) => {
                  // console.log(res);
                  if (res.data.code === 200) {
                    that.$message({
                      message: '添加成功!',
                      type: 'success',
                    });
                    this.loading = false;
                    this.dialogAddshicao = false;
                    this.addPractical.name = '';
                    this.addPractical.demand = '';
                    that.getPracticalTopicList();
                  } else {
                    that.$message({
                      message: '添加失败!',
                      type: 'error',
                    });
                  }
                });
            }
          });
        }
      });
    },
    //导入实操题
    postPractical(data) {
      return new Promise((res) => {
        const that = this;
        // this.loading = true;
        // console.log(id+'测试i')
        // if (this.file === '') {
        //   that.$notify.error({
        //     title: '错误',
        //     message: '图片未选择',
        //   })
        //   return false
        // } else {
        // 文件形式的需要用 formData形式\
        let filename = this.practical.name;
        filename = fileUtil.getFileName(filename, data.dir);
        let formData = new FormData();

        formData.append('key', filename);
        formData.append('policy', data.policy);
        formData.append('OSSAccessKeyId', data.accessId);
        formData.append('success_action_status', '200');
        formData.append('signature', data.signature);
        formData.append('file', this.practical);
        // console.log(formData);

        let config = {
          headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
        };
        // this.btn.disable = true;
        // this.btn.message = "上传中，请等待";
        this.$axios.post(data.host, formData, config).then(function (response) {
          // console.log(response);
          if (response.status < 300) {
            that.practicals.status = 1;
            const data = {
              fileName: that.practicals.file.name,
              fileSize: that.practicals.file.size,
              fileSuffix: that.practicals.file.name.split('.')[1],
              fileType: 'pricate',
              objectname: filename,
            };
            // console.log(data);
            // this.loading = false;
            res(data);

            // that.resData.push(data);
          } else {
            // this.loading = false;
            that.$message({
              type: 'error',
              message: '文件上传失败！',
            });
          }
        });
      });
    },
    //导入理论题
    submitFile() {
      // return new Promise((res) => {
      // this.dialogVisible = false;
      if (this.uploadType === '') {
        this.$message({
          type: 'error',
          message: '请先选择上传的题型！',
        });
      } else {
        // this.loading = true;
        // console.log(this.files);
        // console.log(this.files.file);
        this.loading = true;
        const that = this;
        let formData = new FormData();

        formData.append('file', this.file);

        let config = {
          headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
        };
        this.$axios
          .post(
            `/teach/exam/uploadChoice?menuId=${this.clickMenuId}&type=${this.uploadType}`,
            formData,
            config
          )
          .then(function (response) {
            // console.log(response);
            if (response.data.code === 200) {
              that.$message({
                type: 'success',
                message: '上传成功！',
              });
              that.loading = false;
              that.dialogLead = false;
              that.getTopicList();
            } else {
              that.loading = false;
              that.$message({
                type: 'error',
                message: '上传失败！',
              });
            }
          });
      }
      // });
    },
    // 下载模板
    uploaddanxuan() {
      // console.log(this.upload);
      if (this.upload === '') {
        this.$message({
          type: 'error',
          message: '请选择下载类型',
        });
      } else if (this.upload == 1) {
        window.open('api2/templete/单选题.xls');
      } else if (this.upload == 2) {
        window.open('api2/templete/判断题.xls');
      } else {
        return;
      }
    },
    // 上一题、下一题
    switchoverTopic(data) {
      if (data.status !== '0') {
        this.$axios
          .get(
            `/teach/exam/findlastExam?questionId=${
              data.id
            }&status=${0}&menuId=${this.clickMenuId}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.previewData = res.data.data;
            }
          });
      } else {
        this.$message({
          type: 'error',
          message: '已经是第一题',
        });
      }
    },
    switchoverTopics(data) {
      if (data.status !== '1') {
        this.$axios
          .get(
            `/teach/exam/findlastExam?questionId=${
              data.id
            }&status=${1}&menuId=${this.clickMenuId}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.previewData = res.data.data;
            }
          });
      } else {
        this.$message({
          type: 'error',
          message: '已经是最后一题',
        });
      }
    },
    // 获取理论题目列表
    getTopicList() {
      if (this.clickMenuId === '') {
        this.$message({
          type: 'error',
          message: '请选择目录！',
        });
      } else {
        this.$axios
          .get(
            `/teach/exam/findMenuLIQuestion?menuId=${this.clickMenuId}&type=${
              this.btnFlag === true ? 'choice' : 'judge'
            }&page=${this.currentPage3}&rows=${this.pageSize}`
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.code === 200) {
              console.log(res.data.data.total);
              this.total = res.data.data.total;
              this.tableData = res.data.data.items;
            }
          });
      }
    },
    // 获取实操题目列表
    getPracticalTopicList() {
      const that = this;
      if (this.clickMenuId === '') {
        this.$message({
          type: 'error',
          message: '请选择目录！',
        });
      } else {
        this.$axios
          .get(
            `/teach/exam/findMenuLIQuestion?menuId=${
              this.clickMenuId
            }&type=${'pricate'}&page=${this.currentPage3}&rows=${this.pageSize}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              that.total = res.data.data.total;
              that.tableDatas = res.data.data.items;
            }
          });
      }
    },
    // 添加理论题
    submitadd() {
      // this.alterTopic = false;
      // this.dialogAdd = false;
      if (this.alterTopic === false) {
        // console.log(
        //   '分值--------' + this.num,
        //   '题目名称--------' + this.addFrom.name,
        //   'A--------' + this.addFrom.A,
        //   'B--------' + this.addFrom.B,
        //   'C--------' + this.addFrom.C,
        //   'D--------' + this.addFrom.D,
        //   '解析--------' + this.addFrom.analysis,
        //   '正确答案--------' + this.radio
        // );
        const data = {
          fraction: this.num,
          name: this.addFrom.name,
          optionsA: this.addFrom.A,
          optionsB: this.addFrom.B,
          optionsC: this.addFrom.C,
          optionsD: this.addFrom.D,
          answer: this.radio,
          parsing: this.addFrom.analysis,
          type: this.topicType,
          menuId: this.clickMenuId,
        };
        this.$axios.post('/teach/exam/insertLiQuestion', data).then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '添加成功',
            });
            this.num = 1;
            this.addFrom.name = '';
            this.addFrom.A = '';
            this.addFrom.B = '';
            this.addFrom.C = '';
            this.addFrom.D = '';
            this.radio = '';
            this.addFrom.analysis = '';
            this.getTopicList();
            this.dialogAdd = false;
            this.dialogAddlilun = false;
          } else {
            this.$message({
              type: 'error',
              message: '添加失败',
            });
          }
        });
      } else {
        this.alterTopicData();
      }
    },
    // 根据不同的状态展示不同的对话框
    menuFlags() {
      if (this.menuFlag === false) {
        this.dialogAddlilun = true;
      } else {
        this.dialogAddshicao = true;
      }
    },
    // 根据不同的状态切换不同的题型
    typeHandle() {
      if (this.btnFlag === true) {
        this.topicType = 'choice';
        // console.log(this.topicType);
        this.addFrom.A = '';
        this.addFrom.B = '';
        this.getTopicList();
      } else {
        this.topicType = 'judge';
        this.addFrom.A = '正确';
        this.addFrom.B = '错误';
        // console.log(this.topicType);
        this.getTopicList();
      }
    },
    // 获取到侧边栏id，并根据name来切换状态
    getMenuId(item) {
      // this.itemlists = []
      // console.log(item.id);
      this.clickItem = item;
      this.clickMenuId = item.id;
      // console.log(this.clickMenuId);
      if (item.parentname === '理论题库') {
        this.menuFlag = false;
        this.getTopicList();
      } else if (item.parentname === '实操题库') {
        this.menuFlag = true;
        this.getPracticalTopicList();
      }
      // console.log(this.menuFlag);
      if (item.level < 3) {
        this.allFlag = false;
      } else {
        this.allFlag = true;
      }
      if (item.level === 1) {
        this.silderData = [];
        this.allFlag = false;
        this.silderData.push(item);
        if (this.silderData[0].name === '公共资源库') {
          this.buttonFlag = false;
        } else {
          this.buttonFlag = true;
        }
        if (this.role === 'student') {
          this.buttonFlag = false;
        }
        // console.log(this.silderData);
      } else if (
        item.level > this.silderData[this.silderData.length - 1].level
      ) {
        this.silderData.push(item);
        // console.log(this.silderData);
      } else if (
        item.level === this.silderData[this.silderData.length - 1].level
      ) {
        this.silderData.pop();
        this.silderData.push(item);
        // console.log(this.silderData);
      } else {
        this.silderData.pop();
        // console.log(this.silderData);
        if (this.silderData[this.silderData.length - 1].name !== item.name) {
          this.silderData.pop();
          this.silderData.push(item);
          // console.log(this.silderData);
        }
      }
      // if (item.level >= 3) {
      //   this.$axios
      //     .get(
      //       `/teach/courseware/findOneMenuWare?menuId=${item.id}&page=${this.currentPage3}&rows=${this.pageCount}`
      //     )
      //     .then((res) => {
      //       console.log(res.data);
      //       if (res.data.code === 200) {
      //         this.itemlists = res.data.data.items;
      //         this.total = res.data.data.total;
      //         this.pageSize = res.data.data.pageSize;
      //       }
      //     });
      // }
    },
    // 获取侧边栏数据
    getList() {
      const that = this;
      this.types = '题库资源';
      this.$axios
        .get(
          `/teach/courseware/findMenuWare?cerificateId=${this.silderId}&type=${this.types}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            if (that.role === 'admin' || that.role === 'superadmin') {
              that.menuList = [];
              that.menuList.push(res.data.data[0]);
            } else {
              that.menuList = res.data.data;
            }
            that.list = true;
          }
        });
    },
    //查看
    previews(item) {
      this.previewData = {};
      this.showAnswer = false;
      // console.log(item);
      this.clickItem = item;
      this.preview = true;
      this.$axios
        .get(`/teach/exam/findQuestion?questionId=${item.id}`)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code === 200) {
            this.previewData = res.data.data;
          }
        });
    },
    //删除
    delitem(item) {
      // console.log(item);
      this.clickItemId = item.id;
      this.delTabItem = true;
    },
    delOne() {
      this.$axios
        .delete(`/teach/exam/delQuestion?questionId=${this.clickItemId}`)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            if (this.menuFlag) {
              this.$message({
                type: 'success',
                message: '删除成功',
              });
              this.getPracticalTopicList();
              this.delTabItem = false;
            } else {
              this.$message({
                type: 'success',
                message: '删除成功',
              });
              this.delTabItem = false;
              this.getTopicList();
            }
          } else {
            this.$message({
              type: 'error',
              message: '删除失败',
            });
          }
        });
    },
    //编辑 先回显
    compile(item) {
      // console.log(item);
      // console.log('menuid----------', this.menuId);
      this.clickItemId = item.id;
      this.clickItem = item;
      this.dialogAddlilun = true;
      this.alterTopic = true;
      this.$axios
        .get(`/teach/exam/findQuestion?questionId=${item.id}`)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code === 200) {
            if (res.data.data.type === 'choice') {
              this.btnFlag = true;
            } else {
              this.btnFlag = false;
            }
            this.num = res.data.data.fraction;
            this.addFrom.name = res.data.data.name;
            this.addFrom.A = res.data.data.optionsA;
            this.addFrom.B = res.data.data.optionsB;
            // this.addFrom.A === 'true' ? '正确' : res.data.data.optionsA;
            // this.addFrom.B === 'false' ? '错误' : res.data.data.optionsB;
            this.addFrom.C = res.data.data.optionsC;
            this.addFrom.D = res.data.data.optionsD;
            this.radio = res.data.data.answer;
            this.addFrom.analysis = res.data.data.parsing;
            this.topicType = res.data.data.type;
          }
        });
    },
    // 提交修改
    alterTopicData() {
      const data = {
        fraction: this.num,
        name: this.addFrom.name,
        optionsA: this.addFrom.A,
        optionsB: this.addFrom.B,
        optionsC: this.addFrom.C,
        optionsD: this.addFrom.D,
        answer: this.radio,
        parsing: this.addFrom.analysis,
        type: this.topicType,
        menuId: this.clickMenuId,
        id: this.clickItemId,
      };
      this.$axios.post('/teach/exam/updatequestion', data).then((res) => {
        // console.log('662------------------' + res.data);
        if (res.data.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功',
          });
          this.dialogAddlilun = false;
          this.alterTopic = false;
          this.getTopicList();
        }
      });
    },
    handlePictureCardPreviews(file) {
      this.aa = file;
    },
    handleRemoves() {
      // console.log(file, fileList);
    },
    handleChansges(file) {
      this.practicals.file = file;
      this.practical = file.raw;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // console.log(this.dialogImageUrl)
      // console.log(file.url);
    },
    handleRemove() {
      // console.log(file, fileList);
    },
    handleChansge(file) {
      // console.log(file);
      this.files.file = file;
      this.file = file.raw;
    },
    // 关闭新增对话框的时候
    closeDialog() {
      this.alterTopic = false;
      this.num = 1;
      this.addFrom.name = '';
      this.addFrom.A = '';
      this.addFrom.B = '';
      this.addFrom.C = '';
      this.addFrom.D = '';
      this.radio = '';
      this.addFrom.analysis = '';
      if (this.btnFlag === false) {
        this.addFrom.A = '正确';
        this.addFrom.B = '错误';
      }
    },
    handleChange() {
      // console.log(value);
    },
    // 页码大小变动的时候触发
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1;
      this.pageSize = val;
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      console.log(this.menuFlag);
      // if(this.btnFlag === true){

      // }else{

      // }
      // this.getTopicList();
      if (this.menuFlag === true) {
        this.currentPage3 = val;
        this.getPracticalTopicList();
      } else {
        this.currentPage3 = val;
        this.getTopicList();
      }
      // this.getMenuId(this.clickItem);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  height: calc(100vh - 30px - 144px);
  display: flex;
}
.sidemenu {
  background: #fff;
  border-radius: 4px;
  min-width: 300px;
  height: calc(100vh - 30px - 144px);
  overflow-y: auto;
  overflow-x: hidden;
}
.topic {
  width: 100%;
  position: relative;
  height: calc(100vh - 30px - 144px);
  background: #fff;
  border-radius: 4px;
  margin-left: 16px;
}
.topic_title {
  height: 64px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    text-indent: 15px;
  }
  .btn_box {
    margin-right: 16px;
    span {
      display: inline-block;
      width: 140px;
      height: 40px;
      background: #409eff;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      margin-right: 16px;
    }
    span:hover {
      cursor: pointer;
    }
    .yellowBtn {
      background: #ffe040;
      color: #333;
      border: none;
    }
    .info {
      background: #909399;
    }
  }
  .topic_class {
    justify-content: center;
    span {
      display: inline-block;
      border: 1px solid #999999;
      color: #000;
      text-indent: 0;
      user-select: none;
      padding: 8px 20px;
    }
    span:nth-child(1) {
      border-radius: 4px 0 0 4px;
    }
    span:nth-child(2) {
      border-radius: 0 4px 4px 0;
      border-left: none;
    }
    .cur {
      background: #409eff;
      color: #ffffff;
      border: #409eff 1px solid;
    }
  }
}
.preview_p {
  width: 664px;
  margin: 0 auto;
}
.form_table {
  width: 100%;
  // margin-left: 8px;
  position: relative;
}
.lead_box {
  text-align: center;
  span {
    margin-right: 10px;
  }
}
.lead_box_input {
  width: 552px;
  margin: 0 auto;
  div {
    margin: 8px 0;
    .upload-demo {
      width: 468px;
      height: 225px;
      position: relative;
      top: -40px;
      left: 95px;
    }
    /deep/.el-upload-dragger {
      width: 438px;
      height: 225px;
    }
  }
  span {
    width: 100px;
    display: inline-block;
    margin-right: 10px;
  }
  .jeixi {
    // margin-left: 1px;
  }
}
.upload-demo {
  text-align: center;
  margin: 8px 0;
}
// /deep/.el-upload-dragger {
//   width: 300px !important;
//   height: 255px !important;
//   margin: 0 auto;
// }
.title_p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.title_span {
  display: block;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  font-size: 12px;
}
.el-input {
  width: 438px;
  margin-top: 16px;
}
.shangchaun {
  color: #409eff;
  margin: 80px 0 20px 0;
  font-size: 44px;
}

.block {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, -50%);
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
.clickXiazai {
  margin: 0 auto;
  width: 300px;
  height: 225px;
  background: #f1f3f5;
  border-radius: 4px;
  .icons {
    color: #409eff;
    font-size: 44px;
    position: relative;
    left: 42%;
    top: 30%;
  }
  p {
    position: relative;
    top: 30%;
    left: 25%;
  }
}

.preview {
  /deep/.el-dialog__body {
    margin: 0;
    padding: 0;
    .topicContent {
      width: 100%;
      min-height: 300px;
      margin: 0;
      padding: 0;
      .header {
        width: 100%;
        height: 72px;
        background: #f1f3f5;
        .spans {
          display: inline-block;
          font-size: 14px;
          line-height: 72px;
          margin: 0 16px;
        }
        .el-button {
          width: 100px;
        }
        .daan {
          margin-left: 16px;
          color: #f56c6c;
        }
      }
      .centers {
        p {
          font-size: 18px;
          margin-left: 16px;
        }
        ul {
          margin-left: 16px;
          li {
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
        span {
          display: inline-block;
          margin-left: 16px;
          margin-bottom: 32px;
        }
        button {
          display: inline-block;
          width: 140px;
          height: 40px;
          border: 1px solid #c6e2ff;
          border-radius: 4px;
          margin: 0 0 16px 16px;
          background: #ecf5ff;
          text-align: center;
          color: #409eff;
        }
      }
    }
    .previewPractical {
      width: 664px;
      margin: 32px auto;
      .el-button {
        margin-top: 32px;
        margin-bottom: 32px;
        margin-left: 43%;
      }
    }
  }
}
.zhanweikuang {
  width: 400px;
}
// .addshicao {
//   z-index: 2003 !important;
// }
/deep/.el-loading-mask {
  height: 100%;
  z-index: 2005 !important;
}
</style>
