<template>
  <!-- 评论模块 -->
  <div class="comment">
    <div class="title_btn">
      <!-- 头部切换排序方式及打分 -->
      <div class="tab_title">
        <span
          class="hot"
          :class="{ active: tabflag === 0 }"
          @click="tabchange(0)"
          >按热度排序</span
        >
        <span class="shu">|</span>
        <span
          class="time"
          :class="{ active: tabflag === 1 }"
          @click="tabchange(1)"
          >按时间排序</span
        >
        <span class="all">全部评论 {{ commentcount }}</span>
      </div>
      <div
        class="evaluate"
        v-show="messageData.myscore == -1"
        @click="openDialog = true"
      >
        课件评分
      </div>
    </div>
    <div class="one">
      <!-- 发布一级评论部分 -->
      <div class="inputBox">
        <img
          :src="heading == 'null' ? '/img/avatar.4e4aa2c3.jpg' : heading"
          alt=""
        />
        <textarea v-model="text"></textarea>
        <span class="fabiao" @click="postComment(false, false, 1)">发表</span>
        <!-- <span class="biaoqing" @click="clickFace"
          ><icon-svg icon-class="xiaolian"></icon-svg>表情</span
        > -->
        <div class="face">
          <face ref="face" @postFace="postFace"></face>
        </div>
      </div>
      <!-- 评论区 -->
      <div class="commentBox">
        <!-- 一级 -->
        <ul class="yiji" v-for="(item, index) in commentData" :key="index">
          <li>
            <!-- {{ item.imgurl }} -->
            <img
              class="imgs"
              :src="
                item.imgurl == 'null' ? '/img/avatar.4e4aa2c3.jpg' : item.imgurl
              "
              alt=""
            />
            <div class="boxs">
              <div class="commentHeader">
                <span class="name">{{ item.username }}</span>
                <span class="time">{{ item.createTime }}</span>
              </div>
              <div class="content">
                {{ item.content }}
              </div>
              <div class="commentFooter">
                <div class="left">
                  <span class="zan">
                    <span v-show="item.status == 0" @click="clickLike(item)"
                      ><icon-svg icon-class="dianzan"></icon-svg>({{
                        item.countlike
                      }}) </span
                    ><span v-show="item.status == 1" @click="clickUnLike(item)"
                      ><icon-svg
                        style="color: #409eff"
                        icon-class="dianzanshixin"
                      ></icon-svg
                      >({{ item.countlike }})
                    </span></span
                  >
                  <span class="huifu" @click="reply(1, index)"
                    >回复({{ item.countCommand }})</span
                  >
                </div>
                <div class="right">
                  <el-popover
                    class="nodeModifiy"
                    placement="right"
                    width="60"
                    trigger="hover"
                    :open-delay="delay"
                    v-if="item.ifDel == 1"
                  >
                    <!-- style="display:flex;flex-direction:column" -->
                    <div class="nodeModifiy-div">
                      <span @click.stop="deleteComment(item.id)">删除</span>
                    </div>
                    <span slot="reference">
                      <icon-svg icon-class="gengduo"></icon-svg>
                    </span>
                  </el-popover>
                </div>
              </div>
              <!-- 回复一级评论 -->
              <div class="onereply" v-show="item.isshow">
                <div class="inputBox">
                  <img
                    :src="
                      heading == 'null' ? '/img/avatar.4e4aa2c3.jpg' : heading
                    "
                    alt=""
                  />
                  <textarea class="oneContent" v-model="text1"></textarea>
                  <span
                    class="fabiao1"
                    @click="postComment(item.id, item.pubId, 2)"
                    >发表</span
                  >
                  <!-- <span class="biaoqing1" @click="clickFace"
                    ><icon-svg icon-class="xiaolian"></icon-svg>表情</span
                  > -->
                  <div class="face1">
                    <face ref="face" @postFace="postFace1"></face>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- 二级 -->
          <template v-if="item.resCommandings.length > 0">
            <ul
              class="two"
              v-for="(items, indexs) in item.resCommandings"
              :key="indexs"
            >
              <!-- <template v-show="indexs < 2"> -->
              <li v-if="indexs < item.flag || !item.flag">
                <img
                  :src="
                    items.url == 'null' ? '/img/avatar.4e4aa2c3.jpg' : items.url
                  "
                  alt=""
                />
                <div class="boxss">
                  <div class="commentHeader">
                    <span class="name"
                      >{{ items.pubUsername }} 回复
                      {{ items.subUsername }}</span
                    >
                    <span class="time">{{ items.createTime }}</span>
                  </div>
                  <div class="content">
                    {{ items.content }}
                  </div>
                  <div class="commentFooter">
                    <div class="left">
                      <span class="zan"
                        ><span
                          v-if="items.status == 0"
                          @click="clickLike(items)"
                        >
                          <icon-svg icon-class="dianzan"></icon-svg>({{
                            items.countlike
                          }})
                        </span>
                        <span v-else @click="clickUnLike(items)">
                          <icon-svg
                            style="color: #409eff"
                            icon-class="dianzanshixin"
                          ></icon-svg
                          >({{ items.countlike }})
                        </span>
                      </span>
                      <span class="huifu" @click="reply(2, indexs, index)"
                        >回复</span
                      >
                    </div>
                    <div class="right">
                      <el-popover
                        class="nodeModifiy"
                        placement="right"
                        width="60"
                        trigger="hover"
                        :open-delay="delay"
                        v-if="items.ifDel == 1"
                      >
                        <!-- style="display:flex;flex-direction:column" -->
                        <div class="nodeModifiy-div">
                          <span @click.stop="deleteComment(items.id)"
                            >删除</span
                          >
                        </div>
                        <span slot="reference">
                          <icon-svg icon-class="gengduo"></icon-svg>
                        </span>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </li>
              <!-- </template> -->

              <!-- 回复二级评论 -->
              <div class="tworeply" v-show="items.isshow">
                <div class="inputBox">
                  <img
                    class="images"
                    :src="
                      heading == 'null' ? '/img/avatar.4e4aa2c3.jpg' : heading
                    "
                    alt=""
                  />
                  <textarea class="oneContent2" v-model="text2"></textarea>
                  <span
                    class="fabiao2"
                    @click="postComment(item.id, items.pubId, 3)"
                    >发表</span
                  >
                  <!-- <span class="biaoqing2" @click="clickFace"
                    ><icon-svg icon-class="xiaolian"></icon-svg>表情</span
                  > -->
                  <div class="face2">
                    <face ref="face" @postFace="postFace2"></face>
                  </div>
                </div>
              </div>
            </ul>
            <span
              class="ckgd"
              @click="openComment(item, index)"
              v-show="item.flag == 2"
              >查看更多回复</span
            >
            <span
              class="ckgd"
              @click="closeComment(item, index)"
              v-show="item.flag == item.resCommandings.length"
              >收起回复</span
            >
          </template>
        </ul>
      </div>
    </div>
    <!--评分对话框-->
    <el-dialog title="课件评分" :visible.sync="openDialog">
      <el-rate :allow-half="true" :show-score="true" v-model="value1"></el-rate>
      <div slot="footer" class="dialog-footer">
        <el-button @click="openDialog = false">取 消</el-button>
        <el-button type="primary" @click="grade()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import face from './face/face.vue';
export default {
  components: {
    face,
  },
  data() {
    return {
      tabflag: 0,
      value1: null,
      openDialog: false,
      commentData: [],
      commentcount: 0,
      text: '',
      text1: '',
      text2: '',
      delay: 800,
      faceText: '',
      heading: '',
    };
  },
  props: {
    menuIds: String,
    messageData: {},
    getMessageDatas: Function,
    courseId: String,
  },
  created() {
    // console.log(this.messageData);
    // console.log(this.courseId);
    // if( ===)
    this.getComment();
    this.heading = sessionStorage.getItem('header');
    // console.log(this.heading);
  },
  methods: {
    openComment(item) {
      // console.log(item.resCommandings.length, index);
      // console.log();
      this.$set(item, 'flag', item.resCommandings.length);
      // console.log(item);
    },
    closeComment(item) {
      // console.log(item, index);
      // console.log();
      this.$set(item, 'flag', 2);
    },
    // 获取子组件传过来的表情
    postFace(value) {
      this.faceText = value;
      // console.log(this.faceText);
      this.text += this.faceText;
    },
    postFace1(value) {
      this.faceText = value;
      // console.log(this.faceText);
      this.text1 += this.faceText;
    },
    postFace2(value) {
      this.faceText = value;
      // console.log(this.faceText);
      this.text2 += this.faceText;
    },
    clickFace() {
      this.$refs.face.faceContent();
    },
    //删除评论
    deleteComment(id) {
      this.$axios
        .delete(`/teach/command/delCommand?commandId=${id}`)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.getComment();
            this.$message({ type: 'success', message: '删除成功！' });
          } else {
            this.$message({ type: 'error', message: res.data.message });
          }
        });
    },
    // 控制每一条评论的回复框
    reply(i, index, e) {
      console.log(i, index);
      if (i == 1) {
        // this.commentData[index];
        if (this.commentData[index].isshow == false) {
          this.text1 = '';
          this.$set(this.commentData[index], 'isshow', true);
        } else {
          this.$set(this.commentData[index], 'isshow', false);
          this.text1 = '';
        }
      } else {
        // console.log(index);
        if (this.commentData[e].resCommandings[index].isshow == false) {
          this.text2 = '';
          this.$set(this.commentData[e].resCommandings[index], 'isshow', true);
        } else {
          this.$set(this.commentData[e].resCommandings[index], 'isshow', false);
          this.text2 = '';
        }
      }
    },
    // 发表评论
    postComment(id, toId, i) {
      // 第一个接收的是一级评论的id
      //第二个参数接收的是接收者的id
      if (
        (this.text === '' && i == 1) ||
        (this.text1 === '' && i == 2) ||
        (this.text2 === '' && i == 3)
      ) {
        this.$message({ type: 'warning', message: '请输入评论内容！' });
        return;
      }
      let content = i == 1 ? this.text : i == 2 ? this.text1 : this.text2;
      let ids = id ? id : -1;
      let toIds = toId ? toId : -1;
      let data = {
        content: content,
        parentId: ids,
        resourceId: this.menuIds,
        subId: toIds,
        courseId: this.courseId,
      };
      // console.log(data);
      this.$axios.post('/teach/command/addCommand', data).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.$message({
            type: 'success',
            message: '评论成功！',
          });
          i == 1
            ? (this.text = '')
            : i == 2
            ? (this.text1 = '')
            : (this.text2 = '');
          this.getComment();
        } else {
          this.$message({
            type: 'error',
            message: res.data.message,
          });
        }
      });
    },
    // 取消点赞
    clickUnLike(item) {
      let data = {
        commandId: item.id,
        type: 1,
      };
      this.$axios.post('/teach/command/addCommandLikes', data).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          // this.getComment();
          item.status = 0;
          item.countlike = item.countlike - 1;
        } else {
          this.$message({ type: 'success', message: res.data.message });
        }
      });
    },
    // 点赞
    clickLike(item) {
      let data = {
        commandId: item.id,
        type: 0,
      };
      this.$axios.post('/teach/command/addCommandLikes', data).then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          // this.getComment();
          item.status = 1;
          item.countlike = item.countlike + 1;
        } else {
          this.$message({ type: 'error', message: res.data.message });
        }
      });
    },
    // 获取评论数据
    getComment() {
      this.$axios
        .post(
          `/teach/command/findCommand?menuId=${this.menuIds}&type=${this.tabflag}`
        )
        .then((res) => {
          if (res.data.code === 200) {
            this.commentData = res.data.data.commandList;
            this.commentcount = res.data.data.count;
            this.commentData.forEach((item) => {
              if (item.resCommandings.length > 2) {
                // this.$set(items, 'isshow', this.commentData.resCommandings[0]);
                // let two = [];
                // two.push(item.resCommandings[0]);
                // two.push(item.resCommandings[1]);
                this.$set(item, 'flag', 2);
              }
              // item.isshow = false;
              this.$set(item, 'isshow', false);
              if (item.resCommandings.length > 0) {
                item.resCommandings.forEach((items) => {
                  this.$set(items, 'isshow', false);
                });
              }
            });

            // console.log(this.commentData);
          }
        });
    },
    grade() {
      console.log(this.value1);
      if (this.value1 === 0) {
        this.$message({
          type: 'warning',
          message: '请选择分数',
        });
      } else {
        let data = {
          menuId: this.menuIds,
          score: this.value1,
        };
        // console.log(data);
        this.$axios.post('/teach/command/addMark', data).then((res) => {
          // console.log(res);
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '评分成功！',
            });
            this.getMessageDatas();
            this.openDialog = false;
          } else {
            this.$message({
              type: 'error',
              message: '评分失败！',
            });
          }
        });
      }
    },
    tabchange(v) {
      if (v === 0) {
        this.tabflag = 0;
        this.getComment();
      } else {
        this.tabflag = 1;
        this.getComment();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.comment {
  width: 72.5%;
  margin: 0 2%;
  .title_btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    .tab_title {
      // float: left;
      span {
        display: inline-block;
      }
      .hot,
      .time {
        cursor: pointer;
      }
      .shu {
        margin: 0 10px;
        font-size: 14px;
      }
      .all {
        margin-left: 15px;
      }
      .active {
        color: #409eff;
      }
    }
    .evaluate {
      // float: right;
      margin-top: -6px;
      border: 1px solid #409eff;
      color: #409eff;
      padding: 6px 14px;
      cursor: pointer;
    }
  }
  .one {
    width: 100%;
    .inputBox {
      margin-top: 10px;
      margin-bottom: 15px;
      float: left;
      width: 100%;
      position: relative;
      .face {
        width: 300px;
        position: absolute;
        z-index: 100000000000000000;
        top: 98px;
        left: 593px;
      }
      img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        margin-right: 16px;
        position: relative;
        top: -20px;
      }
      .oneContent {
        width: 78%;
      }
      .oneContent2 {
        width: 78%;
      }
      textarea {
        width: 80%;
        min-height: 88px;
        border-radius: 2px 0px 0px 2px;
        background: #ffffffff;
        border: 1px solid #0000004c;
        resize: none;
      }
      input:focus {
        border: 1px solid red;
      }
      .fabiao {
        background: #409eff;
        color: #fff;
        padding: 12px 26px;
        margin-left: 12px;
        // position: absolute;
        float: right;
      }
      .biaoqing {
        background: #0000004c;
        color: #fff;
        padding: 6px 15px;
        margin-top: -37px;
        // margin-right: 7px;
        float: right;
      }
      .fabiao1 {
        background: #409eff;
        color: #fff;
        padding: 12px 26px;
        margin-left: 12px;
        // position: absolute;
        float: right;
      }
      .biaoqing1 {
        background: #0000004c;
        color: #fff;
        padding: 6px 15px;
        margin-top: -37px;

        // margin-left: 12px;
        // position: absolute;
        // top: 165px;
        float: right;
      }
      .fabiao2 {
        background: #409eff;
        color: #fff;
        padding: 12px 26px;
        margin-left: 12px;
        // position: absolute;
        float: right;
      }
      .biaoqing2 {
        background: #0000004c;
        color: #fff;
        padding: 6px 15px;
        margin-top: -37px;

        // margin-left: 12px;
        // position: absolute;
        // top: 165px;
        float: right;
      }
      .fabiao,
      .fabiao1,
      .biaoqing1,
      .fabiao2,
      .biaoqing2,
      .biaoqing:hover {
        cursor: pointer;
      }
    }
    .commentBox {
      width: 100%;
      ul {
        width: 100%;
        li {
          width: 100%;
          min-height: 10px;
          // position: relative;
          overflow: hidden;
          .boxs {
            width: 91.5%;
            float: right;
            position: relative;
            padding-bottom: 10px;
            border-bottom: 1px #c8c8c833 solid;
          }
          .imgs {
            width: 54px;
            height: 54px;
            border-radius: 50%;
            // position: absolute;
            // top: 100px;
            // left: -80px;
          }

          .commentHeader {
            width: 100%;
            // position: absolute;
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
            // left: 70px;
            // top: 0px;
            float: right;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .name {
              display: inline-block;
              color: #333333ff;
              font-weight: 600;
              font-size: 14px;
            }
            .time {
              display: inline-block;
              color: #333333ff;
              font-size: 12px;
              letter-spacing: 0px;
            }
          }
          .content {
            float: right;
            // display: inline-block;
            width: 100%;
            margin-bottom: 10px;
            // min-height: 20px;
            // position: absolute;
            // left: 70px;
            // top: 30px;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            color: #333333ff;
            font-size: 14px;
            letter-spacing: 0px;
          }
          .commentFooter {
            width: 100%;
            margin-top: 8px;
            // background: red;
            // position: absolute;
            float: right;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left {
              .zan {
                display: inline-block;
                margin-right: 20px;
              }
              .zan:hover {
                cursor: pointer;
              }
              .huifu:hover {
                cursor: pointer;
                color: #409eff;
              }
            }
            .right {
              font-size: 12px;
            }
            .right:hover {
              cursor: pointer;
            }
          }
        }
      }
      .two {
        // margin-top: 20px;
        width: 92%;
        float: right;
        position: relative;
        .images {
          width: 54px;
          height: 54px;
        }
        li {
          width: 100%;
          overflow: hidden;

          .boxss {
            width: 95%;
            float: right;
            position: relative;
            padding-bottom: 10px;
            margin-bottom: 10px;
            border-bottom: 1px solid #c8c8c833;
          }
          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
          }
          .commentHeader {
            width: 100%;
            // position: absolute;
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
            // left: 70px;
            // top: 0px;
            float: right;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .name {
              display: inline-block;
              color: #333333ff;
              font-weight: 600;
              font-size: 14px;
            }
            .time {
              display: inline-block;
              color: #333333ff;
              font-size: 12px;
              letter-spacing: 0px;
            }
          }
          .content {
            float: right;
            // display: inline-block;
            width: 100%;
            // min-height: 20px;
            // position: absolute;
            // left: 70px;
            // top: 30px;
            word-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            color: #333333ff;
            font-size: 14px;
            letter-spacing: 0px;
          }
          .commentFooter {
            width: 100%;
            margin-top: 8px;
            // background: red;
            // position: absolute;
            float: right;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left {
              .zan {
                display: inline-block;
                margin-right: 20px;
              }
              .zan:hover {
                cursor: pointer;
              }
              .huifu:hover {
                cursor: pointer;
                color: #409eff;
              }
            }
            .right {
              font-size: 12px;
            }
            .right:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.ckgd {
  color: #409eff;
  float: left;
  margin-left: 110px;
  margin-bottom: 10px;
  margin-top: 10px;
  // margin-right: 800px;
  // position: absolute;
  // top: 100px;
}
.ckgd:hover {
  cursor: pointer;
}
/deep/ .el-dialog__body {
  height: 0px !important;
}
.el-rate {
  text-align: center;
}
</style>
