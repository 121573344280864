<template>
  <div class="preview">
    <div>
      <coursewareBox
        :itemList="itemlists"
        :menuId="menuId"
        v-on:sendList="sendLists"
      ></coursewareBox>
    </div>
    <div class="block" v-show="itemlists">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import coursewareBox from '../../../common/coursewareBox.vue';
export default {
  components: {
    coursewareBox,
  },
  data() {
    return {
      menuId: null,
      currentPage3: 1,
      pageCount: 10,
      total: 0,
      pageSize: 10,
      itemlists: null,
    };
  },
  created() {
    this.menuId = this.$route.query.menuId;
    this.getDetailData();
  },
  methods: {
    getDetailData() {
      this.$axios
        .get(
          `/teach/courseware/findOneMenuWare?menuId=${this.menuId}&page=${this.currentPage3}&rows=${this.pageCount}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.itemlists = res.data.data.items;
          }
        });
    },
    // 把数据发送给子组件
    sendLists(data) {
      this.itemlists = data.itemlists;
      this.currentPage3 = data.currentPage3;
      this.total = data.total;
      this.pageSize = data.pageSize;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1;
      this.pageSize = val;
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      // this.getMenuId(this.clickItem);
      this.currentPage3 = val;
      // this.getMenuId(this.clickItem);
    },
  },
};
</script>

<style lang="less" scoped>
.preview {
  width: 100%;
  background: #fff;
  border-radius: 4px;
}
.block {
  text-align: center;
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
