const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
const maxPos = chars.length;
function randomString(len) {
  len = len || 32;

  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

function getFileName(filename, dir, type) {
  let a = Date.now();
  var aData = new Date();
  let date =
    aData.getFullYear() + '-' + (aData.getMonth() + 1) + '-' + aData.getDate();
  const ext = filename.substring(filename.lastIndexOf('.'));
  return dir + '/' + type + '/' + date + '/' + a + randomString(20) + ext;
}
export default {
  getFileName,
};
