<template>
  <!-- 试卷 -->
  <div class="zksc-examing">
    <div class="answer-box" v-if="role == 'student' || role == 'teacher'">
      <div class="examTime">
        <div>
          <span class="text">剩余时间:</span>
          <span class="time">{{ countDown }}</span>
        </div>
        <div>
          <span class="text">当前进度:</span>
          <span class="nums">
            <span class="num">{{ total }}</span
            >/
            <span>30</span>
          </span>
        </div>
      </div>
      <div class="answer-sheet">
        <h3>答题卡</h3>
        <div class="answer-part">
          <span>单选题</span>
          <ul>
            <a :href="'#choice' + i" v-for="(item, i) in choice" :key="i">
              <li :class="{ complete: !!item.answer }">{{ i + 1 }}</li>
            </a>
          </ul>
        </div>
        <div class="answer-part">
          <span>判断题</span>
          <ul>
            <a :href="'#judge' + i" v-for="(item, i) in judge" :key="i">
              <li :class="{ complete: !!item.answer }">{{ i + 1 }}</li>
            </a>
          </ul>
        </div>
        <div class="btn-group">
          <el-button type="primary" @click="subExam">交卷</el-button>
          <el-button @click="leaveExam">离开</el-button>
        </div>
      </div>
    </div>
    <div class="subject">
      <div class="title">单选题（共20题）每题 4分，共80分</div>
      <ul class="subject-box">
        <li
          v-for="(item, i) in choice"
          :key="item.questionId"
          :id="'choice' + i"
        >
          <p>{{ i + 1 }}. {{ item.name }}</p>
          <p>
            <el-radio @change="choiceChange()" v-model="item.answer" label="A"
              >A. {{ item.optionsA }}</el-radio
            >
          </p>
          <p>
            <el-radio @change="choiceChange()" v-model="item.answer" label="B"
              >B. {{ item.optionsB }}</el-radio
            >
          </p>
          <p>
            <el-radio @change="choiceChange()" v-model="item.answer" label="C"
              >C. {{ item.optionsC }}</el-radio
            >
          </p>
          <p>
            <el-radio @change="choiceChange()" v-model="item.answer" label="D"
              >D. {{ item.optionsD }}</el-radio
            >
          </p>
        </li>
      </ul>
      <div class="title">判断题 (10题)，每题2分，共20分</div>
      <ul class="subject-box">
        <li v-for="(item, i) in judge" :key="item.questionId" :id="'judge' + i">
          <p>{{ i + 1 }}. {{ item.name }}</p>
          <p>
            <el-radio
              @change="choiceChange()"
              v-model="item.answer"
              label="正确"
              >正确</el-radio
            >
          </p>
          <p>
            <el-radio
              @change="choiceChange()"
              v-model="item.answer"
              label="错误"
              >错误</el-radio
            >
          </p>
        </li>
      </ul>
    </div>

    <el-dialog
      title="成绩信息"
      :visible.sync="isGrade"
      @close="closeGradeDialog"
    >
      <div class="dialog-content">
        <div class="content-item">
          <span class="left">总分:</span>
          <span>100</span>
        </div>
        <div class="content-item">
          <span class="left">得分:</span>
          <span>{{ grade }}</span>
        </div>
        <div class="content-item">
          <span class="left">答题用时:</span>
          <span>{{ examTime }}</span>
        </div>
        <div class="content-item">
          <span class="left">正确题数:</span>
          <span>{{ count }}/30</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      choice: [],
      judge: [],
      count: '',
      grade: '',
      isGrade: false,
      total: 0,
      totalTime: /* 45 * */ 45 * 60,
      examTime: '',
      oldTime: '',
      countDown: '45:00',
      timer: null,
      role: '',
    };
  },
  mounted() {
    this.role = sessionStorage.getItem('role');
    if (this.role == 'student' || this.role == 'teacher') {
      this.startExam();
    } else {
      this.getEaxm();
    }
  },
  beforeDestroy() {
    /* 销毁路由 直接交卷*/
    // this.autoExam();
    this.clearExam();
  },
  methods: {
    startExam() {
      // console.log(sessionStorage.getItem('startExam'),'sessionStorage.getItem(startExam)');
      if (sessionStorage.getItem('startExam')) {
        /* 防止刷新丢失题目 */
        this.choice = JSON.parse(sessionStorage.getItem('choice'));
        this.judge = JSON.parse(sessionStorage.getItem('judge'));
        this.total = sessionStorage.getItem('total');
        /* 重新获取 开始考试时间 */
        this.oldTime = sessionStorage.getItem('startTime');

        /* 重新计算剩余时间 */
        this.totalTime = ~~(
          45 * 60 -
          (new Date() - new Date(this.oldTime)) / 1000
        );

        // this.getEaxm();
        /* 开启倒计时 */
        this.timeCountDown();
      } else {
        this.$alert(`请做好准备，即将开始考试`, '提示', {
          confirmButtonText: '确定',
          callback: async () => {
            this.$message({
              type: 'warning',
              message: `已经开始考试，请认真答题`,
            });
            sessionStorage.setItem('startExam', true);
            sessionStorage.setItem('startTime', new Date());

            await this.getEaxm();
            /* 防止刷新丢失题目 */
            sessionStorage.setItem('choice', JSON.stringify(this.choice));
            sessionStorage.setItem('judge', JSON.stringify(this.judge));
            this.oldTime = new Date();
            this.timeCountDown();
          },
        });
      }
    },
    async getEaxm() {
      // let examId = this.$route.query.examId;
      let examId = sessionStorage.getItem('examId');
      const { data } = await this.$axios.post(
        `/teach/student/examination?examId=${examId}`
      );
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      data.data.map((v) => {
        if (v.type == 'choice') {
          this.choice.push(v);
        } else {
          this.judge.push(v);
        }
        v.examId = examId;
      });
    },
    /* 当前完成题目数量 */
    choiceChange() {
      let len1 = this.choice.filter((v) => !!v.answer).length;
      let len2 = this.judge.filter((v) => !!v.answer).length;
      this.total = len1 + len2;

      /* 防止刷新丢失题目 */
      sessionStorage.setItem('choice', JSON.stringify(this.choice));
      sessionStorage.setItem('judge', JSON.stringify(this.judge));
      sessionStorage.setItem('total', this.total);
    },
    /* 考试倒计时 */
    timeCountDown() {
      /* 正常情况倒计时 */
      /* 刷新之后如何倒计时？ */
      let count = this.totalTime;
      if (!sessionStorage.getItem('startExam')) {
        this.oldTime = new Date();
        sessionStorage.setItem('startTime', new Date());
      } else {
        /* 重新计算剩余时间 */
        count = ~~(
          this.totalTime -
          (new Date() - new Date(this.oldTime)) / 1000
        );
      }
      this.timer = setInterval(() => {
        count--;
        let s =
          count % 60 > 10 ? count % 60 : ((count % 60) + '').padStart(2, 0);
        let m =
          count / 60 > 10 ? ~~(count / 60) : (count / 60 + '').padStart(2, 0);
        this.countDown = ~~m + ':' + ~~s;
        if (count == 3 * 60) {
          this.$message.warning('还有' + (30 - this.total) + '到题没有完成');
        }
        if (count == 0) {
          /* 考试结束清除倒计时 */
          clearInterval(this.timer);
          /* 自动交卷 */
          this.autoExam();
          /* 清除考试信息 */
          this.clearExam();
        }
      }, 1000);
    },
    /* 自动交卷 */
    async autoExam() {
      const exam = [...this.choice, ...this.judge];
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const { data } = await this.$axios.post('/teach/student/endExam', exam);
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      }
      setTimeout(() => {
        loading.close();
      }, 500);
      this.$message.success(data.message);
      this.count = data.data.count;
      this.grade = data.data.grade;

      /* 打开对话框 */
      this.isGrade = true;
      /* 清除考试信息 */
      this.clearExam();
    },
    /* 提交考试 */
    subExam() {
      /* 未做校验 */
      if (this.totalTime > (new Date() - this.oldTime) / 1000) {
        /* 还没到时间，禁止交卷 */
        for (let i in this.choice) {
          if (!this.choice[i].answer) {
            return this.$message.warning('选择题第' + (~~i + 1) + '题还没有做');
          }
        }
        for (let i in this.judge) {
          if (!this.judge[i].answer) {
            return this.$message.warning('判断题第' + (~~i + 1) + '题还没有做');
          }
        }
      }
      /* 发送请求 */
      const exam = [...this.choice, ...this.judge];
      this.$confirm('是否提交试卷', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            // spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          const { data } = await this.$axios.post(
            '/teach/student/endExam',
            exam
          );
          if (data.code !== 200) {
            setTimeout(() => {
              loading.close();
            }, 500);
            return this.$message.error(data.message);
          }
          setTimeout(() => {
            loading.close();
          }, 500);
          this.clearExam();
          this.$message.success(data.message);
          this.count = data.data.count;
          this.grade = data.data.grade;
          let m = ~~(data.data.time / 1000 / 60);
          let s = ~~((data.data.time / 1000) % 60);
          this.examTime = `${m}分${s}秒`;
          /* 打开对话框 */
          this.isGrade = true;
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消交卷',
          });
        });
    },
    /* 关闭成绩对话框 */
    closeGradeDialog() {
      this.isGrade = false;
      this.clearExam();
      this.$router.push({
        path: '/exam',
        name: 'Exam',
      });
    },
    /* 清除考试信息 */
    clearExam() {
      sessionStorage.removeItem('startExam');
      sessionStorage.removeItem('judge');
      sessionStorage.removeItem('choice');
      sessionStorage.removeItem('startTime');
      sessionStorage.removeItem('examId');
      clearInterval(this.timer);
      this.countDown = '00:00';
    },
    /* 离开考试 */
    leaveExam() {
      this.$confirm('确定离开考试么？这将会提交试卷', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(() => {
          this.autoExam();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
  },
  /* 离开考试 */
  // beforeRouteLeave(to, from, next) {
  //   /* 考试期间禁止跳转到别的页面 */
  //   if (from.path == '/examing') {
  //     let t =
  //       this.totalTime -
  //       (new Date() - new Date(sessionStorage.getItem('startTime'))) / 1000;
  //     if (t > 0) {
  //       this.$alert('请勿离开考试', '提示', {
  //         confirmButtonText: '确定',
  //         callback: () => {},
  //       });
  //     } else {
  //       next();
  //     }
  //   } else {
  //     /* 必须调用 `next` */
  //     next();
  //   }
  // },
};
</script>
<style lang="less" scoped>
.zksc-examing {
  box-sizing: border-box;
  padding: 16px 0;
  min-height: calc(100vh - 30px - 56px - 56px);
  display: flex;
  justify-content: center;
  // flex-direction: row;
  .answer-box {
    margin-right: 16px;

    .examTime {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      width: 296px;
      height: 100px;
      border-radius: 4px;
      background: #ffffff;

      div {
        margin-left: 16px;
        .text {
          font-size: 14px;
          color: #333;
        }
        .time {
          margin-left: 16px;
          color: #f8282a;
          font-size: 24px;
          font-weight: heavy;
        }
        .nums {
          margin-left: 16px;
          font-size: 14px;
          color: #333;
          .num {
            font-size: 24px;
          }
        }
      }
    }
    .answer-sheet {
      box-sizing: border-box;
      margin-top: 16px;
      padding: 8px;
      width: 296px;
      // height: 300px;
      border-radius: 4px;
      background: #ffffff;

      h3 {
        text-align: center;
      }
      .answer-part {
        span {
          color: #333333;
          font-size: 14px;
        }
        ul {
          margin: 8px 0;
          display: flex;
          flex-wrap: wrap;
          li {
            margin: 4px;
            width: 20px;
            height: 20px;
            border-radius: 4px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            color: #333;

            text-align: center;
            line-height: 20px;
            font-size: 12px;

            cursor: pointer;
          }
          .complete {
            border: 1px solid #409eff;
            background: #409eff;
            color: #fff;
          }
        }
      }
      .btn-group {
        margin-top: 30px;
      }
      .judge-box {
        span {
          color: #333333;
          font-size: 14px;
        }
      }
    }
  }
  .subject {
    width: 920px;
    height: calc(100vh - 30px - 56px - 56px - 32px);
    border-radius: 4px;
    background: #ffffff;
    overflow: auto;
    .title {
      margin: 10px 16px;
      width: 888px;
      height: 70px;
      border-radius: 4px;
      background: #f1f3f5;
      color: #333333;
      font-family: PingFang SC;
      font-weight: heavy;
      font-size: 24px;
      text-align: center;
      line-height: 70px;
    }
    .subject-box {
      margin: 0;
      li {
        box-sizing: border-box;
        padding: 30px 0;
        margin: 0 auto;
        width: 560px;

        border-bottom: 1px solid #ccc;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
.dialog-content {
  margin: 0 auto;
  width: 300px;

  .content-item {
    display: flex;
    margin: 8px 0;
    font-size: 24px;
    color: #333;
    .left {
      text-align: end;
    }
    span {
      width: 140px;
      margin-right: 20px;
    }
  }
}
</style>
