import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import Login from '../components/Login.vue';
import SuperLogin from '../components/superLogin.vue';

import Home from '../views/Home.vue';

import Class from '../components/class/class.vue';
/* 班级子路由 */
// import Plan from '../components/class/plan/plan.vue';
import ClassCourse from '../components/class/classCourse/classCourse.vue';
import Homework from '../components/class/homework/homework.vue';
import Exam from '../components/class/exam/exam.vue';
import Member from '../components/class/member/member.vue';
import Notice from '../components/class/notice/notice.vue';
import ClassList from '../components/class/classList/classList.vue';

/**消息通知 */
import Message from '../views/message/message.vue';
import Notices from '../views/notice/notice.vue';
import Examing from '../components/class/examing/examing.vue';

/* 资源 */
import Resource from '../components/resource/resource.vue';
import ResourceDetail from '../components/resource/resourceDetail.vue';
import NumberDetail from '../components/resource/numberDetail.vue';
import Courseware from '../components/resource/courseware/courseware.vue';
import ListDemo from '../views/list.vue';
import AddCourseware from '../components/resource/courseware/addCourseware/addCourseware.vue';
import addMessage from '../components/resource/courseware/addCourseware/addMessage.vue';
import addNumber from '../components/resource/number/addNumber/addNumber.vue';
import alterNumber from '../components/resource/number/alterNumber/alterNumber.vue';
import Number from '../components/resource/number/number.vue';
import Examination from '../components/resource/examination/examination.vue';
import Topic from '../components/resource/topic/topic.vue';

import Course from '../components/course/course.vue';

/** 公共资源审核 */
import Admin from '../components/resource/admin/admin.vue';
/** 公共资源预览 */
import PreviewCourseware from '../components/resource/admin/preview/previewCourseware.vue';
import PreviewTopic from '../components/resource/admin/preview/previewTopic.vue';
import PreviewNumber from '../components/resource/admin/preview/previewNumber.vue';
import PreviewExamination from '../components/resource/admin/preview/previewExamination.vue';

/* 课程管理子路由 */
import CourseManage from '../components/course/courseManage/courseManage.vue';
import CourseDesign from '../components/course/courseDesign/courseDesign.vue';
import LookCourse from '../components/course/lookCourse/lookCourse.vue';

/* 我的空间 */
import MySpace from '../components/mySpace/mySpace.vue';
import WorkSpace from '../components/mySpace/space/space.vue';
import PersonalMessage from '../components/mySpace/message/message.vue';
import ErrorCollection from '../components/mySpace/errorCollection/errorCollction.vue';
import Collection from '../components/mySpace/collection/collection.vue';
import Note from '../components/mySpace/note/note.vue';

/* 超管端 证书管理 */
import Certificate from '../components/certificate/certificate.vue';
import CertificateList from '../components/certificate/certificateList/certificateList.vue';

import Error404 from '../components/common/404.vue';
Vue.use(Router);

/* 静态路由 */
export const constantRoutes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/superlogin',
    name: 'SuperLogin',
    component: SuperLogin,
    meta: {
      title: '超管登录',
      roles: ['superadmin'],
    },
  },
  // {
  //   path: '*',
  //   name: 'Error404',
  //   component: Error404,
  // },
];

/* 动态路由 */
// import Home from '../../views/Home.vue'
export const asyncRoutes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/class',
        name: 'Class',
        component: Class,
        redirect: '/plan',
        meta: {
          title: '班级管理',
          roles: ['teacher', 'student', 'admin', 'superadmin'],
        },
        children: [
          {
            // 教学计划
            path: '/notice',
            name: 'Notice',
            component: Notice,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 班级课程
            path: '/ClassCourse',
            name: 'ClassCourse',
            component: ClassCourse,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 班级作业
            path: '/homework',
            name: 'Homework',
            component: Homework,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 考试
            path: '/exam',
            name: 'Exam',
            component: Exam,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 成员
            path: '/member',
            name: 'Member',
            component: Member,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 班级列表
            path: '/classList',
            name: 'ClassList',
            component: ClassList,
            meta: {
              roles: ['admin', 'superadmin'],
            },
          },
          {
            // 考试
            path: '/examing',
            name: 'Examing',
            component: Examing,
            meta: {
              roles: ['admin', 'teacher', 'student'],
            },
          },
        ],
      },
      // 资源管理
      {
        path: '/resource',
        name: 'Resource',
        component: Resource,
        meta: {
          roles: ['teacher', 'student', 'admin', 'superadmin'],
        },
        children: [
          {
            // 课件
            path: '/courseware',
            name: 'Courseware',
            component: Courseware,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 侧边栏demo
            path: '/list',
            name: 'ListDemo',
            component: ListDemo,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          //教师端的通知
          {
            path: '/notices',
            name: 'Notices',
            component: Notices,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 消息
            path: '/message',
            name: 'Message',
            component: Message,
            meta: {
              roles: ['teacher', 'student', 'admin'],
            },
          },
          //添加课件资源
          {
            path: '/addcourseware',
            name: 'AddCourseware',
            component: AddCourseware,
            meta: {
              roles: ['teacher', 'student', 'admin'],
            },
          },
          //添加课件信息
          {
            path: '/addmessage',
            name: 'addMessage',
            component: addMessage,
            meta: {
              roles: ['teacher', 'student', 'admin'],
            },
          },
          // 课件资源详情
          {
            path: '/resourcedetail',
            name: 'ResourceDetail',
            component: ResourceDetail,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 数字
            path: '/number',
            name: 'Number',
            component: Number,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          // 数字资源详情
          {
            path: '/numberdetail',
            name: 'NumberDetail',
            component: NumberDetail,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 试卷
            path: '/examination',
            name: 'Examination',
            component: Examination,
            meta: {
              roles: ['teacher', 'admin', 'superadmin'],
            },
          },
          {
            path: '/addNumber',
            name: 'addNumber',
            component: addNumber,
            meta: {
              roles: ['teacher', 'student', 'admin'],
            },
          },
          {
            path: '/alterNumber',
            name: 'alterNumber',
            component: alterNumber,
            meta: {
              roles: ['teacher', 'student', 'admin'],
            },
          },
          {
            // 试题
            path: '/topic',
            name: 'Topic',
            component: Topic,
            meta: {
              roles: ['teacher', 'student', 'admin', 'superadmin'],
            },
          },
          {
            // 审核
            path: '/admin',
            name: 'Admin',
            component: Admin,
            meta: {
              roles: ['admin'],
            },
          },
          {
            // 预览课件
            path: '/previewCourseware',
            name: 'PreviewCourseware',
            component: PreviewCourseware,
            meta: {
              roles: ['admin'],
            },
          },
          {
            // 预览试题
            path: '/previewTopic',
            name: 'PreviewTopic',
            component: PreviewTopic,
            meta: {
              roles: ['admin'],
            },
          },
          {
            // 预览试卷
            path: '/previewExamination',
            name: 'PreviewExamination',
            component: PreviewExamination,
            meta: {
              roles: ['admin'],
            },
          },
          {
            // 预览数字
            path: '/previewNumber',
            name: 'PreviewNumber',
            component: PreviewNumber,
            meta: {
              roles: ['admin'],
            },
          },
        ],
      },

      {
        path: '/course',
        name: 'Course',
        component: Course,
        redirect: '/courseManage',
        meta: {
          roles: ['teacher', 'admin', 'student'],
        },
        children: [
          {
            // 课程管理
            path: '/courseManage',
            name: 'CourseManage',
            component: CourseManage,
            meta: {
              roles: ['teacher'],
            },
          },
          {
            path: '/courseDesign',
            name: 'CourseDesign',
            component: CourseDesign,
            meta: {
              roles: ['teacher'],
            },
          },
          {
            path: '/lookCourse',
            name: 'LookCourse',
            component: LookCourse,
            meta: {
              roles: ['teacher', 'admin', 'student'],
            },
          },
        ],
      },

      {
        path: '/mySpace',
        name: 'MySpace',
        component: MySpace,
        redirect: '/workSpace',
        meta: {
          roles: ['student'],
        },
        children: [
          {
            // 工作空间
            path: '/workSpace',
            name: 'WorkSpace',
            component: WorkSpace,
            meta: {
              roles: ['student'],
            },
          },
          {
            // 个人信息
            path: '/personalMessage',
            name: 'PersonalMessage',
            component: PersonalMessage,
            meta: {
              roles: ['student'],
            },
          },
          {
            // 错题集
            path: '/errorCollection',
            name: 'ErrorCollection',
            component: ErrorCollection,
            meta: {
              roles: ['student'],
            },
          },
          {
            // 收藏
            path: '/collection',
            name: 'Collection',
            component: Collection,
            meta: {
              roles: ['student'],
            },
          },
          {
            // 笔记
            path: '/note',
            name: 'Note',
            component: Note,
            meta: {
              roles: ['student'],
            },
          },
        ],
      },
      /* 超管端 */
      {
        path: '/certificate',
        name: 'Certificate',
        component: Certificate,
        // redirect: '/workSpace',
        meta: {
          roles: ['superadmin'],
        },
        children: [
          {
            path: '/certificateList',
            name: 'CertificateList',
            component: CertificateList,
            // redirect: '/workSpace',
            meta: {
              roles: ['superadmin'],
            },
          },
          {
            // 消息
            path: '/message',
            name: 'Message',
            component: Message,
            meta: {
              roles: ['superadmin'],
            },
          },
        ],
      },

      /* TODO 匹配一个找不到的页面 */
    ],
  },
];

export const error404 = {
  path: '*',
  name: 'Error404',
  component: Error404,
};
/**
 * 创建路由对象
 * @returns Router
 */
const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

/**
 * 创建路由对象
 */
const router = createRouter();

// Vue.use(Router)
// const router = new Router({
//   routes
// })
// 避免导航重复而报错
const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
/**
 * 重置路由
 */
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

/* 路由守卫校验 */
router.beforeEach(async (to, from, next) => {
  /* 没有角色信息 返回登录 */
  // if (to.path !== '/' || to.path !== '/login') {
  //   console.log(to.path, '474');
  // } else {
  //   if (
  //     sessionStorage.getItem('role') == null ||
  //     sessionStorage.getItem('role') == ''
  //   ) {
  //     console.log('role');
  //   }
  // }
  // console.log(sessionStorage.getItem('role'), 'sessionStorage.getItem(role)');
  // TODO
  // if (!localStorage.getItem('role')) {
  //   next({ path: '/login' });
  // }
  /* 判断vuex中的路由是否还存在，不存在重新生产路由 */
  if (store.getters.permission_routes.length < 1) {
    // console.log('546456');
    /* 重新生产路由 */
    /* TODO 此时还需判断登录状态 */
    await store.dispatch('changeRoles', sessionStorage.getItem('role'));
  }
  if (router.options.routes[router.options.routes.length - 1].path != '*') {
    // console.log('------------');
    router.options.routes = router.options.routes.concat([
      // {
      //   path: '*',
      //   name: 'Error404',
      //   component: Error404,
      // },
    ]);
  }
  // console.log(store.state);
  /* 必须调用 `next` */
  next();
});
/* 动态创建路由 */
// this.$store.dispatch('changeRoles', role);

export default router;
