<template>
  <div
    class="modules-item"
    :class="{
      'modules-item1': bgi == 1,
      'modules-item2': bgi == 2,
      'modules-item3': bgi == 3,
    }"
  >
    <h2>{{ title1 }}</h2>
    <p>{{ title2 }}</p>
    <span>{{ value }}</span>
    <div class="progress-box" v-if="progress">
      <div class="progress" :style="{ width: progress + '%' }"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['bgi', 'title1', 'title2', 'progress', 'value'],
};
</script>
<style lang="less" scoped>
.modules-item {
  position: relative;
  box-sizing: border-box;
  padding: 16px;

  width: 300px;
  height: 220px;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(119.36deg, #1b88ed 0%, #47c4ed 100%);
  background-image: url('../../../../assets/images/course.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  h2 {
    color: #ffffff;
    font-size: 24px;
  }
  p {
    margin: 10px 0 30px 0;
    color: #ffffff;
    font-size: 16px;
  }
  span {
    color: #ffffff;
    font-size: 52px;
  }
  .progress-box {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    width: 90%;
    height: 4px;
    border-radius: 4px;
    background: #ffffff;
    .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 90%;
      height: 4px;
      border-radius: 4px;
      background: #ffe040;
    }
  }
}
.modules-item1 {
  background: linear-gradient(119.36deg, #5b5bfe 0%, #48b6f3 100%);
  background-image: url('../../../../assets/images/favourites.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.modules-item2 {
  background: linear-gradient(119.36deg, #8080ff 0%, #f487fd 100%);
  background-image: url('../../../../assets/images/task.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.modules-item3 {
  background: linear-gradient(119.36deg, #0cb4e8 0%, #50ecdc 100%);
  background-image: url('../../../../assets/images/grade.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
