<template>
  <div class="box">
    <div class="sidemenu" ref="sidemenu" @mousewheel="gundong">
      <SideMenu
        :menuLists="menuList"
        :type="types"
        :titles="silderData[0]"
        v-on:getIds="getMenuId"
        v-if="list"
      ></SideMenu>
    </div>
    <div class="courseware">
      <!-- <div class="sidemenu">
        <SideMenu></SideMenu>
      </div> -->
      <div class="twoBox">
        <div class="courseware_title">
          <p>
            <span v-for="(item, index) in silderData" :key="index">{{
              index >= 1 ? '/ ' + item.name : item.name
            }}</span>
          </p>
          <div v-show="buttonFlag === true">
            <div class="btn_box" v-show="itemlists">
              <div v-show="role !== 'student'">
                <span class="new" type="primary" @click="addDetail"
                  >新建课件资源</span
                >
                <span
                  class="newMessage"
                  v-show="btnFlag === true"
                  type="primary"
                  @click="addMessage"
                  >新建课件信息</span
                ><span
                  class="alterMessage"
                  v-show="btnFlag === false"
                  type="warning"
                  @click="alterMessage"
                  >修改课件信息
                </span>
                <span
                  type="info"
                  class="yellowBtn"
                  @click="dialogVisible = true"
                  >转化为公共资源</span
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <coursewareBox
            :itemList="itemlists"
            :menuId="clickMenuId"
            :titles="silderData"
            v-on:sendList="sendLists"
          ></coursewareBox>
        </div>
        <el-dialog title="转化为公共资源" :visible.sync="dialogVisible" center>
          <p class="title_p">审核通过后，将出现在公共课件资源区</p>
          <span class="title_span"
            >建议提交整套课件资源，这样审核更加容易通过哦！</span
          >
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="addPublic">提 交</el-button>
            <el-button @click="dialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>
        <div class="block" v-show="itemlists">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage3"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import coursewareBox from '../components/common/coursewareBox.vue';
import SideMenu from '../components/sideMenus';

export default {
  components: {
    coursewareBox,
    SideMenu,
  },
  data() {
    return {
      currentPage3: 1,
      pageCount: 10,
      total: 0,
      pageSize: 10,
      dialogVisible: false,
      menuList: [],
      itemlists: null,
      list: false,
      clickMenuId: null,
      clickItem: null,
      types: '',
      silderData: [],
      btnFlag: true,
      buttonFlag: true,
      role: null,
      silderId: null,
    };
  },
  created() {
    this.role = sessionStorage.getItem('role');
    this.silderId = localStorage.getItem('silderId');
    this.getList();
    // console.log(this.$store.state.silderId);
  },
  // mounted() {
  //   this.role = sessionStorage.setItem('role');
  // },
  methods: {
    // 滑轮滚动时触发的
    gundong() {
      // this.$refs.sidemenu.style.overflow-y = 'auto';
      // this.$refs.sidemenu.style.overflow-x = 'none';
    },
    // 查看消息是否编辑
    getMessageFlag() {
      if (this.role === 'student') {
        return;
      }
      const that = this;
      this.$axios
        .get(`/teach/courseware/findStatusCommon?menuId=${this.clickMenuId}`)
        .then((res) => {
          if (res.data.code === 200) {
            // console.log(res.data);
            if (res.data.data === 1) {
              that.btnFlag = false;
            } else {
              that.btnFlag = true;
            }
          }
        });
    },
    // 提交到公共资源库
    addPublic() {
      if (this.itemlists.length < 1) {
        this.$message({
          type: 'error',
          message: '请最少上传一个课件',
        });
        return;
      }
      let data = {
        menuId: this.clickMenuId,
        rescourseType: '课件资源',
      };
      this.$axios.post('/teach/courseware/toConversion', data).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: 'success',
            message: res.data.message,
          });
          this.dialogVisible = false;
        } else {
          this.$message({
            type: 'error',
            message: res.data.message,
          });
        }
      });
    },
    // 把数据发送给子组件
    sendLists(data) {
      this.itemlists = data.itemlists;
      this.currentPage3 = data.currentPage3;
      this.total = data.total;
      this.pageSize = data.pageSize;
    },
    // 获取menuid
    getMenuId(item) {
      // this.itemlists = []
      // const that = this;
      this.clickItem = item;
      this.clickMenuId = item.id;
      if (item.level === 1) {
        this.silderData = [];
        this.itemlists = null;
        this.silderData.push(item);
        if (this.silderData[0].name === '公共资源库') {
          this.buttonFlag = false;
        } else {
          this.buttonFlag = true;
        }
        // console.log(this.silderData);
      } else if (
        item.level > this.silderData[this.silderData.length - 1].level
      ) {
        this.silderData.push(item);
        // console.log(this.silderData);
      } else if (
        item.level === this.silderData[this.silderData.length - 1].level
      ) {
        this.silderData.pop();
        this.silderData.push(item);
        // console.log(this.silderData);
      } else {
        this.silderData.pop();
        // console.log(this.silderData);
        if (this.silderData[this.silderData.length - 1].name !== item.name) {
          this.silderData.pop();
          this.silderData.push(item);
          // console.log(this.silderData);
        }
      }

      // console.log(this.clickMenuId);
      if (item.level >= 3) {
        this.$axios
          .get(
            `/teach/courseware/findOneMenuWare?menuId=${item.id}&page=${this.currentPage3}&rows=${this.pageCount}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.itemlists = res.data.data.items;
              this.total = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              this.getMessageFlag();
              // if (this.itemlists.length === 0) {
              //   that.btnFlag = true;
              // } else {
              //   if (that.itemlists[0].map.staus === '0') {
              //     that.btnFlag = true;
              //   } else {
              //     that.btnFlag = false;
              //   }
              // }
            } else {
              this.$message({
                type: 'error',
                message: res.data.message,
              });
            }
          });
      }
    },

    getList() {
      const that = this;
      this.types = '课件资源';
      this.$axios
        .get(
          `/teach/courseware/findMenuWare?cerificateId=${this.silderId}&type=${this.types}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            if (that.role === 'admin' || that.role === 'superadmin') {
              that.menuList = [];
              that.menuList.push(res.data.data[0]);
            } else {
              that.menuList = res.data.data;
            }
            that.list = true;
          }
        });
    },
    addDetail() {
      if (this.clickItem.level !== 3) {
        this.$message({
          message: '请选择课件分组',
          type: 'error',
        });
      } else {
        this.$router.push({
          path: '/addcourseware',
          query: {
            menuId: this.clickMenuId,
          },
        });
      }
    },
    //添加消息
    addMessage() {
      if (this.clickItem.level !== 3) {
        this.$message({
          message: '请选择课件分组',
          type: 'error',
        });
      } else {
        this.$router.push({
          path: '/addmessage',
          query: {
            menuId: this.clickMenuId,
            type: 'add',
          },
        });
      }
    },
    // 修改消息
    alterMessage() {
      if (this.clickItem.level !== 3) {
        this.$message({
          message: '请选择课件分组',
          type: 'error',
        });
      } else {
        this.$router.push({
          path: '/addmessage',
          query: {
            menuId: this.clickMenuId,
            type: 'alter',
          },
        });
      }
    },
    // 页码大小变动的时候触发
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1;
      this.pageSize = val;
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      // this.getMenuId(this.clickItem);
      this.currentPage3 = val;
      // this.getMenuId(this.clickItem);
      this.$axios
        .get(
          `/teach/courseware/findOneMenuWare?menuId=${this.clickItem.id}&page=${this.currentPage3}&rows=${this.pageCount}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.itemlists = res.data.data.items;
            this.total = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.getMessageFlag();
            // if (this.itemlists.length === 0) {
            //   that.btnFlag = true;
            // } else {
            //   if (that.itemlists[0].map.staus === '0') {
            //     that.btnFlag = true;
            //   } else {
            //     that.btnFlag = false;
            //   }
            // }
          } else {
            this.$message({
              type: 'error',
              message: res.data.message,
            });
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  min-height: calc(100vh - 30px - 144px);
  display: flex;
}
.twoBox {
  position: relative;
  height: 100%;
}
.sidemenu::-webkit-scrollbar {
  width: 12px;
}
.sidemenu::-webkit-scrollbar-track-piece {
  background-color: none;
} /* 滚动条的内层滑轨背景颜色 */

.sidemenu::-webkit-scrollbar-track {
  background-color: none;
} /* 滚动条的外层滑轨背景颜色 */

.sidemenu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 3px;
} /* 滚动条的内层滑块颜色 */

.sidemenu::-webkit-scrollbar-button {
  background-color: #fff;
  display: none;
}
.sidemenu {
  background: #fff;
  border-radius: 4px;
  min-width: 300px;
  height: calc(100vh - 30px - 144px);
  // overflow-y: auto;
  overflow-x: hidden;
}
.courseware {
  width: 100%;
  min-height: calc(100vh - 30px - 144px);
  background: #fff;
  margin-left: 16px;
  // margin-left: 32px;

  border-radius: 4px;
  .kong {
    width: 100%;
    height: 100%;
    /deep/.el-empty {
      width: 100%;
      height: 100%;
    }
  }
}
.courseware_title {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    text-indent: 15px;
  }
  .btn_box {
    margin-right: 16px;
    span {
      display: inline-block;
      width: 140px;
      height: 40px;
      background: #409eff;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      margin-right: 16px;
    }
    .alterMessage {
      background: #e6a23c;
    }
    span:hover {
      cursor: pointer;
    }
    .yellowBtn {
      background: #ffe040;
      color: #333333;
      border: none;
    }
  }
}
.title_p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.title_span {
  display: block;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  font-size: 12px;
}
.block {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, -50%);
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
