<template>
  <div
    class="admin"
    v-loading="loading"
    element-loading-text="拼命上传中..."
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="title">
      <p>新建数字资源</p>
    </div>
    <div class="coursewareMessage">
      <p>资源信息</p>
      <div>
        <em class="hongdian"></em>
        <el-input
          type="text"
          v-model="title.name"
          placeholder="请输入资源名称"
        />
      </div>
      <div>
        <em class="hongdian"></em>
        <el-input
          type="text"
          v-model="title.software"
          placeholder="请输入适用软件"
        />
      </div>
      <div>
        <em class="hongdian"></em>
        <el-input
          type="text"
          v-model="title.versions"
          placeholder="请输入软件版本"
        />
      </div>

      <div class="richBox">
        <rich-text
          :detail="log.detail"
          isClear="false"
          ref="editorRef"
          @change="changeLogDetail"
        ></rich-text>
      </div>
    </div>
    <div class="coursewareCover">
      <p>资源封面<span>支持上传jpg和png文件</span></p>
      <em class="hongdian"></em>
      <div class="coverbox" v-show="coverUrl">
        <span class="cha" @click="delCover">
          <icon-svg
            style="float: right; margin-top: 5px; margin-right: 6px"
            icon-class="shanchu"
          ></icon-svg>
        </span>
        <img :src="coverUrl" alt="" />
      </div>

      <el-upload
        accept="image/*"
        v-show="coverUrl === ''"
        class="upload-demo"
        ref="uploadCover"
        drag
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
        :on-preview="handlePictureCardPreview"
        :auto-upload="false"
        :on-remove="handleRemove"
        :limit="1"
        :on-change="handleChansge"
        v-model="coverUrl"
      >
        <icon-svg icon-class="shangchuan1" class="shangchaun"></icon-svg>
        <div class="el-upload__text">
          将文件拖到此处，或<em>点击上传</em><br />
          <span>支持jpg/png格式</span><br />
          <span>大小不超过2M</span>
        </div>
        <!-- <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件，且不超过500kb
        </div> -->
      </el-upload>
    </div>
    <div class="coursewareContent">
      <p>
        轮播图<span>支持上传jpg和png格式，大小不超过2M，最多上传10张图片</span>
      </p>
      <el-upload
        v-show="bannerImg.length < 10"
        accept="image/*"
        class="upload-demo"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-remove="handleRemoveBanner"
        multiple
        :show-file-list="false"
        :auto-upload="false"
        :limit="10"
        :on-change="handleChansgeBanner"
        v-model="ruleForm.coverFile"
      >
        <em class="hongdian"></em>
        <el-button class="upload-btn" size="small" type="primary"
          >上传内容</el-button
        >
        <br />
        <!-- <div slot="tip" class="el-upload__tip">
          支持上传zip和rar文件，大小不超过2GB
        </div> -->
      </el-upload>
      <div
        v-show="bannerImg.length > 0"
        v-for="(item, index) in bannerImg"
        class="imgBox"
        :key="index"
      >
        <span class="cha" @click="alterImg(index)">
          <icon-svg
            style="float: right; margin-top: 5px; margin-right: 6px"
            icon-class="shanchu"
          ></icon-svg>
        </span>
        <img :src="item" alt="" />
        <span class="fileName">
          {{ bannerFile[index].name }}
        </span>
      </div>
    </div>
    <div class="material">
      <p class="pbiaoqian">
        资源素材<span
          >支持上传zip和rar文件，大小不超过2GB，最多上传一个文件</span
        >
      </p>
      <template>
        <span class="type">上传方式：</span>
        <el-radio v-model="radio" @change="handleRadio()" label="1"
          >本地上传</el-radio
        >
        <el-radio v-model="radio" @change="handleRadios()" label="2"
          >网盘上传</el-radio
        >
      </template>
      <div class="wangpang" v-show="radio === '2'">
        <p>
          <em class="hongdian"></em
          ><el-input v-model="links" placeholder="请输入网盘链接"></el-input>
        </p>
        <p>
          <em class="hongdian"></em
          ><el-input
            v-model="extractPsw"
            placeholder="请输入提取密码"
          ></el-input>
        </p>
        <p>
          <em class="hongdian"></em
          ><el-input
            v-model="decompressionPsw"
            placeholder="请输入解压密码"
          ></el-input>
        </p>
      </div>
      <div class="coursewareContents" v-show="radio === '1'">
        <el-upload
          accept=".zip,.rar"
          ref="uploadZip"
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-remove="handleRemove"
          multiple
          :limit="1"
          :auto-upload="false"
          :on-change="handleChansgeZip"
        >
          <el-button size="small" type="primary">上传素材</el-button>
          <!-- <div slot="tip" class="el-upload__tip">
          支持上传zip和rar文件，大小不超过2GB
        </div> -->
        </el-upload>
      </div>
    </div>
    <div class="footerBtn">
      <el-button type="primary" @click="postOss">发布课件</el-button>
      <el-button>取消发布</el-button>
    </div>
  </div>
</template>

<script>
// import EditorBar from '@/components/commom/wangeditor.vue'
import richText from '../../../common/richText.vue';
import fileUtil from '../../../../utils/index';
export default {
  components: {
    richText,
  },
  data() {
    return {
      log: {
        detail: '',
      },
      title: {
        name: '',
        software: '',
        versions: '',
      },
      cover: {
        file: '',
        raw: '',
        Url: '',
      },
      banner: {
        file: '',
        raw: '',
        Url: '',
      },
      // 预上传保存图片url进行展示
      ruleForm: {
        coverUrl: '',
        coverFile: '',
      },
      bannerFile: [],
      bannerImg: [],
      radio: '',
      links: '',
      extractPsw: '',
      decompressionPsw: '',
      fileList: [],
      oss: {},
      coverUrl: '',
      zipFile: '',
      loading: false,
      bannerDatas: [],
    };
  },
  methods: {
    //删除预览封面
    delCover() {
      this.coverUrl = '';
      this.$refs.uploadCover.clearFiles();
    },
    postOss() {
      // console.log(this.bannerFile, this.cover.raw);
      if (
        this.title.name === '' ||
        this.title.software === '' ||
        this.title.versions === '' ||
        this.cover.raw === '' ||
        this.bannerFile <= 0
      ) {
        this.$message({
          type: 'warning',
          message: '请将内容补充完整',
        });
        return;
      }
      const that = this;
      this.$axios.post('/teach/courseware/ossSignature').then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.loading = true;
          this.oss = res.data.data;
          Promise.all([
            this.postText(),
            this.postBanner(),
            this.postCover(),
          ]).then((res) => {
            // console.log(res);
            let resData = [];
            res.forEach((item, index) => {
              if (Array.isArray(item)) {
                // console.log(item);
                resData = resData.concat(item);
                // console.log(resData);
              } else {
                resData.push(item);
                if (index === res.length - 1) {
                  // console.log(resData);
                }
              }
            });

            console.log(that.radio);
            if (that.radio === '1') {
              // console.log('*************************');
              that.postZip().then((ress) => {
                // console.log(ress);
                resData.push(ress);
                // console.log(res);
                let zip = {
                  uploadUrl: that.links,
                  unzipCode: that.decompressionPsw,
                  uploadCode: that.extractPsw,
                };
                let data = {
                  id: this.$route.query.id,
                  downloadDTO: zip,
                  list: resData,
                  name: that.title.name,
                  canEngine: that.title.software,
                  canVerison: that.title.versions,
                  type: 0,
                  menuId: that.$route.query.menuId,
                };
                that.$axios
                  .post('/teach/digital/upLoadDigital', data)
                  .then((res) => {
                    // console.log(res);
                    if (res.data.code === 200) {
                      that.$message({
                        type: 'success',
                        message: '添加成功！',
                      });
                      that.loading = false;
                      that.$router.push('/number');
                      sessionStorage.setItem('one', res.data.data.menuId);
                      sessionStorage.setItem('two', res.data.data.firstMenuId);
                      sessionStorage.setItem(
                        'three',
                        res.data.data.secondMenuId
                      );
                      that.title.name = '';
                      that.title.software = '';
                      that.title.versions = '';
                      resData = [];
                      that.links = '';
                      that.decompressionPsw = '';
                      that.extractPsw = '';
                      that.bannerFile = [];
                      that.bannerImg = [];
                      that.coverUrl = '';
                      that.ruleForm.coverUrl = '';
                      that.ruleForm.coverFile = '';
                      that.log.detail = '';
                      that.$refs.editorRef.clearTxt();
                      that.$refs.uploadCover.clearFiles();
                      that.$refs.uploadZip.clearFiles();
                    } else {
                      that.loading = false;

                      that.$message({
                        type: 'error',
                        message: '添加失败！',
                      });
                    }
                  });
              });
            } else if (that.radio === '2') {
              // console.log(that.decompressionPsw);
              // console.log(that.extractPsw);
              // console.log(that.links);
              let zip = {
                uploadUrl: that.links,
                unzipCode: that.decompressionPsw,
                uploadCode: that.extractPsw,
              };
              let resData = [];
              res.forEach((item, index) => {
                if (Array.isArray(item)) {
                  // console.log(item);
                  resData = resData.concat(item);
                  // console.log(resData);
                } else {
                  resData.push(item);
                  if (index === res.length - 1) {
                    // console.log(resData);
                  }
                }
              });
              let data = {
                id: this.$route.query.id,
                downloadDTO: zip,
                list: resData,
                name: that.title.name,
                canEngine: that.title.software,
                canVerison: that.title.versions,
                type: 1,
                menuId: that.$route.query.menuId,
              };
              that.$axios
                .post('/teach/digital/upLoadDigital', data)
                .then((res) => {
                  // console.log(res);
                  if (res.data.code === 200) {
                    that.$message({
                      type: 'success',
                      message: '添加成功！',
                    });
                    that.title.name = '';
                    that.title.software = '';
                    that.title.versions = '';
                    resData = [];
                    that.links = '';
                    that.decompressionPsw = '';
                    that.extractPsw = '';
                    that.bannerFile = [];
                    that.bannerImg = [];
                    that.coverUrl = '';
                    that.ruleForm.coverUrl = '';
                    that.ruleForm.coverFile = '';
                    that.log.detail = '';
                    that.$refs.editorRef.clearTxt();
                    that.$refs.uploadCover.clearFiles();
                    that.$refs.uploadZip.clearFiles();
                  } else {
                    that.$message({
                      type: 'error',
                      message: '添加失败！',
                    });
                  }
                });
            }
          });
          // this.postText().then((res) => {
          //   console.log(res);
          // });
          // this.postZip().then((res) => {
          //   console.log(res);
          // });
          // console.log(
          //   this.title.name,
          //   this.title.software,
          //   this.title.versions,
          //   this.log.detail,
          //   this.cover.raw,
          //   this.bannerFile,
          //   this.bannerImg,
          //   this.zipFile,
          //   this.links,
          //   this.extractPsw,
          //   this.decompressionPsw
          // );
          // let arr = [
          //   [{ name: 'jie', age: 20 }, { name: 'wang' }],
          //   { name: 'han' },
          //   { age: 16 },
          // ];
          // let arr2 = [];
          // arr.forEach((item, index) => {
          //   if (Array.isArray(item)) {
          //     console.log(item);
          //     arr2 = arr2.concat(item);
          //   } else {
          //     arr2.push(item);
          //   }
          // });
          // console.log(arr2);
        }
      });
    },
    postZip() {
      return new Promise((res) => {
        let data = this.oss;

        if (this.zipFile.name) {
          const that = this;
          let filename = this.zipFile.name;
          filename = fileUtil.getFileName(filename, data.dir, 'material');
          let formData = new FormData();
          formData.append('key', filename);
          formData.append('policy', data.policy);
          formData.append('OSSAccessKeyId', data.accessId);
          formData.append('success_action_status', '200');
          formData.append('signature', data.signature);
          formData.append('file', this.zipFile);
          let config = {
            headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
          };
          this.$axios
            .post(data.host, formData, config)
            .then(function (response) {
              // console.log(response);
              if (response.status < 300) {
                const data = {
                  fileName: that.zipFile.name,
                  fileSize: that.zipFile.size,
                  fileSuffix: that.zipFile.name.split('.')[1],
                  fileType: 'content',
                  objectname: filename,
                  resourceType: 'digital',
                };
                // console.log(data);
                // that.resData.push(data);
                res(data);
                // that.zipData = data;
                // console.log('zipData---------*', that.zipData);
              } else {
                that.$message({
                  type: 'error',
                  message: '封面上传失败！',
                });
              }
            });
        } else {
          // return;
        }
      });
    },
    //上传富文本
    postText() {
      return new Promise((ress) => {
        const that = this;
        // 先把字符串转化为文件，设置编码格式
        let txt = this.log.detail;
        let list = this.oss;
        let str = new Blob([txt], { type: 'text/plain;charset=utf-8' });
        // console.log(str);
        // let blob = new Blob([str], { type: 'text/plain;charset=utf-8' });
        // let url = window.URL.createObjectURL(blob);
        // let a = document.createElement('a');
        // let file = 'a.txt';
        // a.href = url;
        // 设置文件名
        let filename = 'fuwenben.txt';
        let txtName = 'fuwenben.txt';
        filename = fileUtil.getFileName(filename, list.dir, 'text');
        let formData = new FormData();
        formData.append('key', filename);
        formData.append('policy', list.policy);
        formData.append('OSSAccessKeyId', list.accessId);
        formData.append('success_action_status', '200');
        formData.append('signature', list.signature);
        formData.append('file', str, `fuwenben.txt`);
        // console.log(formData);
        let config = {
          headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
        };
        this.$axios.post(list.host, formData, config).then(function (res) {
          // console.log(res.data);
          if (res.status < 300) {
            const data = {
              fileName: txtName,
              fileSize: str.size,
              fileSuffix: txtName.split('.')[1],
              fileType: 'text',
              objectname: filename,
              resourceType: 'digital',
            };
            // console.log(data);
            // that.resData.push(data);
            ress(data);
          } else {
            that.$message({
              type: 'error',
              message: '富文本上传失败！',
            });
          }
        });
      });
    },
    // 上传轮播图
    async postBanner() {
      return new Promise((res) => {
        const that = this;
        // let listdata = [];

        if (this.bannerFile.length < 1) {
          // return;
          res();
        } else {
          let datas = [];
          for (let i = 0; i < this.bannerFile.length; i++) {
            // if (this.bannerFile[i].headerFile === '') {
            // return;
            // } else {
            let list = this.bannerFile;
            let data = this.oss;
            let filename = list[i].name;
            filename = fileUtil.getFileName(filename, data.dir, 'group');
            let formData = new FormData();
            formData.append('key', filename);
            formData.append('policy', data.policy);
            formData.append('OSSAccessKeyId', data.accessId);
            formData.append('success_action_status', '200');
            formData.append('signature', data.signature);
            formData.append('file', list[i].raw);
            let config = {
              headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
            };
            this.$axios
              .post(data.host, formData, config)
              .then(function (response) {
                // console.log(response);
                if (response.status < 300) {
                  const data = {
                    fileName: list[i].raw.name,
                    fileSize: list[i].raw.size,
                    fileSuffix: list[i].raw.name.split('.')[1],
                    fileType: 'lunimg',
                    objectname: filename,
                    resourceType: 'digital',
                  };
                  //
                  // res();
                  datas.push(data);
                  // res(data);
                } else {
                  that.$message({
                    type: 'error',
                    message: '轮播图上传失败！',
                  });
                }
                if (datas.length === that.bannerFile.length) {
                  that.bannerDatas = datas;
                  res(datas);
                }
              });
            // }
          }
          // res(datas);
        }
      });
    },
    //上传封面
    postCover() {
      return new Promise((res) => {
        const that = this;
        if (this.cover.raw === '') {
          // return;
          res();
        } else {
          let data = this.oss;
          let filename = this.cover.raw.name;
          filename = fileUtil.getFileName(filename, data.dir, 'cover');
          let formData = new FormData();

          formData.append('key', filename);
          formData.append('policy', data.policy);
          formData.append('OSSAccessKeyId', data.accessId);
          formData.append('success_action_status', '200');
          formData.append('signature', data.signature);
          formData.append('file', this.cover.raw);
          // console.log(formData);

          let config = {
            headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
          };
          // this.btn.disable = true;
          // this.btn.message = "上传中，请等待";
          this.$axios
            .post(data.host, formData, config)
            .then(function (response) {
              // console.log(response);
              if (response.status < 300) {
                // that.files.status = 1;
                const data = {
                  fileName: that.cover.raw.name,
                  fileSize: that.cover.raw.size,
                  fileSuffix: that.cover.raw.name.split('.')[1],
                  fileType: 'cover',
                  objectname: filename,
                  resourceType: 'digital',
                };
                // console.log(data);
                // that.resData.push(data);
                res(data);

                // that.resData.push(data);
              } else {
                that.$message({
                  type: 'error',
                  message: '封面上传失败！',
                });
              }
            });
        }
      });
    },
    //删除预览图片
    alterImg(index) {
      this.bannerImg.splice(index, 1);
      this.bannerFile.splice(index, 1);
    },
    // 吧文件列表的图片转换为url
    imagePreview: function (file, count) {
      let self = this;
      //定义一个文件阅读器
      let reader = new FileReader();
      //文件装载后将其显示在图片预览里
      reader.onload = function (e) {
        //将bade64位图片保存至数组里供上面图片显示
        if (count === 1) {
          self.bannerImg.push(e.target.result);
        } else {
          self.coverUrl = e.target.result;
        }
        // console.log(self.bannerImg);
      };
      reader.readAsDataURL(file);
    },
    handleRadio() {
      // console.log(this.radio);
      this.radio = '1';
      this.type = '0';
      // console.log(this.type);
    },
    handleRadios() {
      this.radio = '2';
      this.type = '1';
      // console.log(this.radio);
      // console.log(this.type);
    },
    //上传富文本
    // postText(list) {
    //   return new Promise((ress) => {
    //     const that = this;
    //     // 先把字符串转化为文件，设置编码格式
    //     let txt = this.log.detail;
    //     let str = new Blob([txt], { type: 'text/plain;charset=utf-8' });
    //     console.log(str);
    //     // let blob = new Blob([str], { type: 'text/plain;charset=utf-8' });
    //     // let url = window.URL.createObjectURL(blob);
    //     // let a = document.createElement('a');
    //     // let file = 'a.txt';
    //     // a.href = url;
    //     // 设置文件名
    //     let filename = 'fuwenben.txt';
    //     let txtName = 'fuwenben.txt';
    //     filename = fileUtil.getFileName(filename, list.dir);
    //     let formData = new FormData();
    //     formData.append('key', filename);
    //     formData.append('policy', list.policy);
    //     formData.append('OSSAccessKeyId', list.accessId);
    //     formData.append('success_action_status', '200');
    //     formData.append('signature', list.signature);
    //     formData.append('file', str, `fuwenben.txt`);
    //     console.log(formData);
    //     let config = {
    //       headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
    //     };
    //     // const data = {
    //     //   key: filename,
    //     //   policy: list.policy,
    //     //   OSSAccessKeyId: list.accessId,
    //     //   success_action_status: '200',
    //     //   signature: list.signature,
    //     //   Text: this.log.detail + '.txt',
    //     // };
    //     this.$axios.post(list.host, formData, config).then(function (res) {
    //       // console.log(res.data.message + '富文本上传成功')
    //       console.log(res.data);
    //       if (res.status < 300) {
    //         that.rich.status = 1;
    //         const data = {
    //           duration: null,
    //           fileName: txtName,
    //           fileSize: str.size,
    //           fileSuffix: txtName.split('.')[1],
    //           fileType: 'text',
    //           id: null,
    //           objectname: filename,
    //           resourceId: null,
    //           resourceType: 'courseware',
    //           upload_id: null,
    //           wights: null,
    //         };
    //         console.log(data);
    //         ress(data);
    //       } else {
    //         that.$message({
    //           type: 'error',
    //           message: '富文本上传失败！',
    //         });
    //       }
    //       // if (res.data.code === 200) {
    //       //   that.log.detail = '';
    //       //   that.$refs.editorRef.clearTxt();
    //       // }
    //     });
    //   });
    // },
    //富文本
    changeLogDetail(val) {
      this.log.detail = val;
    },
    // 轮播图文件状态
    handleRemoveBanner() {
      // console.log(file);
    },
    handleChansgeBanner(file, filelist) {
      // console.log(file);
      this.bannerFile = filelist;
      // console.log(filelist);
      this.ruleForm.coverFile = file.raw;
      this.imagePreview(this.ruleForm.coverFile, 1);
      // console.log(this.bannerFile);
    },
    // 封面文件状态
    handlePictureCardPreview() {
      // console.log(file);
    },
    handleRemove() {
      // console.log(file);
    },
    handleChansge(file) {
      this.cover.file = file;
      this.cover.raw = file.raw;
      this.imagePreview(this.cover.raw, 2);
    },
    handleChansgeZip(file) {
      // console.log(file);
      this.zipFile = file.raw;
    },
  },
};
</script>

<style lang="less" scoped>
.admin {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgb(241, 242, 246);
  align-items: center;
}
.title {
  width: 58%;
  height: 44px;
  background: #fff;
  border-radius: 4px;
  p {
    margin: 0;
    padding: 0;
    text-indent: 16px;
    line-height: 44px;

    // line-height: 60px;
  }
}
.coursewareMessage {
  width: 58%;
  min-height: 500px;
  background: #fff;
  margin: 16px 0;
  border-radius: 4px;
  padding-bottom: 16px;
  p {
    margin: 0;
    margin-bottom: 16px;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
  }
  .el-cascader {
    width: 95% !important;
    margin: 16px 0 16px 8px;
  }
  .el-input {
    width: 95%;
    margin-left: 8px;
    margin-bottom: 16px;
  }
  .hongdian {
    margin-left: 16px;
  }
  .richBox {
    width: 95%;
    margin-left: 28px;
  }
}
.coursewareCover {
  width: 58%;
  background: #fff;
  margin: 16px 0;
  border-radius: 4px;
  p {
    margin: 0;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
    span {
      font-size: 12px;
      color: #666666;
      margin-left: 16px;
    }
  }
  .upload-demo {
    margin: 16px 0 16px 16px;
  }
  .hongdian {
    position: relative;
    left: 16px;
    top: 110px;
  }
  /deep/ .el-upload {
    margin-left: 15px !important;
  }
  .coverbox {
    width: 360px;
    height: 180px;
    background: red;
    border-radius: 4px;
    margin: 16px 0 16px 28px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .cha {
      display: none;
      width: 30px;
      height: 30px;
      border-radius: 0 0 0 100%;
      background: rgb(205, 205, 207);
      position: absolute;
      right: 0;
      font-size: 12px;
      color: #f56c6c;
    }
  }
  .coverbox:hover .cha {
    display: inline-block;
  }
}
.coursewareContent {
  width: 58%;
  min-height: 100px;
  background: #fff;
  margin: 16px 0;
  border-radius: 4px;
  p {
    margin: 0;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
    span {
      font-size: 12px;
      color: #666666;
      margin-left: 16px;
    }
  }
  .upload-demo {
    margin: 16px 0 16px 16px;
  }
  .hongdian {
    float: left;
    margin: 12px 8px 0 0;
  }
  .imgBox {
    display: inline-block;
    width: 45%;
    height: 196px;
    background: #f1f3f5;
    margin-left: 28px;
    margin-top: 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    .cha {
      display: none;
      width: 30px;
      height: 30px;
      border-radius: 0 0 0 100%;
      background: rgb(205, 205, 207);
      position: absolute;
      right: 0;
      font-size: 12px;
      color: #f56c6c;
    }
    img {
      width: 290px;
      height: 164px;
      border-radius: 4px;
      margin: 16px 0 8px 16px;
      float: left;
    }
    .fileName {
      display: inline-block;
      line-height: 196px;
      text-indent: 16px;
    }
  }
  .imgBox:hover .cha {
    display: block !important;
  }
  .upload-btn {
    float: left;
  }
}
.el-upload__text {
  span {
    color: #000000;
    opacity: 0.25;
  }
}
.cover {
  width: 90%;
  // height: 200px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 2px 2px 1px #e0dfdf;
  p {
    text-indent: 20px;
    color: #888;
  }
  .box {
    width: 95%;
    height: 120px;
    background: #ebeef5;
    margin: 20px auto;
    .fileBtn {
      margin-top: 20px;
      margin-left: 50px;
    }
  }
}
.videoBox {
  width: 90%;
  height: 220px;
  background: #fff;
  margin-top: 10px;
  margin-bottom: 50px;
  box-shadow: 0 2px 2px 1px #e0dfdf;
  p {
    text-indent: 20px;
    color: #888;
  }
  .box {
    width: 95%;
    height: 120px;
    background: #ebeef5;
    margin: 0px auto;
    .fileBtn {
      margin-top: 20px;
      margin-left: 50px;
    }
  }
}
.shangchaun {
  color: #409eff;
  margin: 40px 0 20px 0;
  font-size: 44px;
}
.upload__text {
  p {
    border-bottom: none !important;
  }
}
.el-tag {
  margin: 0 5px;
}
.btn {
  width: 60%;
  height: 60px;
  font-size: 20px;
}
.progress-wrap {
  width: 300px;
}
.progress-wrap p {
  width: 100%;
}
.progress {
  background-color: #c5c8ce;
  height: 20px;
}
.progress span {
  display: block;
  background-color: #19be6b;
  height: 100%;
  width: 0;
}
.footerBtn {
  width: 58%;
  margin-top: 16px;
  .el-button {
    width: 140px;
  }
}
.material {
  width: 58%;
  background: #fff;
  border-radius: 4px;
  .pbiaoqian {
    margin: 0;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
    margin-bottom: 16px;
    span {
      font-size: 12px;
      color: #666666;
      margin-left: 16px;
    }
  }
  .wangpang {
    p {
      border: none;
      margin: 0;
    }
    .el-input {
      width: 95%;
      margin-left: 8px;
      margin-bottom: 16px;
    }
    .hongdian {
      margin: 0 8px;
    }
  }
  .type {
    display: inline-block;
    text-indent: 16px;
    margin-bottom: 16px;
  }
  .coursewareContents {
    margin: 0 16px 16px 16px;
  }
}
</style>
