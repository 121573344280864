<template>
  <div class="error404">
    <!-- <el-empty description="404 找不到" name="kong" :image-size="300">
    </el-empty> -->
    <div class="unfind">
      <!-- <div class="bj"> -->
      <img class="imgbj" src="../../assets/images/404bg.png" alt="" />
      <!-- </div> -->
      <!-- <div class="content"> -->
      <img class="imgcontent" src="../../assets/images/404.png" alt="" />
      <!-- </div> -->
      <p class="p">页面没有找到</p>
      <el-button class="btn" type="primary" @click="tohome">返回首页</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      role: '',
    };
  },
  mounted() {
    this.role = sessionStorage.getItem('role');
  },
  methods: {
    tohome() {
      /* 学生返回个人空间 */
      if (this.role == 'student') {
        sessionStorage.setItem('activeIndex2', '1');
        this.$router.push({
          path: '/workSpace',
        });
      } else if (this.role == 'teacher' || this.role == 'admin') {
        /* 老师返回资源管理 */
        sessionStorage.setItem('activeIndex2', '2');
        this.$router.push({
          path: '/courseware',
        });
      } else if (this.role == 'superadmin') {
        /* 超管返回资源审核 */
        sessionStorage.setItem('activeIndex2', '0');
        this.$router.push({
          path: '/certificateList',
        });
      } else if (this.role == '' || this.role == null) {
        /* 无角色信息返回登录页 */
        sessionStorage.removeItem('activeIndex2');
        this.$router.push({
          path: '/login',
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.error404 {
  width: 100%;
  height: 90vh;
  // position: relative;
  .unfind {
    width: 100%;
    height: 100%;
    // background: red;
    position: relative;
    .imgbj {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .imgcontent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .p {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .btn {
      position: absolute;
      top: 70%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
