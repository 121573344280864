<template>
  <div class="resource">
    <div class="resource_box">
      <nav :class="{ navs: role === 'student' }">
        <router-link class="router_btn" to="/courseware">课件资源</router-link>
        <router-link class="router_btn" to="/topic">题库资源</router-link>
        <router-link class="router_btn" to="/number">数字资源</router-link>
        <router-link
          v-if="role === 'teacher' || role === 'admin'"
          class="router_btn"
          to="/examination"
          >试卷资源</router-link
        >
        <router-link class="router_btn" to="/admin" v-show="role === 'admin'"
          >资源审核</router-link
        >
      </nav>
    </div>
    <el-container>
      <router-view></router-view>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: null,
    };
  },
  mounted() {
    this.role = sessionStorage.getItem('role');
  },
};
</script>

<style lang="less" scoped>
.resource {
  width: 100%;
  background: #f0f2f5;
  min-height: calc(100vh - 30px - 56px);
}
.resource_box {
  width: 100%;
  height: 56px;
  background: #fff;
}
nav {
  width: 100%-2px;
  height: 56px;
  line-height: 56px;
  margin-left: 24px;
  .router_btn {
    display: inline-block;
    color: #333;
    width: 96px;
    height: 38px;
    border-radius: 4px;
    border: 1px solid #666666;
    text-align: center;
    line-height: 40px;
    margin: 0 8px;
  }
  .router-link-exact-active {
    background: #409eff;
    color: #fff;
    border: #409eff 1px solid;
  }
}
.navs {
  width: calc(100% - 180px);
  margin-left: 180px;
}
.el-container {
  background: #f0f2f5;
  padding: 16px 16px 16px 32px;
  // min-height: 100vh;
}
.el-aside {
  background: #fff;
  min-height: 100vh;
  margin: 16px;
}
</style>
