<template>
  <div class="admin">
    <!-- <span class="titles">消息通知</span> -->
    <div class="tabbar">
      <div class="title">
        <span @click="changeTitle(0)" class="shenhe spans"
          >审核通知<span v-show="titleNum === 0"></span
        ></span>
        <span @click="changeTitle(1)" class="pinglun spans"
          >评论通知<span v-show="titleNum === 1"></span
        ></span>
        <span @click="changeTitle(2)" class="dianzan spans"
          >点赞通知<span v-show="titleNum === 2"></span
        ></span>
      </div>
      <div v-show="titleNum == 0">
        <span class="wei" :class="{ cur: tabbar === 0 }" @click="findData"
          >未读</span
        ><span class="yi" :class="{ cur: tabbar === 1 }" @click="findDatas"
          >已读</span
        >
      </div>
    </div>
    <div v-if="titleNum === 0">
      <div class="tabelBox">
        <el-table :data="getData" stripe style="width: 100%">
          <el-table-column prop="date" label="序列" width="300">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="message" label="消息内容" width="800">
          </el-table-column>
          <el-table-column prop="time" label="发布时间"> </el-table-column>
          <el-table-column prop="address" label="操作">
            <template slot-scope="scope"
              ><span class="info" @click="lookOne(scope.row)"
                >查看</span
              ></template
            >
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="titleNum === 1"><commentNotice></commentNotice></div>
    <div v-if="titleNum === 2"><likeNotice></likeNotice></div>

    <el-dialog title="查看反馈" :visible.sync="lookMessage" center>
      <div class="message_content">
        {{ content }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookMessage = false">确 定</el-button>
        <el-button @click="lookMessage = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commentNotice from './commentNotice/commentNotice.vue';
import likeNotice from './likeNotice/likeNotice.vue';
export default {
  components: { commentNotice, likeNotice },
  data() {
    return {
      tableData: [],
      currentPage3: 1,
      pageSize: 10,
      total: 1,
      cerificateId: '',
      tabbar: 0,
      dialogVisible: false,
      value: '',
      unpass: false,
      options: [],
      clickId: '',
      clickMenuId: '',
      clickType: '',
      // path: 'ws://192.168.1.81/chat',
      websock: null,
      lookMessage: false,
      textarea: '',
      userId: '',
      content: '',
      data: [],
      titleNum: 0,
    };
  },
  mounted() {
    // this.initWebSocket();
  },
  created() {
    this.getMessageList();
    this.userId = sessionStorage.getItem('id');
  },
  computed: {
    getData() {
      const that = this;
      return this.tableData.filter(function (item) {
        return item.status === that.tabbar;
      });
    },
  },
  methods: {
    // 切换头部菜单
    changeTitle(i) {
      this.titleNum = i;
    },
    findDatas() {
      this.tabbar = 1;
      this.getMessageList();
    },
    findData() {
      this.tabbar = 0;
      this.getMessageList();
    },
    // getData(i) {
    //   this.data = [];
    //   this.tableData.filter(function (item) {
    //     return item.status === i;
    //   });
    // },
    // 回复反馈
    postMessage() {},
    // 查看和回复
    lookOne(item) {
      this.lookMessage = true;
      this.content = item.message;
      if (item.status === 1) {
        return;
      }
      this.$axios
        .get(
          `/teach/mess/Lookmessage?id=${this.userId}&messageId=${item.id}&mess=${item.messageId}`
        )
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code === 200) {
            // alert('已查看');
            this.getMessageList();
          }
        });
    },
    //  获取消息列表
    getMessageList() {
      this.$axios
        .get(
          `/teach/mess/messageList?page=${this.currentPage3}&rows=${this.pageSize}&type=${this.tabbar}`
        )
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code === 200) {
            this.tableData = res.data.data.items;
            this.total = res.data.data.total;
          }
        });
    },
    // 打开通过对话框获取到该类型的公共资源目录
    // send() {
    //   let actions = {
    //     id: '1',
    //     toName: '2',
    //     message: '345678910jqka!!!!!!!!!!!!!!!!!!!!!!',
    //   };
    //   this.websocketsend(JSON.stringify(actions));
    // },
    openPassBox() {},
    openBox() {},
    handleSizeChange(v) {
      console.log(v);
    },
    handleCurrentChange(v) {
      console.log(v);
      this.currentPage3 = v;
      this.getMessageList();
    },
    // 初始化websocket
    // initWebSocket() {
    //   //初始化weosocket
    //   console.log('开始准备连接');
    //   // const wsuri = 'ws://192.168.1.22:8001/websocket/1212';
    //   const wsuri = 'ws://192.168.1.81:7001/chat';
    //   this.websock = new WebSocket(wsuri);
    //   this.websock.onmessage = this.websocketonmessage;
    //   this.websock.onopen = this.websocketonopen;
    //   this.websock.onerror = this.websocketonerror;
    //   this.websock.onclose = this.websocketclose;
    // },
    // websocketonopen() {
    //   //连接建立之后执行send方法发送数据
    //   console.log('连接成功');
    //   let actions = {
    //     id: '1',
    //     toName: '2',
    //     message: '345678910jqka!!!!!!!!!!!!!!!!!!!!!!',
    //   };
    //   this.websocketsend(JSON.stringify(actions));
    //   console.log('没报错就发送消息成功了');
    // },
    // websocketonerror(e) {
    //   //连接建立失败重连
    //   // this.initWebSocket();
    //   console.log('链接失败');
    //   console.log(e);
    // },
    // websocketonmessage(e) {
    //   //数据接收
    //   const redata = JSON.parse(e.data);
    //   console.log(redata);
    // },
    // websocketsend(Data) {
    //   // 把存在事件总线中的用户信息拿出来
    //   // this.$EventBus.$on('eventName', (data) => {
    //   //   console.log(data);
    //   //   //数据发送
    //   this.websock.send(Data);
    //   // });
    // },
    // websocketclose(e) {
    //   //关闭
    //   console.log('断开连接', e);
    //   // 关闭的时候断开事件总线的监听
    //   // this.$EventBus.$off('eventName');
    // },
  },
  // destroyed() {
  //   // 销毁监听
  //   this.socket.onclose();
  // },
};
</script>

<style lang="less" scoped>
.admin {
  width: 100%;
  min-height: calc(100vh - 30px - 144px);
  background: #fff;
  border-radius: 4px;
  position: relative;
  .titles {
    position: absolute;
    top: 25px;
    left: 16px;
    font-weight: 600;
  }
}
.tabbar {
  text-align: center;
  margin: 18px auto;
  margin-top: 24px;
  .title {
    // background: red;
    margin-bottom: 30px;
    span {
      font-weight: 600;
      position: relative;
      span {
        position: absolute;
        display: inline-block;
        background: #409eff;
        width: 32px;
        height: 2px;
        top: 25px;
        left: 17px;
      }
    }
    .pinglun {
      margin: 0 20px;
    }
  }
  .wei {
    // border-left: 1px solid #666666;
    border: 1px solid #666666;
    padding: 8px 20px;
    border-radius: 4px 0 0 4px;
  }
  .yi {
    border: 1px solid #666666;
    padding: 8px 20px;
    border-radius: 0 4px 4px 0;
  }
  .wei {
    border-radius: 4px 0 0 4px;
  }
  .yi {
    border-radius: 0 4px 4px 0;
    // border-left: none;
  }
  .cur {
    background: #409eff;
    color: #ffffff;
    border: #409eff 1px solid;
  }
  span:hover {
    cursor: pointer;
  }
}
.tabelBox {
  margin-top: 16px;
  .info {
    display: inline-block;
    padding: 5px 24px;
    border: 1px solid #909399;
    background: #909399;
    border-radius: 4px;
    color: #ffffff;
  }
  .info:hover {
    cursor: pointer;
  }
}
.block {
  text-align: center;
  margin-top: 10px;
  // position: absolute;
  // bottom: 16px;
  // left: 50%;
  // transform: translate(-50%, -50%);
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
.xiala {
  width: 686px;
  height: 40px;
  // background: red;
  margin: 0 auto;
}
.el-cascader {
  // width: 100%;
}
// .el-cascader-menu {
//   width: 300px;
// }
</style>
<style lang="less">
/deep/.el-cascader__dropdown {
  width: 686px !important;
}
.el-cascader-menu {
  width: 686px !important;
}
/deep/.el-cascader-panel {
  width: 686px !important ;
}
/deep/.el-scrollbar__view {
  width: 500px !important;
}
// .el-cascader-menu__list {
//   width: 800px;
// }
/deep/.el-dialog__body {
  margin-top: 16px;
}
.message_content {
  width: 100%;
  text-align: center;
}
</style>
