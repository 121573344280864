<template>
  <div class="zksc-classCourse" :class="{ 'is-student': role == 'student' }">
    <ul class="side-menu">
      <li
        v-for="(v, i) in classMenu"
        :key="v.id"
        :class="{ 'li-active': i == choice }"
        @click="choieClass(i, v)"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="v.name"
          placement="top"
          :open-delay="time"
        >
          <span>
            {{ v.name }}
          </span>
        </el-tooltip>
      </li>
    </ul>
    <div class="zksc-classCourse-c">
      <div class="zksc-classCourse-title">
        <h5>{{ className }}</h5>
        <el-button
          v-if="role == 'teacher'"
          type="primary"
          @click="openCourseDialog"
          >添加课程</el-button
        >
      </div>
      <!-- <el-empty
        description="暂时没有课程"
        v-if="tableData.length == 0"
      ></el-empty> -->

      <!-- 课程内容 -->
      <template>
        <el-table :data="tableData" style="width: 100%" stripe>
          <el-table-column
            type="index"
            width="50"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            property="name"
            label="课程名称"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            property="text"
            label="课程介绍"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-html="scope.row.text"></div>
            </template>
          </el-table-column>
          <el-table-column
            label="状态"
            align="center"
            v-if="role !== 'student'"
          >
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.isup == 0">未发布</el-tag>
              <el-tag type="primary" v-else>已发布</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300px" align="center">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.isup == 0 && role == 'teacher'"
                size="mini"
                @click="sendCourse(scope.row, 1)"
                >立即发布</el-button
              >
              <el-button
                v-if="scope.row.isup == 1 && role == 'teacher'"
                size="mini"
                @click="sendCourse(scope.row, 0)"
                >取消发布</el-button
              >
              <el-button size="mini" type="info" @click="lookCourse(scope.row)"
                >查看</el-button
              >
              <el-button
                v-if="role == 'teacher'"
                size="mini"
                type="warning"
                @click="deleteCourse(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          class="zksc-pagination"
          layout="prev, pager, next, jumper"
          :total="totalPage"
          :page-size="pageSize"
          @current-change="handleChangePage"
        ></el-pagination>
      </template>

      <!-- 发布课程对话框 -->
      <el-dialog
        class="zksc-dialog"
        title="添加课程到班级"
        :visible.sync="courseDialog"
        :before-close="handleClose"
        center
        v-loading="loading"
      >
        <el-table :data="UnpublishedTable" style="width: 100%" stripe>
          <el-table-column
            label="序列"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column property="name" label="课程名称"></el-table-column>
          <el-table-column property="text" label="课程介绍">
            <!-- <template slot-scope="scope">
              <div v-html="scope.row.richtext"></div>
            </template> -->
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="info" @click="releaseCourse(scope.row.id)"
                >添加到该班级</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          layout="prev, pager, next, jumper"
          :total="totalPage2"
          :page-size="pageSize2"
          @current-change="handleChangePage2"
        ></el-pagination>
        <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="courseDialog = false">取 消</el-button>
        <el-button type="primary" @click="createCourse">确 定</el-button>
        </span>-->
      </el-dialog>

      <!-- 查看对话框 -->
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      classMenu: [],
      className: '',
      choice: 0,
      courseDialog: false,
      tableData: [],
      UnpublishedTable: [],
      currentPage: 1,
      pageSize: 8,
      totalPage: 0,
      /* 对话框分页 */
      currentPage2: 1,
      pageSize2: 5,
      totalPage2: 0,
      role: '',
      loading: false,
      time: 800,
    };
  },
  mounted() {
    this.classId = sessionStorage.getItem('classId');
    this.choice = sessionStorage.getItem('menuIndex');
    this.className = sessionStorage.getItem('className');
    this.role = sessionStorage.getItem('role');
    if (this.role == 'teacher') {
      this.getClassManageMenu();
    } else if (this.role == 'admin') {
      this.getClassListMenu();
    } else if (this.role == 'student') {
      this.getClassCourse(this.classId);
    }
    if (this.role === 'student') {
      this.$store.commit('delMessage', 0);
      // sessionStorage.setItem('hongdian', 0);
      // this.hongdian = this.$store.state.messageNum;
      // console.log(this.$store.state.messageNum);
    }
  },
  created() {},
  methods: {
    /**
     * 获取班级菜单数据
     */
    async getClassManageMenu() {
      const { data } = await this.$axios.get('/teach/class/findClass');
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.classMenu = data.data;
      if (sessionStorage.getItem('classId') == 'null') {
        sessionStorage.setItem('classId', data.data[0].id);
        sessionStorage.setItem('menuIndex', 0);
        sessionStorage.setItem('className', data.data[0].name);
        this.classId = data.data[0].id;
        this.className = this.classMenu[0].name;
        this.choice = 0;
      }
      this.getClassCourse(this.classId);
    },
    choieClass(i, v) {
      this.choice = i;
      this.className = v.name;
      this.classId = v.id;
      this.currentPage = 1;
      sessionStorage.setItem('classId', v.id);
      sessionStorage.setItem('menuIndex', i);
      sessionStorage.setItem('className', v.name);
      this.getClassCourse(v.id);
    },
    /* 获取已发布的课程列表 */
    async getClassCourse(id) {
      this.tableData = [];
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const { data } = await this.$axios.get('/teach/class/findcourseing', {
        params: {
          classId: id,
          page: this.currentPage,
          rows: this.pageSize,
        },
      });
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      } else {
        if (data.data == null || data.data.items.length == 0) {
          setTimeout(() => {
            loading.close();
          }, 500);
          return this.$message.warning('暂时没有课程');
        }
      }
      // /* 过滤未发布的课程 */
      if (this.role == 'student') {
        this.tableData = data.data.items.filter((v) => v.isup == 1);
      } else {
        this.tableData = data.data.items;
      }

      this.totalPage = data.data.total;
      // this.tableData.map(async (v) => {
      //   let res = await this.$axios.get(v.text);
      //   if (res.status !== 200) {
      //     setTimeout(() => {
      //       loading.close();
      //     }, 500);
      //     return this.$message.error('暂时没有内容呀');
      //   }
      //   this.$set(v, 'text', res.data);
      // });
      setTimeout(() => {
        loading.close();
      }, 500);
    },
    /* 打开发布课程对话框 */
    async openCourseDialog() {
      this.loading = true;
      this.courseDialog = true;

      /* 获取还未发布的课程 */
      const { data } = await this.$axios.get(`/teach/class/findcourse`, {
        params: {
          classId: this.classId,
          page: this.currentPage2,
          rows: this.pageSize2,
        },
      });
      if (data.code !== 200) {
        setTimeout(() => {
          this.loading = false;
        }, 500);

        return this.$message.error('获取未发布课程信息失败');
      }
      if (data.data.items.length == 0) {
        setTimeout(() => {
          this.loading = false;
        }, 500);
        return this.$message.warning('暂时没有未发布的课程');
      }
      this.UnpublishedTable = data.data.items;
      this.totalPage2 = data.data.total;
      // this.UnpublishedTable.map(async (v) => {
      //   const { status } = await this.$axios.get(v.text);
      //   if (status !== 200) {
      //     return this.$message.error('获取失败');
      //   }
      //   this.$set(v, 'richtext', data);
      // });
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    /* 添加课程到班级 */
    async releaseCourse(courseId) {
      const { data } = await this.$axios.post(
        `/teach/class/upclasscourse?courseId=${courseId}&classId=${this.classId}`
      );
      if (data.code !== 200) {
        return this.$message.error('发布课程失败');
      }
      this.$message.success('发布课程成功');

      this.getClassCourse(this.classId);
      this.courseDialog = false;
    },
    /* 关闭对话框 */
    handleClose() {
      this.currentPage2 = 1;
      this.courseDialog = false;
    },
    /* 去查看课程 */
    lookCourse(data) {
      let courseId = data.id;
      // 把课件的信息和课件的名称保存到本地，跳转到新的页面回显之后删掉
      sessionStorage.setItem('couresname', data.name);
      sessionStorage.setItem('courescontent', data.text);
      /* 打开新的页面 */
      const { href } = this.$router.resolve({
        name: 'LookCourse',
        path: '/lookCourse',
        query: {
          courseId,
        },
      });
      window.open(href);
    },
    /* 发布课程 - 取消发布 */
    sendCourse({ id }, status) {
      // console.log(this.classId);
      // console.log(id);
      this.$confirm('是否发布该课程?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          //
          const { data } = await this.$axios.post(
            `/teach/class/addcourse?courseId=${id}&classId=${this.classId}&status=${status}`
          );
          if (data.code !== 200) return this.$message.error(data.message);
          if (status == 1) {
            this.$message.success('发布成功');
          } else {
            this.$message.success('取消发布');
          }
          this.getClassCourse(this.classId);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消发布',
          });
        });
    },
    /* 删除课程 */
    deleteCourse(courseId) {
      this.$confirm('是否删除该课程?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const { data } = await this.$axios.delete(
            `/teach/class/delcourse?courseId=${courseId}&classId=${this.classId}`
          );
          if (data.code !== 200) {
            return this.$message.error('删除课程失败');
          }
          this.$message.success('删除课程成功');
          this.getClassCourse(this.classId);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    handleChangePage(current) {
      this.currentPage = current;

      this.getClassCourse(this.classId);
    },
    handleChangePage2(current) {
      this.currentPage2 = current;

      this.openCourseDialog(this.classId);
    },

    /*-------------- 证书管理员部分 ----------------*/
    /* 证书管理员获取班级菜单 */
    async getClassListMenu() {
      this.tableData = [];
      /* 加载动画 */
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      /* 获取证书id  saveSilderId*/
      let id = localStorage.getItem('silderId');
      // TODO 班级数量超过 100 怎么办？？？
      let { data } = await this.$axios.get(
        `/teach/cerificate/findCerClass?cerificateId=${id}&page=${1}&rows=${100}`
      );
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.warning('暂时没有班级信息');
      }
      this.classMenu = data.data.items;
      /* 初始默认是第一个 */
      if (sessionStorage.getItem('classId') == 'null') {
        sessionStorage.setItem('classId', data.data.items[0].id);
        sessionStorage.setItem('menuIndex', 0);
        sessionStorage.setItem('className', data.data.items[0].name);
        this.classId = data.data.items[0].id;
        this.choice = 0;
      }
      this.getClassCourse(this.classId);
      setTimeout(() => {
        loading.close();
      }, 500);
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-classCourse {
  padding: 16px 22px;
  display: flex;
  justify-content: space-between;
  .side-menu {
    width: 300px;
    margin: 0 16px 0 0;
    background-color: #fff;
    border-radius: 4px;
    li {
      padding: 0 20px;
      height: 40px;
      margin: 6px;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    li:hover {
      border-radius: 4px;
      background: #f1f3f5;
    }
    .li-active {
      border-radius: 4px;
      background: #f1f3f5;
    }
  }
}
.is-student {
  display: block;
  .zksc-classCourse-c {
    width: 100%;
  }
}
.zksc-classCourse-c {
  width: calc(100% - 300px - 22px);
  padding-bottom: 30px;
  min-height: calc(100vh - 30px - 56px - 56px - 32px);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  .zksc-classCourse-title {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 14px;
      font-weight: normal;
      padding-left: 16px;
    }
    .el-button {
      margin-right: 16px;
    }
  }
  .el-table {
    padding: 0 16px 16px;
  }
}
// hahah
// /deep/.zksc-dialog {
//   .el-dialog__header {
//     height: 70px !important;
//     background: #409eff !important;
//     padding: 0 !important;

//     line-height: 70px;
//     text-align: center;
//     .el-dialog__title {
//       color: #ffffff !important;
//       font-size: 24px !important;
//     }
//     .el-dialog__headerbtn {
//       top: 30px;
//       right: 20px;
//     }
//     .el-dialog__close {
//       color: #fff !important;
//     }
//   }
// }

.el-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
