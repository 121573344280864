<template>
  <div class="collection">
    <div class="header">
      <icon-svg icon-class="shoucang_2" class="icon"></icon-svg>
      <h2>我的收藏列表</h2>
    </div>
    <el-empty v-if="itemlists.length == 0" description="暂无收藏"></el-empty>
    <div v-else>
      <coursewareBox
        class="collection-box"
        :itemList="itemlists"
        :menuId="clickMenuId"
        @sendList="getCollection"
      ></coursewareBox>

      <el-pagination
        class="zksc-pagination"
        layout="prev, pager, next, jumper"
        :total="totalPage"
        :page-size="pageSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import coursewareBox from '../../common/coursewareBox.vue';
export default {
  components: {
    coursewareBox,
  },
  data() {
    return {
      tableData: [
        {
          examname: '试卷1',
          proname: '胡图图为什么会长胖',
          class: '选择',
          grade: 4,
        },
      ],
      totalPage: 0,
      pageSize: 10,
      current: 1,
      itemlists: [],
      clickMenuId: 0,
    };
  },
  mounted() {
    this.getCollection();
  },
  methods: {
    handleChangePage() {},
    async getCollection() {
      this.itemlists = [];
      // console.log('sss');
      const { data } = await this.$axios.get('/teach/student/findLike');
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      if (data.data == null) {
        return this.$message.warning('暂时没有收藏呀');
      }
      this.itemlists = data.data.items;
      this.totalPage = data.data.total;
      // console.log('this.tableData: ', this.tableData);
      // console.log(data);
    },
  },
};
</script>
<style lang="less" scoped>
.collection {
  margin: 16px auto;
  padding-bottom: 16px;
  width: 1210px;
  border-radius: 4px;
  background: #ffffff;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #cccccc;
    .icon {
      margin-right: 8px;
      margin-top: 4px;
      color: #409eff;
    }
    h2 {
      font-size: 24px;
      color: #333333;
    }
  }
  .collection-box {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-around;
  }
}
.el-pagination {
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
