<template>
  <div class="zksc-exam" :class="{ 'is-student': role == 'student' }">
    <ul class="side-menu" v-if="role != 'student'">
      <li
        v-for="(v, i) in classMenu"
        :key="v.id"
        :class="{ 'li-active': i == choice }"
        @click="choieClass(i, v)"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="v.name"
          placement="top"
          :open-delay="time"
        >
          <span>
            {{ v.name }}
          </span>
        </el-tooltip>
      </li>
    </ul>
    <div class="zksc-exam-c">
      <div class="zksc-exam-title">
        <h5>{{ className }}</h5>
        <el-button
          v-if="role == 'teacher'"
          type="primary"
          @click="openSendExamDialog"
          >发布模拟考</el-button
        >
        <!-- TODO -->
        <el-button v-if="role == 'student'" @click="opendGreadeDialog"
          >我的历史考试成绩</el-button
        >
      </div>
      <!-- 考试内容 -->
      <el-empty
        description="暂时没有模拟考"
        v-if="tableData.length == 0"
      ></el-empty>
      <template v-else>
        <el-table :data="tableData" style="width: 100%" stripe>
          <el-table-column
            type="index"
            width="50"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            property="name"
            label="试卷名称"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            property="introduction"
            label="试卷描述"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column property="duration" label="考试时长">
            <template slot-scope="scope">
              <div>
                {{ scope.row.duration ? scope.row.duration : '45' }}分钟
              </div>
            </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            label="创建时间"
            width="180px"
            align="center"
          ></el-table-column>
          <!-- <el-table-column
            property="testSrc"
            label="模拟练习"
            v-if="role != 'student'"
          >
            <template slot-scope="scope">
              <span class="start" @click="startPracticing(scope.row)"
                >开始练习</span
              >
            </template>
          </el-table-column> -->

          <el-table-column label="操作" width="380" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="openExamMsgDialog(scope.row)"
                v-if="role != 'student'"
                >考试情况</el-button
              >
              <el-button
                size="mini"
                type="warning"
                @click="delExam(scope.row)"
                v-if="role == 'teacher'"
                >删除</el-button
              >
              <el-button
                v-if="role == 'student' && scope.row.type == 0"
                size="mini"
                type="primary"
                @click="startPracticing(scope.row)"
                >去模拟考</el-button
              >
              <el-button
                v-if="role == 'student' && scope.row.type == 1"
                size="mini"
                type="success"
                >已考</el-button
              >
              <!-- @click="startPracticing(scope.row)" -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          layout="prev, pager, next, jumper"
          :total="total"
          :page-size="pageSize"
          :current-page.sync="currentPage"
          @current-change="handleChangePage"
        ></el-pagination>
      </template>
      <!-- 发布模拟考 -->
      <el-dialog
        class="zksc-dialog"
        title="发布模拟考"
        :visible.sync="sendExamDialog"
        :before-close="closeExamDialog"
      >
        <el-table :data="releaseExamTable" style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            property="name"
            label="试卷名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            property="introduction"
            label="考试描述"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column property="examTime" label="考试时长">
            <template slot-scope="scope">
              <div>{{ scope.row.duration ? scope.row.duration : 45 }}分钟</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <span class="zksc-user" @click="useExam(scope.row)"
                >使用该试卷</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          :page-size="pageSize1"
          layout="prev, pager, next, jumper"
          :total="total1"
          @current-change="sendExamChange"
        ></el-pagination>
      </el-dialog>

      <!-- 考试情况 -->
      <el-dialog
        v-loading="loading"
        class="zksc-dialog"
        title="考试情况(学生考试情况记录表)"
        :visible.sync="examMsgDialog"
        :before-close="closeExamMsgDialog"
      >
        <el-table :data="examMsgTable" style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column property="imgurl" label="头像" width="50">
            <template slot-scope="scope">
              <img
                class="zksc-avatar"
                v-if="scope.row.imgurl"
                :src="scope.row.imgurl"
                alt="头像"
                title="头像"
              />
              <img
                class="zksc-avatar"
                v-else
                src="../../../assets/images/avatar.jpg"
                alt="头像"
                title="头像"
              />
            </template>
          </el-table-column>
          <el-table-column
            property="realname"
            label="真实姓名"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column property="username" label="用户名"></el-table-column>
          <el-table-column property="grade" label="成绩">
            <template slot-scope="scope">
              <div>
                {{ scope.row.grade == -1 ? '暂未考试' : scope.row.grade }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="createTime" label="交卷时间">
            <template slot-scope="scope">
              <div>
                {{
                  scope.row.createTime == null
                    ? '暂未考试'
                    : scope.row.createTime
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          :page-size="pageSize2"
          layout="prev, pager, next, jumper"
          :total="total2"
          @current-change="examMsgChange"
        ></el-pagination>
      </el-dialog>

      <!-- 考试情况 -->
      <el-dialog
        class="zksc-dialog"
        title="我的考试成绩"
        :visible.sync="isGrade"
        :before-close="closeGradeDialog"
      >
        <el-table :data="examGradeTable" style="width: 100%">
          <el-table-column
            label="序号"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            property="examName"
            label="试题名称"
          ></el-table-column>
          <el-table-column property="time" label="花费时间"></el-table-column>
          <el-table-column property label="总分" align="center">
            <template>
              <div>100分</div>
            </template>
          </el-table-column>
          <el-table-column property="grade" label="成绩">
            <template slot-scope="scope">
              <div>{{ scope.row.grade }}分</div>
            </template>
            <!-- <template slot-scope="scope">
              <div>{{scope.row.grade == null?'暂未考试':scope.row.grade}}</div>
            </template>-->
          </el-table-column>
          <!-- <el-table-column property="createTime" label="交卷时间">
            <template slot-scope="scope">
              <div>{{scope.row.createTime == null?'暂未考试':scope.row.createTim}}</div>
            </template>
          </el-table-column>-->
        </el-table>
        <el-pagination
          class="zksc-pagination"
          :page-size="pageSize3"
          layout="prev, pager, next, jumper"
          :total="total3"
          @current-change="examGradeChange"
        ></el-pagination>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// import SideMenu from '../../sideMenu.vue';
export default {
  components: {
    // SideMenu,
  },
  data() {
    return {
      classMenu: [],
      className: '',
      choice: 0,
      classId: '',
      sendExamDialog: false,
      examMsgDialog: false,
      tableData: [
        // {
        //   name: '数字媒体',
        //   disable:
        //     '数字媒体数字媒体数字媒体数字媒体数字媒体数字媒体数字媒体数字媒体数字媒体',
        //   examTime: '45分钟',
        //   createTime: '2021-01-01 10:21:12',
        //   testSrc: 'url',
        // },
      ],
      releaseExamTable: [
        // {
        //   name: '数字媒体',
        //   examDisable:
        //     '数字媒体数字媒体数字媒体数字媒体数字媒体数字媒体数字媒体数字媒体数字媒体',
        //   examTime: '45分钟',
        // },
      ],
      examMsgTable: [
        // {
        //   avatar:
        //     'https://portrait.gitee.com/uploads/avatars/user/1824/5472531_yun_liu_1578987242.png!avatar60',
        //   name: '团子',
        //   no: '123456',
        //   score: '100分',
        //   subTime: '2021-05-21 10:10:10',
        // },
      ],
      /* 未发布的模拟考 */
      pageSize1: 6,
      currentPage1: 1,
      total1: 0,
      /* 已发布 */
      pageSize: 10,
      currentPage: 1,
      total: 0,

      /* 查看考试情况 */
      pageSize2: 6,
      currentPage2: 1,
      total2: 0,
      row: {},
      role: '',
      loading: false,
      /* 学生查看成绩 */
      isGrade: false,
      pageSize3: 5,
      currentPage3: 1,
      total3: 0,
      examGradeTable: [],
      time: 800,
    };
  },
  mounted() {
    this.classId = sessionStorage.getItem('classId');
    this.choice = sessionStorage.getItem('menuIndex');
    this.className = sessionStorage.getItem('className');
    this.role = sessionStorage.getItem('role');
    if (this.role == 'teacher') {
      this.getClassManageMenu();
    } else if (this.role == 'admin') {
      this.getClassListMenu();
    } else if (this.role == 'student') {
      this.published();
      this.$store.commit('delExam', 0);
    }
  },

  methods: {
    /**
     * 获取班级菜单数据
     */
    async getClassManageMenu() {
      const { data } = await this.$axios.get('/teach/class/findClass');
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.classMenu = data.data;
      if (sessionStorage.getItem('classId') == 'null') {
        sessionStorage.setItem('classId', data.data[0].id);
        sessionStorage.setItem('menuIndex', 0);
        sessionStorage.setItem('className', data.data[0].name);
        this.classId = data.data[0].id;
        this.className = this.classMenu[0].name;
        this.choice = 0;
      }
      this.published();
    },
    choieClass(i, v) {
      this.choice = i;
      this.className = v.name;
      this.classId = v.id;
      this.currentPage = 1;
      sessionStorage.setItem('classId', v.id);
      sessionStorage.setItem('menuIndex', i);
      sessionStorage.setItem('className', v.name);
      this.published();
    },
    // 打开发布模拟考对话框
    async openSendExamDialog() {
      this.sendExamDialog = true;
      this.releaseExamTable = [];
      const { data } = await this.$axios.get('/teach/class/findexam', {
        params: {
          currents: this.currentPage1,
          size: this.pageSize1,
          classId: this.classId,
        },
      });
      if (data.code !== 200) {
        return this.$message.error(data.message);
      } else {
        if (data.data.items == 0)
          return this.$message.warning('暂时没有数据呀');
      }
      this.releaseExamTable = data.data.items;
      this.total1 = data.data.total;
    },
    closeExamDialog() {
      this.sendExamDialog = false;
    },
    /* 发布模拟考分页 */
    sendExamChange(current) {
      this.currentPage1 = current;
      this.openSendExamDialog();
    },
    /* 发布模拟考 */
    useExam(row) {
      this.$confirm('是否使用该试卷?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const { data } = await this.$axios.post(
            `/teach/class/upclassexam?examId=${row.id}&classId=${this.classId}`
          );
          if (data.code !== 200) return this.$message.error('发布试卷失败');
          this.$message({
            type: 'success',
            message: '发布试卷成功!',
          });
          /* 重新拉去未发布的试卷 */
          this.openSendExamDialog();
          this.published();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消发布',
          });
        });
    },
    // 删除考试
    delExam(row) {
      this.$confirm('是否删除该模拟考？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const { data } = await this.$axios.delete('/teach/class/delexam', {
            params: {
              classId: this.classId,
              examId: row.id,
            },
          });
          if (data.code !== 200) {
            return this.$message.error('删除该试卷失败');
          }
          this.$message.success('删除试卷成功');
          this.published();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    async openExamMsgDialog(row) {
      this.examMsgDialog = true;
      this.row = row;
      this.loading = true;
      const { data } = await this.$axios.get('/teach/class/findexamdetile', {
        params: {
          classId: this.classId,
          markId: row.id,
          markType: 'exam',
          currents: this.currentPage2,
          size: this.pageSize2,
        },
      });
      if (data.code !== 200) {
        this.loading = false;
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        this.loading = false;
        return this.$message.warning('暂时没有考试情况');
      }
      this.examMsgTable = data.data.items;
      this.total2 = data.data.total;
      this.loading = false;
    },
    /* 分页 */
    examMsgChange(current) {
      this.currentPage2 = current;
      this.openExamMsgDialog(this.row);
    },
    closeExamMsgDialog() {
      this.examMsgDialog = false;
    },
    /* 已发布的模拟考 */
    async published() {
      this.tableData = [];
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const { data } = await this.$axios.get('/teach/class/findclassexam', {
        params: {
          currents: this.currentPage,
          size: this.pageSize,
          classId: this.classId,
        },
      });

      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      } else {
        if (data.data == null || data.data.items.length == 0) {
          setTimeout(() => {
            loading.close();
          }, 500);
          return this.$message.warning('暂时没有模拟考');
        }
      }
      this.tableData = data.data.items;
      this.total = data.data.total;
      setTimeout(() => {
        loading.close();
      }, 500);
    },
    handleChangePage(current) {
      console.log(current);
      this.currentPage = current;
      this.published();
    },
    /* 开始模拟考 */
    startPracticing(row) {
      // this.$message.warning('TODO');
      sessionStorage.setItem('examId', row.id);
      this.$router.push({
        path: '/examing',
        name: 'Examing',
        // query: {
        //   examId: row.id,
        // },
        // params: {},
      });
    },

    /**
     * 证书管理员菜单
     */
    async getClassListMenu() {
      this.tableData = [];
      /* 加载动画 */
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      /* 获取证书id  saveSilderId*/
      let id = localStorage.getItem('silderId');

      let { data } = await this.$axios.get(
        `/teach/cerificate/findCerClass?cerificateId=${id}&page=${1}&rows=${100}`
      );
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.warning('暂时没有班级信息');
      }
      this.classMenu = data.data.items;
      /* 初始默认是第一个 */
      if (sessionStorage.getItem('classId') == 'null') {
        sessionStorage.setItem('classId', data.data.items[0].id);
        sessionStorage.setItem('menuIndex', 0);
        sessionStorage.setItem('className', data.data.items[0].name);
        this.classId = data.data.items[0].id;
        this.choice = 0;
      }
      this.published();
      // this.getPlan(data.data.items[0].id);
      setTimeout(() => {
        loading.close();
      }, 500);
    },

    /* 学生查询考试成绩 */
    async opendGreadeDialog() {
      this.isGrade = true;
      const { data } = await this.$axios.get('/teach/student/findMyExam', {
        params: {
          page: this.currentPage3,
          rows: this.pageSize3,
        },
      });
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      data.data.items.map((v) => {
        let m = ~~(v.time / 1000 / 60);
        let s = ~~((v.time / 1000) % 60);
        v.time = `${m}分${s}秒`;
      });
      this.examGradeTable = data.data.items;
      this.total3 = data.data.total;
      // console.log(data);
    },
    closeGradeDialog() {
      this.isGrade = false;
    },
    examGradeChange(current) {
      this.currentPage3 = current;
      this.opendGreadeDialog();
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-exam {
  padding: 16px 22px;
  display: flex;
  justify-content: space-between;
  .side-menu {
    width: 300px;
    margin: 0 16px 0 0;
    background-color: #fff;
    border-radius: 4px;
    li {
      padding: 0 20px;
      height: 40px;
      margin: 6px;
      line-height: 40px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    li:hover {
      border-radius: 4px;
      background: #f1f3f5;
    }
    .li-active {
      border-radius: 4px;
      background: #f1f3f5;
    }
  }
}
.is-student {
  display: block;
  .zksc-exam-c {
    width: 100%;
  }
}
.zksc-exam-c {
  width: calc(100% - 300px - 22px);
  min-height: calc(100vh - 30px - 56px - 56px - 32px);
  box-sizing: border-box;
  padding-bottom: 30px;
  background-color: #fff;
  border-radius: 4px;
  .zksc-exam-title {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 14px;
      font-weight: normal;
      padding-left: 16px;
    }
    .el-button {
      margin-right: 16px;
    }
  }
  .el-table {
    padding: 0 16px 16px;
    .start {
      color: #2881f6;
      cursor: pointer;
    }
  }
}
/deep/.zksc-dialog {
  .el-dialog__header {
    height: 70px !important;
    background: #409eff !important;
    padding: 0 !important;

    line-height: 70px;
    text-align: center;
    .el-dialog__title {
      color: #ffffff !important;
      font-size: 24px !important;
    }
    .el-dialog__headerbtn {
      top: 30px;
      right: 20px;
    }
    .el-dialog__close {
      color: #fff !important;
    }
  }

  .zksc-user {
    color: #2881f6;
    cursor: pointer;
  }
  .zksc-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.el-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
