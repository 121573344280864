<template>
  <div class="box">
    <div class="examination">
      <div class="examination_list">
        <ul>
          <li v-for="(item, index) in itemData" :key="index">
            <img src="@/assets/images/avatar2.jpg" alt="" />
            <p>
              {{ item.name }}
            </p>
            <div class="time">
              <span>考试时长：45分钟</span>
              <br />
              <span>创建时间：{{ item.createTime }}</span>
            </div>
            <div class="btn">
              <el-button type="info" @click="lookOne(item.id)">查看</el-button>
            </div>
          </li>
        </ul>
      </div>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="新建试卷" :visible.sync="dialogAdd" center>
      <div class="add_box">
        <div>
          <span>组卷方式</span
          ><el-radio v-model="radios" label="1">自动组卷</el-radio>
        </div>
        <div>
          <p>
            <span>自动组卷:</span
            >将从精选题库选出20道单选题和10道判断题进行自动随机组卷
          </p>
        </div>
        <div>
          <span><em class="hongdian"></em>试卷名称</span
          ><el-input v-model="name" placeholder="请输入名称"></el-input>
        </div>
        <div>
          <span><em class="hongdian"></em>试卷描述</span
          ><el-input v-model="content" placeholder="请输入描述"></el-input>
        </div>
        <div>
          <p><span>考试时长:</span>45分钟</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addTextPaper()">提 交</el-button>
        <el-button @click="dialogAdd = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="lookData.name" :visible.sync="dialogDetail" center>
      <div
        class="detailBox"
        v-for="(item, index) in lookData.list"
        :key="index"
      >
        <div class="examinationDetail">
          <p class="issue">{{ index + 1 }}、{{ item.name }}</p>
          <p>
            <el-radio :value="item.answer.toUpperCase()" label="A">{{
              item.optionsA
            }}</el-radio>
          </p>
          <p>
            <el-radio :value="item.answer.toUpperCase()" label="B">{{
              item.optionsB
            }}</el-radio>
          </p>
          <p>
            <el-radio
              v-show="item.optionsC"
              :value="item.answer.toUpperCase()"
              label="C"
              >{{ item.optionsC }}</el-radio
            >
          </p>
          <p>
            <el-radio
              v-show="item.optionsD"
              :value="item.answer.toUpperCase()"
              label="D"
              >{{ item.optionsD }}</el-radio
            >
          </p>
        </div>
        <el-divider></el-divider>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogAdd: false,
      dialogDetail: false,
      dialogOutward: false,
      currentPage3: 1,
      pageCount: 10,
      radios: '1',
      total: 0,
      pageSize: 10,
      name: '',
      radio: '',
      content: '',
      list: false,
      menuList: [],
      itemData: [],
      lookData: [],
      itemId: '',
      silderData: [],
      menuId: '',
    };
  },
  created() {
    this.menuId = this.$route.query.menuId;
    this.getMenuId();
  },
  methods: {
    lookOne(id) {
      this.lookData = [];
      this.dialogDetail = true;
      this.$axios.get(`/teach/exam/findOneExam?examId=${id}`).then((res) => {
        // console.log(res.data);
        if (res.data.code === 200) {
          this.lookData = res.data.data;
          // console.log(this.lookData);
        }
      });
    },
    getMenuId() {
      // this.itemlists = []
      // console.log(item);
      // this.clickItem = item;
      // this.clickMenuId = item.id;
      // console.log(this.clickMenuId);
      // if (item.level === 1) {
      //   this.silderData = [];
      //   this.silderData.push(item);
      //   console.log(this.silderData);
      // } else if (
      //   item.level > this.silderData[this.silderData.length - 1].level
      // ) {
      //   this.silderData.push(item);
      //   console.log(this.silderData);
      // } else if (
      //   item.level === this.silderData[this.silderData.length - 1].level
      // ) {
      //   this.silderData.pop();
      //   this.silderData.push(item);
      //   console.log(this.silderData);
      // } else {
      //   this.silderData.pop();
      //   console.log(this.silderData);
      //   if (this.silderData[this.silderData.length - 1].name !== item.name) {
      //     this.silderData.pop();
      //     this.silderData.push(item);
      //     console.log(this.silderData);
      //   }
      // }
      // if (item.level >= 3) {
      this.$axios
        .get(
          `/teach/exam/findMenuExam?menuId=${this.menuId}&page=${this.currentPage3}&rows=${this.pageCount}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.itemData = res.data.data.items;
            // console.log(this.itemData);
            this.total = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
          }
        });
      // }
    },
    getList() {
      const that = this;
      this.types = '试卷资源';
      this.$axios
        .get(
          `/teach/courseware/findMenuWare?cerificateId=${this.$store.state.silderId}&type=${this.types}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            that.menuList = res.data.data;
            // console.log(that.menuList);
            that.list = true;
          }
        });
    },
    handleSizeChange() {},
    handleCurrentChange() {},
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  min-height: 100vh;
  display: flex;
}
.sidemenu {
  background: #fff;
  border-radius: 4px;
  width: 300px;
  min-height: 100vh;
}
.examination {
  width: 100%;
  height: 100vh;
  background: #fff;
  border-radius: 4px;
  margin-left: 16px;
}
.examination_title {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    text-indent: 15px;
  }
  .btn_box {
    margin-right: 16px;
  }
}
.examination_list {
  width: 100%;
  ul {
    width: calc(100% - 32px);
    height: calc(100vh - 150px);
    margin: 0 16px;
    border-radius: 4px;
    overflow-y: auto;
    li {
      width: 100%;
      height: 196px;
      background: #f1f3f5;
      border-radius: 4px;
      position: relative;
      margin: 8px 0;
      img {
        width: 290px;
        height: 164px;
        border-radius: 4px;
        float: left;
        margin: 16px;
      }
      p {
        margin: 0;
        padding: 0;
        height: 100px;
        position: relative;
        top: 16px;
        .fabu {
          display: inline-block;
          background: #409eff;
          padding: 1px 4px;
          border-radius: 4px;
          margin-left: 14px;
          color: #fff;
          font-size: 12px;
        }
        .weifabu {
          display: inline-block;
          background: none;
          padding: 0 3px;
          border-radius: 4px;
          margin-left: 14px;
          color: #f56c6c;
          font-size: 12px;
          border: 1px #f56c6c solid;
        }
      }
      .time {
        float: left;
        position: relative;
        bottom: -38px;
      }
      .btn {
        float: right;
        position: relative;
        right: 50px;
        top: 40px;
      }
    }
  }
}
.add_box {
  width: 552px;
  margin: 0 auto;
  .el-input {
    width: 468px;
  }
  span {
    display: inline-block;
    margin-right: 16px;
  }
  p {
    margin: 0;
  }
  div {
    margin: 7px 0;
  }
}
.detailBox {
  width: 100%;
}
.examinationDetail {
  width: 664px;
  margin: 0 auto;
  p {
    margin: 0;
  }
  .issue {
    font-size: 18px;
    color: #000000;
    margin-bottom: 16px;
  }
}
.title_p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.title_span {
  display: block;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  font-size: 12px;
}
em {
  display: inline-block;
  margin: 0px 8px 3px 0;
  width: 4px;
  height: 4px;
  background: #f56c6c;
  border-radius: 50%;
}
.block {
  margin-top: 32px;
  text-align: center;
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
