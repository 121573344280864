<template>
  <div class="zksc-mySpace">
    <nav class="zksc-nav" @click="handleLink($event)">
      <router-link
        to="/workSpace"
        class="zksc-nav-btn"
        id="workSpace"
        :class="[choose == 'workSpace' ? 'active' : '']"
        >个人空间</router-link
      >
      <router-link
        to="/personalMessage"
        class="zksc-nav-btn"
        id="personalMessage"
        :class="[choose == 'personalMessage' ? 'active' : '']"
        >个人信息</router-link
      >
      <router-link
        to="/errorCollection"
        class="zksc-nav-btn"
        id="errorCollection"
        :class="[choose == 'errorCollection' ? 'active' : '']"
        >错题集</router-link
      >
      <router-link
        to="/collection"
        class="zksc-nav-btn"
        id="collection"
        :class="[choose == 'collection' ? 'active' : '']"
        >收藏</router-link
      >
      <router-link
        to="/note"
        class="zksc-nav-btn"
        id="note"
        :class="[choose == 'note' ? 'active' : '']"
        >笔记</router-link
      >
    </nav>
    <transition name="zksc-fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      choose: '',
    };
  },
  methods: {
    handleLink(e) {
      this.choose = e.target.id;
      sessionStorage.setItem('choose', e.target.id);
    },
  },
  mounted() {
    this.choose = sessionStorage.getItem('choose');
  },
};
</script>
<style lang="less" scoped>
.zksc-mySpace {
  width: 100%;
  min-height: calc(100vh - 30px - 56px);
  background: #f0f2f5;
}
.zksc-nav {
  height: 56px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 32px;
  .zksc-nav-btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0 16px 0 0;
    transition: 0.1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
  }
}
.active {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

/* 路由切换动画 */
.zksc-fade-enter-active {
  transition: all 0.3s ease;
}
.zksc-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.zksc-fade-enter, .zksc-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
