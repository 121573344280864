<template>
  <div class="admin">
    <span class="titles">反馈中心</span>
    <p class="tabbars">
      <span
        class="wei"
        :class="{ cur: tabbar === 0 }"
        @click="(tabbar = 0), getData()"
        >未回复</span
      ><span
        class="yi"
        :class="{ cur: tabbar === 1 }"
        @click="(tabbar = 1), getDatas()"
        >已回复</span
      >
    </p>
    <span
      class="headerBtn"
      v-if="role !== 'superadmin'"
      @click="feedback = true"
      >反馈问题</span
    >
    <div class="tabelBox">
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="date" label="序列" width="180">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="反馈人" width="180">
        </el-table-column>
        <el-table-column prop="rescourseType" label="发件人头像">
          <template slot-scope="scope">
            <img
              class="imgBox"
              :src="
                scope.row.url === 'null'
                  ? '/img/avatar.4e4aa2c3.jpg'
                  : scope.row.url
              "
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="time" label="反馈时间"> </el-table-column>
        <!-- <el-table-column prop="status" label="回复状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" type=""> 已回复 </el-tag>

            <el-tag v-else type="warning" effect="dark"> 未回复 </el-tag>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope"
            ><span
              v-if="role === 'superadmin'"
              class="caozuobtn"
              type="info"
              @click="lookOne(scope.row)"
              >{{
                role === 'superadmin' && scope.row.status !== 2
                  ? '查看和回复'
                  : '查看'
              }}</span
            >
            <span v-else class="caozuobtn" @click="lookOne(scope.row)"
              >查看</span
            >
          </template>
        </el-table-column>
        <!-- v-if="tabbar === 0" -->
        <!-- <el-table-column v-else prop="address" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isUp === 1" effect="plain">已通过</el-tag>

            <el-tag v-else type="warning" effect="dark">未通过</el-tag>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- 超管查看反馈 -->
    <el-dialog
      title="查看反馈"
      :visible.sync="lookMessage"
      width="56%"
      center
      @close="closeSuper"
    >
      <div class="message_content">
        <div class="user">
          <img
            :src="lookImg === null ? '/img/avatar.4e4aa2c3.jpg' : lookImg"
            alt=""
          />
          <div class="messages">
            <h3>{{ lookTiele }}</h3>
            {{ lookContent }}
          </div>
        </div>
        <el-divider></el-divider>
        <div class="my">
          <el-input
            type="textarea"
            v-if="clickItem.status === 0"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
          <div v-else class="look-content">{{ superData.messages }}</div>
          <img :src="superData.url ? superData.url : myHeader" alt="" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="postMessage">提 交</el-button>
        <el-button @click="lookMessage = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 非超管查看反馈 -->
    <el-dialog title="查看反馈" :visible.sync="looklook" width="56%" center>
      <div class="message_content">
        <div class="user">
          <img
            :src="myUrl === 'null' ? '/img/avatar.4e4aa2c3.jpg' : myUrl"
            alt=""
          />
          <div class="messages">
            <h3>{{ myTitle }}</h3>
            {{ myMessage }}
          </div>
        </div>
        <el-divider v-show="tabbar === 1"></el-divider>
        <div class="my" v-show="tabbar === 1">
          <div class="look-content">{{ superData.messages }}</div>
          <img
            :src="superData.url ? superData.url : '/img/avatar.4e4aa2c3.jpg'"
            alt=""
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="looklook = false">确 定</el-button>
        <el-button @click="looklook = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 非超管第一次发起反馈 -->
    <el-dialog
      title="反馈问题"
      :visible.sync="feedback"
      width="56%"
      center
      @close="closeAllRoleBox"
    >
      <div class="message_content">
        <div class="contentBox">
          <el-form label-position="right" label-width="80px">
            <div class="item-title">
              <em class="hongdian"></em>
              <el-form-item label="标题">
                <el-input v-model="title"></el-input>
              </el-form-item>
            </div>
            <div class="item-content">
              <em class="hongdian"></em>
              <el-form-item label="反馈内容">
                <el-input
                  class="heightIpt"
                  type="textarea"
                  v-model="content"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="postFeedback">确 定</el-button>
        <el-button @click="feedback = false">取 消</el-button>
      </span>
    </el-dialog>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <el-button @click="send">发送</el-button> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentPage3: 1,
      pageSize: 10,
      total: 10,
      cerificateId: '',
      tabbar: 0,
      dialogVisible: false,
      feedback: false,
      value: '',
      unpass: false,
      options: [],
      clickId: '',
      clicksubId: '',
      clickType: '',
      // path: 'ws://192.168.1.81/chat',
      websock: null,
      lookMessage: false,
      looklook: false,
      textarea: '',
      // 反馈内容
      title: '',
      content: '',
      role: null,
      lookTiele: '',
      lookContent: '',
      lookImg: '',
      myHeader: '',
      // 点击查看的那一项
      clickItem: {
        status: 0,
      },
      // 反馈详情超管信息
      superData: {},
      // 自己发送消息的信息
      myTitle: '',
      myMessage: '',
      myUrl: '',
    };
  },
  mounted() {
    // this.initWebSocket();
  },
  created() {
    this.role = sessionStorage.getItem('role');
    this.myHeader = sessionStorage.getItem('header');
    this.getData();
  },
  methods: {
    // 关闭全部角色新增反馈对话框
    closeAllRoleBox() {
      this.title = '';
      this.content = '';
    },
    // 关闭超管对话框
    closeSuper() {
      this.textarea = '';
    },
    // 查看未回复
    getData() {
      const that = this;
      this.tabbar = 0;
      this.$axios
        .get(
          `/teach/mess/feedbackList?page=${this.currentPage3}&rows=${this.pageSize}&type=${this.tabbar}`
        )
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code === 200) {
            that.tableData = res.data.data.items;
            this.total = res.data.data.total;
          }
        });
    },
    // 查看已回复
    getDatas() {
      const that = this;
      this.tabbar = 1;
      this.$axios
        .get(
          `/teach/mess/feedbackList?page=${this.currentPage3}&rows=${this.pageSize}&type=${this.tabbar}`
        )
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code === 200) {
            that.tableData = res.data.data.items;
            this.total = res.data.data.total;
          }
        });
    },
    // 发送反馈
    postFeedback() {
      if (this.title === '' || this.content === '') {
        this.$message({
          type: 'warning',
          message: '请将消息补全',
        });
      } else {
        const data = {
          message: this.content,
          title: this.title,
          type: 1,
        };
        this.$axios.post('/teach/mess/sendCommonMessage', data).then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: res.data.message,
            });
            if (this.tabbar === 1) {
              this.getDatas();
            } else {
              this.getData();
            }
            this.feedback = false;
          }
        });
      }
    },
    // 回复反馈
    postMessage() {
      if (this.textarea === '') {
        this.$message({
          type: 'warning',
          message: '请输入内容',
        });
      } else {
        const data = {
          comvisionId: this.clickId,
          subId: this.clicksubId,
          message: this.textarea,
        };
        this.$axios.post('/teach/mess/replyMessage', data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: res.data.message,
            });
            if (this.tabbar === 1) {
              this.getDatas();
            } else {
              this.getData();
            }
            this.lookMessage = false;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message,
            });
          }
        });
      }
    },
    // 查看和回复
    lookOne(item) {
      this.clickItem = item;
      this.clickId = item.id;
      this.clicksubId = item.subId;
      if (this.role === 'superadmin') {
        this.lookMessage = true;
        this.$axios
          .get(`/teach/mess/messageDetail?messageId=${item.id}`)
          .then((res) => {
            // console.log(res.data.data);
            if (res.data.code === 200) {
              //
              this.lookTiele = res.data.data.put.title;
              this.lookContent = res.data.data.put.messages;
              this.lookImg = res.data.data.put.url;
              if (res.data.data.sub) {
                this.superData = res.data.data.sub;
              }
              if (this.tabbar === 1) {
                this.getDatas();
              } else {
                this.getData();
              }
            }
          });
      } else {
        this.looklook = true;
        this.$axios
          .get(`/teach/mess/messageDetail?messageId=${item.id}`)
          .then((res) => {
            // console.log(res.data.data);
            if (res.data.code === 200) {
              //
              this.myTitle = res.data.data.put.title;
              this.myMessage = res.data.data.put.messages;
              this.myUrl = res.data.data.put.url;
              if (this.tabbar === 1) {
                this.superData = res.data.data.sub;
              }
              // if (this.tabbar === 1) {
              //   this.getDatas();
              // } else {
              //   this.getData();
              // }
            }
          });
      }
    },
    //  获取消息列表
    getMessageList() {
      this.$axios
        .get(`/teach/mess/feedbackList?num=${10}&sum=${10}`)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code === 200) {
            this.tableData = res.data.data.items;
          }
        });
    },
    // 打开通过对话框获取到该类型的公共资源目录
    // send() {
    //   let actions = {
    //     id: '1',
    //     toName: '2',
    //     message: '345678910jqka!!!!!!!!!!!!!!!!!!!!!!',
    //   };
    //   this.websocketsend(JSON.stringify(actions));
    // },
    openPassBox() {},
    openBox() {},
    handleSizeChange() {},
    handleCurrentChange(v) {
      this.currentPage3 = v;
      if (this.tabbar === 0) {
        this.getData();
      } else {
        this.getDatas();
      }
    },
    // 初始化websocket
    // initWebSocket() {
    //   //初始化weosocket
    //   console.log('开始准备连接');
    //   // const wsuri = 'ws://192.168.1.22:8001/websocket/1212';
    //   const wsuri = 'ws://192.168.1.81:7001/chat';
    //   this.websock = new WebSocket(wsuri);
    //   this.websock.onmessage = this.websocketonmessage;
    //   this.websock.onopen = this.websocketonopen;
    //   this.websock.onerror = this.websocketonerror;
    //   this.websock.onclose = this.websocketclose;
    // },
    // websocketonopen() {
    //   //连接建立之后执行send方法发送数据
    //   console.log('连接成功');
    //   let actions = {
    //     id: '1',
    //     toName: '2',
    //     message: '345678910jqka!!!!!!!!!!!!!!!!!!!!!!',
    //   };
    //   this.websocketsend(JSON.stringify(actions));
    //   console.log('没报错就发送消息成功了');
    // },
    // websocketonerror(e) {
    //   //连接建立失败重连
    //   // this.initWebSocket();
    //   console.log('链接失败');
    //   console.log(e);
    // },
    // websocketonmessage(e) {
    //   //数据接收
    //   const redata = JSON.parse(e.data);
    //   console.log(redata);
    // },
    // websocketsend(Data) {
    //   // 把存在事件总线中的用户信息拿出来
    //   // this.$EventBus.$on('eventName', (data) => {
    //   //   console.log(data);
    //   //   //数据发送
    //   this.websock.send(Data);
    //   // });
    // },
    // websocketclose(e) {
    //   //关闭
    //   console.log('断开连接', e);
    //   // 关闭的时候断开事件总线的监听
    //   // this.$EventBus.$off('eventName');
    // },
  },
  destroyed() {
    // 销毁监听
    // this.socket.onclose();
  },
};
</script>

<style lang="less" scoped>
.admin {
  width: 100%;
  height: calc(100vh - 30px);
  background: #fff;
  border-radius: 4px;
  position: relative;
  .titles {
    position: absolute;
    top: 25px;
    left: 16px;
    font-weight: 600;
    color: #333333;
  }
  .headerBtn {
    padding: 9px 41px;
    background: #409eff;
    border: 1px solid #409eff;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    position: absolute;
    z-index: 10;
    right: 16px;
    top: 16px;
  }
  .headerBtn:hover {
    cursor: pointer;
  }
}
.tabbars {
  text-align: center;
  margin: 18px auto;
  margin-top: 24px;
  span {
    border: 1px solid #666666;
    padding: 8px 20px;
    border-radius: 4px;
  }
  span:nth-child(1) {
    border-radius: 4px 0 0 4px;
    border-right: none;
  }
  span:nth-child(2) {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }
  .cur {
    background: #409eff;
    color: #ffffff;
    border: #409eff 1px solid;
  }
  span:hover {
    cursor: pointer;
  }
}
.tabelBox {
  margin-top: 16px;
}
.block {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, -50%);
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
.xiala {
  width: 686px;
  height: 40px;
  // background: red;
  margin: 0 auto;
}
.el-cascader {
  width: 100%;
}
// .el-cascader-menu {
//   width: 300px;
// }
</style>
<style lang="less">
/deep/.el-cascader__dropdown {
  width: 686px !important;
}
.el-cascader-menu {
  width: 686px !important;
}
/deep/.el-cascader-panel {
  width: 686px !important ;
}
/deep/.el-scrollbar__view {
  width: 500px !important;
}
// .el-cascader-menu__list {
//   width: 800px;
// }
/deep/.el-dialog__body {
  margin-top: 16px;
}
.message_content {
  width: 100%;
  .user {
    margin: 0 auto;
    width: 468px;
    position: relative;

    .messages {
      width: 100%;
      min-height: 40px;
      border-radius: 4px;
      border: 1px solid #333333;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
      text-align: left;
      h3 {
        line-height: 30px;
        font-size: 18px;
        margin-bottom: 8px;
      }
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      left: -60px;
    }
  }
  .my {
    width: 468px;
    margin: 0 auto;
    position: relative;
    .message {
      // margin: 0 auto;
      width: 100%;
      min-height: 18px;
      border-radius: 4px;
      border: 1px solid #333333;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      right: -100px;
      top: 0px;
    }
    .look-content {
      width: 500px;
      min-height: 100px;
      border: 1px solid #333333;
      border-radius: 4px;
      text-align: left;
      text-indent: 16px;
      padding-top: 8px;
    }
  }
  .contentBox {
    width: 552px;
    margin: 0 auto;
    position: relative;
    .item-title {
      .hongdian {
        position: absolute;
        top: 18px;
        left: 30px;
      }
    }
    .item-content {
      .hongdian {
        position: absolute;
        top: 80px;
        left: 0px;
      }
    }
    .heightIpt {
      textarea {
        // word-break: break-all;
        height: 96px;
      }
    }
  }
}
.imgBox {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.caozuobtn {
  display: inline-block;
  padding: 5px 14px;
  border-radius: 4px;
  border: 1px solid #666666;
}
.caozuobtn:hover {
  cursor: pointer;
}
</style>
