<template>
  <div class="zksc-part">
    <div class="zksc-base-title">
      <h6>{{ title }}</h6>
      <div class="fold" v-if="fold" @click="foldList()">
        <span>展开</span>
        <span class="title-icon-box">
          <icon-svg icon-class="shouqi" v-show="!isfold"></icon-svg>
          <icon-svg icon-class="zhankai" v-show="isfold"></icon-svg>
          <!-- <icon-svg
            icon-class="jiantou1"
            :class="[isfold ? 'up-arrow' : '']"
          ></icon-svg>
          <icon-svg
            icon-class="jiantou3"
            :class="[isfold ? 'down-arrow' : '']"
          ></icon-svg>-->
        </span>
      </div>
    </div>
    <div class="fold-state" :class="[isfold ? 'fold-active' : '']">
      <div class="space" :class="[isfold ? 'space-active' : '']"></div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: ['fold', 'title'],
  data() {
    return {
      isfold: false,
    };
  },
  methods: {
    foldList() {
      // console.log('fold');
      this.isfold = !this.isfold;
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-part {
  width: 940px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 20px;
}
.zksc-base-title {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  h6 {
    font-weight: normal;
    font-size: 14px;
  }
  .fold {
    display: flex;
    color: #409eff;
    cursor: pointer;
    font-size: 14px;
    .title-icon-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      .svg-icon {
        font-size: 12px !important;
        color: #409eff;
        transition: all ease 0.3s;
      }
      .up-arrow {
        transform: rotate(-180deg);
      }
      .down-arrow {
        transform: rotate(180deg);
      }
    }
  }
}
.fold-state {
  overflow: hidden;
  max-height: 2000px;
  transition: all ease 0.3s;
  .space {
    transition: all ease 0.3s;
    height: 0px;
  }
  .space-active {
    height: 32px;
  }
}
.fold-active {
  max-height: 32px;
}
</style>
