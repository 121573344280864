import { render, staticRenderFns } from "./alterNumber.vue?vue&type=template&id=7a032965&scoped=true&"
import script from "./alterNumber.vue?vue&type=script&lang=js&"
export * from "./alterNumber.vue?vue&type=script&lang=js&"
import style0 from "./alterNumber.vue?vue&type=style&index=0&id=7a032965&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a032965",
  null
  
)

export default component.exports