<template>
  <div>
    <ul>
      <li v-for="(item, index) in messages" :key="index" class="message">
        <div v-if="item.type === 'success'" class="success">
          <icon-svg icon-class="chenggong"></icon-svg>
          <span>
            {{ item.message }}
          </span>
        </div>
        <div v-else-if="item.type === 'warning'" class="warning">
          <icon-svg icon-class="jinggao"></icon-svg>
          <span>
            {{ item.message }}
          </span>
        </div>
        <div v-else-if="item.type === 'error'" class="error">
          <icon-svg icon-class="cuowu"></icon-svg>
          <span>
            {{ item.message }}
          </span>
        </div>
        <div v-else class="info">
          <icon-svg icon-class="pinglun"></icon-svg>
          <span>
            {{ item.message }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import iconSvg from '../components/icon/iconSvg.vue';
export default {
  data() {
    return {
      messages: [],
      id: 0,
    };
  },
  methods: {
    add(options) {
      const layer = {
        id: this.id++,
        ...options,
      };
      layer.timer = setTimeout(() => {
        this.remove(layer);
      }, 3000);
      this.messages.push(layer);
    },
    remove(layer) {
      this.messages = this.messages.filter((item) => item.id !== layer.id);
    },
  },
  components: {
    iconSvg,
  },
};
</script>

<style lang="less" scope>
.message {
  z-index: 1000;
  width: 350px;
  height: 58px;
  line-height: 58px;
  font-size: 14px;
  color: #cfcfcf;
  text-align: left;
  border-radius: 2px;
  position: fixed;
  left: 50%;
  top: 100px;
  // background: #363636;
  transform: translate3d(-50%, 0, 0);
  border-radius: 4px;
  animation: move 0.5s;
  div {
    width: 100%;
    height: 100%;
    padding-left: 28px;
  }
  svg {
    font-size: 16px;
  }
  .success {
    // border-left: 3px solid #0099cc;
    svg {
      color: #0099cc;
    }
  }
  .warning {
    // border-left: 3px solid #ffa800;
    svg {
      color: #ffa800;
    }
  }
  .error {
    // border-left: 3px solid #dd5e5e;
    svg {
      color: #dd5e5e;
    }
  }
  .info {
    // border-left: 3px solid #ccc;
    svg {
      color: #ccc;
    }
  }

  span {
    padding-left: 14px;
  }
}

@keyframes move {
  0% {
    top: 0;
    opacity: 0;
  }
  100% {
    top: 100px;
    opacity: 1;
  }
}
</style>
