<template>
  <div
    class="zksc-lookCourse"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="zksc-lookCourse-title">{{ courseName }}</div>
    <div class="zksc-lookCourse-title">{{ coursecontent }}</div>

    <part :fold="false" title="课程章节">
      <ul class="zksc-capter-box">
        <li
          class="zksc-capter-item"
          v-for="(v, i) in chapterList"
          :key="v.id"
          @click="getChapter(v.id, i, v.annexurl)"
          :class="{ 'capter-item-active': isChoose == i }"
        >
          {{ v.name ? i + 1 + '、' + v.name : i + 1 }}
        </li>
      </ul>
    </part>
    <div class="text" v-show="richtext">
      <div class="title">课程描述</div>
      <div class="content" v-html="richtext"></div>
    </div>
    <template v-for="(v, i) in chapterContent.resSofts">
      <part :fold="true" :title="v.name" :key="i" v-if="v.id">
        <part-item
          ref="pItem"
          :soft="v.soft"
          :types="v.name"
          :type="v.type"
          :cid="cid"
        ></part-item>
        <!-- <div class="zksc-btn-group">
          <el-button
            v-for="(v2,i2) in v.soft"
            :key="v2.name"
            :class="{'is-active':i2==btnIndex}"
            @click="getChapterPartDetail(v2,i2)"
          >{{v2.name}}</el-button>
        </div>-->

        <!-- type = 0 课件类型 -->
        <!-- <div class="zksc-study-content" v-if="v.type == 0">
          <div class="study-list">
            <item></item>
            <item></item>
            <item></item>
            <item></item>
          </div>
          <el-pagination
            class="zksc-pagination"
            border
            :page-size="100"
            layout="prev, pager, next, jumper"
            :total="1000"
          ></el-pagination>
        </div>-->

        <!-- type = 1 题目 -->
        <!-- <template v-else>
          <div class="zksc-btn-classify">
            <span>题目类型</span>
            <el-button-group>
              <el-button @click="classify('d')" :class="[isClass=='d'?'active':'']">单选题</el-button>
              <el-button @click="classify('p')" :class="[isClass=='p'?'active':'']">判断题</el-button>
            </el-button-group>
          </div>
          <div>
            <el-table :data="problems" style="width: 100%">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column prop="name" label="试题名称"></el-table-column>
              <el-table-column prop="class" label="题型" width="80"></el-table-column>
              <el-table-column prop="score" label="分值" width="50"></el-table-column>
              <el-table-column label="操作" width="100">
                <template>
                  <el-button type="info" size="mini">操作</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="zksc-pagination"
              border
              :page-size="100"
              layout="prev, pager, next, jumper"
              :total="1000"
            ></el-pagination>
          </div>
        </template>-->
      </part>
    </template>
    <!-- <part :fold="true" title="理论练习">
      <div class="zksc-btn-group">
        <el-button>数字创意建模(大题库)</el-button>
        <el-button>数字创意建模(精选题)</el-button>
      </div>
      <div class="zksc-btn-classify">
        <span>题目类型</span>
        <el-button-group>
          <el-button @click="classify('d')" :class="[isClass=='d'?'active':'']">单选题</el-button>
          <el-button @click="classify('p')" :class="[isClass=='p'?'active':'']">判断题</el-button>
        </el-button-group>
      </div>
      <div>
        <el-table :data="problems" style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="name" label="试题名称"></el-table-column>
          <el-table-column prop="class" label="题型" width="80"></el-table-column>
          <el-table-column prop="score" label="分值" width="50"></el-table-column>
          <el-table-column label="操作" width="100">
            <template>
              <el-button type="info" size="mini">操作</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          border
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000"
        ></el-pagination>
      </div>
    </part>-->

    <!-- <part :fold="true" title="实操素材">
      <div class="zksc-btn-group">
        <el-button>数字媒体-初级</el-button>
        <el-button>数字媒体-中级</el-button>
        <el-button>数字媒体-高级</el-button>
      </div>
      <div>
        <el-table :data="practice" style="width: 100%">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column prop="require" label="实操要求" show-overflow-tooltip></el-table-column>
          <el-table-column prop="time" label="发布时间"></el-table-column>
          <el-table-column label="附件下载" width="180">
            <template>
              <el-button type="info" size="mini">预览</el-button>
              <el-button type="primary" size="mini">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          border
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000"
        ></el-pagination>
      </div>
    </part>-->

    <!-- <part :fold="true" title="项目案例">
      <div class="zksc-btn-group">
        <el-button>渲染案例教程</el-button>
        <el-button>3DS MAX案例教程</el-button>
      </div>
      <div class="zksc-study-content">
        <div class="study-list">
          <item></item>
          <item></item>
        </div>
        <el-pagination
          class="zksc-pagination"
          border
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000"
        ></el-pagination>
      </div>
    </part>-->

    <!-- <part :fold="true" title="考试全流程解析">
      <div class="zksc-btn-group">
        <el-button>初级</el-button>
        <el-button>中级</el-button>
        <el-button>高级</el-button>
      </div>
      <div class="zksc-study-content">
        <div class="study-list">
          <item></item>
          <item></item>
        </div>
        <el-pagination
          class="zksc-pagination"
          border
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000"
        ></el-pagination>
      </div>
    </part>-->

    <!-- <part :fold="true" title="考核指导" class="last-part">
      <div class="zksc-btn-group">
        <el-button>数字创意建模</el-button>
      </div>
      <div class="zksc-study-content">
        <div class="study-list">
          <item></item>
          <item></item>
        </div>
        <el-pagination
          class="zksc-pagination"
          border
          :page-size="100"
          layout="prev, pager, next, jumper"
          :total="1000"
        ></el-pagination>
      </div>
    </part>-->
  </div>
</template>
<script>
import Part from './part/part.vue';
// import Item from './Item/Item.vue';
import PartItem from './part/partItem.vue';
export default {
  components: {
    Part,
    // Item,
    PartItem,
  },
  data() {
    return {
      isClass: 'd',
      isChoose: 0,
      btnIndex: 0,
      /* 章节id */
      cid: '',
      problems: [],
      courseName: '',
      coursecontent: '',
      practice: [],
      /* 章节列表 */
      chapterList: [],
      /* 章节内容 */
      chapterContent: [],
      richtext: '',
      loading: false,
    };
  },
  // watch: {
  //   '$route.path.query.courseId': {
  //     handler() {
  //       this.getCourseDetail();
  //     },
  //     deep: true,
  //   },
  // },
  // created(){
  //   window.location.reload()
  // },
  created() {
    sessionStorage.setItem('choose', 'courseManage');
  },
  mounted() {
    this.getCourseDetail();
  },
  methods: {
    // getData() {
    //   this.$axios.get('/teach/course/routing').then((res) => {
    //     console.log(res);
    //   });
    // },
    classify(str) {
      this.isClass = str;
    },
    /**
     * 获取课程数据 实际得到 章节id
     */
    getCourseDetail() {
      this.loading = true;
      this.$nextTick(() => {
        /* 获取 课程id courseId */
        let courseId = this.$route.query.courseId;
        if (!courseId) {
          return;
        }
        /* 通过课程id 去获取章节id */
        this.$axios
          .get('/teach/course/total', { params: { courseId } })
          .then((res) => {
            let { data } = res;
            // console.log(data);
            if (data.code !== 200) {
              // setTimeout(() => {
              //   loading.close();
              // }, 500);
              return this.$message.error('获取章节信息失败');
            }

            /* 章节id数据 */
            // console.log(data.data);
            this.chapterList = data.data;

            // console.log('this.chapterList: ',  this.chapterList);
            // console.log(data.data, 'data');
            /* 通过id 去获取资源具体内容 */
            if (data.data.length > 0) {
              // console.log('++++++++++++++++++++');
              this.getChapter(data.data[0].id, 0, data.data[0].annexurl);
            }

            /* 课程标题 */
            // this.courseName = sessionStorage.getItem('couresname');
            // this.coursecontent = sessionStorage.getItem('courescontent');
            // sessionStorage.removeItem('couresname');
            // sessionStorage.removeItem('courescontent');
          });
      });
    },
    /* 获取资源具体内容 */
    getChapter(sectionId, i, text) {
      this.loading = true;
      // console.log('sectionId++++++++++++++++');
      // console.log('sectionId++++++++++++++++', text);
      this.cid = sectionId;
      if (i !== undefined) {
        this.isChoose = i;
      }
      this.$axios
        .get('/teach/course/sectionRoot', { params: { sectionId } })
        .then((res) => {
          // console.log(res);
          let { data } = res;
          if (data.code !== 200) {
            this.loading = false;
            return this.$message.error('获取课程详细信息失败');
          }
          // console.log(data.data);
          this.chapterContent = data.data;
          this.courseName = data.data.courseName;
          this.coursecontent = data.data.coureContent;
          // console.log(text);
          if (text !== 'null') {
            this.$axios.get(text).then((res) => {
              // console.log(res);
              if (res.status < 300) {
                this.richtext = res.data;
                this.loading = false;
              }
            });
          } else {
            this.loading = false;
            this.richtext = '';
          }

          /* 获取资源某部分具体内容  初始获取第0项内容*/
          // console.log(this.chapterContent[0].soft[0].id);
          // this.$nextTick(() => {
          //   this.$refs.pItem[0].getChapterPartDetail(
          //     sectionId,
          //     this.chapterContent[0].soft[0],
          //     0
          //   );

          //   console.log(this.$refs.pItem);
          // });
          // console.log(this.$refs.pItem,'----');
          // this.getChapterPartDetail(this.chapterContent[0].soft[0], 0);
          // console.log('this.chapterContent: ', this.chapterContent);
        });
    },
    /* 获取资源具体某部分内容  */
    getChapterPartDetail(item, i2) {
      this.btnIndex = i2;
      let params = {
        menuId: item.id,
        num: 1,
        sum: 6,
        type: item.type, // 0软件课件   1题目
        types: '', // choice选择judge判断 没有为null
      };
      this.$axios.get('/teach/course/sectionSoft', { params }).then((res) => {
        console.log(res.data);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-lookCourse {
  min-height: 100vh;
  background: #f0f2f5;
  background-attachment: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .zksc-lookCourse-title {
    // display: inline-block;
    // display: inline;
    width: 940px;
    min-height: 44px;
    overflow: hidden;
    white-space: initial;
    word-break: break-all;
    box-sizing: border-box;
    margin-top: 20px;
    padding-left: 20px;
    background-color: #fff;
    border-radius: 4px;
    line-height: 44px;
  }
  .zksc-capter-box {
    width: 100%;
    display: flex;
    margin: 8px;
    flex-wrap: wrap;
    justify-content: space-between;

    .zksc-capter-item {
      width: 30%;
      height: 32px;
      font-size: 14px;
      margin-top: 10px;
      line-height: 32px;
      text-align: center;
      border: 1px solid #666666;
      border-radius: 4px;
      margin-right: 16px;
      cursor: pointer;
    }
    .capter-item-active {
      background-color: #409eff;
      border-color: transparent;
      color: #fff;
    }
  }
  .zksc-btn-group {
    // padding: 16px;
    .el-button {
      border-color: transparent;
      margin: 8px;
    }
    .is-active {
      color: #3a8ee6;
      background-color: #ecf5ff;
    }
  }
  .zksc-study-content {
    margin-top: 20px;

    .study-list {
      display: flex;
      flex-wrap: wrap;
    }
    .el-pagination {
      margin: 16px 0;
      display: flex;
      justify-content: center;
    }
  }
  .zksc-btn-classify {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      margin: 0 20px;
    }
    .active {
      background-color: #409eff;
      color: #fff;
    }
  }
  .el-table {
    padding: 8px;
  }
  .last-part {
    margin-bottom: 20px;
  }
}
.text {
  width: 940px;
  min-height: 50px;
  // padding-top: 20px;
  margin-top: 20px;
  background: #fff;
  border-radius: 4px;
  .title {
    text-indent: 20px;
    height: 41px;
    font-size: 14px;
    line-height: 41px;
    border-bottom: 1px solid #ccc;
  }
  .content {
    margin-left: 5px;
    // overflow: hidden;
    overflow-x: auto;
    // background: red;
  }
}
</style>
<style lang="less">
// .content {
//   /* margin-left: 5px;
//   // background: red; */
//   img {
//     width: 100%;
//   }
// }
</style>
