<template>
  <div class="new_note_box">
    <div id="new_note" v-html="content"></div>
  </div>
</template>

<script>
// 引入 wangEditor
import WangEditor from 'wangeditor';
export default {
  data() {
    return {
      editor: null,
      editorData: '',
    };
  },
  mounted() {
    const editor = new WangEditor('#new_note');
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.editorData = newHtml;
    };
    editor.config.colors = [
      '#000000',
      '#eeece0',
      '#1c487f',
      '#4d80bf',
      '#c24f4a',
      '#8baa4a',
      '#7b5ba1',
      '#46acc8',
      '#f9963b',
      '#000000',
    ];
    editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      'backColor',
      'link',
      'list',
      'todo',
      'justify',
      'quote',
      // 不打开
      // 'emoticon',
      'image',
      // 不打开
      // 'video',
      // 不打开
      // 'table',
      // 不打开
      // 'code',
      'splitLine',
      'undo',
      'redo',
    ];
    // editor.config.uploadImgServer = '/upload-img'
    // 打开本地上传
    editor.config.uploadImgShowBase64 = true;
    // 设置富文本图片文件的大小
    editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
    // 配置网络图片
    editor.config.showLinkImg = false;
    // 创建编辑器
    editor.create();
    this.editor = editor;
  },
  props: ['detail', 'content'],
  watch: {
    // 监听数据变化，传递给父组件currentVal, oldVal
    editorData() {
      // console.log(currentVal);
      // console.log(oldVal);
      this.noteDetailChange();
    },
  },
  methods: {
    // 子组件传值给父组件
    noteDetailChange() {
      // 调用父组件传递的方法
      this.$emit('change', this.editorData);
    },
    deleteEditor() {
      this.editor.$txt.html('<p><br></p>');
    },
    clearTxt() {
      this.editor.txt.clear();
    },
    getEditorData() {
      // 通过代码获取编辑器内容
      const data = this.editor.txt.html();
      alert(data);
    },
    // 使用父组件传过来的数据 进行更新富文本（一般在编辑的时候，动态加载富文本用）
    updateEditorData() {
      this.editor.txt.html(this.detail);
    },
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style lang="less">
#new_note {
  .w-e-toolbar {
    z-index: 1000 !important;
  }
  .w-e-text-container {
    z-index: 100 !important;
    background-color: #fff;
    border: 1px solid rgba(200, 200, 200, 0.2);
    color: #000;
  }
  .w-e-menu {
    z-index: 1001 !important;
    .w-e-dp-title {
      border-bottom: 1px solid rgba(200, 200, 200, 0.2);
      color: #111;
    }
    .w-e-item {
      color: #111;
    }
    .w-e-item:hover {
      background-color: #1989fa;
      color: #fff;
    }
  }
  // 编辑器菜单经过时候的样式
  .w-e-menu:hover {
    background-color: #1989fa;
    color: red;
    i {
      color: #fff;
    }
  }
  .w-e-droplist {
    background-color: #fff;
    border: 1px #cecece solid;
  }
  // .w-e-text{
  //   z-index: 1000;
  // }
  .w-e-text:focus {
    color: #333;
  }
  .w-e-panel-container {
    background-color: #4a4a4a;
    box-shadow: 0 0 0 0;
    .w-e-panel-tab-title {
      border-bottom: 1px solid rgba(200, 200, 200, 0.4);
      padding: 10px 0;
      .w-e-item:hover {
        background-color: transparent;
      }
    }
    .w-e-active {
      border-bottom: 0;
    }
    .block {
      width: 100%;
      height: 40px;
      padding-left: 12px;
      box-sizing: border-box;
      background: #222222;
      border-radius: 0px 2px 2px 0px;
      border: 1px solid rgba(200, 200, 200, 0.2);
      transition: border 0.3s linear;
      color: rgba(200, 200, 200, 0.4);
      margin-bottom: 15px;
    }
    .block:focus {
      color: #c8c8c8;
      outline: none;
    }
    .right {
      display: inline-block;
      background: #ffcb00;
      border-radius: 2px;
      padding: 5px 11px;
      transition: all 0.3s linear;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      cursor: pointer;
    }
    .right:hover {
      background: #ee9922;
    }
  }
}
</style>
