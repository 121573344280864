import { render, staticRenderFns } from "./mySpace.vue?vue&type=template&id=0ec4d73a&scoped=true&"
import script from "./mySpace.vue?vue&type=script&lang=js&"
export * from "./mySpace.vue?vue&type=script&lang=js&"
import style0 from "./mySpace.vue?vue&type=style&index=0&id=0ec4d73a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec4d73a",
  null
  
)

export default component.exports