<template>
  <div
    class="admin"
    v-loading="loading"
    :element-loading-text="progress + '%'"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="title">
      <p>新建课件资源</p>
    </div>
    <div class="coursewareMessage">
      <p>课件信息</p>
      <em class="hongdian"></em>
      <el-input
        type="text"
        v-model="coursewareName"
        placeholder="请输入课件名称"
      />
      <!-- <div class="richBox">
        <rich-text
          :detail="log.detail"
          isClear="false"
          ref="editorRef"
          @change="changeLogDetail"
        ></rich-text>
      </div> -->
    </div>
    <div class="coursewareCover">
      <p>课件封面<span>支持上传jpg和png文件</span></p>
      <em class="hongdian"></em>
      <div v-if="alterFlage">
        <div v-if="alterCover" class="imgBox">
          <span class="cha" @click="alterCover = false">
            <icon-svg
              style="float: right; margin-top: 5px; margin-right: 6px"
              icon-class="shanchu"
            ></icon-svg>
          </span>
          <img v-show="alterCoverURL" :src="alterCoverURL" alt="" />
        </div>
        <el-upload
          v-else
          accept="image/*"
          id="uploadImage"
          class="upload-demo"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePictureCardPreview"
          :auto-upload="false"
          :on-remove="handleRemove"
          :limit="1"
          :on-change="handleChansge"
        >
          <icon-svg icon-class="shangchuan1" class="shangchaun"></icon-svg>
          <div class="el-upload__text">
            将文件拖到此处，或<em>点击上传</em><br />
            <span>支持jpg/png格式</span><br />
            <span>大小不超过2M</span>
          </div>
          <!-- <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件，且不超过500kb
        </div> -->
        </el-upload>
      </div>
      <div v-else>
        <div v-if="coverUrl" class="imgBox">
          <span class="cha" @click="coverUrls">
            <icon-svg
              style="float: right; margin-top: 5px; margin-right: 6px"
              icon-class="shanchu"
            ></icon-svg>
          </span>
          <img :src="coverUrl" alt="" />
        </div>
        <el-upload
          v-else
          accept="image/*"
          id="uploadImage"
          class="upload-demo"
          ref="imgurls"
          drag
          :show-file-list="false"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePictureCardPreview"
          :auto-upload="false"
          :on-remove="handleRemove"
          :limit="1"
          :on-change="handleChansge"
        >
          <icon-svg icon-class="shangchuan1" class="shangchaun"></icon-svg>
          <div class="el-upload__text">
            将文件拖到此处，或<em>点击上传</em><br />
            <span>支持jpg/png格式</span><br />
            <span>大小不超过2M</span>
          </div>
          <!-- <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件，且不超过500kb
        </div> -->
        </el-upload>
      </div>
    </div>
    <div class="coursewareContent">
      <p>
        课件内容<span
          >支持上传mp4和pdf文件，大小不超过2GB，最多上传一个文件</span
        >
      </p>
      <div v-if="alterFlage">
        <div class="fileClass" v-if="alterVideo">
          <span
            ><icon-svg style="margin-right: 8px" icon-class="wenjian"></icon-svg
            >{{ alterVideos }}</span
          >

          <div class="del" @click="alterVideo = false">
            <icon-svg
              style="padding: 10px 9px 10px 8px; color: #f56c6c"
              icon-class="shanchu"
            ></icon-svg>
          </div>
        </div>
        <el-upload
          v-else
          accept=".mp4,.pdf"
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="1"
          :on-exceed="handleExceed"
          :on-change="handleChansgeVideo"
        >
          <em class="hongdian"></em
          ><el-button size="small" type="primary">上传内容</el-button>
          <!-- <div slot="tip" class="el-upload__tip">
          支持上传zip和rar文件，大小不超过2GB
        </div> -->
        </el-upload>
      </div>
      <div v-else>
        <div class="fileClass" v-if="videoUrl">
          <span
            ><icon-svg style="margin-right: 8px" icon-class="wenjian"></icon-svg
            >{{ video.name }}</span
          >

          <div class="del" @click="videoUrls">
            <icon-svg
              style="padding: 10px 9px 10px 8px; color: #f56c6c"
              icon-class="shanchu"
            ></icon-svg>
          </div>
        </div>
        <el-upload
          v-else
          ref="videoUrlss"
          accept=".mp4,.pdf"
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="1"
          :on-change="handleChansgeVideo"
        >
          <em class="hongdian"></em
          ><el-button size="small" type="primary">上传内容</el-button>
          <!-- <div slot="tip" class="el-upload__tip">
          支持上传zip和rar文件，大小不超过2GB
        </div> -->
        </el-upload>
      </div>
    </div>
    <!-- <div class="material">
      <p class="pbiaoqian">
        课件素材<span
          >支持上传zip和rar文件，大小不超过2GB，最多上传一个文件</span
        >
      </p>
      <div class="fileClass" v-if="alterZip">
        <span>{{
          alterData.map.materialob
            ? alterData.map.materialob
            : alterData.map.material
            ? alterData.map.material
            : '暂无资源'
        }}</span>

        <div class="del" @click="alterZip = false">
          <icon-svg
            style="padding: 10px 9px 10px 8px; color: #f56c6c"
            icon-class="shanchu"
          ></icon-svg>
        </div>
      </div>
      <div v-else>
        <template>
          <span class="type">上传方式：</span>
          <el-radio v-model="radio" @change="handleRadio()" label="1"
            >本地上传</el-radio
          >
          <el-radio v-model="radio" @change="handleRadios()" label="2"
            >网盘上传</el-radio
          >
        </template>
      </div>

      <div class="wangpang" v-show="radio === '2'">
        <p>
          <em class="hongdian"></em
          ><el-input v-model="links" placeholder="请输入网盘链接"></el-input>
        </p>
        <p>
          <em class="hongdian"></em
          ><el-input
            v-model="extractPsw"
            placeholder="请输入提取密码"
          ></el-input>
        </p>
        <p>
          <em class="hongdian"></em
          ><el-input
            v-model="decompressionPsw"
            placeholder="请输入解压密码"
          ></el-input>
        </p>
      </div>
      <div class="coursewareContents" v-show="radio === '1'">
        <div class="fileClass" v-if="zipUrl">
          <span>{{ zip.name }}</span>
          <div class="del" @click="zipUrls">
            <icon-svg
              style="padding: 10px 9px 10px 8px; color: #f56c6c"
              icon-class="shanchu"
            ></icon-svg>
          </div>
        </div>
        <el-upload
          accept=".zip,.rar"
          v-else
          class="upload-demo"
          :show-file-list="false"
          ref="zipUrlss"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="1"
          :on-change="handleChansgeZip"
        >
          <el-button size="small" slot="trigger" type="primary"
            >上传素材</el-button
          >
          <!- <div slot="tip" class="el-upload__tip">
          支持上传zip和rar文件，大小不超过2GB
        </div> ->
        </el-upload>
      </div>
    </div> -->
    <!-- <div class="team">
      <p class="pbiaoqian">课程团队</p>
      <el-button class="btn" type="primary" @click="teamFlag = true"
        >添加</el-button
      >
      <div class="addbox" v-show="teamFlag">
        <span class="postHeader"><em class="hongdian"></em> 上传老师头像 </span>
        <el-upload
          accept="image/*"
          class="upload-demo"
          ref="uploads"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePreviewTeam"
          :on-remove="handleRemoveTeam"
          :file-list="fileList"
          :limit="1"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handleTeam"
          v-model="ruleForm.coverFile"
        >
          <div class="headerBox">
            <div class="imgbox" slot="trigger">
              <img slot="trigger" v-if="imgUrl" :src="imgUrl" alt="" />
              <span class="back" v-if="imgUrl" @click.stop="clearImg">
                <icon-svg icon-class="shanchu"></icon-svg>
              </span>
            </div>
            <div class="titles">
              <span class="upload" slot="trigger">点击上传</span>
              <br />
              <span class="uploadTitle">只能上传jpg/png文件 且不超过500kb</span>
            </div>
          </div> -->
    <!-- <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          > -->
    <!-- <el-button
            style="margin-left: 10px"
            size="small"
            type="success"
            @click="submitUpload"
            >上传到服务器</el-button 
          >-->
    <!-- </el-upload>
      </div>
      <div class="teacher" v-show="teamFlag">
        <em class="hongdian"></em
        ><el-input
          placeholder="请输入老师姓名"
          v-model="teacher.name"
        ></el-input>
        <em class="hongdian"></em
        ><el-input
          placeholder="请输入学校名"
          v-model="teacher.school"
        ></el-input>
        <em class="hongdian"></em
        ><el-input
          placeholder="请输入职称"
          v-model="teacher.jobTitle"
        ></el-input>
      </div>
      <div class="checkbox" v-show="teamFlag">
        <em class="hongdian"></em><span>请选择老师角色</span>
        <el-checkbox-group v-model="teachrole">
          <el-checkbox label="课程负责人"></el-checkbox>
          <el-checkbox label="学术总策划"></el-checkbox>
          <el-checkbox label="团队教师"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="teacherTxt" v-show="teamFlag">
        <em class="hongdian"></em> -->
    <!-- <el-input type="textarea" placeholder="请输入内容" v-model="textarea">
        </el-input> -->
    <!-- <el-input
          class="ipt"
          type="textarea"
          placeholder="请输入内容"
          v-model="content"
        >
        </el-input>
      </div>
      <div class="footerBtn" v-show="teamFlag">
        <el-button type="primary" @click="addOne">确定</el-button>
        <el-button @click="teamFlag = false">取消</el-button>
      </div>
      <div class="addList">
        <ul v-if="alterFlage">
          <li
            v-for="(item, index) in teamList"
            v-show="item.heading"
            :key="index"
          >
            <img :src="item.heading" alt="" />
            <div class="textbox">
              <span class="name">{{ item.name }}</span>
              <span class="list">
                <span v-for="(items, indexs) in item.teachrole" :key="indexs">{{
                  indexs >= 1 ? ' / ' + items : items
                }}</span>
              </span>
              <br />
              <span class="school">{{ item.school }}</span>
              <span class="type">{{ item.jobTitle }}</span>
            </div>
            <div class="teamTxt">
              <p>{{ item.content }}</p>
            </div>
            <span class="btn">
              <span class="alter" @click="alterTeacher(index)">编辑</span>
              <span @click="delTeacher(index)">删除</span>
            </span>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(item, index) in teamList" :key="index">
            <img :src="item.heading" alt="" />
            <div class="textbox">
              <span class="name">{{ item.name }}</span>
              <span class="list">
                <span v-for="(items, indexs) in item.teachrole" :key="indexs">{{
                  indexs >= 1 ? ' / ' + items : items
                }}</span>
              </span>
              <br />
              <span class="school">{{ item.school }}</span>
              <span class="type">{{ item.jobTitle }}</span>
            </div>
            <div class="teamTxt">
              <p>{{ item.content }}</p>
            </div>
            <span class="btn">
              <span class="alter" @click="alterTeacher(index)">编辑</span>
              <span @click="delTeacher(index)">删除</span>
            </span>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- <div class="notice">
      <p class="pbiaoqian">课程公告</p>
      <div class="notice_box">
        <el-button type="primary" @click="iptFlage = true">添加</el-button>
        <br />
        <div class="box">
          <div
            class="itemBox"
            v-show="noticeData"
            :key="index"
            v-for="(item, index) in noticeData"
          >
            <span class="alterspan" @click="alterNotice(index)">编辑</span
            ><span class="delspan" @click="delNotice(index)">删除</span>
            <div class="notice_item">
              {{ item.content }}
            </div>
          </div>
        </div>

        <div v-show="iptFlage || alterFlage">
          <el-input
            class="ipt"
            type="textarea"
            placeholder="请输入课程公告"
            v-model="notice"
          >
          </el-input>
          <br />
          <el-button type="primary" @click="addNotice">添加</el-button>
          <el-button @click="iptFlage = false">取消</el-button>
        </div>
      </div>
    </div> -->
    <div class="footerBtn">
      <el-button type="warning" v-if="alterFlage" @click="postAlter()"
        >修改课件</el-button
      >
      <el-button type="primary" v-else @click="postOss">发布课件</el-button>
      <el-button @click="toHome">取消发布</el-button>
    </div>
  </div>
</template>

<script>
// import { client } from '../../../../utils/oss';
// import EditorBar from '@/components/commom/wangeditor.vue'
import fileUtil from '../../../../utils/index';
export default {
  components: {},
  data() {
    return {
      // 富文本
      log: {
        detail: '',
      },
      coursewareName: '',
      // 文件列表
      fileList: [],
      value: [],
      // 图片、视频、压缩包url
      dialogImageUrl: '',
      dialogVideoUrl: '',
      dialogZipUrl: '',
      // 阿里云验证
      ossData: {},
      // 图片文件
      file: '',
      // 视频文件
      video: '',
      //压缩包文件
      zip: '',
      // 网盘链接、下载密码、解压密码
      links: '',
      extractPsw: '',
      decompressionPsw: '',
      //视频的时长
      time: '',
      // 富文本状态
      rich: {
        status: '',
      },
      // 图片文件的raw和状态
      files: {
        status: '',
        file: '',
      },
      // 视频文件的raw和状态
      videos: {
        status: '',
        file: '',
      },
      // 压缩包文件的raw和状态
      zips: {
        status: '',
        file: '',
      },
      // 团队模块的多选
      radio: '0',
      type: '3',
      //上传成功zip保存的数据
      zipData: {},
      //团队模块教师信息
      teacher: {
        name: '',
        school: '',
        jobTitle: '',
      },
      // 教师职位
      teachrole: [],
      //教师的介绍和公告
      content: '',
      // 教师头像file
      teacherHeaders: '',
      // 教师头像文件的raw
      teacherHeader: {},
      // 上传成功之后保存图片url进行展示
      ruleForm: {
        coverUrl: '',
        coverFile: '',
      },
      // 已添加团队的数据
      teamList: [],
      // 通知绑定的内容
      notice: '',
      // 输入框的状态
      iptFlage: false,
      // 团队的状态
      teamFlag: false,
      // 编辑的课件id
      alterId: '',
      //修改回显数据
      alterData: [],
      alterFlage: false,
      alterCover: false,
      alterVideo: false,
      alterZip: false,
      resData: [],
      noticeData: [],
      alterTeam: [],
      imgUrl: '',
      loading: false,
      coverUrl: '',
      videoUrl: '',
      zipUrl: '',
      alterCoverURL: '',
      alterVideos: '',
      progress: 0, //上传进度
    };
  },
  created() {
    this.getOss();
    if (this.$route.query.id) {
      this.alterId = this.$route.query.id;
      this.getAlterData(this.alterId);
      this.alterFlage = true;
      this.alterCover = true;
      this.alterVideo = true;
      this.alterZip = true;
    } else {
      this.alterId = '';
      this.alterFlage = false;
      this.alterCover = false;
      this.alterVideo = false;
      this.alterZip = false;
      // console.log('没id');
    }
  },
  methods: {
    toHome() {
      this.$router.push('/courseware');
    },
    zipUrls() {
      this.zipUrl = '';
      this.$refs.zipUrlss.clearFiles();
    },
    videoUrls() {
      this.videoUrl = '';
      this.$refs.videoUrlss.clearFiles();
    },
    coverUrls() {
      this.coverUrl = '';
      this.$refs.imgurls.clearFiles();
    },
    // 删除一条公告
    delNotice(index) {
      this.noticeData.splice(index, 1);
    },
    // 编辑公告
    alterNotice(index) {
      this.notice = this.noticeData[index].content;
      this.noticeData.splice(index, 1);
    },
    // 添加多条公告
    addNotice() {
      let text = {
        content: this.notice,
      };
      this.noticeData.push(text);
      this.notice = '';
    },
    // 修改老师信息
    alterTeacher(index) {
      this.imgUrl = this.teamList[index].heading;
      this.teacher.name = this.teamList[index].name;
      this.teacher.school = this.teamList[index].school;
      this.teacher.jobTitle = this.teamList[index].jobTitle;
      this.teachrole = this.teamList[index].teachrole;
      this.content = this.teamList[index].content;
      this.teamList.splice(index, 1);
    },
    // 删除一个老师信息
    delTeacher(index) {
      // console.log(index);
      this.teamList.splice(index, 1);
    },
    // 添加老师信息
    addOne() {
      if (
        this.imgUrl === '' ||
        this.teacher.name === '' ||
        this.teacher.school === '' ||
        this.teacher.jobTitle === '' ||
        this.teachrole === [] ||
        this.content === ''
      ) {
        this.$message({
          type: 'error',
          message: '请将课程团队内容补充完整！',
        });
      } else {
        let data = {
          heading: this.imgUrl,
          headerFile: this.teacherHeaders,
          name: this.teacher.name,
          school: this.teacher.school,
          jobTitle: this.teacher.jobTitle,
          teachrole: this.teachrole,
          content: this.content,
        };
        this.teamList.push(data);
        this.$refs.uploads.clearFiles();
        this.imgUrl = '';
        this.teacher.name = '';
        this.teacher.school = '';
        this.teacher.jobTitle = '';
        this.teachrole = [];
        this.content = '';
        // console.log(this.teamList);
      }
    },
    // 吧文件列表的图片转换为url
    imagePreview: function (file) {
      let self = this;
      //定义一个文件阅读器
      let reader = new FileReader();
      //文件装载后将其显示在图片预览里
      reader.onload = function (e) {
        //将bade64位图片保存至数组里供上面图片显示
        self.ruleForm.coverUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    handleRadio() {
      // console.log(this.radio);
      this.radio = '1';
      this.type = '0';
      // console.log(this.type);
    },
    handleRadios() {
      this.radio = '2';
      this.type = '1';
      // console.log(this.radio);
      // console.log(this.type);
    },
    // 获取阿里云验证信息
    getOss() {
      this.$axios.post('/teach/courseware/ossSignature').then((res) => {
        if (res.data.code === 200) {
          this.ossData = res.data.data;
        }
      });
    },
    // 图片url转文件
    initFile() {
      if (this.alterTeam == null) return false;
      let length = this.alterTeam.length;
      this.alterTeam.forEach((element) => {
        fetch(element.heading, {
          method: 'GET',
          mode: 'cors',
        })
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            const file = new File([blob], element.fileName, {
              type: blob.type,
            });
            let data = {
              heading: url,
              headerFile: file,
              content: element.content,
              jobTitle: element.jobTitle,
              name: element.name,
              school: element.school,
              teachrole: element.teachrole,
            };
            this.teamList.push(data);
            // file.url = url;
            // this.$set(this.teamList, index, file);
            // this.$set(this.teamList.heading, index, file.url);
            length--;
            // console.log(length);
            // console.log(this.teamList);
            // 延迟渲染，初始化完成 渲染图片
            if (length === 0) {
              // 数据传递完毕，初始化完成
              this.$nextTick(() => {
                this.hasInit = true;
              });
            }
          });
        // })
      });
    },
    // 提交修改
    async postAlter() {
      if (this.coursewareName === '') {
        this.$message({
          type: 'error',
          message: '请将必填项补充完整！',
        });
        return;
      }
      this.loading = true;
      // const that = this;
      await this.postVideo();
      await this.postText();
      await this.postImg();
      await this.postHeader();
      await this.postZip();
      // console.log(this.resData);
      const zip = {
        unzipCode: this.decompressionPsw,
        uploadCode: this.extractPsw,
        uploadUrl: this.links,
      };
      console.log();
      const data = {
        groupDTOS: this.teamList,
        id: this.$route.query.id,
        downloadDTO: zip,
        list: this.resData,
        gaoDTOS: this.noticeData,
        name: this.coursewareName,
        type: this.type,
        menuId: this.$route.query.menuId,
      };
      this.$axios
        .put('/teach/courseware/updateCourseWare', data)
        .then((res) => {
          // console.log(res);
          if (res.data.code === 200) {
            this.$message({
              message: '修改成功!',
              type: 'success',
            });
            sessionStorage.setItem('one', res.data.data.menuId);
            sessionStorage.setItem('two', res.data.data.firstMenuId);
            sessionStorage.setItem('three', res.data.data.secondMenuId);
            this.$router.push('/courseware');
          } else {
            this.$message({
              message: '修改失败!',
              type: 'error',
            });
            this.loading = false;
          }
        });
    },
    // 提交保存
    async postOss() {
      if (this.coursewareName === '' || this.file === '' || this.video === '') {
        this.$message({
          type: 'error',
          message: '请将必填项补充完整！',
        });
        return;
      }
      this.loading = true;
      await this.postText();
      await this.postHeader();
      await this.postVideo();
      await this.postImg();
      await this.postZip();
      // console.log(this.resData);
      const data = {
        list: this.resData,
        menuId: this.$route.query.menuId,
        name: this.coursewareName,
        type: this.type,
      };
      this.$axios
        .post('/teach/courseware/upLoadCourseWare', data)
        .then((res) => {
          // console.log(res);
          if (res.data.code === 200) {
            this.$message({
              message: '添加成功!',
              type: 'success',
            });
            this.$router.push('/courseware');
            sessionStorage.setItem('one', res.data.data.menuId);
            sessionStorage.setItem('two', res.data.data.firstMenuId);
            sessionStorage.setItem('three', res.data.data.secondMenuId);
          } else {
            this.$message({
              message: '添加失败!',
              type: 'error',
            });
            this.loading = false;
          }
        });
    },
    //上传富文本
    postText() {
      return new Promise((ress) => {
        const that = this;
        let list = this.ossData;
        // 先把字符串转化为文件，设置编码格式
        let txt = this.log.detail;
        let str = new Blob([txt], { type: 'text/plain;charset=utf-8' });
        // console.log(str);
        // let blob = new Blob([str], { type: 'text/plain;charset=utf-8' });
        // let url = window.URL.createObjectURL(blob);
        // let a = document.createElement('a');
        // let file = 'a.txt';
        // a.href = url;
        // 设置文件名
        let filename = 'fuwenben.txt';
        let txtName = 'fuwenben.txt';
        filename = fileUtil.getFileName(filename, list.dir, 'text');
        let formData = new FormData();
        formData.append('key', filename);
        formData.append('policy', list.policy);
        formData.append('OSSAccessKeyId', list.accessId);
        formData.append('success_action_status', '200');
        formData.append('signature', list.signature);
        formData.append('file', str, `fuwenben.txt`);
        // console.log(formData);
        let config = {
          headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
        };
        // const data = {
        //   key: filename,
        //   policy: list.policy,
        //   OSSAccessKeyId: list.accessId,
        //   success_action_status: '200',
        //   signature: list.signature,
        //   Text: this.log.detail + '.txt',
        // };
        this.$axios.post(list.host, formData, config).then(function (res) {
          // console.log(res.data.message + '富文本上传成功')
          // console.log(res.data);
          if (res.status < 300) {
            that.rich.status = 1;
            const data = {
              duration: null,
              fileName: txtName,
              fileSize: str.size,
              fileSuffix: txtName.split('.')[1],
              fileType: 'text',
              id: null,
              objectname: filename,
              resourceId: null,
              resourceType: 'courseware',
              upload_id: null,
              wights: null,
            };
            // console.log(data);
            that.resData.push(data);
            ress(data);
          } else {
            that.$message({
              type: 'error',
              message: '富文本上传失败！',
            });
          }
          // if (res.data.code === 200) {
          //   that.log.detail = '';
          //   that.$refs.editorRef.clearTxt();
          // }
        });
      });
    },
    // 上传头像
    postHeader() {
      // return new Promise((res) => {
      const that = this;
      // let listdata = [];
      let data = this.ossData;

      if (this.teamList.length < 1) {
        // return;
      } else {
        for (let i = 0; i < this.teamList.length; i++) {
          if (this.teamList[i].headerFile === '') {
            // return;
          } else {
            let list = this.teamList;
            let filename = list[i].headerFile.name;
            filename = fileUtil.getFileName(filename, data.dir, 'group');
            list[i].heading = filename;
            // console.log('-------------', this.teamList[i]);
            let formData = new FormData();
            formData.append('key', filename);
            formData.append('policy', data.policy);
            formData.append('OSSAccessKeyId', data.accessId);
            formData.append('success_action_status', '200');
            formData.append('signature', data.signature);
            formData.append('file', list[i].headerFile);
            let config = {
              headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
            };
            this.$axios
              .post(data.host, formData, config)
              .then(function (response) {
                // console.log(response);
                if (response.status < 300) {
                  // const data = {
                  //   fileName: list[i].headerFile.name,
                  //   fileSize: list[i].headerFile.size,
                  //   fileSuffix: list[i].headerFile.name.split('.')[1],
                  //   fileType: 'group',
                  //   objectname: filename,
                  //   resourceType: 'courseware',
                  // };
                  // listdata.push(data);
                  // if (listdata.length === that.teamList.length) {
                  //   console.log(listdata);
                  //   res(listdata);
                  // }
                  // console.log(data);
                  // res(data);
                  // that.resData.push(data);
                } else {
                  that.$message({
                    type: 'error',
                    message: '头像上传失败！',
                  });
                }
              });
          }
        }
      }
      // });
    },
    // 上传封面
    postImg() {
      return new Promise((res) => {
        const that = this;
        let data = this.ossData;
        // this.postHeader(data);
        if (this.file === '') {
          res('');
          return;
        } else {
          let filename = this.file.name;
          // console.log(this.file);
          filename = fileUtil.getFileName(filename, data.dir, 'cover');
          let formData = new FormData();

          formData.append('key', filename);
          formData.append('policy', data.policy);
          formData.append('OSSAccessKeyId', data.accessId);
          formData.append('success_action_status', '200');
          formData.append('signature', data.signature);
          formData.append('file', this.file);
          // console.log(formData);

          let config = {
            headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
          };
          // this.btn.disable = true;
          // this.btn.message = "上传中，请等待";
          this.$axios
            .post(data.host, formData, config)
            .then(function (response) {
              // console.log(response);
              if (response.status < 300) {
                that.files.status = 1;
                const data = {
                  duration: null,
                  fileName: that.files.file.name,
                  fileSize: that.files.file.size,
                  fileSuffix: that.files.file.name.split('.')[1],
                  fileType: 'cover',
                  id: null,
                  objectname: filename,
                  resourceId: null,
                  resourceType: 'courseware',
                  upload_id: null,
                  wights: null,
                };
                // console.log(data);
                that.resData.push(data);
                res(data);

                // that.resData.push(data);
              } else {
                that.$message({
                  type: 'error',
                  message: '封面上传失败！',
                });
              }
            });
        }
      });
    },
    postZip() {
      return new Promise((res) => {
        let data = this.ossData;
        if (this.zip.name) {
          const that = this;
          let filename = this.zip.name;
          filename = fileUtil.getFileName(filename, data.dir, 'material');
          let formData = new FormData();
          formData.append('key', filename);
          formData.append('policy', data.policy);
          formData.append('OSSAccessKeyId', data.accessId);
          formData.append('success_action_status', '200');
          formData.append('signature', data.signature);
          formData.append('file', this.zip);
          let config = {
            headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
          };
          this.$axios
            .post(data.host, formData, config)
            .then(function (response) {
              // console.log(response);
              if (response.status < 300) {
                that.zips.status = 1;
                const data = {
                  duration: null,
                  fileName: that.zips.file.name,
                  fileSize: that.zips.file.size,
                  fileSuffix: that.zips.file.name.split('.')[1],
                  fileType: 'material',
                  id: null,
                  objectname: filename,
                  resourceId: null,
                  resourceType: 'courseware',
                  upload_id: null,
                  wights: null,
                };
                // console.log(data);
                that.resData.push(data);
                res(data);
                that.zipData = data;
                // console.log('zipData---------*', that.zipData);
              } else {
                that.$message({
                  type: 'error',
                  message: '封面上传失败！',
                });
              }
              // const fileUrl = response.data || data.host + '/' + filename;
              // console.log(fileUrl);
              // this.$message({
              //   type: 'success',
              //   message: '上传成功!',
              // });
              // that.btn.disable = false;
              // that.btn.message = "上传服务器";
              // that.$refs.upload.clearFiles();
              // console.log( formData.get('file'))
              // formData.delete('file');
              // console.log( formData.get('file'))
              // that.file = '';
            });
        } else {
          // return;
          res('');
        }
      });
    },

    postVideo() {
      return new Promise((res) => {
        if (this.video.name) {
          const that = this;
          var fileurl = URL.createObjectURL(this.video);
          var audioElement = new Audio(fileurl);
          var duration;
          audioElement.addEventListener('loadedmetadata', function () {
            duration = audioElement.duration;
            // console.log(duration);
            // console.log(Math.ceil(duration % 60)); //单位：秒
            // let ss = Math.ceil(duration % 60); //单位：秒
            // // console.log(ss);
            // // console.log(duration);
            // let mm = '00';
            // let m = duration / 60;
            // if (m < 1) {
            //   mm = '00';
            // } else if (m > 1 || m < 10) {
            //   mm = '0' + m;
            // } else if (m > 9) {
            //   mm = m;
            // }
            // let time = mm + ':' + ss;
            // that.time = time;
            // 下面注释的是正确的视频时长格式，上面的是有问题的（后端处理了）
            // // console.log(that.time);
            let ss = Math.ceil(duration % 60); //单位：秒
            let s = '00';
            // console.log(ss);
            // console.log(duration);
            let mm = '00';
            let m = parseInt(duration / 60);
            if (m < 1) {
              mm = '00';
            } else if (m > 1 && m < 10) {
              mm = '0' + m;
            } else if (m > 9) {
              mm = m;
            }
            if (ss < 1) {
              s = '00';
            } else if (ss > 1 && ss < 10) {
              s = '0' + ss;
            } else if (ss > 9) {
              console.log(ss);
              s = ss;
            }
            let time = mm + ':' + s;
            that.time = time;
          });
          let data = this.ossData;
          let filename = this.video.name;
          console.log(filename);
          filename = fileUtil.getFileName(filename, data.dir, 'content');
          let formData = new FormData();
          formData.append('key', filename);
          formData.append('policy', data.policy);
          formData.append('OSSAccessKeyId', data.accessId);
          formData.append('success_action_status', '200');
          formData.append('signature', data.signature);
          formData.append('file', this.video);
          let config = {
            headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
            onUploadProgress: (progressEvent) => {
              let persent =
                ((progressEvent.loaded / progressEvent.total) * 1000) | 0; //上传进度百分比
              // console.log(persent / 10);
              this.progress = persent / 10;
            },
          };
          // client(this.ossData).multipartUpload(
          //   `videoTest/${this.video.name}`,
          //   this.video,
          //   {
          //     progress: function (p) {
          //       //p进度条的值
          //       console.log(p);
          //       // that.showProgress = true;
          //       that.progress = Math.floor(p * 100);
          //     },
          //   },
          //   console.log('---------------------')
          // );
          this.$axios
            .post(data.host, formData, config)
            .then(function (response) {
              // console.log(response);
              if (response.status < 300) {
                that.videos.status = 1;
                const data = {
                  duration: that.time,
                  fileName: that.videos.file.name,
                  fileSize: that.videos.file.size,
                  fileSuffix: that.videos.file.name.split('.')[1],
                  fileType: 'content',
                  id: null,
                  objectname: filename,
                  resourceId: null,
                  resourceType: 'courseware',
                  upload_id: null,
                  wights: null,
                };
                // console.log(data);
                that.resData.push(data);
                res(data);

                // that.resData.push(data);
              } else {
                that.$message({
                  type: 'error',
                  message: '封面上传失败！',
                });
              }
              // const fileUrl = response.data || data.host + '/' + filename;
              // console.log(fileUrl);
              // this.$message({
              //   type: 'success',
              //   message: '上传成功!',
              // });
              // that.btn.disable = false;
              // that.btn.message = "上传服务器";
              // that.$refs.upload.clearFiles();
              // console.log( formData.get('file'))
              // formData.delete('file');
              // console.log( formData.get('file'))
              // that.file = '';
            });
        } else {
          // return;
          res('');
        }
      });
    },
    //富文本
    changeLogDetail(val) {
      this.log.detail = val;
    },
    handleChansge(file) {
      // console.log(file);
      this.alterCover = true;
      this.files.file = file;
      this.file = file.raw;
      this.coverUrl = URL.createObjectURL(file.raw);
      this.alterCoverURL = URL.createObjectURL(file.raw);
    },
    handleChansgeVideo(file) {
      // console.log(file);
      this.alterVideo = true;
      this.alterVideos = file.name;
      this.videos.file = file;
      this.video = file.raw;
      this.videoUrl = URL.createObjectURL(file.raw);
      //11111111111
    },
    handleChansgeZip(file) {
      // console.log(file);
      this.zips.file = file;
      this.zip = file.raw;
      this.zipUrl = URL.createObjectURL(file.raw);
    },
    handleRemove() {
      // console.log(file, fileList);
    },
    handlePictureCardPreview() {
      // this.dialogImageUrl = file.url;
      // console.log(this.dialogImageUrl)
      // console.log(file.url);
    },
    handleChange() {},
    handleVideo(file) {
      this.dialogVideoUrl = file.url;
      // console.log(file);
    },
    handleZip(file) {
      this.dialogZipUrl = file.url;
      // console.log(file);
    },
    beforeRemove(file) {
      // console.log(fileList);
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleRemoveTeam() {
      // console.log(file, fileList);
    },
    handlePreviewTeam() {
      // console.log(file);
    },
    handleTeam(file) {
      // console.log(file, fileList);
      if (file.size > 510000) {
        this.$refs.uploads.clearFiles();
        this.$message({
          type: 'error',
          message: '文件太大啦',
        });
        return;
      } else {
        this.teacherHeaders = file.raw;
        this.teacherHeader = file;
        this.imgUrl = URL.createObjectURL(file.raw);

        // let data = file.url;
        // console.log('data--------------' + data);
      }
    },
    // 清除团队头像
    clearImg() {
      this.$refs.uploads.clearFiles();
      this.teacherHeaders = '';
      this.teacherHeader = '';
      this.imgUrl = '';
      // console.log('11111');
    },
    // 数据回显
    getAlterData(id) {
      const that = this;
      this.$axios
        .get(`/teach/courseware/findCourseWare?coursewareId=${id}`)
        .then((res) => {
          // console.log(res.data);
          const data = res.data;
          if (data.code === 200) {
            this.coursewareName = data.data.name;
            this.alterData = data.data;
            this.alterCoverURL = this.alterData.map.cover;
            this.alterVideos = this.alterData.map.contentob;
            this.alterTeam = data.data.groupDTOS;
            this.initFile();
            // console.log(this.alterTeam);
            that.noticeData = data.data.gaoDTOS;
            // console.log(this.noticeData);
            if (res.data.data.type === 1) {
              this.alterZip = false;
              this.radio = '2';
              this.links = res.data.data.map.contenturl;
              this.extractPsw = res.data.data.map.contentupcode;
              this.decompressionPsw = res.data.data.map.contentzipcode;
            }
            // this.$axios.get(data.data.map.text).then((res) => {
            //   if (res.status === 200) {
            //     that.log.detail = res.data;
            //     that.$nextTick(() => {
            //       that.$refs.editorRef.updateEditorData();
            //     });
            //   }
            // });
          }
        });
    },
    btof(data, fileName) {
      const dataArr = data.split(',');
      const byteString = atob(dataArr[1]);

      const options = {
        type: 'image/jpg',
        endings: 'native',
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + '.jpg', options);
    },
    getBase64Image(img) {
      let canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
      let dataURL = canvas.toDataURL('image/' + ext);

      return dataURL;
    },
    getImg(img) {
      let image = new Image();
      image.src = img;
      image.setAttribute('crossOrigin', 'Anonymous');
      image.onload = function () {
        let base64 = this.getBase64Image(image);
        // console.log(base64);

        document.getElementById('img').src = base64;

        // let formData = new FormData();

        //转换base64到file
        // let file = this.btof(base64, 'test');
        // return file;
        // console.log(file);
      };
    },
  },
};
</script>

<style lang="less" scoped>
.admin {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  background: rgb(241, 242, 246);
  align-items: center;
}
.title {
  width: 58%;
  height: 44px;
  background: #fff;
  border-radius: 4px;
  p {
    margin: 0;
    padding: 0;
    text-indent: 16px;
    line-height: 44px;

    // line-height: 60px;
  }
}
.coursewareMessage {
  width: 58%;
  // min-height: 500px;
  background: #fff;
  // margin: 16px 0;
  border-radius: 4px;
  margin-top: 16px;
  p {
    margin: 0;
    margin-bottom: 16px;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
  }
  .el-cascader {
    width: 95% !important;
    margin: 16px 0 16px 8px;
  }
  .el-input {
    width: 95%;
    margin-left: 8px;
    margin-bottom: 16px;
  }
  .hongdian {
    margin-left: 16px;
  }
  .richBox {
    width: 95%;
    margin-left: 28px;
  }
}
.coursewareCover {
  width: 58%;
  background: #fff;
  margin-top: 16px;
  border-radius: 4px;
  p {
    margin: 0;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
    span {
      font-size: 12px;
      color: #666666;
      margin-left: 16px;
    }
  }
  .upload-demo {
    margin: 0px 0 16px 16px;
  }
  .hongdian {
    position: relative;
    left: 16px;
    top: 110px;
  }
  /deep/ .el-upload {
    margin-left: 15px !important;
  }
  .imgBox {
    width: 304px;
    height: 56.25%;
    margin-left: 28px;
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    .cha {
      display: none;
      width: 30px;
      height: 30px;
      border-radius: 0 0 0 100%;
      background: rgb(205, 205, 207);
      position: absolute;
      right: 0;
      font-size: 12px;
      color: #f56c6c;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  .imgBox:hover .cha {
    display: block !important;
  }
}
/deep/.el-upload-dragger {
  width: 290px;
  height: 164px;
}
.coursewareContent {
  width: 58%;
  background: #fff;
  margin: 16px 0;
  border-radius: 4px;
  p {
    margin: 0;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
    span {
      font-size: 12px;
      color: #666666;
      margin-left: 16px;
    }
  }
  .upload-demo {
    margin: 16px 0 16px 16px;
  }
  .hongdian {
    margin-right: 8px;
  }
  .fileClass {
    max-width: 500px;
    white-space: nowrap;
    height: 40px;
    margin: 16px 0 16px 16px;
    position: relative;
    border-radius: 4px;
    span {
      // float: left;
      margin: 8px 0 0 16px;
      display: inline-block;
      line-height: 25px;
    }
    .del {
      width: 37px;
      height: 40px;
      display: none;
      background-color: rgb(205, 207, 208);
      position: absolute;
      float: right;
      top: 0px;
      right: 0;
      border-radius: 4px;
    }
  }
  .fileClass:hover {
    background: #f1f3f5;
  }
  .fileClass:hover .del {
    display: block;
  }
}
.el-upload__text {
  span {
    color: #000000;
    opacity: 0.25;
  }
}
.cover {
  width: 90%;
  // height: 200px;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 2px 2px 1px #e0dfdf;
  p {
    text-indent: 20px;
    color: #888;
  }
  .box {
    width: 95%;
    height: 120px;
    background: #ebeef5;
    margin: 20px auto;
    .fileBtn {
      margin-top: 20px;
      margin-left: 50px;
    }
  }
}
.videoBox {
  width: 90%;
  height: 220px;
  background: #fff;
  margin-top: 10px;
  margin-bottom: 50px;
  box-shadow: 0 2px 2px 1px #e0dfdf;
  p {
    text-indent: 20px;
    color: #888;
  }
  .box {
    width: 95%;
    height: 120px;
    background: #ebeef5;
    margin: 0px auto;
    .fileBtn {
      margin-top: 20px;
      margin-left: 50px;
    }
  }
}
.shangchaun {
  color: #409eff;
  margin: 40px 0 20px 0;
  font-size: 44px;
}
.upload__text {
  p {
    border-bottom: none !important;
  }
}
.el-tag {
  margin: 0 5px;
}
.btn {
  width: 60%;
  height: 60px;
  font-size: 20px;
}
.progress-wrap {
  width: 300px;
}
.progress-wrap p {
  width: 100%;
}
.progress {
  background-color: #c5c8ce;
  height: 20px;
}
.progress span {
  display: block;
  background-color: #19be6b;
  height: 100%;
  width: 0;
}
.footerBtn {
  width: 58%;
  margin-top: 16px;
  .el-button {
    width: 140px;
  }
}
.material {
  width: 58%;
  background: #fff;
  border-radius: 4px;
  .pbiaoqian {
    margin: 0;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
    margin-bottom: 16px;
    span {
      font-size: 12px;
      color: #666666;
      margin-left: 16px;
    }
  }
  .wangpang {
    p {
      border: none;
      margin: 0;
    }
    .el-input {
      width: 95%;
      margin-left: 8px;
      margin-bottom: 16px;
    }
    .hongdian {
      margin: 0 8px;
    }
  }
  .type {
    display: inline-block;
    text-indent: 16px;
    margin-bottom: 16px;
  }
  .fileClass {
    max-width: 500px;
    white-space: nowrap;
    height: 40px;
    margin: 16px 0 16px 16px;
    position: relative;
    border-radius: 4px;
    span {
      // float: left;
      margin: 8px 0 0 16px;
      display: inline-block;
      line-height: 25px;
    }
    .del {
      width: 37px;
      height: 40px;
      display: none;
      background-color: rgb(205, 207, 208);
      position: absolute;
      top: 0px;
      right: 0;
      border-radius: 4px;
    }
  }
  .fileClass:hover {
    background: #f1f3f5;
  }
  .fileClass:hover .del {
    display: block;
  }
}
.team {
  width: 58%;
  background: #fff;
  border-radius: 4px;
  margin-top: 16px;
  .btn {
    margin-left: 16px;
    width: 88px;
    height: 40px;
    font-size: 12px;
  }
  .pbiaoqian {
    margin: 0;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
    margin-bottom: 16px;
  }
  .addbox {
    width: 100%;
    min-height: 80px;
    margin-top: 16px;
    .postHeader {
      line-height: 70px;
      float: left;
      margin-left: 16px;
    }
    .headerBox {
      width: 70px;
      height: 70px;
      background: #f1f3f5;
      border-radius: 50%;
      float: left;
      margin-left: 8px;
      .imgbox {
        img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          float: left;
        }
        .back {
          display: none;
          width: 70px;
          position: relative;
          float: left;
          top: -70px;
          height: 70px;
          background: #000000;
          opacity: 0.5;
          border-radius: 50%;
          line-height: 70px;
        }
        // .back:hover {
        //   display: inline-block;
        // }
      }
      .imgbox:hover .back {
        display: inline-block;
      }
    }
    /deep/.upload-demo {
      width: 70px;
      float: left;
    }
    .titles {
      width: 120px;
      // float: left;
      margin-left: 80px;
      position: relative;
      .upload {
        color: #409eff;
        font-size: 14px;
        margin-left: -56px;
      }
      .uploadTitle {
        color: #000000;
        opacity: 0.25;
        font-size: 12px;
      }
    }
  }
  .teacher {
    min-height: 40px;
    margin-bottom: 16px;
    margin-left: 16px;
    .el-input {
      width: 30%;
      margin: 0 8px;
    }
  }
  .checkbox {
    height: 20px;
    margin-left: 16px;
    margin-bottom: 16px;
    .el-checkbox-group {
      float: left;
    }
    .hongdian {
      float: left;
      margin-top: 10px;
    }
    span {
      margin-left: 8px;
      margin-right: 30px;
      float: left;
    }
  }
  .teacherTxt {
    width: 100%;
    margin-left: 16px;
    margin-bottom: 16px;
    .hongdian {
      position: relative;
      top: -16px;
    }
    .ipt {
      margin-left: 10px;
      width: 93%;
      font-size: 14px;
      padding: 0;
    }
    /deep/.el-textarea__inner {
      min-height: 40px;
    }
  }
  .footerBtn {
    width: 100%;
    margin-left: 16px;
    margin-bottom: 16px;
    .el-button {
      width: 88px;
    }
  }
  .addList {
    width: 100%;
    li {
      border-top: 1px solid #cccccc;
      width: 97%;
      padding: 16px;
      position: relative;
      img {
        width: 70px;
        height: 70px;
        background: #000000;
        margin-left: 16px;
        border-radius: 50%;
      }
      .textbox {
        width: 550px;
        height: 60px;
        left: 10px;
        position: relative;
        top: -73px;
        left: 93px;
        span {
          display: inline-block;
        }
        .name {
          font-size: 14px;
          margin-right: 16px;
        }
        .list {
          font-size: 14px;
        }
        .school {
          color: #666;
          font-size: 14px;
          margin-top: 16px;
          margin-right: 16px;
        }
        .type {
          color: #666;
          font-size: 14px;
        }
      }
      .teamTxt {
        width: 94%;
        margin-left: 16px;
        margin-top: -46px;
        border-radius: 4px;
        border: 1px solid #111;
        p {
          display: inline-block;
          word-break: break-all;
          width: 97%;
          margin: 10px 16px 16px 16px;
        }
      }
      .btn {
        display: none;
        position: absolute;
        right: 10px;
        top: 20px;
        span {
          display: inline;
          cursor: pointer;
        }
        .alter {
          margin-right: 16px;
        }
      }
    }
    li:hover .btn {
      display: inline-block;
    }
  }
}
.notice {
  width: 58%;
  background: #fff;
  border-radius: 4px;
  margin-top: 16px;
  padding-bottom: 16px;
  .pbiaoqian {
    margin: 0;
    padding: 0;
    line-height: 50px;
    text-indent: 16px;
    color: #333;
    border-bottom: 2px #cccccc solid;
    margin-bottom: 16px;
  }
  // div {
  //   margin-left: 16px;
  //   padding-bottom: 16px;
  //   .ipt {
  //     width: 95%;
  //     margin-left: 0px;
  //     margin-top: 16px;
  //   }
  // }
  .notice_box {
    .el-button {
      margin: 0 16px 0px 16px;
    }
    width: 100%;
    .box {
      width: 100%;
      .itemBox {
        width: 95%;
        margin-top: 32px;
        position: relative;
        .alterspan {
          display: inline-block;
          position: absolute;
          right: 30px;
          top: -25px;
          font-size: 14px;
        }
        .delspan {
          display: inline-block;
          position: absolute;
          top: -25px;
          right: -10px;
          font-size: 14px;
        }
        span:hover {
          cursor: pointer;
        }
        .notice_item {
          width: 100%;
          height: 50px;
          border-radius: 4px;
          margin-top: 16px;
          margin-left: 16px;
          border: 1px solid #111;
          text-indent: 10px;
        }
      }
    }
  }
  .ipt {
    width: 95%;
    margin: 16px;
  }
}
.coursewareContents {
  margin-left: 16px;
  margin-bottom: 16px;
  .fileClass {
    padding-right: 50px;
  }
}
/deep/.el-scrollbar__view {
  z-index: 100000 !important;
}
</style>
