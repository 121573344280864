<template>
  <div id="login">
    <div class="login_box">
      <div class="right_box">
        <!-- logo 图片背景盒子 -->
        <h2></h2>
        <div class="forget" v-if="forgetPsw">
          <div class="tabbox">重新设置密码</div>
          <span class="bordersss"></span>
          <el-from name="forget">
            <el-input
              v-model="forget.name"
              @input="btnState"
              autocomplete="off"
              placeholder="请输入用户名"
              @keydown.enter.native="login"
            >
              <i slot="prefix" class="el-icon-user"></i>
            </el-input>
            <div class="verify_box">
              <el-input
                style="width: 200px"
                v-model="forget.code"
                @input="btnState"
                autocomplete="off"
                placeholder="请输入验证码"
                type="text"
                @keydown.enter.native="login"
              >
                <i slot="prefix" class="el-icon-lock"></i>
              </el-input>
              <el-button
                @click="getCode()"
                :disabled="codeMsg"
                style="float: right; min-width: 110px"
                :type="forget.type"
                >{{
                  btnTxt === '获取验证码' ? '获取验证码' : btnTxt + 's'
                }}</el-button
              >
            </div>
            <el-input
              v-model="forget.password"
              @input="btnState"
              autocomplete="off"
              placeholder="请输入密码"
              @keydown.enter.native="login"
            >
              <i slot="prefix" class="el-icon-user"></i>
            </el-input>
          </el-from>
        </div>
        <div class="tab_box" v-if="forgetPsw === false">
          <span class="borders" v-show="isActive === true"></span>
          <span class="borderss" v-show="isActive === false"></span>
          <div
            class="note_login"
            @click="alterLogins"
            :class="{ cur: isActive === true }"
          >
            短信登录
          </div>
          <div
            class="psw_login"
            @click="alterLogin"
            :class="{ cur: isActive === false }"
          >
            密码登录
          </div>
        </div>
        <el-form
          status-icon
          ref="loginFormRef"
          class="login_form"
          v-if="needLogin"
        >
          <el-form-item prop="userName" v-if="forgetPsw === false">
            <el-input
              v-model="username"
              @input="btnState"
              autocomplete="off"
              placeholder="请输入用户名"
              @keydown.enter.native="login"
            >
              <i slot="prefix" class="el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item
            prop="password"
            v-show="isActive === false"
            v-if="forgetPsw === false"
          >
            <el-input
              v-model="password.psw"
              @input="btnState"
              autocomplete="off"
              placeholder="请输入密码"
              type="password"
              @keydown.enter.native="login"
            >
              <i slot="prefix" class="el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <!-- 根据用户选择的登录方式来切换登录时需要切换的局部输入框 -->
          <el-form-item v-show="isActive" v-if="forgetPsw === false">
            <el-input
              style="width: 200px"
              v-model="password.msg"
              @input="btnState"
              autocomplete="off"
              placeholder="请输入验证码"
              type="text"
              @keydown.enter.native="login"
            >
              <i slot="prefix" class="el-icon-lock"></i>
            </el-input>
            <el-button
              @click="getCode()"
              :disabled="codeMsg"
              style="float: right; min-width: 110px"
              type="primary"
              >{{
                btnTxt === '获取验证码' ? '获取验证码' : btnTxt + 's'
              }}</el-button
            >
          </el-form-item>
          <!-- <el-select
            style="width: 100%"
            @change="btnState"
            v-model="values"
            placeholder="请选择"
            v-if="options !== []"
            v-show="forgetPsw === false"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
          <el-form-item class="text_left">
            <el-checkbox v-model="checked" @change="btnState">
              我已阅读
              <!-- 用户协议地址 需要确认后修改 -->
              <el-button type="text" @click="showProtocalDetail"
                >用户协议</el-button
              >和
              <!-- 隐私政策地址需要确认后修改 -->
              <el-button type="text" @click="showPricavyDetail"
                >隐私政策</el-button
              >
            </el-checkbox>
            <el-link
              @click="(loginFlag = !loginFlag), (forgetPsw = !forgetPsw)"
              type="primary"
              >{{ loginFlag === false ? '忘记密码' : '去登录' }}</el-link
            >
          </el-form-item>
          <el-form-item>
            <el-button
              :disabled="usernameMsg"
              type="primary"
              class="login_button"
              @click="login"
              >{{ forgetPsw === false ? '登录' : '重置' }}</el-button
            >
          </el-form-item>
          <div class="text_right"></div>
        </el-form>

        <el-dialog
          :visible.sync="infoDialogVisible"
          :title="loginTitle"
          class="blue_title"
          append-to-body
        >
          <div class="content">{{ loginText }}</div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: true,
      usernameMsg: true,
      loginFlag: false,
      // 是否需要渲染 登录组件
      needLogin: true,
      // 登录用户信息
      checked: false,
      forgetPsw: false,
      //按钮
      btnTxt: '获取验证码',
      username: '',
      codeMsg: false,
      timer: null,
      password: {
        psw: '',
        msg: '',
      },
      // 下拉菜单
      options: [],
      // values: '',
      names: '',
      // 注册用户表单数据
      registerInfo: {
        userName: '',
        password: '',
        passwordRepeat: '',
        code: '',
        checked: false,
      },
      // 忘记密码绑定的值
      forget: {
        name: '',
        code: '',
        password: '',
        type: 'primary',
      },
      loginText: '',
      loginTitle: '',
      protocalDetail: ' 用户协议详情',
      pricavyDetail: '隐私政策详情',
      // 验证规则
      // rulesw: {
      //   // 验证用户名是否合法
      //   userName: [
      //     { required: true, message: '请输入账号', trigger: 'blur' },
      //     {
      //       min: 4,
      //       max: 15,
      //       message: '用户名在 4 到 15 个字符',
      //       trigger: 'blur',
      //     },
      //   ],
      //   // 验证密码是否合法
      //   password: [
      //     { required: true, message: '请输入密码', trigger: 'blur' },
      //     {
      //       min: 6,
      //       max: 15,
      //       message: '长度在 6 到 15 个字符',
      //       trigger: 'blur',
      //     },
      //   ],
      // },
      infoDialogVisible: false,
    };
  },
  created() {
    this.getCertificate();
  },
  mounted() {},
  methods: {
    btnState() {
      // if (this.checked === true) {
      if (
        this.username !== '' &&
        this.password.msg !== '' &&
        // this.values !== '' &&
        this.checked === true
      ) {
        this.usernameMsg = false;
      } else {
        this.usernameMsg = true;
      }
      if (
        this.username !== '' &&
        this.password.psw !== '' &&
        // this.values !== '' &&
        this.checked === true
      ) {
        this.usernameMsg = false;
      }
      // } else {
      //   alert('请先阅读用户协议和隐私政策');
      // }

      if (this.password.msg !== '') {
        this.codeMsg = true;
      }
    },
    // 获取验证码
    getCode() {
      // const data = {
      //   phone: this.username,
      // };
      if (this.username === '') {
        this.$message({
          message: '请先输入手机号!',
          type: 'error',
        });
      } else {
        this.$axios
          .post(`/teach/common/sendmessage?phone=${this.username}`)
          .then((res) => {
            // console.log(res);
            if (res.data.data === 1) {
              this.$message({
                message: '发送成功，请注意查收!',
                type: 'success',
              });
              const that = this;
              const TIME_COUNT = 60;
              if (!that.timer) {
                that.btnTxt = TIME_COUNT;
                that.codeMsg = true;
                that.forget.type = 'info';
                that.timer = setInterval(() => {
                  if (that.btnTxt > 0 && that.btnTxt <= TIME_COUNT) {
                    that.btnTxt--;
                  } else {
                    // this.codeMsg = true;
                    clearInterval(that.timer);
                    that.timer = null;
                    that.codeMsg = false;
                    that.btnTxt = '获取验证码';
                    that.forget.type = 'primary';
                  }
                }, 1000);
              }
            } else {
              this.$message({
                message: '验证码发送失败！',
                type: 'error',
              });
            }
          });
      }

      // // console.log(this.$api.api.sendCode(param));
      // this.$api.api.sendCode(param).then((res) => {
      //   console.log(resx);
      // });

      // this.$axios
      //   .post(`/teach/common/sendmessage?phone=${this.username}`)
      //   .then((res) => {
      //     console.log(res.data);
      //   });
    },
    // 切换登录方式清空输入框
    alterLogin() {
      this.isActive = false;
      this.username = '';
      this.password.msg = '';
      this.password.psw = '';
    },
    // 切换登录方式清空输入框
    alterLogins() {
      this.isActive = true;
      this.username = '';
      this.password.msg = '';
      this.password.psw = '';
    },
    // 登录
    login() {
      const that = this;
      if (this.isActive === true) {
        const data = {
          phone: this.username,
          code: this.password.msg,
        };
        this.$axios.post('/teach/common/smslogin', data).then((res) => {
          if (res.data.code === 200) {
            localStorage.setItem('token', res.data.data.token);
            sessionStorage.setItem('role', res.data.data.role);
            // const datas = {
            //   certificateId: that.values,
            // };
            let role = res.data.data.role;
            that.$axios
              .post(`/teach/common/verifyCertificate?certificateId=${0}`)
              .then((res) => {
                if (res.data.code === 200 && res.data.data !== -1) {
                  that.$message({
                    message: '登录成功',
                    type: 'success',
                  });
                  // this.$store.commit('saveSilderId', this.values);
                  sessionStorage.setItem('role', res.data.data.role);
                  /* 证书id */
                  sessionStorage.setItem('id', res.data.data.id);
                  // TODO 根据角色进行跳转
                  if (role == 'teacher' || role == 'admin') {
                    this.$router.push('/courseware');
                  } else if (role == 'superadmin') {
                    this.$router.push('/certificateList');
                  } else {
                    this.$router.push('/mySpace');
                  }

                  /* 学生端所需班级id */
                  sessionStorage.setItem('classId', res.data.data.classId);
                  sessionStorage.setItem('className', res.data.data.className);
                }
              });
          } else {
            that.$message({
              message: '登录失败',
              type: 'error',
            });
          }
        });
      } else if (this.isActive === false) {
        const user = {
          username: this.username,
          password: this.password.psw,
        };
        // console.log(JSON.stringify(user));
        this.$axios.post('/teach/common/login', user).then((res) => {
          if (res.data.code === 200 && res.data.data !== -1) {
            // const datas = {
            //   certificcateId: this.values,
            // };
            localStorage.setItem('token', res.data.data.token);
            sessionStorage.setItem('role', res.data.data.role);
            let role = res.data.data.role;
            that.$axios
              .post(`/teach/common/verifyCertificate?certificateId=${0}`)
              .then((res) => {
                // console.log('res: ', res);

                if (res.data.code === 200) {
                  // console.log('res.data: ', res.data);
                  that.$message({
                    message: '登录成功',
                    type: 'success',
                  });
                  // this.$store.commit('saveSilderId', this.values);
                  // sessionStorage.setItem('silderId', this.values);
                  sessionStorage.setItem('header', res.data.data.headPicture);
                  sessionStorage.setItem('name', res.data.data.realname);
                  // sessionStorage.setItem('silder', this.values);
                  sessionStorage.setItem('id', res.data.data.id);
                  // let userData = res.data.data;
                  // this.$EventBus.$emit('eventName', userData); //登录成功之后吧用户信息存到事件总线里面去

                  /* todo 根据不同角色进入不同的页面*/
                  if (role == 'teacher' || role == 'admin') {
                    this.$router.push('/courseware');
                  } else if (role == 'superadmin') {
                    this.$router.push('/certificateList');
                  } else {
                    this.$router.push('/mySpace');
                  }
                  // that.$router.push('/courseware');
                  /* 动态创建路由 */
                  this.$store.dispatch('changeRoles', role);
                  /* 学生端所需班级id */
                  sessionStorage.setItem('classId', res.data.data.classId);
                  sessionStorage.setItem('className', res.data.data.className);
                } else {
                  this.$message.error(res.data.message);
                }
              });
          } else {
            that.$message({
              message: res.data.message,
              type: 'error',
            });
          }
        });
      }

      // this.$axios.post('/teach/common/smslogin', data).then((res) => {
      //   console.log(res);
      // });
    },
    clickLogin() {
      // this.username = '';
      // this.password.psw = '';
      // this.password.code = '';
      if (this.forgetPsw === false) {
        if (this.isActive === false) {
          return;
        } else {
          return;
        }
      } else {
        return;
      }
    },
    // clickrLogin() {
    //   if (this.isActive === true) {
    //     this.loginFlag = true;
    //   }
    // },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // login() {
    //   if (this.loginFlag === true) {
    //     // 登录
    //   } else {
    //     // 重置密码
    //   }
    // },
    // 用户发起登录方法
    // login() {
    //   if (this.loginInfo.checked === false)
    //     return this.$message.info('请先阅读用户协议和隐私政策');
    //   this.$refs.loginFormRef.validate(async (valid) => {
    //     if (!valid) return;
    //     const loading = this.$loading({
    //       lock: true,
    //       text: '登录中',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.8)',
    //     });
    //     const { data: res } = await this.$axios.post(
    //       '/user/login',x
    //       this.loginInfo
    //     );
    //     if (res.code !== 10000) {
    //       loading.close();
    //       return this.$message.error(res.msg);
    //     }
    //     this.$message.success('登录成功！');
    //     // 根据是否点保存 保存用户的信息
    //     // this.setUserInfo()
    //     // 1.将登录成功之后的 token，保存到客户端的 sessionStorage中
    //     //  1.1 项目中除了登录之外的其他APP接口，必须在登录之后才能访问
    //     //  1.2 token 只有应在当前网站打开期间生效，所以将token 保存在sessionStorage 中
    //     window.sessionStorage.setItem('token', res.data.token);
    //     // 保存用户角色 到sessionStorage 中
    //     // 账号
    //     // 保存用户名到vuex中
    //     this.$store.commit('saveUserId', res.data.id);
    //     this.$store.commit('saveRealName', res.data.realName);
    //     // // 保存用户角色信息到vuex中
    //     this.$store.commit('saveRoleIdd',res.data.roleId);
    //     this.$store.commit('saveUserName', res.data.userName);
    //     this.$store.commit('saveClassId', res.data.classId);
    //     this.$router.push('/home');
    //   });
    // },
    //获取证书信息
    getCertificate() {
      const that = this;
      this.$axios.get('/teach/common/findCerification').then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          for (let i = 0; i < res.data.data.length; i++) {
            const data = {
              value: res.data.data[i].id,
              label: res.data.data[i].name,
            };
            that.options.push(data);
          }
        }
      });
    },
    showProtocalDetail() {
      this.loginTitle = '用户协议';
      this.loginText = this.protocalDetail;
      this.infoDialogVisible = true;
    },
    showPricavyDetail() {
      this.loginTitle = '隐私政策';
      this.loginText = this.pricavyDetail;
      this.infoDialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
#login {
  // webpack4.0 开始 需要用 ~@ 代表根目录下的 src
  background-image: url('~@/assets/images/login-background.jpg');
  height: 100vh;
  width: 100vw;
}
.login_box {
  width: 1212px;
  height: 740px;
  background-image: url('~@/assets/images/login-logo3.png');
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .right_box {
    width: 320px;
    height: 626px;
    box-sizing: border-box;
    margin: 25px 146px 0px 0px;
    float: right;
    padding: 104px 0;
    h2 {
      width: 324px;
      height: 115px;
      margin-bottom: 32px;
      background: url('~@/assets/images/zksc-logo1.png') center center no-repeat;
    }
    .login_button {
      width: 100%;
    }
    .text_left {
      .el-button {
        color: red;
        margin: 0 6px;
      }
    }
    .text_right {
      text-align: right;
    }
    .register_button {
      width: 100%;
    }
  }
}
.el-form-item {
  margin-bottom: 16px;
}
.el-form-item__content {
  right: -10px;
}
.verify_box {
  width: 330px;
  .el-input {
    margin-right: -10px !important;
  }
  .el-button {
    float: right;
    margin-top: 10px;
    margin-left: 8px;
  }
}
.tab_box {
  width: 100%;
  min-height: 30px;
  // background: red;
  margin-bottom: 8px;
  .note_login,
  .psw_login {
    float: left;
    margin: 0 5px;
    margin-bottom: 10px;
  }
  .borders {
    display: inline-block;
    position: relative;
    top: 10px;
    left: -125px;
    width: 30px;
    height: 2px;
    background: rgb(100, 157, 231);
  }
  .borderss {
    display: inline-block;
    position: relative;
    top: 10px;
    left: -50px;
    width: 30px;
    height: 2px;
    background: rgb(100, 157, 231);
  }
}
.el-link {
  float: right;
  margin-top: 10px;
}
.cur {
  // border-bottom: 2px solid rgb(100, 157, 231);
  border-width: 2px;
}
.forget {
  .tabbox {
    margin: 0 5px;
    // border-bottom: 2px solid rgb(100, 157, 231);
    float: left;
  }
  .bordersss {
    display: inline-block;
    position: relative;
    top: 10px;
    left: -73px;
    width: 42px;
    height: 2px;
    background: rgb(100, 157, 231);
  }
  .el-input {
    line-height: 40px;
    margin: 10px;
  }
}
</style>
