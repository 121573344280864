<template>
  <div class="item-box" @click="toDetail(data)">
    <img class="item-box-img" :src="data.cover" alt />
    <div class="item-box-disable">
      <p>{{ data.name }}</p>
      <div>
        <span>
          <icon-svg icon-class="dianzan"></icon-svg>
          {{ data.likes }}
        </span>
        <span>
          <icon-svg icon-class="bofang"></icon-svg>
          {{ data.visit }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data', 'menuId'],
  methods: {
    /* 去详情页 */
    toDetail(data) {
      this.$router.push({
        path: `/resourcedetail?id=${data.id}&menuId=${this.menuId}`,
      });
      sessionStorage.setItem('choose', 'courseManage');
    },
  },
};
</script>
<style lang="less" scoped>
.item-box {
  width: 290px;
  height: 264px;
  background: #f1f3f5;
  border-radius: 4px;
  overflow: hidden;
  margin: 8px 0 8px 16px;
  cursor: pointer;
  &:nth-child(1) {
    margin-left: 16px;
  }
  .item-box-img {
    width: 290px;
    height: 164px;
    vertical-align: middle;
  }
  .item-box-disable {
    height: 100px;

    // padding: 16px 16px 10px 16px;
    padding: 16px;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      height: 40px;
      font-size: 14px;
      color: #333;
      margin: 0;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    div {
      font-size: 12px;
      span {
        margin-right: 10px;
      }
    }
  }
}
</style>
