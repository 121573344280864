<template>
  <div class="commentNotice">
    <ul>
      <li v-for="(item, index) in listData" :key="index">
        <img
          :src="
            item.urlimg == 'null' ? '/img/avatar.4e4aa2c3.jpg' : item.urlimg
          "
          alt=""
        />
        <div class="nameTime">
          <div>
            <span> {{ item.username }} </span> 点赞了我的评论
          </div>
          <div>
            <span class="time">{{ item.createTime }}</span>
            <!-- <span class="look">查看</span> -->
          </div>
        </div>
        <br />
        <div class="content">{{ item.content }}</div>
      </li>
    </ul>
    <div class="block" v-show="dataFlag">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current"
        :page-size="size"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <h2 v-show="!dataFlag">暂无内容</h2> -->
    <el-empty v-show="!dataFlag" description="暂无内容"></el-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataFlag: true,
      current: 1,
      total: 1,
      size: 10,
      listData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.listData = [];
      this.$axios
        .post(
          `/teach/command/commandList?current=${this.current}&size=${
            this.size
          }&type=${3}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            if (res.data.data.total == 0) {
              this.dataFlag = false;
            } else {
              this.dataFlag = true;
              console.log(res.data.data);
              this.listData = res.data.data.items;
              this.total = res.data.data.total;
            }
          }
        });
    },
    handleSizeChange() {},
    handleCurrentChange(v) {
      this.current = v;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
ul {
  width: 100%;
  li {
    width: 96%;
    min-height: 80px;
    margin: 0 2%;
    border-bottom: 2px #cccccc solid;
    overflow: hidden;
    margin-top: 10px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      float: left;
      margin-right: 20px;
    }
    .nameTime {
      width: 90%;
      float: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .content {
      width: 95%;
      // float: right;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 80px;
    }
    .look {
      margin-left: 16px;
      cursor: pointer;
      color: #409eff;
    }
    // .look:hover {
    // }
  }
}
.block {
  text-align: center;
  bottom: 16px;
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
