<template>
  <div class="detail">
    <div class="content">
      <div class="video_box">
        <h2>{{ detailData.name }}</h2>
        <p>
          <icon-svg
            style="font-size: 12px; margin-right: 8px"
            icon-class="shijian"
          ></icon-svg>
          {{ detailData.createTime
          }}<span
            ><icon-svg
              style="font-size: 14px; margin-right: 8px"
              icon-class="xiazai_2"
            ></icon-svg
            >{{ detailData.download }}</span
          >
        </p>
        <div class="video_content">
          <el-carousel height="500px">
            <el-carousel-item
              v-for="(item, index) in detailData.resAnnexes"
              :key="index"
            >
              <!-- <h1 class="small">{{ item }}</h1> -->
              <img :src="item.url" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="user_box">
          <p>支持的软件：{{ detailData.canEngine }}</p>
          <p>支持的引擎版本：{{ detailData.canVerison }}</p>
          <div class="collectLike">
            <span v-if="like === false" @click="clickLike('like')"
              ><icon-svg icon-class="dianzan"></icon-svg>点赞({{
                likeCount
              }})</span
            >

            <span class="unlike" v-else @click="unclickLike('like')"
              ><icon-svg icon-class="dianzanshixin"></icon-svg>取消点赞({{
                likeCount
              }})</span
            >
            <el-button type="primary" @click="upluad()">下载</el-button>
          </div>
        </div>
      </div>
      <!-- <div class="video_list">
        <div class="list_title">
          <p>
            <icon-svg
              icon-class="ziyuan"
              style="margin-right: 6px; font-weight: 600"
            ></icon-svg
            >课件列表共（83）课
          </p>
        </div>
      </div> -->
    </div>
    <div class="message_box">
      <div class="title">
        <div class="introduce">
          资源介绍<br /><span class="introduce_span"></span>
        </div>
        <!-- <div class="material" @click="spanFlag = !spanFlag">
          课件素材<span
            v-show="spanFlag === false"
            class="material_span"
          ></span>
        </div> -->
      </div>
      <div
        class="introduce_content"
        v-show="spanFlag === true"
        v-html="rich"
      ></div>
      <!-- <div class="material_content" v-show="spanFlag === false">
        你猜这里是什么？哈哈哈哈哈哈哈~你猜错了这里是素材
      </div> -->
    </div>
    <el-dialog title="下载资源" :visible.sync="dialogUpload" center>
      <div class="lead_box">
        <div>
          <span><icon-svg icon-class="kaisuo"></icon-svg>提取密码</span>
          <el-tag>{{ detailData.map.contentupcode }}</el-tag>
          <div class="copy" @click="copy(detailData.map.contentupcode)">
            <icon-svg icon-class="fuzhi"></icon-svg>
          </div>
        </div>
        <div>
          <span><icon-svg icon-class="iconset0191"></icon-svg>解压密码</span>
          <el-tag>{{ detailData.map.contentzipcode }}</el-tag>
          <div class="copy1" @click="copy(detailData.map.contentzipcode)">
            <icon-svg icon-class="fuzhi"></icon-svg>
          </div>
        </div>
        <div>
          <a :href="'https://' + detailData.map.contenturl" target="_blank">
            <el-button type="primary" plaintype="primary" plain>
              <icon-svg icon-class="download"></icon-svg>
              下载</el-button
            >
          </a>
          <div class="copy11" @click="copy(detailData.map.contenturl)">
            <icon-svg icon-class="fuzhi"></icon-svg>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import iconSvg from '../icon/iconSvg.vue';
export default {
  components: { iconSvg },
  data() {
    return {
      spanFlag: true,
      dialogUpload: false,
      like: false,
      likeCount: null,
      detailId: null,
      rich: '',
      detailData: [],
    };
  },
  created() {
    this.detailId = this.$route.query.id;
    this.getDetail();
    this.getLikeStatus();
  },
  methods: {
    copy(item) {
      let txt = item;
      let oinput = document.createElement('input');
      oinput.value = '';
      oinput.value = txt;
      document.body.appendChild(oinput);
      oinput.select();
      // console.log(oinput.value);
      document.execCommand('Copy');
      this.$message({
        type: 'success',
        message: '复制成功',
      });
      oinput.remove();
    },
    upluad() {
      if (this.detailData.type === 0) {
        // window.open(this.detailData)
        this.$axios
          .post(`/teach/digital/download?digitalId=${this.detailId},'_blank'`)
          .then(() => {
            // console.log(res.data);
            window.open(this.detailData.map.contentob);
          });
      } else {
        this.dialogUpload = true;
      }
    },
    getDetail() {
      const that = this;
      this.$axios
        .get(`/teach/digital/findDigital?digitalId=${this.detailId}`)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.detailData = res.data.data;
            // console.log(this.detailData);
            that.$axios.get(res.data.data.map.text).then((res) => {
              // console.log(res);
              if (res.status === 200) {
                that.rich = res.data;
              }
            });
          }
        });
    },
    unclickLike(e) {
      this.$axios
        .post(
          `/teach/digital/Unlikedigital?digitalId=${this.$route.query.id}&status=${e}`
        )
        .then(() => {
          // console.log(res);
          this.getLikeStatus();
        });
    },
    clickLike(e) {
      this.$axios
        .post(
          `/teach/digital/likedigital?digitalId=${this.$route.query.id}&status=${e}`
        )
        .then(() => {
          // console.log(res);
          this.getLikeStatus();
        });
    },
    getLikeStatus() {
      const that = this;
      this.$axios
        .get(`/teach/digital/findlikedital?digital=${this.$route.query.id}`)
        .then((res) => {
          // console.log(res);
          this.likeCount = res.data.data.likecount;
          if (res.data.data.like === '0') {
            that.like = false;
          } else {
            that.like = true;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  min-height: 100vh;
  background: #f0f3f5;
  .content {
    width: 1076px;
    min-height: 716px;
    background: #f0f3f5;
    margin: 16px auto;
    .video_box {
      width: 1076px;
      height: 100%;
      background: #fff;
      float: left;
      border-radius: 4px;
      h2 {
        margin: 16px 0 0 16px;
      }
      p {
        text-indent: 16px;
        font-size: 14px;
        color: #333333;
        span {
          display: inline-block;
          margin-left: 16px;
          font-size: 14px;
          color: #333333;
        }
      }
      .video_content {
        width: 1044px;
        min-height: 500px;
        margin: 0 0 16px 16px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
      .user_box {
        width: 1044px;
        min-height: 82px;
        background: #f1f3f5;
        margin: 0 0 16px 16px;
        border-radius: 4px;
        .collectLike {
          float: right;
          margin-right: 16px;
          margin-top: -50px;
          span {
            margin-top: 20px;
            margin-right: 16px;
            font-size: 14px;
          }
          span:hover {
            cursor: pointer;
          }
          .unlike {
            color: #409eff;
          }
        }
        p {
          font-size: 16px;
          margin: 0;
          line-height: 41px;
        }
        p:nth-child(2) {
          width: 500px;
        }
        span {
          float: left;
          font-size: 14px;
        }
        .el-button {
          width: 140px;
        }
        // p:nth-child(2) {
        //   margin: 0;
        //   float: left;
        //   line-height: 82px;
        //   font-size: 18px;
        // }
        // p:nth-child(3) {
        //   float: right;
        //   margin: 0;
        //   line-height: 82px;
        //   span {
        //     color: #333;
        //     font-size: 14px;
        //     margin-right: 16px;
        //   }
        // }
      }
    }
    .video_list {
      width: 296px;
      height: 100%;
      background: #fff;
      float: left;
      margin-left: 16px;
      border-radius: 4px;
      .list_title {
        width: 100%;
        background: #fff;
        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid #ccc;
        p {
          margin: 0;
          padding: 16px 0 16px 16px;
          font-weight: 600;
          color: #409eff;
        }
      }
    }
  }
  .message_box {
    width: 1076px;
    min-height: 450px;
    background: #fff;
    border-radius: 4px;
    margin: 16px auto;
    .title {
      width: 1044px;
      height: 40px;
      background: #fff;
      border-radius: 4px 4px 0 0;
      border-bottom: 2px #cccccc solid;
      margin: 16px auto;
      div {
        float: left;
        line-height: 40px;
        font-size: 14px;
        span {
          display: inline-block;
          width: 28px;
          height: 2px;
          background: #409eff;
          position: relative;
          right: 42px;
          top: 17px;
        }
      }
      .introduce {
        margin: 0 16px;
        // float: left;
        height: 40px;
        span {
          top: -23px;
          right: -15px;
        }
      }
    }
    .introduce_content {
      font-size: 14px;
      margin: 16px 16px;
    }
    .material_content {
      font-size: 14px;
      margin: 16px 16px;
    }
  }
  .lead_box {
    width: 556px;
    min-height: 30px;
    margin: 0 auto;
    div {
      width: 100%;
      margin-bottom: 16px;
      span {
        margin-right: 10px;
        font-size: 14px;
      }
      .el-tag {
        color: #333333;
        width: 82%;
        height: 40px;
        line-height: 40px;
      }
      .el-button {
        width: 540px;
      }
      .copy,
      .copy1,
      .copy11 {
        width: 40px;
        height: 40px;
        font-size: 16px;
        text-align: center;
        line-height: 40px;
        position: absolute;
        border-radius: 0 4px 4px 0;
        color: #409eff;
      }
      .copy {
        top: 80px;
        left: 761px;
      }
      .copy1 {
        top: 135px;
        left: 761px;
      }
      .copy11 {
        top: 190px;
        left: 761px;
      }
      .copy:hover {
        background: #409eff;
        color: #fff;
      }
      .copy1:hover {
        background: #409eff;
        color: #fff;
      }
      .copy11:hover {
        background: #409eff;
        color: #fff;
      }
    }
  }
}
/deep/.el-carousel__arrow {
  color: #fff;
  background: none;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 24px;
  height: 24px;
  opacity: 0.6;
}
/deep/.el-carousel__arrow--left:hover {
  color: #fff;
  background: none;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 24px;
  height: 24px;
  opacity: 1 !important;
}
/deep/.el-carousel__arrow--right:hover {
  color: #fff;
  background: none;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 24px;
  height: 24px;
  opacity: 1 !important;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
