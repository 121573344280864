<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  // watch: {
  //   '$route.path': {
  //     handler(to, from) {
  //       console.log(this.$router, '1111');
  //       console.log(to, '--');
  //       console.log(from, '++');
  //     },
  //   },
  // },
};
</script>

<style lang="less"></style>
