<template>
  <div class="resource">
    <div class="resource_box">
      <nav>
        <router-link
          v-if="role === 'superadmin'"
          class="router_btn"
          to="/certificateList"
          >证书列表</router-link
        >
      </nav>
    </div>
    <el-container>
      <router-view></router-view>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: null,
    };
  },
  mounted() {
    this.role = sessionStorage.getItem('role');
  },
};
</script>

<style lang="less" scoped>
.resource {
  width: 100%;
  background: #f0f2f5;
}
.resource_box {
  width: 100%;
  height: 56px;
  background: #fff;
}
nav {
  width: 100%-2px;
  height: 56px;
  line-height: 56px;
  margin-left: 24px;
  .router_btn {
    display: inline-block;
    color: #333;
    width: 96px;
    height: 38px;
    border-radius: 4px;
    border: 1px solid #666666;
    text-align: center;
    line-height: 40px;
    margin: 0 8px;
  }
  .router-link-exact-active {
    background: #409eff;
    color: #fff;
    border: #409eff 1px solid;
  }
}

.el-container {
  background: #f0f2f5;
  padding: 16px 32px 16px 32px;
  // min-height: 100vh;
  border-radius: 4px;
}
.el-aside {
  background: #fff;
  // min-height: 100vh;
  margin: 16px;
}
</style>
