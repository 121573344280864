<template>
  <div class="coursewareBox">
    <ul>
      <li v-for="(item, index) in itemList" :key="index">
        <div class="list_btn" v-if="role === 'teacher' && flag === 0">
          <div class="hoverbox">
            <span @click="toDetail(item.id, menuId)"
              ><icon-svg icon-class="liulan"></icon-svg
            ></span>
            <span class="zhong" @click="alterDetail(item.id)"
              ><icon-svg icon-class="bianji_2"></icon-svg
            ></span>
            <span @click="delOne(item.id)"
              ><icon-svg icon-class="shanchu"></icon-svg
            ></span>
          </div>
        </div>
        <!-- <div class="list_btn" style="text-align: center" v-else>
          <div class="hoverbox">
            <span @click="toDetail(item.id, menuId)"
              ><icon-svg icon-class="liulan"></icon-svg
            ></span>
          </div>
        </div> -->
        <!-- <div class="list_btn">
          <div>
            <button @click="toDetail(item.id, menuId)">查看</button>
            <button @click="alterDetail(item.id)" v-if="role != 'student'">
              编辑
            </button>
            <button @click="delOne(item.id)" v-if="role != 'student'">
              删除
            </button>
            <button @click="cancel(item)" v-if="role == 'student'">
              取消收藏
            </button>
          </div>
        </div> -->

        <img @click="toDetail(item.id, menuId)" :src="item.map.cover" alt />
        <p class="contentName">{{ item.name }}</p>
        <span class="box_botter">
          <span>
            <icon-svg icon-class="dianzan" />
            {{ item.likes }}
          </span>
          <span>
            <icon-svg icon-class="bofang" />
            {{ item.visit }}
          </span>
        </span>
        <span class="time">{{ item.updateTime.substring(0, 10) }}</span>
      </li>
    </ul>
    <el-dialog title="课件管理" :visible.sync="dialogVisible" center>
      <p class="title_p">确定删除此作品？</p>
      <span class="title_span">删除后不可恢复</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del()">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import iconSvg from '../icon/iconSvg.vue';
export default {
  components: { iconSvg },
  data() {
    return {
      dialogVisible: false,
      clickItemId: '',
      role: '',
      flag: 0,
    };
  },
  created() {
    this.role = sessionStorage.getItem('role');
    // console.log(this.role);
    // console.log(this.titles);
  },
  watch: {
    titles(newdata) {
      console.log(newdata[0].name);
      // console.log(newdata);
      // console.log(olddata);
      if (newdata[0].name === '公共资源库') {
        this.flag = 1;
        console.log(newdata[0].name);
      } else {
        this.flag = 0;
      }
    },
    itemList() {
      // console.log(newList);
      // console.log(oldList);
    },
    menuId() {
      // console.log(newid);
      // console.log(oldid);
    },
    download() {
      // console.log(news);
      // console.log(old);
    },
    immediate: true,
  },
  props: {
    itemList: {
      type: Array,
    },
    menuId: {},
    download: {},
    titles: {
      type: Array,
    },
  },
  mounted() {
    this.role = sessionStorage.getItem('role');
    console.log(this.titles[0].name);
    if (this.titles[0].name === '公共资源库') {
      this.flag = 1;
    } else {
      this.flag = 0;
    }
  },
  methods: {
    clickitem() {
      // console.log(this.titles);
    },
    del() {
      const that = this;
      this.dialogVisible = false;
      this.$axios
        .delete(
          `/teach/courseware/delCourseWare?coursewareId=${this.clickItemId}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            let item = {
              id: this.menuId,
            };
            that.$axios
              .get(
                `/teach/courseware/findOneMenuWare?menuId=${
                  item.id
                }&page=${1}&rows=${10}`
              )
              .then((res) => {
                // console.log(res.data);
                if (res.data.code === 200) {
                  let data = {
                    itemlists: res.data.data.items,
                    total: res.data.data.total,
                    currentPage3: res.data.data.totalPage,
                    pageSize: res.data.data.pageSize,
                  };

                  that.$emit('sendList', data);
                }
              });
          }
        });
    },
    toDetail(id, menuId) {
      this.$router.push({
        path: '/resourcedetail',
        query: {
          id: id,
          menuId: menuId,
          tabNum: 1,
        },
      });
    },
    alterDetail(id) {
      this.$router.push({
        path: '/addcourseware',
        query: {
          id: id,
        },
      });
    },
    delOne(id) {
      this.dialogVisible = true;
      this.clickItemId = id;
    },
    /* 学生端 */
    cancel({ id }) {
      this.$confirm('是否取消该收藏?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const { data } = await this.$axios.post(
            `/teach/courseware/Unlikecourseware?coursewareId=${id}&status=${'favorite'}`
          );
          if (data.code !== 200) {
            return this.$message.error(data.message);
          }
          /* 触发父组件重新获取数据 */
          this.$emit('sendList');
          this.$message({
            type: 'success',
            message: '取消成功!',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '放弃取消',
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.coursewareBox {
  width: 100%;
  height: 100%;
  ul {
    display: flex;
    flex-wrap: wrap;

    // align-items: center;
    // justify-content: space-around;
    // background: red;
    // margin:
    li {
      width: 290px;
      height: 264px;
      background: #f1f3f5;
      position: relative;
      border-radius: 4px;
      margin: 1% 0.5%;
      .list_btn {
        display: none;

        // span
        div {
          width: 106px;
          margin: 0 auto;
          span {
            // display: inline;
            line-height: 40px;
            text-align: center;
          }
          .zhong {
            margin: 0 20px;
          }
        }
      }
      .hoverbox {
        span:hover {
          color: #409eff;
        }
      }
      .img_btn {
        width: 100%;
        height: 62.1%;
      }
      img {
        width: 100%;
        height: 62.1%;
        background: #cecece;
        border-radius: 4px 4px 0 0;
      }
      p {
        font-size: 14px;
        margin: 6px 16px;
      }
      .box_botter {
        display: inline-block;
        font-size: 12px;
        color: #666666;
        position: absolute;
        bottom: 12px;
        left: 16px;
        span {
          margin-right: 8px;
        }
        // span:nth-child(3) {
        //   position: absolute;
        //   right: -170px;
        // }
      }
      .time {
        display: inline-block;
        font-size: 12px;
        position: absolute;
        color: #666666;
        right: 16px;
        bottom: 14px;
      }
    }
    li:hover {
      cursor: pointer;
      .list_btn {
        display: block;
        width: 100%;
        height: 40px;
        background-color: rgba(22, 21, 21, 0.5);
        display: block;
        color: #fff;
        opacity: 1;
        position: absolute;
        border-radius: 4px;
      }
    }
  }
}
.title_p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.title_span {
  display: block;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  font-size: 12px;
}
/deep/.el-dialog__header {
  background: #409eff;
}
/deep/.el-dialog__title {
  color: #fff;
}
/deep/.el-dialog__close {
  color: #fff !important;
}
.contentName {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
