<template>
  <div class="box">
    <div class="sidemenu">
      <SideMenu
        :menuLists="menuList"
        :type="types"
        v-on:getIds="getMenuId"
        v-if="list"
      ></SideMenu>
    </div>
    <div class="examination">
      <div v-show="requestFlag">
        <div class="examination_title">
          <p>
            <span v-for="(item, index) in silderData" :key="index">{{
              index >= 1 ? ' / ' + item.name : item.name
            }}</span>
          </p>
          <div v-show="buttonFlag === true && addBtn">
            <div class="btn_box" v-show="role !== 'student'">
              <span type="primary" @click="dialogAdd = true">新建试卷</span>
              <span class="yellowBtn" @click="dialogVisible = true"
                >转化为公共资源</span
              >
            </div>
          </div>
        </div>
        <div class="examination_list">
          <ul v-if="itemData !== null && itemData.length > 0">
            <li v-for="(item, index) in itemData" :key="index">
              <img src="../../../assets/images/avatar2.jpg" alt="" />
              <p>
                {{ item.name }}<br /><span class="miaoshu">{{
                  item.introduction
                }}</span>
                <!-- <span
                class="fabu"
                >已发布</span
              ><span class="weifabu">未发布</span> -->
              </p>
              <div class="time">
                <span>考试时长：45分钟</span>
                <br />
                <span>创建时间：{{ item.createTime }}</span>
              </div>
              <div class="btn">
                <!-- <el-button
                style="padding: 10px 20px"
                @click="dialogOutward = true"
                >取消发布</el-button
              > -->
                <span class="info" @click="lookOne(item.id)">查看</span>
                <span
                  type="warning"
                  v-show="buttonFlag"
                  @click="(dialogDel = true), (itemId = item.id)"
                  >删除</span
                >
              </div>
            </li>
            <!-- <li>
            <img src="../../../assets/images/avatar2.jpg" alt="" />
            <p>
              这里可以有很多很多文字这里可以有很多很多文字这里可以有很多很多文字
            </p>
            <div class="time">
              <span>考试时长：45分钟</span>
              <br />
              <span>创建时间：2020/09/09 15:23:21</span>
            </div>
            <div class="btn"> -->
            <!-- <el-button style="padding: 10px 20px">取消发布</el-button> -->
            <!-- <el-button type="info" @click="dialogDetail = true"
                >查看</el-button
              >
              <el-button type="warning" @click="dialogDel = true"
                >删除</el-button
              >
            </div>
          </li> -->
          </ul>
          <el-empty v-else description="暂无内容"></el-empty>
        </div>
        <div class="block" v-show="addBtn">
          <el-pagination
            v-show="itemData.length > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage3"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <div v-if="itemData === []">11111</div> -->

    <el-dialog title="转化为公共资源" :visible.sync="dialogVisible" center>
      <p class="title_p">审核通过后，将出现在公共课件资源区</p>
      <span class="title_span">建议提交整套资源，这样审核更加容易通过哦！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addPublic">提 交</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新建试卷" :visible.sync="dialogAdd" center>
      <div class="add_box">
        <div>
          <span>组卷方式</span
          ><el-radio v-model="radios" label="1">自动组卷</el-radio>
        </div>
        <div>
          <p>
            <span>自动组卷:</span
            >将从精选题库选出20道单选题和10道判断题进行自动随机组卷
          </p>
        </div>
        <div>
          <span><em class="hongdian"></em>试卷名称</span
          ><el-input v-model="name" placeholder="请输入名称"></el-input>
        </div>
        <div>
          <span><em class="hongdian"></em>试卷描述</span
          ><el-input v-model="content" placeholder="请输入描述"></el-input>
        </div>
        <div>
          <p><span>考试时长:</span>45分钟</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addTextPaper()">提 交</el-button>
        <el-button @click="dialogAdd = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="lookData.name" :visible.sync="dialogDetail" center>
      <div
        class="detailBox"
        v-for="(item, index) in lookData.list"
        :key="index"
      >
        <div class="examinationDetail">
          <p class="issue">{{ index + 1 }}、{{ item.name }}</p>
          <p>
            <el-radio :value="item.answer.toUpperCase()" label="A"
              >A、{{ item.optionsA }}</el-radio
            >
          </p>
          <p>
            <el-radio :value="item.answer.toUpperCase()" label="B"
              >B、{{ item.optionsB }}</el-radio
            >
          </p>
          <p>
            <el-radio
              v-show="item.optionsC"
              :value="item.answer.toUpperCase()"
              label="C"
              >C、{{ item.optionsC }}</el-radio
            >
          </p>
          <p>
            <el-radio
              v-show="item.optionsD"
              :value="item.answer.toUpperCase()"
              label="D"
              >D、{{ item.optionsD }}</el-radio
            >
          </p>
        </div>
        <el-divider></el-divider>
      </div>
      <!-- <div class="detailBox">
        <div class="examinationDetail">
          <p class="issue">1.一形复叠在另一形上，产生前后关系叫（）</p>
          <p><el-radio v-model="radio" label="1">分离</el-radio></p>
          <p><el-radio v-model="radio" label="2">差叠</el-radio></p>
          <p><el-radio v-model="radio" label="3">透叠</el-radio></p>
          <p><el-radio v-model="radio" label="4">重叠</el-radio></p>
        </div>
        <el-divider></el-divider>
      </div> -->
    </el-dialog>
    <el-dialog title="删除试卷" :visible.sync="dialogDel" center>
      <p class="title_p">删除后不可恢复，确定删除此试卷吗？</p>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="delOne">提 交</el-button>
        <el-button @click="dialogDel = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- <el-dialog
      title="发布试卷"
      :visible.sync="dialogOutward"
      width="56%"
      center
    >
      <p class="title_p">确定发布试卷吗？</p>
      <p class="title_p">确定取消发布试卷吗？</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogOutward = false"
          >提 交</el-button
        >
        <el-button @click="dialogOutward = false">取 消</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import SideMenu from '../../sideMenus';

export default {
  components: {
    SideMenu,
  },
  data() {
    return {
      dialogVisible: false,
      dialogAdd: false,
      dialogDel: false,
      dialogDetail: false,
      dialogOutward: false,
      currentPage3: 1,
      pageCount: 10,
      radios: '1',
      total: 0,
      pageSize: 10,
      name: '',
      radio: '',
      content: '',
      list: false,
      menuList: [],
      itemData: [],
      lookData: [],
      itemId: '',
      silderData: [],
      buttonFlag: true,
      role: '',
      silderId: null,
      types: '',
      addBtn: false,
      requestFlag: false,
    };
  },
  created() {
    this.silderId = localStorage.getItem('silderId');
    this.role = sessionStorage.getItem('role');

    this.getList();
  },
  methods: {
    // 提交到公共资源库
    addPublic() {
      if (this.itemData.length < 1) {
        this.$message({
          type: 'error',
          message: '请最少上传一个课件',
        });
        return;
      }
      let data = {
        menuId: this.clickMenuId,
        rescourseType: '试卷资源',
      };
      this.$axios.post('/teach/courseware/toConversion', data).then((res) => {
        if (res.data.code === 200) {
          this.$message({
            type: 'success',
            message: res.data.message,
          });
          this.dialogVisible = false;
        } else {
          this.$message({
            type: 'error',
            message: res.data.message,
          });
        }
      });
    },
    lookOne(id) {
      this.lookData = [];
      this.dialogDetail = true;
      this.$axios.get(`/teach/exam/findOneExam?examId=${id}`).then((res) => {
        // console.log(res.data);
        if (res.data.code === 200) {
          this.lookData = res.data.data;
          // console.log(this.lookData);
        }
      });
    },
    // 删除试卷
    delOne() {
      // this.dialogDel = true
      this.$axios
        .delete(`/teach/exam/delOneExam?examId=${this.itemId}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功！',
            });
            this.dialogDel = false;
            this.getMenuId(this.clickItem);
          } else {
            this.$message({
              type: 'error',
              message: '删除失败！',
            });
          }
        });
    },
    // 添加试卷
    addTextPaper() {
      // console.log(this.radios);
      // console.log(this.name);
      // console.log(this.content);
      // console.log(this.clickMenuId);
      // this.dialogAdd = false
      if (this.name === '' || this.content === '' || this.radios === '') {
        this.$message({
          type: 'error',
          message: '请将内容补充完整',
        });
        return;
      }
      let data = {
        name: this.name,
        introduction: this.content,
        menuId: this.clickMenuId,
      };
      this.$axios.post('/teach/exam/saveExam', data).then((res) => {
        // console.log(res.data);
        if (res.data.code === 200) {
          this.$message({
            type: 'success',
            message: '添加成功！',
          });
          this.name = '';
          this.content = '';
          this.getMenuId(this.clickItem);
          this.dialogAdd = false;
        } else {
          this.$message({
            type: 'error',
            message: res.data.message,
          });
        }
      });
    },
    getMenuId(item) {
      // this.itemlists = []
      // console.log(item);
      this.clickItem = item;
      this.clickMenuId = item.id;
      // console.log(this.clickMenuId);
      if (item.level === 1) {
        this.silderData = [];
        this.itemData = [];
        this.silderData.push(item);
        if (this.silderData[0].name === '公共资源库') {
          this.buttonFlag = false;
        } else {
          this.buttonFlag = true;
        }
        if (this.role === 'student') {
          this.buttonFlag = false;
        }
        // console.log(this.silderData);
      } else if (
        item.level > this.silderData[this.silderData.length - 1].level
      ) {
        this.silderData.push(item);
        // console.log(this.silderData);
      } else if (
        item.level === this.silderData[this.silderData.length - 1].level
      ) {
        this.silderData.pop();
        this.silderData.push(item);
        // console.log(this.silderData);
      } else {
        this.silderData.pop();
        // console.log(this.silderData);
        if (this.silderData[this.silderData.length - 1].name !== item.name) {
          this.silderData.pop();
          this.silderData.push(item);
          // console.log(this.silderData);
        }
      }
      if (item.level >= 3) {
        this.addBtn = true;
        this.requestFlag = false;
        this.$axios
          .get(
            `/teach/exam/findMenuExam?menuId=${item.id}&page=${this.currentPage3}&rows=${this.pageCount}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.itemData = res.data.data.items;
              console.log(this.itemData);
              this.total = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              this.requestFlag = true;
            }
          });
      } else {
        this.addBtn = false;
      }
    },
    getList() {
      const that = this;
      this.types = '试卷资源';
      this.$axios
        .get(
          `/teach/courseware/findMenuWare?cerificateId=${this.silderId}&type=${this.types}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            if (that.role === 'admin') {
              that.menuList = [];
              that.menuList.push(res.data.data[0]);
            } else {
              that.menuList = res.data.data;
            }
            that.list = true;
          }
        });
    },
    handleSizeChange() {},
    handleCurrentChange(val) {
      this.currentPage3 = val;
      this.$axios
        .get(
          `/teach/exam/findMenuExam?menuId=${this.clickItem.id}&page=${this.currentPage3}&rows=${this.pageCount}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.itemData = res.data.data.items;
            // console.log(this.itemData);
            this.total = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  min-height: 100vh;
  display: flex;
}

.sidemenu {
  background: #fff;
  border-radius: 4px;
  min-width: 300px;
  min-height: 100vh;
}
.examination {
  width: 100%;
  // height: 100vh;
  background: #fff;
  border-radius: 4px;
  margin-left: 16px;
}
.examination_title {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    text-indent: 15px;
  }
  .btn_box {
    margin-right: 16px;
    span {
      display: inline-block;
      width: 140px;
      height: 40px;
      background: #409eff;
      border-radius: 4px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      margin-right: 16px;
    }
    span:hover {
      cursor: pointer;
    }
    // .info {
    //   background: #909399;
    // }
    .yellowBtn {
      background: #ffe040;
      color: #333333;
      border: none;
    }
  }
}
.examination_list {
  width: 100%;
  ul {
    width: calc(100% - 32px);
    // height: calc(100vh - 150px);
    margin: 0 16px;
    border-radius: 4px;
    li {
      width: 100%;
      height: 196px;
      background: #f1f3f5;
      border-radius: 4px;
      position: relative;
      margin: 8px 0;
      img {
        width: 290px;
        height: 164px;
        border-radius: 4px;
        float: left;
        margin: 16px;
      }
      p {
        margin: 0;
        padding: 0;
        height: 100px;
        position: relative;
        color: #333333;
        top: 16px;
        .miaoshu {
          color: #666666;
          font-size: 14px;
          line-height: 30px;
        }
        .fabu {
          display: inline-block;
          background: #409eff;
          padding: 1px 4px;
          border-radius: 4px;
          margin-left: 14px;
          color: #fff;
          font-size: 12px;
        }
        .weifabu {
          display: inline-block;
          background: none;
          padding: 0 3px;
          border-radius: 4px;
          margin-left: 14px;
          color: #f56c6c;
          font-size: 12px;
          border: 1px #f56c6c solid;
        }
      }
      .time {
        float: left;
        position: relative;
        bottom: -38px;
      }
      .btn {
        float: right;
        position: relative;
        right: 0px;
        top: 40px;
        span {
          display: inline-block;
          width: 96px;
          height: 40px;
          border-radius: 4px;
          background: #e6a23c;
          text-align: center;
          line-height: 40px;
          margin-right: 16px;
          color: #ffffff;
        }
        .info {
          background: #909399;
        }
        span:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.add_box {
  width: 552px;
  margin: 0 auto;
  .el-input {
    width: 468px;
  }
  span {
    display: inline-block;
    margin-right: 16px;
  }
  p {
    margin: 0;
  }
  div {
    margin: 7px 0;
  }
}
.detailBox {
  width: 100%;
}
.examinationDetail {
  width: 664px;
  margin: 0 auto;
  p {
    margin: 0;
  }
  .issue {
    font-size: 18px;
    color: #000000;
    margin-bottom: 16px;
  }
}
.title_p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.title_span {
  display: block;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  font-size: 12px;
}
em {
  display: inline-block;
  margin: 0px 8px 3px 0;
  width: 4px;
  height: 4px;
  background: #f56c6c;
  border-radius: 50%;
}
.block {
  margin-top: 32px;
  text-align: center;
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
