<template>
  <div class="commentNotice">
    <div class="tabbar">
      <span class="wei" :class="{ cur: tabbar === 0 }" @click="findData"
        >未读</span
      ><span class="yi" :class="{ cur: tabbar === 1 }" @click="findDatas"
        >已读</span
      >
    </div>
    <ul>
      <li v-for="(item, index) in listData" :key="index">
        <img
          :src="
            item.urlimg == 'null' ? '/img/avatar.4e4aa2c3.jpg' : item.urlimg
          "
          alt=""
        />
        <div class="nameTime">
          <div>
            <span> {{ item.username }} </span> 在课件 《<span>
              {{ item.courseName }} </span
            >》 回复我
          </div>
          <div>
            <span class="time">{{ item.createTime }}</span>
            <span class="look" @click="getItemData(item)">查看</span>
          </div>
        </div>
        <br />
        <div class="content">
          {{ item.content }}
        </div>
      </li>
    </ul>
    <div class="block" v-show="dataFlag">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current"
        :page-size="size"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <h2 v-show="!dataFlag">暂无内容</h2> -->
    <el-empty v-show="!dataFlag" description="暂无内容"></el-empty>

    <el-dialog title="查看评论" :visible.sync="lookMessage" center>
      <div class="message_content">
        <span>
          <img
            :src="
              clickItem.urlimg == 'null'
                ? '/img/avatar.4e4aa2c3.jpg'
                : clickItem.urlimg
            "
            alt=""
          />
          <span class="contentBox">
            <span> {{ clickItem.username }} </span> 在课件 《<span>
              {{ clickItem.courseName }} </span
            >》 回复我：
          </span>
          <span>
            {{ clickItem.content }}
          </span>
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goComment">去 回 复</el-button>
        <el-button @click="lookMessage = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: 1,
      size: 10,
      total: 0,
      tabbar: 0,
      listData: [],
      lookMessage: false,
      clickItem: {},
      dataFlag: false,
    };
  },
  created() {
    this.findData();
  },
  methods: {
    // 跳转
    goComment() {
      this.$router.push({
        path: '/resourcedetail',
        query: {
          id: this.clickItem.courseId,
          menuId: this.clickItem.menuId,
          tabNum: 5,
        },
      });
      this.clickItem.menuId;
      this.clickItem.pubId;
    },
    // 查看
    getItemData(item) {
      this.lookMessage = true;
      this.clickItem = item;
      if (this.tabbar == 1) {
        return;
      }
      this.$axios
        .post(`/teach/command/readCommand?commandId=${item.commandId}`)
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.getData();
          }
        });
    },
    // 切换已读未读
    findData() {
      this.tabbar = 0;
      this.getData();
    },
    findDatas() {
      this.tabbar = 1;
      this.getData();
    },
    // 获取评论数据
    getData() {
      this.listData = [];
      // let data = {
      //   current: this.current,
      //   size: this.size,
      //   type: this.tabbar,
      // };
      this.$axios
        .post(
          `/teach/command/commandList?current=${this.current}&size=${this.size}&type=${this.tabbar}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            if (res.data.data.total == 0) {
              this.dataFlag = false;
            } else {
              this.dataFlag = true;
              console.log(res.data.data);
              this.listData = res.data.data.items;
              this.total = res.data.data.total;
            }
          }
        });
    },
    handleSizeChange() {},
    handleCurrentChange(v) {
      this.current = v;
      this.getData();
    },
  },
};
</script>

<style lang="less" scoped>
.tabbar {
  text-align: center;
}
.wei {
  // border-left: 1px solid #666666;
  border: 1px solid #666666;
  padding: 8px 20px;
  border-radius: 4px 0 0 4px;
}
.yi {
  border: 1px solid #666666;
  padding: 8px 20px;
  border-radius: 0 4px 4px 0;
}
.wei {
  border-radius: 4px 0 0 4px;
}
.yi {
  border-radius: 0 4px 4px 0;
  // border-left: none;
}
.cur {
  background: #409eff;
  color: #ffffff;
  border: #409eff 1px solid;
}
h2 {
  text-align: center;
  line-height: 300px;
}
ul {
  width: 100%;
  li {
    width: 96%;
    min-height: 80px;
    margin: 10px 2%;
    border-bottom: 2px #cccccc solid;
    overflow: hidden;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      float: left;
      margin-right: 20px;
    }
    .nameTime {
      width: 92%;
      // float: left;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .content {
      width: 95%;
      // float: right;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 80px;
    }
    .look {
      margin-left: 16px;
      cursor: pointer;
      color: #409eff;
    }
    // .look:hover {
    //   cursor: pointer;
    // }
  }
}
.block {
  text-align: center;
  bottom: 16px;
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
// 对话框
.message_content {
  // width: 50%;
  margin: 0 auto;
  .contentBox {
    margin-top: -10px;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-top: 10px;
  }
}
</style>
