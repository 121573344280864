import { render, staticRenderFns } from "./courseware.vue?vue&type=template&id=40f1c323&scoped=true&"
import script from "./courseware.vue?vue&type=script&lang=js&"
export * from "./courseware.vue?vue&type=script&lang=js&"
import style0 from "./courseware.vue?vue&type=style&index=0&id=40f1c323&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f1c323",
  null
  
)

export default component.exports