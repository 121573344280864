<template>
  <div class="box">
    <div class="topic">
      <div class="topic_title">
        <p class="topic_class" v-show="menuFlag === false">
          题目类型:
          <span
            @click="(btnFlag = !btnFlag), typeHandle()"
            :class="{ cur: btnFlag === true }"
            >单选题</span
          >
          <span
            @click="(btnFlag = !btnFlag), typeHandle()"
            :class="{ cur: btnFlag === false }"
            >判断题</span
          >
        </p>
      </div>
      <div class="form_table">
        <el-table
          :data="tableDatas"
          v-if="menuFlag === true"
          stripe
          style="width: 100%"
          :header-cell-style="{ color: '#333333' }"
        >
          <el-table-column prop="date" label="序号" width="86">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="试题名称"
            width="191"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="required"
            label="实操题要求"
            width="508"
            show-overflow-tooltip
          >
          </el-table-column>
          <!-- <el-table-column prop="type" label="题型" width="78">
          </el-table-column>
          <el-table-column prop="grade" label="分值" width="78">
          </el-table-column>
          <el-table-column prop="people" label="创建人" width="78">
          </el-table-column> -->
          <el-table-column prop="createTime" label="发布时间" width="186">
          </el-table-column>
          <el-table-column prop="time" label="附件下载" width="256">
            <template slot-scope="scope">
              <el-link
                type="primary"
                :href="scope.row.quesionurl"
                @click="onLoad(scope.row)"
                ><icon-svg icon-class="download"></icon-svg>下载</el-link
              >
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" width="283">
            <template slot-scope="scope">
              <el-button size="small" type="info" @click="previews(scope.row)"
                >预览</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-table
          :data="tableData"
          v-else
          stripe
          style="width: 100%"
          :header-cell-style="{ color: '#333333' }"
        >
          <el-table-column label="序号" width="126">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="试题名称" width="691">
          </el-table-column>
          <!-- <el-table-column prop="address" label="实操题要求" width="98">
          </el-table-column> -->
          <el-table-column v-if="menuFlag === false" label="题型" width="168">
            <template slot-scope="scope">
              {{ scope.row.type === 'choice' ? '单选题' : '判断题' }}
            </template>
          </el-table-column>
          <el-table-column prop="fraction" label="分值" width="118">
          </el-table-column>
          <!-- <el-table-column prop="people" label="创建人" width="78">
          </el-table-column> -->
          <el-table-column prop="updateTime" label="发布时间" width="336">
          </el-table-column>
          <!-- <el-table-column prop="time" label="附件下载" width="156">
            <template slot-scope="scope"> 
              <el-link type="primary" @click="onLoad(scope.row)"
                ><icon-svg icon-class="download"></icon-svg>下载</el-link
              >
            </template>
          </el-table-column>-->
          <el-table-column prop="address" label="操作" width="383">
            <template slot-scope="scope">
              <el-button size="small" type="info" @click="previews(scope.row)"
                >预览</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage3"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 预览某一项 -->
    <el-dialog
      :title="menuFlag === false ? '题目详情' : previewData.name"
      :visible.sync="preview"
      class="preview"
      center
    >
      <div class="topicContent" v-if="menuFlag === false">
        <div class="header">
          <span class="spans"
            >题目类型：{{
              previewData.type === 'choice' ? '单选题' : '判断题'
            }}</span
          >
          <span class="spans">分值：{{ previewData.fraction }}</span>
          <el-button
            @click="showAnswer = !showAnswer"
            :type="showAnswer === false ? 'primary' : 'null'"
            >{{ showAnswer === false ? '显示答案' : '隐藏答案' }}</el-button
          >
          <span class="daan" v-show="showAnswer"
            >正确答案：{{ previewData.answer }}</span
          >
        </div>
        <div class="centers">
          <p>题目：{{ previewData.name }}</p>
          <ul>
            <li>A：{{ previewData.optionsA }}</li>
            <li>B：{{ previewData.optionsB }}</li>
            <li v-show="previewData.type !== 'judge'">
              C：{{ previewData.optionsC }}
            </li>
            <li v-show="previewData.type !== 'judge'">
              D：{{ previewData.optionsD }}
            </li>
          </ul>
          <span v-show="showAnswer">解析：{{ previewData.parsing }}</span>
          <br />
          <button @click="switchoverTopic(previewData)">上一题</button>
          <button @click="switchoverTopics(previewData)">下一题</button>
        </div>
      </div>
      <div class="previewPractical" v-else>
        <div class="content">{{ previewData.required }}</div>
        <a :href="previewData.quesionurl">
          <el-button type="primary">
            <icon-svg icon-class="download"></icon-svg>下载
          </el-button>
        </a>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage3: 1,
      pageCount: 10,
      total: 0,
      pageSize: 10,
      btnFlag: true,
      radio: '',
      uploadType: '',
      delTabItem: false,
      preview: false,
      clickMenuId: '',
      addFrom: {
        name: '',
        A: '',
        B: '',
        C: '',
        D: '',
        analysis: '',
      },
      addPractical: {
        name: '',
        demand: '',
      },
      dialogVisible: false,
      dialogLead: false,
      dialogUpload: false,
      dialogAddlilun: false,
      dialogAdd: false,
      dialogAddshicao: false,
      menuFlag: true,
      alterTopic: false,
      showAnswer: false,
      topicType: 'choice',
      num: 1,
      upload: '',
      input: '',
      // 上传理论题文件
      file: '',
      files: { file: '' },
      // 上传实操题文件
      practicals: {
        file: '',
        status: '',
      },
      practical: '',
      labels: 'A',
      list: false,
      clickItemId: '',
      clickItem: {},
      previewData: {},
      tableData: [],
      tableDatas: [],
      types: '',
      silderData: [],
      allFlag: false,
      menuId: null,
    };
  },
  created() {
    this.menuId = this.$route.query.menuId;
    this.type = this.$route.query.type;
    if (this.type === 'pricate') {
      this.menuFlag = true;
      this.getPracticalTopicList();
    } else {
      this.menuFlag = false;
      this.getTopicList();
    }
  },
  methods: {
    // 上一题、下一题
    switchoverTopic(data) {
      if (data.status !== '0') {
        this.$axios
          .get(
            `/teach/exam/findlastExam?questionId=${
              data.id
            }&status=${0}&menuId=${this.menuId}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.previewData = res.data.data;
            }
          });
      } else {
        this.$message({
          type: 'error',
          message: '已经是第一题',
        });
      }
    },
    switchoverTopics(data) {
      if (data.status !== '1') {
        this.$axios
          .get(
            `/teach/exam/findlastExam?questionId=${
              data.id
            }&status=${1}&menuId=${this.menuId}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.previewData = res.data.data;
            }
          });
      } else {
        this.$message({
          type: 'error',
          message: '已经是最后一题',
        });
      }
    },
    // 获取理论题目列表
    getTopicList() {
      this.$axios
        .get(
          `/teach/exam/findMenuLIQuestion?menuId=${this.menuId}&type=${
            this.btnFlag === false ? 'judge' : 'choice'
          }&page=${this.currentPage3}&rows=${this.pageSize}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.total = res.data.data.total;
            this.tableData = res.data.data.items;
          }
        });
    },
    // 获取实操题目列表
    getPracticalTopicList() {
      const that = this;
      this.$axios
        .get(
          `/teach/exam/findMenuLIQuestion?menuId=${this.menuId}&type=${this.type}&page=${this.currentPage3}&rows=${this.pageSize}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            that.total = res.data.data.total;
            that.tableDatas = res.data.data.items;
          }
        });
    },
    // 根据不同的状态切换不同的题型
    typeHandle() {
      if (this.btnFlag === true) {
        this.topicType = 'choice';
        // console.log(this.topicType);
        this.addFrom.A = '';
        this.addFrom.B = '';
        this.getTopicList();
      } else {
        this.topicType = 'judge';
        this.addFrom.A = '正确';
        this.addFrom.B = '错误';
        // console.log(this.topicType);
        this.getTopicList();
      }
    },
    // 获取到侧边栏id，并根据name来切换状态
    // getMenuId(item) {
    //   // this.itemlists = []
    //   console.log(item);
    //   this.clickItem = item;
    //   this.clickMenuId = item.id;
    //   console.log(this.clickMenuId);
    //   if (item.parentname === '理论题库') {
    //     this.menuFlag = false;
    //     this.getTopicList();
    //   } else if (item.parentname === '实操题库') {
    //     this.menuFlag = true;
    //     this.getPracticalTopicList();
    //   }
    //   console.log(this.menuFlag);
    //   if (item.level < 3) {
    //     this.allFlag = false;
    //   } else {
    //     this.allFlag = true;
    //   }
    //   if (item.level === 1) {
    //     this.silderData = [];
    //     this.silderData.push(item);
    //     console.log(this.silderData);
    //   } else if (
    //     item.level > this.silderData[this.silderData.length - 1].level
    //   ) {
    //     this.silderData.push(item);
    //     console.log(this.silderData);
    //   } else if (
    //     item.level === this.silderData[this.silderData.length - 1].level
    //   ) {
    //     this.silderData.pop();
    //     this.silderData.push(item);
    //     console.log(this.silderData);
    //   } else {
    //     this.silderData.pop();
    //     console.log(this.silderData);
    //     if (this.silderData[this.silderData.length - 1].name !== item.name) {
    //       this.silderData.pop();
    //       this.silderData.push(item);
    //       console.log(this.silderData);
    //     }
    //   }
    //   // if (item.level >= 3) {
    //   //   this.$axios
    //   //     .get(
    //   //       `/teach/courseware/findOneMenuWare?menuId=${item.id}&page=${this.currentPage3}&rows=${this.pageCount}`
    //   //     )
    //   //     .then((res) => {
    //   //       console.log(res.data);
    //   //       if (res.data.code === 200) {
    //   //         this.itemlists = res.data.data.items;
    //   //         this.total = res.data.data.total;
    //   //         this.pageSize = res.data.data.pageSize;
    //   //       }
    //   //     });
    //   // }
    // },
    //查看
    previews(item) {
      this.previewData = {};
      this.showAnswer = false;
      // console.log(item);
      this.clickItem = item;
      this.preview = true;
      this.previewData = item;
    },
    handlePictureCardPreviews(file) {
      this.aa = file;
    },
    handleRemoves() {
      // console.log(file, fileList);
    },
    handleChansges(file) {
      this.practicals.file = file;
      this.practical = file.raw;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // console.log(this.dialogImageUrl)
      // console.log(file.url);
    },
    handleRemove() {
      // console.log(file, fileList);
    },
    handleChansge(file) {
      // console.log(file);
      this.files.file = file;
      this.file = file.raw;
    },
    // 关闭新增对话框的时候
    closeDialog() {
      this.alterTopic = false;
    },
    handleChange() {
      // console.log(value);
    },
    // 页码大小变动的时候触发
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.currentPage3 = 1;
      this.pageSize = val;
    },
    // 当前页变动的时候触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.getTopicList(val);
      this.currentPage3 = val;
      // this.getMenuId(this.clickItem);
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  min-height: 100vh;
  display: flex;
}
.sidemenu {
  background: #fff;
  border-radius: 4px;
  width: 300px;
  min-height: 100vh;
}
.topic {
  width: 100%;
  height: 100vh;
  background: #fff;
  border-radius: 4px;
  margin-left: 16px;
}
.topic_title {
  width: 100%;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  p {
    text-indent: 15px;
  }
  .btn_box {
    margin-right: 16px;
    .yellowBtn {
      background: #ffe040;
      color: #333;
      border: none;
    }
  }
  .topic_class {
    text-align: center;
    span {
      display: inline-block;
      border: 1px solid #999999;
      color: #000;
      text-indent: 0;
      user-select: none;
      padding: 8px 20px;
    }
    span:nth-child(1) {
      border-radius: 4px 0 0 4px;
    }
    span:nth-child(2) {
      border-radius: 0 4px 4px 0;
      border-left: none;
    }
    .cur {
      background: #409eff;
      color: #ffffff;
      border: #409eff 1px solid;
    }
  }
}
.preview_p {
  width: 664px;
  margin: 0 auto;
}
.form_table {
  width: 100%;
  // margin-left: 8px;
}
.lead_box {
  text-align: center;
  span {
    margin-right: 10px;
  }
}
.lead_box_input {
  width: 552px;
  margin: 0 auto;
  div {
    margin: 8px 0;
    .upload-demo {
      width: 468px;
      height: 225px;
      position: relative;
      top: -40px;
      left: 95px;
    }
    /deep/.el-upload-dragger {
      width: 438px;
      height: 225px;
    }
  }
  span {
    width: 100px;
    display: inline-block;
    margin-right: 10px;
  }
  .jeixi {
    // margin-left: 1px;
  }
}
.upload-demo {
  text-align: center;
  margin: 8px 0;
}
// /deep/.el-upload-dragger {
//   width: 300px !important;
//   height: 255px !important;
//   margin: 0 auto;
// }
.title_p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.title_span {
  display: block;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  font-size: 12px;
}
.el-input {
  width: 438px;
  margin-top: 16px;
}
.shangchaun {
  color: #409eff;
  margin: 80px 0 20px 0;
  font-size: 44px;
}

.block {
  margin-top: 32px;
  text-align: center;
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
.clickXiazai {
  margin: 0 auto;
  width: 300px;
  height: 225px;
  background: #f1f3f5;
  border-radius: 4px;
  .icons {
    color: #409eff;
    font-size: 44px;
    position: relative;
    left: 42%;
    top: 30%;
  }
  p {
    position: relative;
    top: 30%;
    left: 25%;
  }
}

.preview {
  /deep/.el-dialog__body {
    margin: 0;
    padding: 0;
    .topicContent {
      width: 100%;
      min-height: 300px;
      margin: 0;
      padding: 0;
      .header {
        width: 100%;
        height: 72px;
        background: #f1f3f5;
        .spans {
          display: inline-block;
          font-size: 14px;
          line-height: 72px;
          margin: 0 16px;
        }
        .el-button {
          width: 100px;
        }
        .daan {
          margin-left: 16px;
          color: #f56c6c;
        }
      }
      .centers {
        p {
          font-size: 18px;
          margin-left: 16px;
        }
        ul {
          margin-left: 16px;
          li {
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
        span {
          display: inline-block;
          margin-left: 16px;
          margin-bottom: 32px;
        }
        button {
          display: inline-block;
          width: 140px;
          height: 40px;
          border: 1px solid #c6e2ff;
          border-radius: 4px;
          margin: 0 0 16px 16px;
          background: #ecf5ff;
          text-align: center;
          color: #409eff;
        }
      }
    }
    .previewPractical {
      width: 664px;
      margin: 32px auto;
      .el-button {
        margin-top: 32px;
        margin-bottom: 32px;
        margin-left: 43%;
      }
    }
  }
}
</style>
