<template>
  <div class="errorCollection">
    <div class="header">
      <icon-svg icon-class="cuoti" class="icon"></icon-svg>
      <h2>错题集</h2>
    </div>
    <el-empty
      description="你太厉害了，没有错题"
      v-if="tableData.length == 0"
    ></el-empty>
    <el-table :data="tableData" style="width: 100%" v-else>
      <el-table-column
        type="index"
        label="序号"
        width="50px"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="examName"
        label="模拟试卷名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="试题名称"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="type" label="题型" width="80px" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.type == 'choice'">选择题</div>
          <div v-if="scope.row.type == 'judge'">判断</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="fraction"
        label="分值"
        align="center"
        width="50px"
      ></el-table-column>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <div>
            <el-button
              type="info"
              size="mini"
              @click="openErrorDialog(scope.row)"
              >查看</el-button
            >
            <el-button type="warning" size="mini" @click="master(scope.row)"
              >我已掌握</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="zksc-pagination"
      layout="prev, pager, next, jumper"
      :total="totalPage"
      :page-size="pageSize"
      @current-change="handleChangePage"
    ></el-pagination>

    <!-- 查看 -->
    <el-dialog
      :title="menuFlag === false ? '题目详情' : previewData.name"
      :visible.sync="preview"
      class="preview"
      center
    >
      <div class="topicContent" v-if="menuFlag === false">
        <div class="header">
          <span>所属试卷: {{ previewData.examName }}</span>
          <span class="spans">
            题目类型：{{ previewData.type === 'choice' ? '单选题' : '判断题' }}
          </span>
          <span class="spans">分值：{{ previewData.fraction }}</span>
          <el-button
            @click="showAnswer = !showAnswer"
            :type="showAnswer === false ? 'primary' : 'null'"
            >{{ showAnswer === false ? '显示答案' : '隐藏答案' }}</el-button
          >
          <span class="daan" v-show="showAnswer"
            >正确答案：{{ previewData.answer }}</span
          >
        </div>
        <div class="centers">
          <p>题目：{{ previewData.name }}</p>
          <ul>
            <li>A：{{ previewData.optionsA }}</li>
            <li>B：{{ previewData.optionsB }}</li>
            <li v-show="previewData.type !== 'judge'">
              C：{{ previewData.optionsC }}
            </li>
            <li v-show="previewData.type !== 'judge'">
              D：{{ previewData.optionsD }}
            </li>
          </ul>
          <span>解析：{{ previewData.parsing }}</span>
          <br />
          <button
            @click="switchoverTopic(previewData, 0)"
            v-if="previewData.status != '0'"
          >
            上一题
          </button>
          <button
            @click="switchoverTopic(previewData, 1)"
            v-if="previewData.status != '1'"
          >
            下一题
          </button>
        </div>
      </div>
      <div class="previewPractical" v-else>
        <div class="content">{{ previewData.required }}</div>
        <a :href="previewData.quesionurl">
          <el-button type="primary">
            <icon-svg icon-class="download"></icon-svg>下载
          </el-button>
        </a>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      totalPage: 0,
      pageSize: 8,
      current: 1,
      /* 查看题目 */
      preview: false,
      menuFlag: false,
      showAnswer: false,
      previewData: {},
    };
  },
  mounted() {
    this.getError();
    // console.log(this.$route.query);
  },
  methods: {
    handleChangePage(current) {
      this.current = current;
      this.getError();
    },
    async getError() {
      this.tableData = [];
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const { data } = await this.$axios.get('/teach/student/findHisquestion', {
        params: {
          page: this.current,
          rows: this.pageSize,
        },
      });
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 100);
        return this.$message.error(data.message);
      }
      if (data.data.items && data.data.items.length == 0) {
        setTimeout(() => {
          loading.close();
        }, 100);
        return this.$message.warning('暂时没有错题');
      }
      this.tableData = data.data.items;
      this.totalPage = data.data.total;
      setTimeout(() => {
        loading.close();
      }, 100);
    },
    async openErrorDialog({ examId, questionId }) {
      this.preview = true;
      const { data } = await this.$axios.get(
        '/teach/student/findThhisQuestion',
        {
          params: {
            questionId,
            examId,
          },
        }
      );
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.previewData = data.data;
      // console.log('this.previewData: ', this.previewData);
    },
    /* 上一题 下一题 */
    async switchoverTopic(row, status) {
      // console.log(questionId,examId);
      let query = {
        params: {
          questionId: row.id,
          status,
          examId: this.examId,
        },
      };
      if (status) {
        query.params.examId = row.afterExamId;
      } else {
        query.params.examId = row.lastExamId;
      }
      const { data } = await this.$axios.get(
        '/teach/student/findLastQuestion',
        query
      );
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.previewData = data.data;
      // console.log(data);
    },
    /* 掌握 */
    master({ questionId }) {
      this.$confirm('确定掌握这一题了么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const { data } = await this.$axios.post(
            `/teach/student/delHisQuestion?questionId=${questionId}`
          );
          if (data.code !== 200) {
            return this.$message.error(data.message);
          }
          this.$message({
            type: 'success',
            message: data.message,
          });
          this.getError();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消掌握',
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.errorCollection {
  margin: 16px auto;
  padding-bottom: 16px;
  width: 1080px;
  // min-height: (100vh - 30px - 56px - 56px);
  border-radius: 4px;
  background: #ffffff;
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #cccccc;
    .icon {
      margin-right: 8px;
      margin-top: 4px;
      color: #409eff;
    }
    h2 {
      font-size: 24px;
      color: #333333;
    }
  }
}
.el-pagination {
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
.preview {
  /deep/.el-dialog__body {
    margin: 0;
    padding: 0;
    .topicContent {
      width: 100%;
      min-height: 300px;
      margin: 0;
      padding: 0;
      .header {
        width: 100%;
        height: 72px;
        background: #f1f3f5;
        .spans {
          display: inline-block;
          font-size: 14px;
          line-height: 72px;
          margin: 0 16px;
        }
        .el-button {
          width: 100px;
        }
        .daan {
          margin-left: 16px;
          color: #f56c6c;
        }
      }
      .centers {
        p {
          font-size: 18px;
          margin-left: 16px;
        }
        ul {
          margin-left: 16px;
          li {
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
        span {
          display: inline-block;
          margin-left: 16px;
          margin-bottom: 32px;
        }
        button {
          display: inline-block;
          width: 140px;
          height: 40px;
          border: 1px solid #c6e2ff;
          border-radius: 4px;
          margin: 0 0 16px 16px;
          background: #ecf5ff;
          text-align: center;
          color: #409eff;
        }
      }
    }
    .previewPractical {
      width: 664px;
      margin: 32px auto;
      .el-button {
        margin-top: 32px;
        margin-bottom: 32px;
        margin-left: 43%;
      }
    }
  }
}
</style>
