import Vue from 'vue';
import App from './App.vue';
/* 全局路由 */
// import router from './router';
/* 动态路由 */
import router from './router/aysncRouter';
import store from './store';
import ElementUI from 'element-ui';
import axios from 'axios';
import * as echarts from 'echarts';
import { Message } from './utils/message.js';
//引入新浪的表情包
import VueSinaEmotion from 'vue-sina-emotion';

// import api from './api/axiosconfig/index';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css';
import './assets/css/loading.css';

import iconSvg from './components/icon/iconSvg.vue';

Vue.config.productionTip = false;
Vue.use(ElementUI);
//需要挂载到Vue原型上
Vue.prototype.$echarts = echarts;
// Vue.prototype.$api = api;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.config.productionTip = false;
axios.defaults.withCredentials = false;
axios.defaults.baseURL = process.env.VUE_APP_API;
Vue.component('icon-svg', iconSvg);
Vue.component('vue-sina-emotion', VueSinaEmotion);

// 全局请求处理
axios.interceptors.request.use(
  (config) => {
    // 每次请求 在请求头里面加入token
    config.headers.Authentication = window.localStorage.getItem('token');
    return config;
  },
  (erroror) => {
    return Promise.reject(erroror);
  }
);

if (localStorage.getItem('silderId') && localStorage.getItem('token')) {
  axios.post('/teach/common/AutoLogin').then((res) => {
    // console.log(res.data);
    if (res.data.data === 1) {
      Message.success({
        message: '登录失效，请重新登录',
        type: 'error',
      });
      // sessionStorage.setItem('role', res.data.data.role);
      // sessionStorage.setItem('role', res.data.data.role);
      // if (res.data.data.role == 'teacher' || res.data.data.role == 'admin') {
      // let query = router.currentRoute.query;
      // console.log(query);
      // router.push(query);
      // } else if (res.data.data.role == 'student') {
      //   this.$router.push('/mySpace');
      // }
    } else if (res.data.data === 0) {
      Message.success({
        message: ' 该证书已被删除，您的账号已无效',
        type: 'error',
      });
    } else {
      sessionStorage.setItem('role', res.data.data.role);
      sessionStorage.setItem('id', res.data.data.id);
      sessionStorage.setItem('name', res.data.data.realname);
      store.dispatch('changeRoles', res.data.data.role);
    }
  });
} else {
  router.push('/login');
}

// 全局响应处理
axios.interceptors.response.use(
  (response) => {
    // 捕获全局的状态码 登录失效
    // console.log(response.data);
    // if (response.data.code === 30010) {
    //   Message.success({
    //     message: '登录失效，请重新登录',
    //     type: 'warning',
    //   });
    //   window.localStorage.removeItem('token');
    //   // 重定向到登录页面
    //   if (router.currentRoute.path !== '/superlogin') {
    //     router.push('/login');
    //   }
    // } else if (response.data.code === 403) {
    //   Message.success({
    //     message: '您没有权限查看该页面',
    //     type: 'warning',
    //   });
    //   // 重定向到首页
    //   if (router.currentRoute.path !== '/index') {
    //     router.push('/index');
    //   }
    // } else if (response.data.code === 500) {
    //   Message.success({
    //     message: '服务器正忙，请稍后再试',
    //     type: 'warning',
    //   });
    // }
    return response;
  },
  (error) => {
    // ==============  错误处理  ====================
    if (error && error.response) {
      // console.log(error.response.data.code, 'error错误？');

      if (error.response.data.code === 30010) {
        Message.success({
          message: '登录失效，请重新登录',
          type: 'error',
        });
        localStorage.removeItem('token');
        // if (sessionStorage.role === 'superadmin') {
        //   if (router.currentRoute.path !== '/superlogin') {
        //     router.push('/superlogin');
        //   }
        // } else {
        if (router.currentRoute.path !== '/login') {
          router.push('/login');
        }
        // }
      }
      // switch (error.response.status) {
      //   case 400:
      //     error.message = '请求错误';
      //     break;
      //   case 401:
      //     error.message = '登录失效请重新登录';
      //     window.localStorage.setItem('token', null);
      //     // 重定向到登录页面
      //     if (router.currentRoute.path !== '/login') {
      //       router.push('/login');
      //     }
      //     break;
      //   case 403:
      //     error.message = '您没有权限查看该页面';
      //     if (router.currentRoute.path !== '/index') {
      //       router.push('/index');
      //     }
      //     break;
      //   case 404:
      //     error.message = '请求出错(404)';
      //     break;
      //   case 408:
      //     error.message = '请求超时(408)';
      //     break;
      //   case 500:
      //     error.message = '服务器错误,请稍后再试';
      //     break;
      //   case 501:
      //     error.message = '服务未实现(501)';
      //     break;
      //   case 502:
      //     error.message = '网络错误(502)';
      //     break;
      //   case 503:
      //     error.message = '服务不可用(503)';
      //     break;
      //   case 504:
      //     error.message = '网络超时(504)';
      //     break;
      //   case 505:
      //     error.message = 'HTTP版本不受支持(505)';
      //     break;
      //   default:
      //     error.message = `连接出错(${error.response.status})!`;
      // }
    } else {
      error.message = '连接服务器失败!';
    }
    // Message.success({ message: error.message, type: 'error' });
    // return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
