<template>
  <div class="home">
    <div class="line">数字创意教辅平台</div>
    <el-menu
      :default-active="activeIndex2"
      class="el-menu-demo my-menu"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item
        index="0"
        @click="alterItem('0')"
        class="zksc-menu-item"
        v-if="role === 'superadmin'"
      >
        <router-link to="/certificateList">
          <ul>
            <icon-svg icon-class="ziyuan"></icon-svg>
            <li>证书管理</li>
          </ul>
        </router-link>
      </el-menu-item>
      <el-menu-item
        @click="alterItem('1')"
        index="1"
        class="zksc-menu-item"
        v-if="role == 'student'"
      >
        <router-link to="/mySpace">
          <ul>
            <icon-svg icon-class="a-Group380"></icon-svg>
            <li>我的空间</li>
          </ul>
        </router-link>
      </el-menu-item>
      <el-menu-item
        index="2"
        @click="alterItem('2')"
        class="zksc-menu-item"
        v-if="role !== 'superadmin'"
      >
        <router-link to="/courseware">
          <ul>
            <icon-svg icon-class="ziyuan185"></icon-svg>
            <li>资源管理</li>
          </ul>
        </router-link>
      </el-menu-item>
      <el-menu-item
        index="3"
        @click="alterItem('3')"
        class="zksc-menu-item"
        v-if="role == 'teacher' && role != 'student' && role != 'superadmin'"
      >
        <router-link to="/course">
          <ul>
            <icon-svg icon-class="kechengguanli"></icon-svg>
            <li>课程管理</li>
          </ul>
        </router-link>
      </el-menu-item>
      <el-menu-item
        index="4"
        @click="alterItem('4')"
        class="zksc-menu-item"
        v-if="role !== 'superadmin'"
      >
        <router-link to="/member">
          <ul>
            <icon-svg icon-class="banjiguanli"></icon-svg>
            <li>
              班级管理
              <em
                v-show="
                  parseInt($store.state.messageNum) > 0 ||
                  parseInt($store.state.noticeNum) > 0 ||
                  parseInt($store.state.workNum) > 0 ||
                  parseInt($store.state.examNum) > 0
                "
                class="hongdian"
                >{{
                  parseInt($store.state.messageNum) +
                  parseInt($store.state.noticeNum) +
                  parseInt($store.state.workNum) +
                  parseInt($store.state.examNum)
                }}</em
              >
            </li>
          </ul>
        </router-link>
      </el-menu-item>
      <!-- <el-menu-item index="4" class="zksc-menu-item">
        <router-link to="/academy">
          <ul>
            <icon-svg icon-class="xuexiao"></icon-svg>
            <li>院校管理</li>
          </ul>
        </router-link>
      </el-menu-item>-->
      <div class="user_img">
        <!-- <img src="" alt="" />
        <span>你好！李焕英</span>
        <span
          ><icon-svg style="font-size: 10px" icon-class="jiantou1"></icon-svg
          教师端bug：上传课件资源换为网盘资源的时候无法正确显示网盘资源，编辑新增之后无法跳回之前打开的侧边栏，教师端的公共资源有编辑按钮
          证书端：审核消息发送，通知的消息
          超管端：切换证书，发送
        ></span>-->

        <el-dropdown>
          <span class="el-dropdown-link">
            <img
              :src="header === 'null' ? '/img/avatar.4e4aa2c3.jpg' : header"
              alt
            />
            <span>你好！{{ name }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="alterUserMessage = true">
              个人资料
              <p>基本信息、联系方式</p>
            </el-dropdown-item>
            <el-dropdown-item @click.native="alterUserHeader = true">
              头像
              <p>修改头像</p>
            </el-dropdown-item>
            <el-dropdown-item @click.native="alterUserPsw = true">
              修改密码
              <p>修改后再次登录生效</p>
            </el-dropdown-item>
            <el-dropdown-item @click.native="outLogin()"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <span class="wenti" @click="goNotice"
        ><em v-show="lingdangFlag" class="hongdian"></em
        ><icon-svg icon-class="lingdang"></icon-svg
      ></span>
      <span class="wenti" @click="goFeedback" style="font-size: 20px"
        ><em v-show="messageFlag" class="hongdian"></em
        ><icon-svg icon-class="wenti"></icon-svg
      ></span>
      <!-- <el-dropdown v-if="role === 'superadmin'">
        <span class="el-dropdown-link">
          <span>数字创意建模</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in zhengshuData"
            :key="index"
            @click.native="alterId(item)"
          >
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </el-menu>
    <el-container>
      <!-- <transition name="zksc-fade" mode="out-in"> -->
      <!-- <keep-alive> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
      <!-- </transition> -->
    </el-container>
    <!-- 对话框 -->
    <el-dialog
      title="修改个人资料"
      :visible.sync="alterUserMessage"
      center
      @open="openBox"
    >
      <div class="message_box">
        <div>
          <span> <em></em> 姓名 </span>
          <el-input v-model="addFrom.name" placeholder="请输入姓名"></el-input>
        </div>
        <div>
          <span>用户名</span>
          <el-input
            v-model="addFrom.username"
            placeholder="请输入用户名"
            :disabled="true"
          ></el-input>
        </div>
        <div>
          <span>角色</span>
          <el-input
            v-model="addFrom.roles"
            :disabled="true"
            placeholder="请输入角色"
          ></el-input>
        </div>
        <!-- <div>
          <span> 电话 </span>
          <el-input v-model="addFrom.name" placeholder="请输入电话"></el-input>
        </div>-->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="alterUserName">提 交</el-button>
        <el-button @click="alterUserMessage = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改头像"
      accept="image/*"
      :visible.sync="alterUserHeader"
      center
      @open="openheader"
    >
      <div class="upload_header">
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :limit="1"
          :on-change="handleExceed"
        >
          <img
            :src="headerUrl === 'null' ? '/img/avatar.4e4aa2c3.jpg' : headerUrl"
            alt
          />
          <p>点击上传新头像</p>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb
          </div>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="alterHeader">提 交</el-button>
        <el-button @click="alterUserHeader = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改密码"
      @open="openAlterPsw"
      :visible.sync="alterUserPsw"
      center
    >
      <div class="alter">
        <el-form label-position="right" label-width="80px">
          <div class="item">
            <em class="hongdian"></em>
            <el-form-item label="原密码">
              <el-input
                :type="type1"
                v-model="alterList.oldpassword"
              ></el-input>
              <span class="guan" v-show="yan1" @click="cutYan1()"
                ><icon-svg icon-class="yanjing-guan"></icon-svg
              ></span>
              <span class="kai" v-show="!yan1" @click="cutYan1()"
                ><icon-svg icon-class="yanjing-kai"></icon-svg
              ></span>
            </el-form-item>
          </div>
          <div class="item-two">
            <em class="hongdian"></em>
            <el-form-item label="新密码">
              <el-input
                :type="type2"
                v-model="alterList.newpassword"
              ></el-input>
              <span class="guan" v-show="yan2" @click="cutYan2"
                ><icon-svg icon-class="yanjing-guan"></icon-svg
              ></span>
              <span class="kai" v-show="!yan2" @click="cutYan2"
                ><icon-svg icon-class="yanjing-kai"></icon-svg
              ></span>
            </el-form-item>
          </div>
          <div class="item-last">
            <em class="hongdian"></em>
            <el-form-item label="确认新密码">
              <el-input
                :type="type3"
                v-model="alterList.newokpassword"
              ></el-input>
              <span class="guan" v-show="yan3" @click="cutYan3"
                ><icon-svg icon-class="yanjing-guan"></icon-svg
              ></span>
              <span class="kai" v-show="!yan3" @click="cutYan3"
                ><icon-svg icon-class="yanjing-kai"></icon-svg
              ></span>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="alterPsw">提 交</el-button>
        <el-button @click="alterUserPsw = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex2: '',
      alterUserMessage: false,
      alterUserPsw: false,
      alterUserHeader: false,
      fileList: [],
      addFrom: {
        name: '',
        username: '',
        roles: '',
      },
      alterList: {
        oldpassword: '',
        newpassword: '',
        newokpassword: '',
      },
      role: null,
      userData: {},
      header: '',
      name: '',
      headerUrl: '',
      headerfile: '',
      // zhengshuData: [],
      yan1: true,
      yan2: true,
      yan3: true,
      type1: 'password',
      type2: 'password',
      type3: 'password',
      source: null,
      nowUserId: null,
      // 消息提示
      messageFlag: null,
      lingdangFlag: false,
      //班级通知
      hongdian: 0,
    };
  },
  mounted() {
    // console.log('------');
    if (this.role === 'student') {
      if (sessionStorage.getItem('hongdian')) {
        let num = sessionStorage.getItem('hongdian');
        this.$store.commit('initMessage', num);
        this.hongdian = this.$store.state.messageNum;
      } else {
        this.hongdian = this.$store.state.messageNum;
      }
    }
    this.monitorSSE();
  },
  created() {
    // this.autoLogin();
    // const that = this;
    this.role = sessionStorage.getItem('role');
    this.name = sessionStorage.getItem('name');
    this.nowUserId = sessionStorage.getItem('id');
    this.header = sessionStorage.getItem('header');
    /* 菜单状态 */
    if (sessionStorage.getItem('activeIndex2')) {
      this.activeIndex2 = sessionStorage.getItem('activeIndex2');
      return;
    }
    if (this.role === 'superadmin') {
      // this.getCertificate();
      this.activeIndex2 = '0';
    }
    // this.role = sessionStorage.getItem('role');
    if (this.role == 'student') {
      this.activeIndex2 = '1';
      sessionStorage.setItem('activeIndex2', this.activeIndex2);
    } else if (this.role == 'teacher' || this.role == 'admin') {
      this.activeIndex2 = '2';
      sessionStorage.setItem('activeIndex2', this.activeIndex2);
    } else if (this.role == 'superadmin') {
      this.activeIndex2 == '0';
      sessionStorage.setItem('activeIndex2', this.activeIndex2);
    }

    // let m = 1;
    // setInterval(function () {
    //   // alert(m);
    //   // m++;
    //   console.log('请求了~');
    // }, 3000);

    this.openheader();
  },
  methods: {
    // getHongdian() {
    //   this.hongdian = sessionStorage.getItem('hongdian');
    // },
    alterItem(index) {
      sessionStorage.setItem('activeIndex2', index);
    },
    // 断开连接
    closeSse() {
      // this.source.close();
      const httpRequest = new XMLHttpRequest();
      httpRequest.open(
        'GET',
        `/api/teach/common/close?userId=${this.nowUserId}`
      );
      httpRequest.send();
      // console.log('close');
    },
    // 建立、连接sse
    monitorSSE() {
      const that = this;
      if (window.EventSource) {
        // 建立连接
        this.source = new EventSource(
          `/api/teach/common/connect?userId=${this.nowUserId}`
        );
        // console.log(this.source);

        /**
         * 连接一旦建立，就会触发open事件
         * 另一种写法：source.onopen = function (event) {}
         */
        this.source.addEventListener(
          'open',
          function () {
            // console.log(e, '建立连接。。。');
          },
          false
        );
        /**
         * 客户端收到服务器发来的数据
         * 另一种写法：source.onmessage = function (event) {}
         */
        this.source.addEventListener('message', function (e) {
          // 接收的参数就是e.data
          console.log(e);
          if (that.role === 'student') {
            if (e.data == 3001) {
              // console.log(that.hongdian);
              // sessionStorage.setItem('hongdian', that.hongdian + 1);
              that.$store.commit('add');
              console.log(that.$store.state.messageNum);
              // that.hongdian = that.$store.state.messageNum;
              // sessionStorage.setItem('hongdian', that.hongdian);
              // that.getHongdian();
            }
            if (e.data == 3003) {
              // console.log(that.hongdian);
              // sessionStorage.setItem('hongdian', that.hongdian + 1);
              that.$store.commit('addWork');
              console.log(that.$store.state.workNum);
              // that.hongdian = that.$store.state.noticeNum;
              // sessionStorage.setItem('hongdian', that.hongdian);
              // that.getHongdian();
            }
            if (e.data == 3005) {
              // console.log(that.hongdian);
              // sessionStorage.setItem('hongdian', that.hongdian + 1);
              that.$store.commit('addExam');
              console.log(that.$store.state.examNum);
              // that.hongdian = that.$store.state.workNum;
              // sessionStorage.setItem('hongdian', that.hongdian);
              // that.getHongdian();
            }
            if (e.data == 3007) {
              // console.log(that.hongdian);
              // sessionStorage.setItem('hongdian', that.hongdian + 1);
              that.$store.commit('addNotice');
              console.log(that.$store.state.noticeNum);
              // that.hongdian = that.$store.state.examNum;
              // sessionStorage.setItem('hongdian', that.hongdian);
              // that.getHongdian();
            }
            that.$notify({
              title: JSON.parse(e.data).title,
              message: JSON.parse(e.data).message,
              duration: 0,
              onClick() {
                this.$axios
                  .get(`?commandId=${e.data.commandId}`)
                  .then((res) => {
                    if (res.data.code == 200) {
                      this.$router.push({
                        path: '/resourcedetail',
                        query: {
                          id: e.data.courseId,
                          menuId: e.data.menuId,
                          tabNum: 4,
                        },
                      });
                    }
                  });
              },
            });
            that.lingdangFlag = true;
          } else {
            if (
              e.data != 3002 &&
              e.data != 3001 &&
              e.data != 3003 &&
              e.data != 3004 &&
              e.data != 3005 &&
              e.data != 3006 &&
              e.data != 3007 &&
              e.data != 3008
            ) {
              that.$notify({
                title: JSON.parse(e.data).title,
                message: JSON.parse(e.data).message,
                duration: 0,
                onClick() {
                  this.$axios
                    .get(`?commandId=${e.data.commandId}`)
                    .then((res) => {
                      if (res.data.code == 200) {
                        this.$router.push({
                          path: '/resourcedetail',
                          query: {
                            id: e.data.courseId,
                            menuId: e.data.menuId,
                            tabNum: 4,
                          },
                        });
                      }
                    });
                },
              });
              that.lingdangFlag = true;
            }
          }
          // if (e.data == 3001) {
          //   // console.log(that.hongdian);
          //   // sessionStorage.setItem('hongdian', that.hongdian + 1);
          //   that.$store.commit('add');
          //   that.hongdian = that.$store.state.messageNum;
          //   sessionStorage.setItem('hongdian', that.hongdian);
          //   // that.getHongdian();
          // } else
        });
        /**
         * 如果发生通信错误（比如连接中断），就会触发error事件
         * 或者：
         * 另一种写法：source.onerror = function (event) {}
         */
        this.source.addEventListener(
          'error',
          function (e) {
            // console.log(e);
            if (e.readyState === EventSource.CLOSED) {
              // console.log(e);
              // console.log('连接关闭');
              this.monitorSSE();
            } else {
              // console.log(e);
            }
          },
          false
        );
      } else {
        alert('你的浏览器不支持SSE');
      }
    },
    // 修改密码切换type
    cutYan1() {
      this.yan1 = !this.yan1;
      if (this.yan1 === false) {
        this.type1 = 'text';
      } else {
        this.type1 = 'password';
      }
    },
    cutYan2() {
      this.yan2 = !this.yan2;
      if (this.yan2 === false) {
        this.type2 = 'text';
      } else {
        this.type2 = 'password';
      }
    },
    cutYan3() {
      this.yan3 = !this.yan3;
      if (this.yan3 === false) {
        this.type3 = 'text';
      } else {
        this.type3 = 'password';
      }
    },
    //修改密码
    alterPsw() {
      if (
        this.alterList.oldpassword === '' ||
        this.alterList.newpassword === '' ||
        this.alterList.newokpassword === ''
      ) {
        this.$message({
          type: 'warning',
          message: '请将内容补全',
        });
      } else if (this.alterList.newpassword !== this.alterList.newokpassword) {
        this.$message({
          type: 'error',
          message: '新密码与确认密码不一致',
        });
      } else {
        this.$axios
          .post(
            `/teach/common/updatePwd?oldPwd=${this.alterList.oldpassword}&newPwd=${this.alterList.newokpassword}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.$message({
                type: 'success',
                message: res.data.message + ',请重新登录',
              });
              // if (this.role === 'superadmin') {
              //   this.$router.push('/superlogin');
              // } else {
              this.$router.push('/login');
              // }
            } else {
              this.$message({
                type: 'error',
                message: res.data.message,
              });
            }
          });
      }
    },
    // 打开修改密码的对话框、
    openAlterPsw() {
      this.alterList.oldpassword = '';
      this.alterList.newpassword = '';
      this.alterList.newokpassword = '';
    },
    //修改用户信息
    alterUserName() {
      this.$axios
        .post(`/teach/common/updateUsrename?username=${this.addFrom.name}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: res.data.message,
            });
            this.name = this.addFrom.name;
            sessionStorage.setItem('name', this.addFrom.name);
            this.alterUserMessage = false;
          } else {
            this.$message({
              type: 'error',
              message: res.data.message,
            });
          }
        });
    },
    // 打开修改用户信息对话框
    openBox() {
      this.$axios.get('/teach/common/updateUser').then((res) => {
        // console.log(res.data.data);
        if (res.data.code === 200) {
          this.userData = res.data.data;
          this.role = res.data.data.role;
          if (this.role === 'admin') {
            this.addFrom.roles = '证书管理员';
          } else if (this.role === 'teacher') {
            this.addFrom.roles = '老师';
          } else if (this.role === 'superadmin') {
            this.addFrom.roles = '超级管理员';
          } else if (this.role === 'student') {
            this.addFrom.roles = '学生';
          }
          this.addFrom.name = res.data.data.realname;
          this.addFrom.username = res.data.data.username;
          this.header = res.data.data.headPicture;
        }
      });
    },
    // 打开更改头像的对话框
    openheader() {
      this.$axios.get('/teach/common/updateUser').then((res) => {
        // console.log(res.data.data);
        if (res.data.code === 200) {
          this.headerUrl = res.data.data.headPicture;
          this.header = res.data.data.headPicture;
          sessionStorage.setItem('header', res.data.data.headPicture);
        }
      });
    },
    //修改用户头像
    alterHeader() {
      if (this.headerfile === '') {
        this.$message({
          type: 'warning',
          message: '请选择图片',
        });
      } else {
        let formData = new FormData();
        formData.append('file', this.headerfile);
        let config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        };
        this.$axios
          .post('/teach/common/updateUsreimg', formData, config)
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.$message({
                type: 'success',
                message: res.data.message,
              });
              this.openheader();
              this.alterUserHeader = false;
            }
          });
      }
    },
    handleSelect() {},
    handlePreview() {},
    handleRemove() {},
    beforeRemove() {},
    handleExceed(file) {
      // console.log(file);
      this.headerfile = file.raw;
      this.headerUrl = URL.createObjectURL(file.raw);
    },
    goFeedback() {
      if (this.$route.path === '/message') {
        this.messageFlag = false;
        return;
      } else {
        this.$router.push('/message');
        this.messageFlag = false;
      }
    },
    goNotice() {
      this.$router.push('/notices');
      this.lingdangFlag = false;
    },
    outLogin() {
      /* 清空缓存数据 */
      localStorage.removeItem('token');
      sessionStorage.removeItem('role');
      localStorage.removeItem('silderId');
      sessionStorage.removeItem('id');
      sessionStorage.removeItem('classId');
      sessionStorage.removeItem('header');
      sessionStorage.removeItem('name');
      sessionStorage.removeItem('className');
      sessionStorage.removeItem('activeIndex2');
      sessionStorage.removeItem('menuIndex');
      sessionStorage.removeItem('choose');
      // if (this.role === 'superadmin') {
      //   this.$router.push('/superlogin');
      // } else {
      this.$router.push('/login');
      // }
    },
  },
  beforeDestroy() {
    this.closeSse();
  },
  watch: {
    '$route.path': (to) => {
      // console.log(to, '--');
      // console.log(from, '++');
      switch (to) {
        case '/courseware':
          sessionStorage.setItem('choose', 'courseware');
          break;
        case '/member':
          sessionStorage.setItem('choose', 'member');
          break;
        case '/courseManage':
          sessionStorage.setItem('choose', 'courseManage');
          break;
        case '/workSpace':
          sessionStorage.setItem('choose', 'workSpace');
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
}
.line {
  width: 100%;
  height: 30px;
  background: #888;
  color: #fff;
  line-height: 30px;
  font-weight: 600;
  text-indent: 30px;
}
///deep/
.my-menu {
  width: 100% -32px;
  padding-left: 32px;
  height: 56px;
  background-image: linear-gradient(to right, #0d2c65, #04a6a6);
}
.zksc-menu-item {
  width: 156px !important;
  height: 56px !important;
  text-align: center;
  color: #ffffff;
  position: relative;
  ul {
    .icon-svg {
      font-size: 50px;
    }
    width: 100%;
    height: 100%;
    color: #fff;
    line-height: 30px;
    li {
      color: #fff;
      font-size: 14px;
    }
  }
  .hongdian {
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    font-style: normal;
    position: absolute;
    top: 25px;
    right: 35px;
    width: 18px;
    height: 18px;
  }
}
.zksc-menu-item:focus {
  background-color: transparent !important;
}
/deep/.zksc-menu-item:hover {
  background-image: url('~@/assets/images/hover-menu-bgc.png') !important;
  background-color: transparent !important;
  background-size: 156px 70px;
  background-repeat: no-repeat !important;
  ul {
    color: #333;
    li {
      color: #333;
    }
  }
  .hongdian {
    color: #fff;
  }
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.is-active {
  background-image: url('~@/assets/images/hover-menu-bgc.png') !important;
  background-size: 100% 56px !important;
  background-repeat: no-repeat !important;
  border-bottom: none !important;
  color: #333333 !important;
  .icon_box {
    opacity: 1;
  }
  ul {
    color: #333333;
    li {
      color: #333333;
    }
  }
  .hongdian {
    color: #fff;
  }
}
.el-dropdown {
  float: right;
  margin-top: 0px;
  span {
    font-size: 14px;
    color: #fff;
    line-height: 56px;
    float: left;
    margin: 0 8px;
  }
}
.el-dropdown-link {
  height: 56px;
  float: right;
  margin-right: 30px;
  img {
    width: 28px;
    height: 28px;
    background: #04a6a6;
    border-radius: 50%;
    margin-top: 14px;
    float: left;
  }
}
.el-dropdown-link:hover {
  cursor: pointer;
}
.el-dropdown-menu {
  .el-dropdown-menu__item {
    span {
      float: none;
      color: #111;
    }
    p {
      margin: 0;
      line-height: 20px;
      font-size: 12px;
      color: #cecece;
    }
  }
}
.wenti {
  color: #fff;
  float: right;
  margin-right: 16px;
  line-height: 56px;
  position: relative;
  .hongdian {
    position: absolute;
    top: 12px;
    right: 0px;
  }
}
.wenti:hover {
  cursor: pointer;
}

/* 路由切换动画 */
// .zksc-fade-enter-active {
//   transition: all 0.3s ease;
// }
// .zksc-fade-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .zksc-fade-enter, .zksc-fade-leave-to
// /* .slide-fade-leave-active for below version 2.1.8 */ {
//   transform: translateX(10px);
//   opacity: 0;
// }
.title_p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.title_span {
  display: inline;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  font-size: 12px;
}

.upload_header {
  width: 300px;
  margin: 0 auto;
  /deep/.el-upload {
    width: 100%;
  }

  img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
  }
  .el-upload__tip {
    color: #000000;
    opacity: 0.25;
    text-align: center;
  }
}

/deep/.el-dialog__header {
  background: #409eff;
}
/deep/.el-dialog__title {
  color: #fff;
}
/deep/.el-dialog__close {
  color: #fff !important;
}
.message_box {
  width: 526px;
  margin: 0 auto;
}
em {
  display: inline-block;
  margin: 0px 0px 3px 0;
  width: 4px;
  height: 4px;
  background: #f56c6c;
  border-radius: 50%;
}
.message_box {
  div {
    margin: 8px 0;
    span {
      display: inline-block;
      width: 42px;
      text-align: center;
      margin-right: 10px;
    }
    .el-input {
      width: 448px;
    }
  }
}
.alter {
  width: 566px;
  margin: 0 auto;
  /deep/.el-form-item__label {
    width: 100px !important;
  }
  /deep/.el-form-item__content {
    margin-left: 100px !important;
  }
  .item {
    span:hover {
      cursor: pointer;
    }
    position: relative;
    .hongdian {
      position: relative;
      left: 35px;
      top: 30px;
    }
    .guan {
      position: absolute;
      right: 10px;
    }
    .kai {
      position: absolute;
      right: 10px;
    }
  }
  .item-two {
    span:hover {
      cursor: pointer;
    }
    .hongdian {
      position: relative;
      left: 35px;
      top: 30px;
    }
    .guan {
      position: absolute;
      right: 10px;
    }
    .kai {
      position: absolute;
      right: 10px;
    }
  }
  .item-last {
    span:hover {
      cursor: pointer;
    }
    .hongdian {
      position: relative;
      left: 5px;
      top: 30px;
    }
    .guan {
      position: absolute;
      right: 10px;
    }
    .kai {
      position: absolute;
      right: 10px;
    }
  }
}
</style>
