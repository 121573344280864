<template>
  <div class="box">
    <div class="tree-title">{{ list.name }}</div>
    <div class="check-content">
      <ul>
        <template v-for="(item, i) in list.arr">
          <li
            :class="{ 'li-active': i == firstChoice }"
            :key="'a' + i"
            @click="handleFirst(item, i)"
            v-show="item.arr.length > 0"
          >
            <div class="left-area">
              <div
                class="check-box"
                :class="{ 'is-check': item.checkStatus != -1 || item.checkAll }"
                @click="handleCheckAll(item, i)"
              >
                <icon-svg
                  class="gou"
                  icon-class="gougou"
                  v-if="item.checkAll"
                ></icon-svg>
                <div
                  class="icon-jian"
                  v-if="item.checkStatus == 0 && item.checkAll == false"
                ></div>
              </div>
              <!-- <span>{{ item.checkAll }}-- {{ item.checkStatus }}</span> -->

              <el-tooltip
                v-if="item.name.length > 15"
                :content="item.name"
                placement="top"
              >
                <span class="itemName">{{ item.name }}</span>
              </el-tooltip>
              <span v-else class="itemName">{{ item.name }}</span>
            </div>
            <icon-svg icon-class="jiantou" class="jiantou"></icon-svg>
          </li>
        </template>
      </ul>
      <ul v-if="list.arr[firstChoice] && list.arr[firstChoice].arr">
        <li
          :class="{ 'li-active': i2 == secondChoice }"
          v-for="(item2, i2) in list.arr[this.firstChoice].arr"
          :key="'b' + i2"
          @click="checkFirst(item2, i2)"
        >
          <div class="left-area">
            <div
              class="check-box"
              :class="{ 'is-check': item2.checked == true }"
            >
              <icon-svg
                class="gou"
                icon-class="gougou"
                v-if="item2.checked"
              ></icon-svg>
            </div>
            <!-- <span>{{ item2.checked }}</span> -->
            <el-tooltip
              v-if="item2.name.length > 15"
              :content="item2.name"
              placement="top"
            >
              <span class="itemName">{{ item2.name }}</span>
            </el-tooltip>
            <span v-else class="itemName">{{ item2.name }}</span>
          </div>
          <!-- <icon-svg icon-class="jiantou" class="jiantou"></icon-svg> -->
        </li>
      </ul>

      <!-- 右侧标签 -->
      <transition-group
        name="tag-complete"
        class="tagbox"
        tag="div"
        v-if="list.arr[firstChoice]"
      >
        <div
          class="tag tag-complete-item"
          v-for="item in list.tags"
          :key="item.id"
        >
          <div class="tagname">{{ item.name }}</div>
          <div @click="deleteTag(item)" class="delete">
            <icon-svg icon-class="cha" class="icon-cha"></icon-svg>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
export default {
  props: ['list'],
  data() {
    return {
      /* 一级选择索引 */
      firstChoice: 0,
      secondChoice: 0,
      tags: [],
    };
  },
  mounted() {},
  methods: {
    /* 控制二级切换 */
    handleFirst(item, i) {
      // console.log(item, i);
      this.firstChoice = i;
    },
    /* 全选 */
    handleCheckAll(item, i) {
      // console.log('item, i: ', item, i);

      /* 样式控制----start */
      this.firstChoice = i;
      this.list.arr[i].checkAll = !item.checkAll;
      // item.checkAll = !item.checkAll;
      /* 全选状态 */

      if (this.list.arr[i].checkAll) {
        this.list.arr[i].checkStatus = 1;
        this.list.arr[i].arr.map((v) => (v.checked = true));
      } else {
        /* 全不选状态 */
        this.list.arr[i].checkStatus = -1;
        this.list.arr[i].arr.map((v) => (v.checked = false));
      }
      /* 样式控制----end  */

      /* 数据处理 */
      /* 取得三级id */
      let menuId3 = this.list.arr[i].arr.map((v) => (v.checked ? v.id : ''));
      let resultData = {
        softwareId2: this.list.arr[i],
        softwareId3: menuId3,
      };
      console.log(resultData);
      this.$emit('change', resultData);

      /* 右侧标签控制 */
      this.rightTags();
    },
    /* 二级菜单 */
    checkFirst(item, i2) {
      // console.log(item, i2);
      /* 样式控制----------start */
      this.secondChoice = i2;
      /* 当前选项取非 */
      this.list.arr[this.firstChoice].arr[i2].checked = !item.checked;
      // item.checked = !item.checked;
      /* 遍历全选 */
      let isAll = this.list.arr[this.firstChoice].arr.every(
        (v) => v.checked == true
      );
      /* 判断全选状态 */
      if (isAll) {
        /* 二级全选 ,勾选一级 */
        this.list.arr[this.firstChoice].checkAll = true;
        /* 取消半选 */
        this.list.arr[this.firstChoice].checkStatus = 1;
      } else {
        /* 取消全选 */
        this.list.arr[this.firstChoice].checkAll = false;
        /* 打开半选 */
        this.list.arr[this.firstChoice].checkStatus = 0;
      }

      /* 二级不不选 */
      let isNotAll = this.list.arr[this.firstChoice].arr.every(
        (v) => v.checked == false
      );
      if (isNotAll) this.list.arr[this.firstChoice].checkStatus = -1;
      /* 样式控制----------end  */

      /* 数据处理 */
      let menuId3 = this.list.arr[this.firstChoice].arr
        .filter((v) => v.checked == true)
        .map((v) => v.id);
      /* 二级中个其中一个为 true 触发一下逻辑 */

      let resultData = {
        softwareId2: this.list.arr[this.firstChoice],
        softwareId3: menuId3,
      };
      this.$emit('change', resultData);

      /* 右侧标签控制 */
      this.rightTags();
    },
    /* 删除tags中的元素 */
    deleteTag(item) {
      this.list.tags.map((v, i) => {
        if (v.id == item.id) {
          if (v.grade == 1) {
            /* 1级菜单移除 */
            this.handleCheckAll(v, i);
            // this.firstChoice = i;
          } else if (v.grade == 2) {
            /* 2级菜单移除 */
            this.list.arr.map((v, i1) => {
              v.arr.map((v2, i2) => {
                if (item.id == v2.id) {
                  this.firstChoice = i1;
                  this.checkFirst(item, i2);
                  // console.log('this.list.arr', this.list.arr[i1]);
                }
              });
            });
          }
          /* 删除标签  */
          // this.list.tags.splice(i, 1);
        }
      });
      /* 删除一级标签，对应数据发生改变 */
    },

    /* 右侧标签控制 */
    rightTags() {
      let currentChoice = this.list.arr[this.firstChoice];

      /* 控制一级加入 */
      if (currentChoice.checkAll) {
        /* 当前一级选中，将一级加入 tags 中 */
        /* 看看有没有重复的 */

        this.list.tags.push(JSON.parse(JSON.stringify(currentChoice)));
      } else {
        /* 删除一级 */
        this.list.tags.map((v, i) => {
          if (v.id == currentChoice.id) {
            this.list.tags.splice(i, 1);
          }
        });
      }

      /* 控制二级加入 */
      let isAllChoice = true;
      currentChoice.arr.map((v) => {
        /* 二级单选 */
        if (v.checked == true && currentChoice.checkAll == false) {
          let flag = true;
          this.list.tags.map((v2) => {
            if (v2.id == v.id) {
              flag = false;
            }
          });
          /* 没有一个相同的 */
          if (flag) {
            this.list.tags.push(JSON.parse(JSON.stringify(v)));
          }
        }
        /* 二级全选 */
        if (v.checked == false) {
          isAllChoice = false;
        }
      });
      /* 二级全选，删除二级标签，替换为一级标签 */
      if (isAllChoice) {
        /* 删除所有二级 */
        this.list.tags.map((v, i) => {
          currentChoice.arr.map((v2) => {
            if (v.id == v2.id) {
              this.list.tags.splice(i, 1);
            }
          });
        });
        currentChoice.arr.map((v) => {
          this.list.tags.map((v2, i) => {
            if (v.id == v2.id) {
              this.list.tags.splice(i, 1);
            }
          });
        });
        /* 加入一级 checkAll 已经加入，*/
        // this.list.tags.push(JSON.parse(JSON.stringify(currentChoice)));
      } else {
        // console.log('单个排除');
        currentChoice.arr.map((v) => {
          this.list.tags.map((v2, i) => {
            if (v.checked == false && v.id == v2.id) {
              // console.log('排除？？？');
              this.list.tags.splice(i, 1);
            }
          });
        });
      }
    },
  },
};
</script>
<style lang="less">
// .box {
//   margin-top: 11px;
// }
.tree-title {
  // padding: 10px 16px;
  line-height: 50px;
  text-indent: 16px;
  border-bottom: 1px solid #ccc;
}
.check-content {
  display: flex;
  ul {
    padding-top: 10px;
    margin: 0 0 10px 0;
    width: 33%;
    max-height: 300px;
    // padding: 0 16px;
    border-right: 1px solid #ccc;
    box-sizing: border-box;
    overflow: auto;
    li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
      // border-radius: 4px;
      // background-color: #f0f0f0;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #535353;
    }
    .li-active {
      border-radius: 4px;
      background: #eff7ff;
      margin: 0 10px;
    }
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      line-height: 54px;
      margin: 0px 10px;
      .left-area {
        width: 100%;
        display: flex;
        align-items: center;
        .itemName {
          width: 100%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .check-box {
        border: 1px solid #dcdfe6;
        border-radius: 2px;
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        background-color: #fff;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .gou {
          font-size: 14px;
          color: #fff;
        }
        .icon-jian {
          width: 8px;
          height: 2px;
          background-color: #fff;
          border-radius: 4px;
        }
      }
      .jiantou {
        font-size: 8px;
        margin-right: 16px;
      }
      .is-check {
        background-color: #409eff;
        border: 1px solid #409eff;
      }
    }
  }
  .tagbox {
    // display: inline-flex;
    // flex-wrap: wrap;
    // justify-content: space-around;
    // align-items: center;
    padding-left: 10px;
    width: 33%;
    height: 300px;
    overflow: auto;
    .tag {
      // display: flex;
      // align-items: center;
      float: left;
      margin: 4px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 30px;
      background-color: #409eff;
      color: #fff;
      cursor: pointer;
      .tagname {
        display: inline-block;
        margin: 0 10px 0 20px;
      }
      .delete {
        display: inline-block;
        .icon-cha {
          font-size: 8px;
          margin: 0 20px 1px 4px;
        }
      }
    }
  }
}
.tag-complete-item {
  transition: all 1s;
}
.tag-complete-enter,
.tag-complete-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.tag-complete-leave-active {
  position: absolute;
}
</style>
