<template>
  <div class="zksc-member" :class="{ 'is-student': role == 'student' }">
    <!--  :menuLists="menuList" v-on:getIds="getMenuId" v-if="list" -->
    <!-- <SideMenu></SideMenu> -->
    <ul class="side-menu" v-if="role != 'student'">
      <li
        v-for="(v, i) in classMenu"
        :key="v.id"
        :class="{ 'li-active': i == choice }"
        @click="choieClass(i, v)"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="v.name"
          placement="top"
          :open-delay="time"
        >
          <span>
            {{ v.name }}
          </span>
        </el-tooltip>
        <!-- {{ v.name }} -->
      </li>
    </ul>
    <div class="zksc-member-c">
      <div class="zksc-member-title">
        <h5>{{ className }}</h5>
        <div>
          <el-button
            v-if="role == 'admin'"
            type="primary"
            @click="openAddUsertDialog('teacher')"
            >添加老师</el-button
          >
          <el-button
            v-if="role == 'admin'"
            type="primary"
            @click="openAddUsertDialog('student')"
            >新增学生</el-button
          >
          <a href="api2/templete/用户.xls">
            <el-button v-if="role == 'admin'" type="info">下载模板</el-button>
          </a>

          <el-button
            v-if="role == 'admin'"
            type="info"
            @click="openTempalteDialog"
            >批量导入</el-button
          >
          <el-button v-if="role == 'teacher'" type="primary" @click="getMember"
            >导出学生信息</el-button
          >
        </div>
      </div>
      <!-- 班级成员 -->
      <el-empty
        description="暂时没有班级成员"
        v-if="tableData.length == 0"
      ></el-empty>
      <template v-else>
        <el-table :data="tableData" style="width: 100%" stripe>
          <el-table-column
            type="index"
            width="50"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column property="headPicture" label="头像" width="80px">
            <template slot-scope="scope">
              <img
                class="zksc-avatar"
                :src="
                  scope.row.headPicture === 'null'
                    ? '/img/avatar.4e4aa2c3.jpg'
                    : scope.row.headPicture
                "
              />
              <!-- 给定默认头像 -->
              <!-- <img
                class="zksc-avatar"
                v-else
                src=""
                alt
              /> -->
            </template>
          </el-table-column>
          <el-table-column
            property="username"
            label="用户名"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            property="realname"
            label="真实姓名"
          ></el-table-column>
          <el-table-column property="sex" label="性别" align="center">
            <template slot-scope="scope">
              <icon-svg
                icon-class="nan"
                class="icon-nan"
                v-if="scope.row.sex"
              ></icon-svg>
              <icon-svg
                icon-class="nv"
                class="icon-nv"
                style="color: #ff9ed1"
                v-else
              ></icon-svg>
            </template>
          </el-table-column>
          <el-table-column property="role" label="角色" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.role == 'student'">学生</div>
              <div v-else>老师</div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="role == 'admin'"
            label="操作"
            width="190px"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="info"
                size="mini"
                @click="openEditDialog(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="warning"
                size="mini"
                @click="deleteUser(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          layout="prev, pager, next, jumper"
          :page-size="pageSize"
          :total="totalPage"
          :current-page="currentPage"
          @current-change="handleChangePage"
        ></el-pagination>
      </template>

      <!-- 批量导入对话框 -->
      <el-dialog
        class="zksc-dialog"
        title="批量导入"
        :visible.sync="templateDialog"
        :before-close="closetemplateDialog"
        center
      >
        <el-upload
          class="upload-demo zksc-upload"
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          type="file"
          accept=".xlsx, .xls"
          :http-request="XLSFile"
          ref="uploadRef"
          :multiple="false"
          :limit="1"
        >
          <icon-svg icon-class="shangchuan1"></icon-svg>
          <div class="el-upload__text">
            <p>点击上传，或拖到此处</p>
            <p class="file-type-size">
              <span>只能上传.xlsx和.xls文件，且不超过500KB</span>
            </p>
          </div>
        </el-upload>
        <span class="btn">
          <el-button @click="closetemplateDialog">取消</el-button>
          <el-button @click="uploadXLSFile" type="primary"
            >上传成员名单</el-button
          >
        </span>
      </el-dialog>

      <!-- 新增用户 -->
      <el-dialog
        class="zksc-dialog"
        :title="title"
        :visible.sync="addStudent"
        :before-close="closeAddUsertDialog"
      >
        <el-form
          label-width="100px"
          :model="addUser"
          :rules="addUserRules"
          ref="addUserRef"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="addUser.username"
              placeholder="请输入手机号作为用户名"
              type="number"
              v-popover:popover
            ></el-input>
            <el-popover
              ref="popover"
              class="mypopover"
              placement="bottom"
              width="400"
              height="300"
              trigger="click"
              v-if="addUser.role == 'teacher'"
            >
              <el-table :data="teacherList">
                <el-table-column
                  property="username"
                  label="手机"
                ></el-table-column>
                <el-table-column
                  property="realname"
                  label="真实姓名"
                ></el-table-column>
                <el-table-column property="sex" label="性别">
                  <template slot-scope="scoped">
                    <div>{{ scoped.row.sex ? '男' : '女' }}</div>
                  </template>
                </el-table-column>
                <el-table-column property="sex" label="操作">
                  <template slot-scope="scoped">
                    <el-button size="mini" @click="usernameChange(scoped.row)"
                      >使用</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-popover>
            <!-- <el-select
              v-model="addUser.username"
              filterable
              remote
              reserve-keyword
              placeholder="请输入手机号作为用户名"
              style="width: 100%"
              :remote-method="getTeacherList"
              :loading="loading"
              :no-data-text="addUser.username"
              @change="usernameChange"
            >
              <el-option
                v-for="item in teacherList"
                :key="item.value"
                :label="item.username"
                :value="item.username"
              ></el-option>
            </el-select>-->
          </el-form-item>
          <el-form-item label="真实姓名" prop="realname">
            <el-input
              v-model="addUser.realname"
              placeholder="请输入真实姓名"
              :disabled="isDisable"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select
              v-model="addUser.sex"
              clearable
              placeholder="请选择性别"
              class="selectSex"
              :disabled="isDisable"
            >
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeAddUsertDialog">取 消</el-button>
          <el-button type="primary" @click="addStudents">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 编辑用户 -->
      <el-dialog
        class="zksc-dialog"
        title="编辑"
        :visible.sync="editUser"
        :before-close="closeEditDialog"
      >
        <el-form
          label-width="100px"
          :model="addUser"
          :rules="addUserRules"
          ref="editUserRef"
        >
          <el-form-item label="用户名" prop="username">
            <el-input
              v-model="addUser.username"
              placeholder="请输入手机号作为用户名"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="realname">
            <el-input
              v-model="addUser.realname"
              placeholder="请输入真实姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="sex">
            <el-select
              v-model="addUser.sex"
              clearable
              placeholder="请选择性别"
              class="selectSex"
            >
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeEditDialog">取 消</el-button>
          <el-button type="primary" @click="editUsers">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      classMenu: [],
      className: '',
      choice: 0,
      classId: '',
      currentPage: 1,
      pageSize: 9,
      totalPage: 0,
      tableData: [],
      templateDialog: false,

      /* 证书管理员 */
      role: '',
      addStudent: false,
      addUser: {
        classId: '',
        realname: '',
        role: '',
        sex: '',
        username: '',
      },
      addUserRules: {
        classId: [],
        realname: [
          { required: true, message: '请输入真实名称', trigger: 'blur' },
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        username: [
          { required: true, validator: validatePhone, trigger: 'blur' },
        ],
      },
      title: '',
      editUser: false,
      loading: false,
      teacherList: [],
      isDisable: false,
      time: 800,
    };
  },
  mounted() {
    this.classId = sessionStorage.getItem('classId');
    this.choice = sessionStorage.getItem('menuIndex');
    this.className = sessionStorage.getItem('className');
    this.role = sessionStorage.getItem('role');

    /* 是否是从班级列表跳转过来带有班级id */
    if (this.$route.query.classId) {
      this.classId = this.$route.query.classId;
    }
    if (this.role == 'teacher') {
      this.getClassManageMenu();
    } else if (this.role == 'admin') {
      this.getClassListMenu();
    } else if (this.role == 'student') {
      this.getClassList(this.classId);
    }
  },
  watch: {
    'addUser.username': {
      handler: function (newV) {
        if (newV.length % 3 == 0) {
          this.getTeacherList(newV);
        } else {
          this.teacherList = [];
        }
      },
    },
  },
  methods: {
    /**
     * 获取班级菜单数据
     */
    async getClassManageMenu() {
      const { data } = await this.$axios.get('/teach/class/findClass');
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      if (data.data.length == 0) {
        return this.$message.warning('暂时没有数据');
      }
      this.classMenu = data.data;
      // this.className = this.classMenu[0].name;
      /* 右侧状态保存 */
      // if (sessionStorage.getItem('classId') == null) {
      sessionStorage.setItem('classId', data.data[0].id);
      sessionStorage.setItem('menuIndex', 0);
      sessionStorage.setItem('className', data.data[0].name);
      this.classId = data.data[0].id;
      this.className = this.classMenu[0].name;
      this.choice = 0;
      // }

      this.getClassList(this.classId);
    },
    /**
     * 选择班级
     */
    choieClass(i, v) {
      this.choice = i;
      this.classId = v.id;
      this.className = v.name;
      this.currentPage = 1;
      /* 右侧状态保存 */
      sessionStorage.setItem('classId', v.id);
      sessionStorage.setItem('menuIndex', i);
      sessionStorage.setItem('className', v.name);
      this.getClassList(v.id);
    },
    /**
     * 获取班级列表数据
     */
    async getClassList(id) {
      this.tableData = [];
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const { data } = await this.$axios.get('/teach/class/findstudent', {
        params: {
          classId: id,
          current: this.currentPage,
          size: this.pageSize,
        },
      });
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.warning('暂时没有数据呀');
      }

      this.tableData = data.data.items;
      // /* 判断是否有老师 */
      // if (!this.isHaveTeacher) {
      //   let isHaveTeach = this.tableData.filter((v) => v.role == 'teacher');
      //   if (isHaveTeach.length > 0) this.isHaveTeacher = true;
      // }
      this.totalPage = data.data.total;
      setTimeout(() => {
        loading.close();
      }, 500);
    },
    /**
     * 获取班级成员xls
     */
    async getMember() {
      /* 请求类型为 arraybuffer */
      const { status, data } = await this.$axios.get('/teach/class/findExcel', {
        params: { classId: this.classId },
        responseType: 'arraybuffer',
      });

      if (status !== 200) {
        return this.$message.error('获取失败');
      }
      // 核心代码
      let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
      let url = window.URL.createObjectURL(blob);
      /* 添加节点 */
      let link = document.createElement('a');
      // console.log('link: ', link);
      link.href = url;
      link.click();
      /* TODO删除节点 */
    },
    /**
     * 翻页
     */
    handleChangePage(current) {
      this.currentPage = current;
      this.getClassList(this.classId);
    },
    /*-------------- 证书管理员部分 ----------------*/
    /* 证书管理员获取班级菜单 */
    async getClassListMenu() {
      this.tableData = [];
      /* 加载动画 */
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      /* 获取证书id  saveSilderId*/
      let id = localStorage.getItem('silderId');

      let { data } = await this.$axios.get(
        `/teach/cerificate/findCerClass?cerificateId=${id}&page=${1}&rows=${100}`
      );
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.warning('暂时没有班级信息');
      }
      this.classMenu = data.data.items;
      /* 初始默认是第一个 */
      // this.className = data.data.items[0].name;
      // this.classId = data.data.items[0].id;
      // if (sessionStorage.getItem('classId') == 'null') {
      sessionStorage.setItem('classId', data.data.items[0].id);
      sessionStorage.setItem('menuIndex', 0);
      sessionStorage.setItem('className', data.data.items[0].name);
      this.classId = data.data.items[0].id;
      this.choice = 0;
      // }
      this.getClassList(this.classId);
      /* 左侧菜单状态 */
      this.classMenu.map((v, i) => {
        if (v.id == this.$route.query.classId) {
          sessionStorage.setItem('menuIndex', i);
          this.choice = i;
        }
      });
      setTimeout(() => {
        loading.close();
      }, 500);
    },
    /* 证书管理员查看班级信息 */

    /* 打开添加学生对话框 */
    openAddUsertDialog(role) {
      this.addUser.role = role;
      role == 'teacher' ? (this.title = '添加老师') : (this.title = '添加学生');
      // if (role == 'teacher' && this.isHaveTeacher) {
      //   return this.$message.warning('当前班级已经有老师了,不能再添加了');
      // }
      this.addStudent = true;
      this.isDisable = false;
    },
    /* 关闭添加对话框 */
    closeAddUsertDialog() {
      this.addStudent = false;
      this.$refs.addUserRef.resetFields();
    },
    /* 添加学生  / 老师 */
    addStudents() {
      // if (this.addUser.role == 'teacher' && this.isHaveTeacher) {
      //   this.$message.error('当前班级已经有老师了');
      //   this.closeAddUsertDialog();
      //   return;
      // }
      this.addUser.classId = this.classId;
      this.$refs.addUserRef.validate(async (valid) => {
        if (!valid) return;
        let { data } = await this.$axios.post(
          '/teach/cerificate/addUser',
          this.addUser
        );
        console.log(data);
        if (data.code != 200) {
          return this.$message.error(data.message);
        } else if (data.data == 0) {
          return this.$message.success(data.message);
        } else {
          let arr = data.data.join();
          alert('添加成功！' + arr + '用户已在溯·创空间注册过账号');
        }
        this.closeAddUsertDialog();
        this.getClassList(this.classId);
        return this.$message.success(data.message);
      });
    },
    /* 打开上传对话框 */
    openTempalteDialog() {
      this.templateDialog = true;
    },
    /* 关闭上传对话框 */
    closetemplateDialog() {
      this.templateDialog = false;
      this.$refs.uploadRef.clearFiles();
    },
    /* 上传文件 */
    XLSFile(fileObj) {
      if (fileObj.file.size / 1024 > 500) {
        /* 清除文件列表 */
        this.$refs.uploadRef.clearFiles();
        return this.$message.warning('上传的文件不能大于500KB');
      }
      this.xlsFile = fileObj.file;
    },
    async uploadXLSFile() {
      if (!this.xlsFile) {
        this.$message({
          type: 'error',
          message: '请选择文件！',
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let fd = new FormData();
      fd.append('file', this.xlsFile, '用户.xls');
      fd.append('classId', this.classId);
      let { data } = await this.$axios.post(
        '/teach/cerificate/bathaddStudents',
        fd,
        {
          headers: {
            'Content-Type': 'multipart/from-data',
            x: 'y',
          },
        }
      );
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      if (!data.data) {
        return this.$message.success(data.message);
      } else {
        let arr = data.data.join();
        alert(
          '添加成功！其中' +
            arr +
            '用户已在溯·创空间注册过账号,其他用户添加成功'
        );
      }
      // this.$message.success(data.message);
      this.closetemplateDialog();
      this.getClassList(this.classId);
      loading.close();
      this.templateDialog = false;
    },
    /* 删除用户 */
    deleteUser({ id }) {
      this.$confirm('是否删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          let { data } = await this.$axios.delete(
            `/teach/cerificate/delUser?userId=${id}&classId=${this.classId}`
          );
          if (data.code !== 200) return this.$message.error(data.message);
          this.getClassList(this.classId);
          return this.$message.success(data.message);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    /* 打开编辑用户对户口 */
    openEditDialog(row) {
      this.addUser = row;
      this.editUser = true;
    },
    closeEditDialog() {
      this.editUser = false;
      this.addUser = {};
      this.$refs.editUserRef.resetFields();
    },
    /* 编辑用户 */
    editUsers() {
      this.$refs.editUserRef.validate(async (valid) => {
        if (!valid) return;
        let { data } = await this.$axios.post(
          '/teach/cerificate/updateUser',
          this.addUser
        );
        if (data.code !== 200) return this.$message.error(data.message);

        this.$message.success(data.message);
        this.getClassList(this.classId);
        this.closeEditDialog();
      });
    },
    /* 获取老师列表 */
    async getTeacherList(v) {
      this.teacherList = [];
      if (v == '') return;
      const { data } = await this.$axios.post(
        `/teach/cerificate/threeTeacher?code=${v}`
      );
      // console.log(data, 'data');
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      if (data.data.length == 0) {
        return (this.addUser.username = v);
      }
      this.teacherList = data.data;
    },
    /* 选择列表填充 */
    usernameChange({ username, realname, sex }) {
      this.addUser.username = username;
      this.addUser.realname = realname;
      this.addUser.sex = sex;
      this.$refs.addUserRef.validate();
    },
  },
};
/* 校验手机 */
var validatePhone = (rule, value, callback) => {
  let regx =
    /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  if (regx.test(value)) {
    callback();
  } else {
    callback(new Error('请输入正确的手机号作为用户名'));
  }
};
</script>
<style lang="less" scoped>
.zksc-member {
  padding: 16px 22px;
  display: flex;
  justify-content: space-between;
  // overflow: hidden;
  .side-menu {
    width: 300px;
    margin: 0 16px 0 0;
    background-color: #fff;
    border-radius: 4px;
    li {
      padding: 0 20px;
      height: 40px;
      margin: 6px;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    li:hover {
      border-radius: 4px;
      background: #f1f3f5;
    }
    .li-active {
      border-radius: 4px;
      background: #f1f3f5;
    }
  }
}
.is-student {
  display: block;
  .zksc-member-c {
    width: 100%;
  }
}
.zksc-member-c {
  width: calc(100% - 300px - 22px);
  padding-bottom: 30px;
  min-height: calc(100vh - 30px - 56px - 56px - 32px);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  .zksc-member-title {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 14px;
      font-weight: normal;
      padding-left: 16px;
    }
    .el-button {
      margin: 0;
      margin-right: 16px;
    }
  }
  .el-table {
    padding: 0 16px 16px;
    .zksc-avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
  .icon-nan {
    font-size: 10px;
    color: #409eff;
  }
  .icon-nv {
    font-size: 10px;
    color: #409eff;
  }
}

/deep/.zksc-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-around;
  .el-upload {
    display: flex;
    justify-content: space-around;
  }
  .el-upload-dragger {
    background-color: #f1f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .svg-icon {
      font-size: 30px;
      color: #409eff;
    }
  }
  .el-upload__text {
    p {
      margin: 14px 0;
      padding: 0;
      line-height: 1em;
    }
    .file-type-size {
      display: flex;
      flex-direction: column;
      color: #ccc;
      font-size: 12px;
      span {
        margin-bottom: 4px;
      }
    }
  }
}

.el-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
/deep/.zksc-dialog {
  .el-dialog__header {
    height: 70px !important;
    background: #409eff !important;
    padding: 0 !important;

    line-height: 70px !important;
    text-align: center !important;
    .el-dialog__title {
      color: #ffffff !important;
      font-size: 24px !important;
    }
    .el-dialog__headerbtn {
      top: 30px;
      right: 20px;
    }
    .el-dialog__close {
      color: #fff !important;
    }
  }

  .btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}
.selectSex {
  width: 100%;
}
// /deep/.el-popover,
// .el-popper {
//   height: 300px !important;
//   overflow-y: auto !important;
// }
</style>
<style>
.mypopover,
.el-popover {
  max-height: 300px !important;
  overflow: auto !important;
}
</style>
