import Vue from 'vue';
import MessageComponent from './message.vue';

class Msg {
  // eslint-disable-next-line space-before-function-paren
  constructor() {
    const vm = new Vue({
      render: (h) => h(MessageComponent),
    }).$mount();
    document.body.appendChild(vm.$el);
    this.component = vm.$children[0];
  }

  success(options) {
    this.component.add(options);
  }
}
Msg.getInstance = (function () {
  let instance;
  return function () {
    if (!instance) {
      instance = new Msg();
    }
    return instance;
  };
})();

export const Message = Msg.getInstance();
