<template>
  <div class="zksc-navMeun">
    <ul class="zksc-nav">
      <!-- 先循环 -->
      <li
        class="zksc-nav-li-title"
        v-for="(item, index) in menuListss"
        :key="index"
      >
        <!-- 需要这样嵌套一下，否则hover样式会覆盖 -->
        <ul>
          <!-- 一级菜单 -->
          <li class="zksc-nav-li-item" @click.stop="getIndex(index, item, 1)">
            <span style="font-size: 12px"
              ><icon-svg
                class="jiantou"
                :class="{ jiantous: item.isshow }"
                icon-class="jiantou"
              ></icon-svg
            ></span>
            {{ item.name }}
            <div class="title-right">
              <el-tooltip
                class="item"
                effect="dark"
                :open-delay="delay"
                content="点击+可以添加下级分类"
                placement="top-start"
                v-if="item.isAdd === 1"
                v-show="role !== 'student'"
              >
                <span
                  @click.stop="openAddNode(item)"
                  style="font-size: 11px; margin-right: 16px"
                >
                  <icon-svg icon-class="jiahaocu"></icon-svg>
                </span>
              </el-tooltip>
            </div>
          </li>
          <!-- 这里是二级菜单最大的块 -->
          <ul
            class="one-title-box"
            :class="{ 'one-title-boxs': item.isshow }"
            v-show="item.isshow"
          >
            <li
              class="zksc-nav-li2-title"
              v-for="(item2, index2) in item.children"
              :key="index2"
            >
              <ul>
                <!-- 二级菜单 -->
                <li
                  class="zksc-nav-li2-item"
                  @click.stop="getIndex(index2, item2, 2)"
                >
                  <span style="font-size: 12px"
                    ><icon-svg
                      class="jiantou"
                      :class="{ jiantous: item2.isshow }"
                      icon-class="jiantou"
                    ></icon-svg
                  ></span>
                  {{ item2.name }}
                  <div class="title-right">
                    <div @click.stop>
                      <el-popover
                        class="nodeModifiy"
                        placement="right"
                        width="60"
                        :open-delay="delay"
                        trigger="hover"
                        v-if="item2.isWrite === 1"
                        v-show="role === 'student' ? false : true"
                      >
                        <!-- style="display:flex;flex-direction:column" -->
                        <div
                          class="nodeModifiy-div"
                          v-show="item2.isWrite === 1 || role === 'admin'"
                        >
                          <span @click.stop="openRename(item2)">重命名</span>
                          <span @click.stop="deleteNode(item2, index2)"
                            >删除</span
                          >
                        </div>
                        <span
                          slot="reference"
                          style="font-size: 10px; margin-right: 16px"
                          v-show="item2.isWrite === 1"
                        >
                          <icon-svg icon-class="gengduo"></icon-svg>
                        </span>
                      </el-popover>
                    </div>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      :open-delay="delay"
                      content="点击+可以添加下级分类"
                      placement="top-start"
                      v-if="item2.isAdd === 1"
                      v-show="role === 'student' ? false : true"
                    >
                      <span
                        @click.stop="openAddNode(item2)"
                        style="font-size: 11px; margin-right: 16px"
                      >
                        <icon-svg icon-class="jiahaocu"></icon-svg>
                      </span>
                    </el-tooltip>
                  </div>
                </li>
                <!-- 三级菜单盒子 -->
                <ul v-show="item2.isshow">
                  <li
                    class="zksc-nav-li3-title"
                    :class="{ 'zksc-nav-native': nowItem === item3.id }"
                    v-for="(item3, index3) in item2.children"
                    :key="index3"
                  >
                    <ul>
                      <!-- 三级 -->
                      <li
                        class="zksc-nav-li3-item"
                        @click.stop="getIndex(index3, item3, 3)"
                      >
                        <!-- <span style="font-size: 12px"
                          ><icon-svg icon-class="jiantou"></icon-svg
                        ></span> -->
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item3.name"
                          placement="top"
                          :open-delay="delay"
                          :enterable="false"
                        >
                          <span class="zksc-nav-li3-item-span">
                            {{ item3.name }}
                          </span>
                        </el-tooltip>

                        <div class="title-right">
                          <!-- <div @click.stop> -->
                          <el-popover
                            class="nodeModifiy"
                            placement="right"
                            width="60"
                            trigger="hover"
                            :open-delay="delay"
                            v-if="item3.isWrite === 1"
                            v-show="role === 'student' ? false : true"
                          >
                            <!-- style="display:flex;flex-direction:column" -->
                            <div
                              class="nodeModifiy-div"
                              v-show="item3.isWrite === 1 || role === 'admin'"
                            >
                              <span @click.stop="openRename(item3)"
                                >重命名</span
                              >
                              <span @click.stop="deleteNode(item3, index3)"
                                >删除</span
                              >
                            </div>
                            <span
                              slot="reference"
                              style="font-size: 10px; margin-right: 16px"
                              v-show="item3.isWrite === 1"
                            >
                              <icon-svg icon-class="gengduo"></icon-svg>
                            </span>
                          </el-popover>
                        </div>
                        <!-- </div> -->
                      </li>
                    </ul>
                  </li>
                </ul>
              </ul>
            </li>
          </ul>
        </ul>
      </li>
    </ul>

    <!-- 添加子节点对话框 -->
    <el-dialog
      title="新增分组"
      :visible.sync="addNodedialog"
      width="30%"
      :before-close="closeAddNodedialog"
      class="zksc-dialog"
      @close="closeAdd"
    >
      <div class="zksc-dialog-content">
        <span>
          <em></em>
          分组名称
        </span>
        <el-input placeholder="请输入分类名称" v-model="nodeName"></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addNodedialog = false">取 消</el-button>
        <el-button type="primary" @click="addNode">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 重命名对话框 -->
    <el-dialog
      title="重命名分组"
      :visible.sync="renameDialog"
      width="30%"
      :before-close="closeRenameDialog"
      class="zksc-dialog"
      @close="closeDel"
    >
      <div class="zksc-dialog-content">
        <span>
          <em></em>
          分组名称
        </span>
        <el-input placeholder="请输入分类名称" v-model="nodeNames"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="renameDialog = false">取 消</el-button>
        <el-button type="primary" @click="renameNode">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="删除分组"
      :visible.sync="delDialog"
      width="33%"
      :before-close="closeRenameDialog"
      class="zksc-dialog"
      @close="closeDel"
    >
      <div>
        温馨提示：确认删除将删除此菜单内所有课件，但不会影响课程。如果您已经发起提交到公共资源库，那么证书管理员的审核列表里将不再有这一条审核，但如果已通过的话将不会影响。
      </div>
      <div class="zksc-dialog-content">
        <span>
          <em></em>
          分组名称：
        </span>
        <el-input placeholder="请输入分类名称" v-model="delInput"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialog = false">取 消</el-button>
        <el-button type="primary" @click="delNode">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import iconSvg from './icon/iconSvg.vue';
export default {
  components: { iconSvg },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      nowId: '',
      alterId: '',
      inputBorder: false,
      /* 添加节点 */
      addNodedialog: false,
      nodeName: '',
      /* 重命名节点 */
      renameDialog: false,
      nodeNames: '',
      tempList: [],
      tempNode: {},
      sliderName: [],
      activeIndex2: '1',
      menuListss: [],
      clickItem: null,
      role: null,
      silderId: null,
      delId: null,
      delName: null,
      delDialog: false,
      delInput: '',
      listFlag: false,
      listFlag2: false,
      nowItem: null,
      //重命名 一级id
      clickitemId: null,
      alterItem: null,
      delay: 500,
      levels: null,
    };
  },
  props: {
    menuLists: [],
    type: {},
  },
  mounted() {
    this.role = sessionStorage.getItem('role');
  },
  // mounted() {
  //   this.role = sessionStorage.setItem('role');
  // },
  created() {
    this.silderId = localStorage.getItem('silderId');
    // console.log(this.menuLists);
    this.menuListss = this.menuLists;
    // console.log(this.menuListss);
    this.menuListss.forEach((item) => {
      // item.isshow = false;
      this.$set(item, 'isshow', false);
      if (item.children) {
        item.children.forEach((items) => {
          this.$set(items, 'isshow', false);
        });
      }
    });
    // 每次加载进来默认打开一级菜单下的第一个二级菜单的第一个列表
    if (sessionStorage.getItem('one')) {
      this.getSilderId();
    } else {
      this.moren();
    }
  },
  watch: {
    menuLists: function (newData, oldData) {
      // console.log(newData, oldData);
      if (newData !== oldData) {
        this.menuListss = newData;
      } else {
        this.menuListss = oldData;
      }
    },
    deep: true,
  },
  computed: {
    menuListsAlter: function () {
      return this.menuLists;
    },
  },
  methods: {
    // 添加完成默认跳转添加页
    getSilderId() {
      // this.getList();
      let one = sessionStorage.getItem('one');
      let two = sessionStorage.getItem('two');
      let three = sessionStorage.getItem('three');
      // console.log(one, '11111111');
      // console.log(two, '2222222');
      // console.log(three, '3333333');
      //先通过id吧该id项的索引找到
      let ones = this.menuListss.findIndex((item) => item.id === three);
      // console.log(ones);
      let twos = this.menuListss[ones].children.findIndex(
        (item) => item.id === two
      );
      let threes = this.menuListss[ones].children[twos].children.findIndex(
        (item) => item.id === one
      );
      this.$set(this.menuListss[ones], 'isshow', true);
      this.$set(this.menuListss[ones].children[twos], 'isshow', true);

      // this.menuListss[ones].isshow = true;
      // this.menuListss[ones].children[twos].isshow = true;
      // this.menuListss[ones].children[twos].children[threes].isshow = true;
      this.nowItem = one;
      // 吧侧边栏组件的信息传递给父组件
      this.$emit('getIds', this.menuListss[ones]);
      this.$emit('getIds', this.menuListss[ones].children[twos]);
      // console.log(this.menuListss[ones].children[twos].children);
      // console.log(threes);
      this.$emit(
        'getIds',
        this.menuListss[ones].children[twos].children[threes]
      );

      // 最后吧本地的数据给清掉
      sessionStorage.removeItem('one');
      sessionStorage.removeItem('two');
      sessionStorage.removeItem('three');
    },
    // handleNodeClick(data) {
    //   console.log(data);
    // },
    /**每次切换二级路由，侧边栏数据重新渲染的时候聚焦到首个有内容的侧边栏 */
    moren() {
      // console.log(this.menuListss);
      for (let i = 0; i < this.menuListss.length; i++) {
        if (this.menuListss[i].children) {
          for (let j = 0; j < this.menuListss[i].children.length; j++) {
            if (this.menuListss[i].children[j].children) {
              // console.log(i);
              // console.log(j);
              // console.log(this.menuListss[i]);
              // console.log(this.menuListss[i].children[j]);
              // console.log(this.menuListss[i].children[j].children[0]);
              // 打开一级菜单 并把一级菜单传递给父组件
              this.$set(this.menuListss[i], 'isshow', true);
              this.$emit('getIds', this.menuListss[i]);
              // 打开二级菜单 并把二级菜单传递给父组件
              this.$set(this.menuListss[i].children[j], 'isshow', true);
              this.$emit('getIds', this.menuListss[i].children[j]);
              // 聚焦到三级，并把三级菜单的信息传递给父组件
              this.nowItem = this.menuListss[i].children[j].children[0].id;
              this.$emit('getIds', this.menuListss[i].children[j].children[0]);
              return;
            }
          }
        }
      }
    },
    /**每次点击侧边栏的时候 index是第几个 item是每一项的具体数据  i是层级 */
    getIndex(index, item, i) {
      this.clickItem = item.id;
      this.nowId = item.id;
      // console.log(item);
      // console.log(index, item, i);
      // item.isshow = !;
      let isshow = !item.isshow;
      this.$set(item, 'isshow', isshow);

      if (i === 1) {
        this.$emit('getIds', item);
        // console.log(item);
      }
      if (i === 2) {
        this.$emit('getIds', item);
        // console.log(item);
        if (item.level === 2) {
          // console.log(item.parentId);
          /**根据点击的二级菜单的父级id去获取总侧边栏数据里找到该条数据的父级数据拿到之后传递给父组件 */
          let data = this.menuListss.findIndex(
            (items) => items.id === item.parentId
          );
          // console.log(data);
          this.$emit('getIds', this.menuListss[data]);
          this.$emit('getIds', item);
        }
      }
      if (i === 3) {
        // console.log(item);
        this.nowItem = item.id;
        // console.log(item.parentId); // 三级的侧边栏的父级ID
        for (let i = 0; i < this.menuListss.length; i++) {
          if (this.menuListss[i].children) {
            let data = this.menuListss[i].children.findIndex(
              (items) => items.id === item.parentId
            );
            if (data !== -1) {
              // console.log(data);
              // console.log(i);
              // console.log(this.menuListss[i]);
              // console.log(this.menuListss[i].children[data]);
              this.$emit('getIds', this.menuListss[i]);
              this.$emit('getIds', this.menuListss[i].children[data]);
              this.$emit('getIds', item);

              // console.log(this.menuListss[i].children);
              // let datas = this.menuListss[i].children[data].findIndex(
              //   (itemss) => itemss.id === item.parentId
              // );
              // console.log(datas);xieburou
            }
          }
        }

        // console.log(data);
        // // console.log(data)
        // let datas = this.menuListss.findIndex(
        //   (itemss) => itemss.id === this.menuListss[data].parentId
        // );
        // console.log(data, datas);

        // console.log(this.nowItem);
      }
      // this.listFlag = !this.listFlag;
    },
    // getIndex2(index, item) {
    //   console.log(index, item);
    //   item.isshow = !item.isshow;
    //   this.itemindex = index;
    //   // this.listFlag = !this.listFlag;
    // },
    closeAdd() {
      this.nodeName = '';
    },
    closeDel() {
      this.delInput = '';
      this.nodeNames = '';
    },
    // getId(item) {
    // console.log(item);
    // if (item.level >= 2) {
    // console.log(this.nowId);
    // }
    // console.log(item.name.concat(item.name));
    // console.log(item.level);
    // },
    // openMenu(index) {
    // console.log('open', index);
    // },
    openAddNode(item) {
      this.addNodedialog = true;
      this.nowId = item.id;
      this.levels = item.level;
    },
    closeAddNodedialog() {
      this.addNodedialog = false;
    },
    /**添加 */
    addNode() {
      const that = this;
      // let node = {
      //   index: new Date().toString(),
      //   router: '',
      //   name: this.nodeName,
      //   children: [],
      // };
      // this.tempList.push(node);
      // this.addNodedialog = false;
      if (this.nodeName === '') {
        this.$message({
          type: 'warning',
          message: '请输入名称',
        });
      }
      let data = {
        menuId: this.nowId,
        name: this.nodeName,
        cerificateId: this.silderId,
      };
      this.$axios.post('/teach/courseware/insertOneWare', data).then((res) => {
        // console.log(res.data.data);
        if (res.data.code === 200) {
          that.nodeName = '';
          that.addNodedialog = false;
          that.getList().then(() => {
            // console.log(that.levels, '---------------****-----');
            if (that.levels == 2) {
              sessionStorage.setItem('one', res.data.data.menuId);
              sessionStorage.setItem('two', res.data.data.firstMenuId);
              sessionStorage.setItem('three', res.data.data.secondMenuId);
              that.getSilderId();
            } else {
              that.moren();
            }
          });
          // console.log(this.menuLists);
        }
      });
    },
    // 获取列表数据
    async getList() {
      const that = this;
      await this.$axios
        .get(
          `/teach/courseware/findMenuWare?cerificateId=${this.silderId}&type=${this.type}`
        )
        .then((res) => {
          // console.log(res.data); //teach/courseware/findMenuWare
          if (res.data.code === 200) {
            if (this.role === 'admin' || this.role === 'superadmin') {
              // console.log(res.data.data[0]);
              that.menuListss = [];
              that.menuListss.push(res.data.data[0]);
              // that.moren();
            } else {
              that.menuListss = res.data.data;
              // that.moren();
            }
            // console.log(that.menuListss);
          }
        });
    },
    openRename(item) {
      // console.log(item);
      this.renameDialog = true;
      this.nodeNames = item.name;
      this.alterId = item.id;
      this.alterItem = item;
      this.levels = item.level;
      if (this.role === 'teacher') {
        this.clickitemId = this.menuListss[1].id;
      } else if (this.role === 'admin') {
        this.clickitemId = this.menuListss[0].id;
      }
      // sessionStorage.setItem('one',item.menuId);
      // sessionStorage.setItem('two',this.clickitemId);
      // sessionStorage.setItem('three',item.parentId);
    },
    closeRenameDialog() {
      this.renameDialog = false;
    },
    /**重命名 */
    renameNode() {
      const that = this;
      // this.tempNode.name = this.nodeName;
      // this.renameDialog = false;
      if (this.nodeNames === '') {
        this.$message({
          type: 'error',
          message: '分组名不能为空!',
        });
      } else {
        // console.log(this.clickitemId);

        // console.log(this.clickitemId);
        // console.log(this.alterItem.parentId);
        // console.log(this.alterItem.id);
        this.$axios
          .put(
            `/teach/courseware/updateOneWare?menuId=${this.alterId}&name=${this.nodeNames}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功!',
              });
              this.renameDialog = false;

              this.getList().then(() => {
                if (that.levels > 2) {
                  // console.log(this.alterItem.id)
                  // console.log(this.alterItem.parentId))
                  // console.log(this.clickitemId)
                  sessionStorage.setItem('one', this.alterItem.id);
                  sessionStorage.setItem('two', this.alterItem.parentId);
                  sessionStorage.setItem('three', this.clickitemId);
                  that.getSilderId();
                } else {
                  that.moren();
                }
              });
            } else {
              this.$message({
                type: 'error',
                message: '修改失败!',
              });
            }
          });
      }
    },
    /**删除一个侧边栏 */
    delNode() {
      const that = this;
      if (this.delInput === '') {
        this.$message({
          type: 'error',
          message: '请输入该分组名称',
        });
      } else if (this.delInput === this.delName) {
        this.$axios
          .delete(`/teach/courseware/delMenuWare?menuId=${this.delId}`)
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              this.getList().then(() => {
                console.log(this.menuListss);
                if (this.levels == 3) {
                  // sessionStorage.setItem('one', res.data.data.menuId); //3级id
                  sessionStorage.setItem('two', res.data.data.firstMenuId); //2级id
                  sessionStorage.setItem('three', this.clickitemId); //1级id
                  // let yi = this.clickitemId
                  let er = res.data.data.firstMenuId;
                  // let san = res.data.data.menuId
                  // 通过id找到点击的三级的一级索引
                  let one = this.menuListss.findIndex(
                    (item) => item.id === this.clickitemId
                  );
                  // 通过id找到点击的三级的二级索引lengdoa
                  // console.log(this.menuListss[one].children);
                  let twos = this.menuListss[one].children.findIndex(
                    (items) => items.id == er
                  );
                  console.log(twos);
                  // 判断二级菜单有没有子集
                  if (this.menuListss[one].children[twos].children) {
                    // 如果有的话
                    console.log('有子集');
                    this.$set(this.menuListss[one], 'isshow', true);
                    this.$set(
                      this.menuListss[one].children[twos],
                      'isshow',
                      true
                    );
                    this.$set(
                      this.menuListss[one].children[twos].children[0],
                      'isshow',
                      true
                    );
                    this.nowId =
                      this.menuListss[one].children[twos].children[0].id;
                    console.log(this.nowId);
                    console.log(
                      this.menuListss[one].children[twos].children[0].id
                    );
                    sessionStorage.setItem(
                      'one',
                      this.menuListss[one].children[twos].children[0].id
                    );
                    this.$emit('getIds', this.menuListss[one]);
                    this.$emit('getIds', this.menuListss[one].children[twos]);
                    // console.log(this.menuListss[ones].children[twos].children);
                    // console.log(threes);
                    this.$emit(
                      'getIds',
                      this.menuListss[one].children[twos].children[0]
                    );
                    console.log(
                      this.menuListss[one].children[twos].children[0]
                    );
                    this.getSilderId();
                  } else {
                    //如果没有的话 把一级和二级展开
                    console.log('没有子集');
                    this.$set(this.menuListss[one], 'isshow', true);
                    this.$set(
                      this.menuListss[one].children[twos],
                      'isshow',
                      true
                    );
                  }
                } else if (that.levels == 2) {
                  that.moren();
                }
              });
              this.delInput = '';
              this.delDialog = false;
            } else {
              this.$message({
                type: 'error',
                message: '删除失败!',
              });
            }
          });
      } else {
        this.$message({
          type: 'error',
          message: '您输入的名称有误',
        });
      }
    },
    // 打开删除的对画框
    deleteNode(item, index) {
      console.log(index);
      console.log(item);
      // for(let i = 0;i<this.menuListss.length;i++){

      // }
      this.delId = item.id;
      this.delName = item.name;
      this.delDialog = true;
      this.alterItem = item;
      this.levels = item.level;
      if (this.role === 'teacher') {
        this.clickitemId = this.menuListss[1].id;
      } else if (this.role === 'admin') {
        this.clickitemId = this.menuListss[0].id;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-navMeun {
  width: 300px;
  margin-right: 16px;
  background-color: #fff;
  border-radius: 4px;
  // .zksc-nav {
  // .zksc-nav-li {
  //   li:hover {
  //     cursor: pointer;
  //     background: red;
  //   }
  // }
  // }
  // li:hover {
  //   background: red;
  // }

  /deep/.zksc-input {
    // box-sizing: border-box;
    height: 40px;
    margin: 0 auto;
    position: relative;
    margin: 16px;
    border: 1px solid #666;
    border-radius: 4px;
    .el-input__inner {
      border-color: transparent;
    }
    .el-input__inner:focus {
      border-color: transparent;
    }
    .el-input__inner:hover {
      border-color: transparent;
    }
    .el-input-group__append {
      background-color: transparent;
      border-color: transparent;
    }
    .el-button {
      border-color: transparent;
    }
    // display: flex;
    // align-items: center;
    .el-input {
      border: none;
      &:hover {
        border: none;
      }
      &:active {
        border: none !important;
      }
      &:focus {
        border: none !important;
      }
    }
    &:hover {
      border-color: #409eff;
    }
    &:focus {
      border-color: #409eff;
    }
    &:active {
      border-color: #409eff;
    }
  }
  /deep/.zksc-submenu {
    border-right: none;
    color: #666;
    margin-top: 8px;
    .grad-one {
      margin: 0 8px;
    }
    .zksc-submenu-sub {
      margin: 0 8px;
      border-radius: 40px;
      .zksc-submenu {
        .el-menu--inline {
          .is-opened {
            background: #f1f3f5 !important; // 当前点击的侧边栏
          }
        }
      }
    }
    .el-submenu__title {
      height: 40px;
      border-radius: 4px;
    }
    .el-submenu__title:hover {
      background-color: #f1f3f5;
    }
    .el-submenu__title:focus {
      background-color: #f1f3f5;
    }
    .title {
      display: flex;
      justify-content: space-between;
      height: 40px;
      .title-left {
        display: flex;
        align-items: center;
      }
      .title-right {
        display: flex;
        align-items: center;
        /deep/.nodeModifiy {
          .nodeModifiy-div {
            width: 80px;
            display: flex;
            flex-direction: column;
            .span {
              width: 74px;
              height: 36px;
              line-height: 74px;
              text-align: center;
            }
          }
        }
      }
    }
    .svg-icon {
      font-size: 8px;
      margin-right: 8px;
      color: #666;
      vertical-align: 0;
    }
    .el-icon-arrow-down {
      display: none;
    }
    .el-menu-item {
      height: 40px;
      padding-right: 20px;
    }
    .el-menu-item:focus {
      background-color: #f1f3f5;
    }
    .el-menu-item:hover {
      background-color: #f1f3f5;
    }
    .el-submenu {
      transform: rotate(0deg) !important;
      transition: all 0.3s !important;
      font-size: 14px !important;
    }

    .is-opened {
      .jiantous {
        transform: rotate(90deg) !important;
        transition: all 0.3s !important;
        font-size: 14px !important;
      }
    }
    .is-opened {
      .is-opened {
        .jiantou {
          transform: rotate(90deg) !important;
          transition: all 0.3s !important;
          font-size: 14px !important;
        }
      }
    }
  }
  .zksc_isopen {
    .is-opened {
      background: #f1f3f5 !important;
    }
  }
}
.zksc-nav-native {
  background-color: #f1f3f5;
}
/deep/.zksc-dialog {
  .el-dialog__header {
    height: 70px !important;
    background: #409eff !important;
    padding: 0 !important;

    line-height: 70px;
    text-align: center;
    .el-dialog__title {
      color: #ffffff !important;
      font-size: 24px !important;
    }
    .el-dialog__headerbtn {
      top: 30px;
      right: 20px;
    }
    .el-dialog__close {
      color: #fff !important;
    }
  }
  .zksc-dialog-content {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 100px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    .el-button {
      margin-right: 10px;
    }
  }
}
.zksc-nav {
  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
  padding-right: 15px;
  li {
    // height: 40px;
    line-height: 40px;
    border-radius: 4px;
    padding: 1px 0px 1px 15px;
    .title-right {
      float: right;
      div {
        float: left;
      }
    }
  }
}
.zksc-nav-li-title:hover {
  cursor: pointer;
  background: #fff;
}
.zksc-nav-li-item:hover {
  cursor: pointer;
  background: #f1f3f5;
}
.zksc-nav-li2-title:hover {
  cursor: pointer;
  background: #fff;
}
.zksc-nav-li2-item:hover {
  cursor: pointer;
  background: #f1f3f5;
}
.zksc-nav-li3-title:hover {
  cursor: pointer;
  background: #f1f3f5;
}
// .zksc-nav-li3-item:hover {
//   cursor: pointer;
//   background: #f1f3f5;
// }
.jiantou {
  transform: rotate(0deg) !important;
  transition: all 0.3s !important;
  font-size: 14px !important;
}
.jiantous {
  transform: rotate(90deg) !important;
  transition: all 0.3s !important;
  font-size: 14px !important;
}
.one-title-box {
  transition: height 0.3s ease !important;
}
// .one-title-boxs {
//   transition: height 3s ease !important;
// }
.zksc-nav-li3-item-span {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 190px;
  height: 30px;
  line-height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<style>
.el-popover {
  min-width: 74px !important;
  padding: 0 !important;
}
.nodeModifiy-div {
  display: flex;
  flex-direction: column;
}
.nodeModifiy-div span {
  width: 74px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
}
.nodeModifiy-div span:hover {
  background: #f1f3f5;
}
.ospan {
  transform: rotate(90deg) !important;
  transition: all 0.3s !important;
  font-size: 14px !important;
}
.aspan {
  transform: rotate(0deg) !important;
  transition: all 0.3s !important;
  font-size: 14px !important;
}
.icon_jiantou {
  /* color: red !important; */
}
/* .is-opened {
  .jiantou {
    transform: rotate(90deg) !important;
    transition: all 0.3s !important;
    font-size: 14px !important;
  }
} */

/* .zksc-nav-li-title:hover {
  cursor: pointer;
  background: red;
} */
</style>
