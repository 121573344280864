import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';

import router, { resetRouter } from '../router/aysncRouter';
Vue.use(Vuex);

/* vuex 模块导入 */
const modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  state: {
    silderId: '1',
    messageNum: 0,
    noticeNum: 0,
    workNum: 0,
    examNum: 0,
  },
  mutations: {
    saveSilderId(state, num) {
      state.silderId = num;
    },
    add(state) {
      // 变更状态
      state.messageNum++;
    },
    addNotice(state) {
      // 变更状态
      state.noticeNum++;
    },
    addWork(state) {
      // 变更状态
      state.workNum++;
    },
    addExam(state) {
      // 变更状态
      state.examNum++;
    },
    delMessage(state) {
      state.messageNum = 0;
    },
    delNotice(state) {
      state.noticeNum = 0;
    },
    delWork(state) {
      state.workNum = 0;
    },
    delExam(state) {
      state.examNum = 0;
    },
    initMessage(state, num) {
      state.messageNum = num;
    },
    initNotice(state, num) {
      state.noticeNum = num;
    },
    initWork(state, num) {
      state.workNum = num;
    },
    initExam(state, num) {
      state.examNum = num;
    },
  },
  actions: {
    /**
     * 根据role 生产路由表
     *
     * @param {*} { commit, dispatch }
     * @param {*} role
     */
    async changeRoles({ dispatch }, role) {
      /* 角色 */
      const roles = [role];
      // 清空之前的路由表
      resetRouter();

      // 通过角色生成路由列表
      const accessRoutes = await dispatch('permission/generateRoutes', roles, {
        root: true,
      });

      // addRoutes 废弃  采用addRoute
      // router.addRoutes(accessRoutes)
      router.addRoute(accessRoutes[0]);
      // 重置访问的视图和缓存的视图
      /* TODO */
      // dispatch('tagsView/delAllViews', null, { root: true })
    },
  },
  modules,
  getters,
});
