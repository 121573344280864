<template>
  <div
    class="zksc-courseDesign"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="zksc-newCourse">{{ title }}</div>

    <el-form class="zksc-msgBox">
      <el-form-item>
        <div class="zksc-msgBox-title">课程信息</div>
        <div class="zksc-input-box">
          <em class="hongdian"></em>
          <el-input
            class="zksc-base"
            placeholder="请输入课程名称"
            v-model="className"
          />
        </div>
      </el-form-item>

      <el-form-item class>
        <div class="zksc-richbox zksc-input-box">
          <em class="hongdian"></em>
          <!-- <RichText
            class="zksc-richText"
            @change="richTextChang"
            :detail="richText"
            ref="rText"
          ></RichText> -->
          <!-- <span>{{ 2000 - richTextLength }}</span> -->
          <el-input
            class="zksc-base"
            placeholder="请输入课程描述"
            v-model="classContent"
          />
        </div>
      </el-form-item>

      <el-form-item class="zksc-chapter">
        <div class="zksc-msgBox-title">课程章节</div>
        <transition-group class="chapter-box" name="list-complete" tag="ul">
          <li
            class="chapter-item list-complete-item"
            v-for="(v, i) in chapterList"
            :key="i"
            :class="{ active: i == isChoice }"
            @click="choiceChapter(i)"
          >
            {{ v }}
          </li>
          <li key="-1" class="chapter-item add" @click="addChapter(1)">
            <icon-svg iconClass="jiahaocu"></icon-svg>
          </li>
          <li key="-2" class="chapter-item add">
            <el-popconfirm
              confirm-button-text="好的"
              cancel-button-text="不用了"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除最后一个章节吗？"
              @confirm="delChapter"
            >
              <icon-svg iconClass="jian" slot="reference"></icon-svg>

              <!-- <el-button slot="reference" >删除</el-button> -->
            </el-popconfirm>
          </li>
        </transition-group>
        <span
          v-show="addzhangjie === false"
          class="addzhangjieBtn"
          @click="tianjiazhangjie"
          >添加章节介绍</span
        >
      </el-form-item>

      <!-- 学习内容多级列表 -->
      <template v-if="studyList.length > 0">
        <div v-show="addzhangjie" style="background: #fff">
          <div style="width: 1080px; background: #fff; padding: 10px 0">
            <el-input
              @blur.native.capture="nameBlur"
              class="zksc-base"
              @input="inputName"
              @change="inputChange"
              placeholder="请输入章节标题"
              v-model="zhangjieName"
              maxlength="20"
            />
          </div>
          <div style="width: 1080px; background: #fff">
            <RichText
              class="zksc-richText"
              @change="richTextChang"
              @blur.native.capture="richBlur"
              :detail="richText"
              ref="editorRef"
            ></RichText>
          </div>
          <span v-show="addzhangjie" class="delzhangjie" @click="delzhanjie">
            <icon-svg iconClass="shanchu"></icon-svg>
            删除</span
          >
        </div>

        <h1 class="title_h1">公共资源库</h1>
        <!-- <p>{{ publicTree[0].arr }}</p>
        <p>{{ isChoice }}</p> -->
        <el-form-item
          class="zksc-learning-content"
          v-for="items in publicTree[isChoice].arr"
          :key="items.id + 's'"
        >
          <CourseTree :list="items" @change="courseTreeChange"></CourseTree>
        </el-form-item>
        <h1 class="title_h1" v-show="localityTree[isChoice].arr.length > 0">
          本地资源库
        </h1>
        <el-form-item
          class="zksc-learning-content"
          v-for="items in localityTree[isChoice].arr"
          :key="items.id + 's'"
        >
          <CourseTree :list="items" @change="courseTreeChange"></CourseTree>
        </el-form-item>
      </template>
      <el-empty v-if="studyList === []" description="暂无内容"></el-empty>

      <el-form-item class="zksc-btn-group">
        <!-- <el-button type="primary" @click="postRich">保存</el-button> -->
        <el-button type="primary" @click="createCourse">保存</el-button>
        <el-button @click="quit">退出</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import RichText from '../../common/richText.vue';
import CourseTree from './courseTree/courserTree.vue';
import fileUtil from '../../../utils/index';
export default {
  components: {
    RichText,
    CourseTree,
  },
  data() {
    return {
      title: '新建课程',
      addzhangjie: false,
      classContent: '',
      chapterList: [1],
      richTextLength: 0,
      richText: '',
      studyList: [],
      className: '',
      isChoice: 0,
      courseId: 0, // 课程id
      capterContent: [
        /* 章节内容 */
        {
          courseId: 0 /* 课程id */,
          section: 1 /* 第几章节 */,
          pricate: [] /**实操题 */,
          softwareId: [
            /* 软件教程 */
            // {
            //   /* 二级菜单 */
            //   // softwareId2: 25,
            //   /* 三级菜单 */
            //   // softwareId3: [97],
            // },
          ],
          /* 题目 */
          theoryId: [],
        },
      ],
      studyTree: [
        // {
        //   courseId: '',
        //   section: 1,
        //   arr: [
        //     {
        //       id: '123',
        //       name: '软件学习',
        //       arr: [
        //         {
        //           id: '111',
        //           name: 'MAYA',
        //           checkAll: false,
        //           isIndeterminate: false,
        //           /* -1 未选， 0  半选  1全选 */
        //           checkStatus: '-1',
        //           type: 0,
        //           arr: [
        //             { id: 1, name: '剪刀工具1', checked: false },
        //             { id: 2, name: '剪刀工具2', checked: false },
        //             { id: 458, name: '剪刀工具3', checked: false },
        //           ],
        //         },
        //         {
        //           id: '1111111',
        //           name: 'MAYA2',
        //           checkAll: false,
        //           isIndeterminate: false,
        //           /* -1 未选， 0  半选  全选 */
        //           checkStatus: '-1',
        //           type: 0,
        //           arr: [{ id: '', name: '剪刀ghjghjg工具', checked: false }],
        //         },
        //       ],
        //     },
        //     {
        //       id: '656sdd',
        //       name: '理论题库',
        //       arr: [
        //         {
        //           id: '1115',
        //           name: '理论题库',
        //           checkAll: false,
        //           isIndeterminate: false,
        //           /* -1 未选， 0  半选  全选 */
        //           checkStatus: '-1',
        //           type: 1,
        //           arr: [
        //             { id: 568, name: '剪刀工具1', checked: false },
        //             { id: 2254, name: '剪刀工具2', checked: false },
        //             { id: 5653, name: '剪刀工具3', checked: false },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   courseId: '',
        //   section: 2,
        //   arr: [
        //     {
        //       id: '15379',
        //       name: '软件学习',
        //       arr: [
        //         {
        //           id: '111',
        //           name: 'MAYA',
        //           checkAll: false,
        //           isIndeterminate: false,
        //           /* -1 未选， 0  半选  全选 */
        //           checkStatus: '-1',
        //           type: 0,
        //           arr: [
        //             { id: 1, name: '剪刀工具1', checked: false },
        //             { id: 2, name: '剪刀工具2', checked: false },
        //             { id: 3, name: '剪刀工具3', checked: false },
        //           ],
        //         },
        //         {
        //           id: '1111111',
        //           name: 'MAYA2',
        //           checkAll: false,
        //           isIndeterminate: false,
        //           /* -1 未选， 0  半选  全选 */
        //           checkStatus: '-1',
        //           type: 0,
        //           arr: [{ id: 90, name: '剪刀jkljkljl工具', checked: false }],
        //         },
        //       ],
        //     },
        //     {
        //       id: '14561',
        //       name: '理论题库',
        //       arr: [
        //         {
        //           id: '111sd',
        //           name: '理论题库',
        //           checkAll: false,
        //           isIndeterminate: false,
        //           /* -1 未选， 0  半选  全选 */
        //           checkStatus: '-1',
        //           type: 1,
        //           arr: [
        //             { id: 1212, name: '剪刀工具1', checked: false },
        //             { id: 2121, name: '剪刀工具2', checked: false },
        //             { id: 3131, name: '剪刀工具3', checked: false },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      /** 富文本数组 */
      richData: [],
      nameData: [],
      zhangjieName: '',
      /* 测试数据 */
      tempSecond: {},
      publicData: [],
      publicTree: [],
      localityData: [],
      localityTree: [],
      oss: {},
      huixianData: null,
      loading: false,
    };
  },
  mounted() {
    this.getStudyList();
    this.getOss();
  },
  methods: {
    inputName() {
      if (this.zhangjieName.length == 20) {
        this.$message({ type: 'warning', message: '最多输入20个字符！' });
      }
    },
    inputChange() {
      this.nameData[this.isChoice];
      // console.log(this.zhangjieName);
      this.nameData.splice(this.isChoice, 1, this.zhangjieName);
    },
    /**
     * 取消添加章节
     */
    delzhanjie() {
      // console.log(this.$refs.editorRef.editor.txt.html());
      this.addzhangjie = false;
      this.zhangjieName = '';
      this.$refs.editorRef.clearTxt();
      this.richText = '';
      this.richData.splice(this.isChoice, 1, '');
      this.nameData.splice(this.isChoice, 1, '');
      // console.log(this.richData);
      // console.log(this.nameData);
      // console.log(this.isChoice);
    },
    /**
     * 确定添加章节
     */
    tianjiazhangjie() {
      this.addzhangjie = true;
      // console.log(this.richData);
    },
    /**
     * 上传富文本
     */
    async postRich() {
      // this.$axios.post('/teach/courseware/ossSignature').then((res) => {
      //   console.log(res);
      // });
      for (let i = 0; i < this.richData.length; i++) {
        if (this.richData[i] !== '') {
          let txt = this.richData[i];
          let list = this.oss;
          let str = new Blob([txt], { type: 'text/plain;charset=utf-8' });
          let fileName = fileUtil.getFileName(
            'fuwenben',
            list.dir,
            'chapter-txt'
          );
          let formData = new FormData();
          formData.append('key', fileName);
          formData.append('policy', list.policy);
          formData.append('OSSAccessKeyId', list.accessId);
          formData.append('success_action_status', '200');
          formData.append('signature', list.signature);
          formData.append('file', str, 'fuwenben.txt');
          let config = {
            headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
          };
          await this.$axios.post(list.host, formData, config).then((res) => {
            // console.log(res);
            if (res.status < 300) {
              let annexDTO = {
                fileName: 'fuwenben.txt',
                fileSize: str.size,
                objectname: fileName,
              };
              this.capterContent[i].annexDTO = annexDTO;
            }
          });
        }
        this.capterContent[i].sectionName = this.nameData[i];
      }
    },
    /**
     * 获取oss签名
     */
    getOss() {
      this.$axios.post('/teach/courseware/ossSignature').then((res) => {
        // console.log(res);
        if (res.data.code === 200) {
          this.oss = res.data.data;
        }
      });
    },
    /**
     * 名称输入框失去焦点
     */
    nameBlur() {
      if (this.zhangjieName === '') {
        return;
      } else {
        if (this.zhangjieName === this.nameData[this.isChoice]) {
          return;
        } else {
          if (this.richTzhangjieNameext === '') {
            this.nameData.push(this.zhangjieName);
          } else {
            this.nameData.splice(this.isChoice, 1, this.zhangjieName);
          }
        }
      }
    },
    /**
     * 富文本失去焦点
     */
    richBlur() {
      // console.log(this.richText); //这个是当前富文本内容
      // console.log(this.$refs.editorRef.editor.txt.html());
      if (this.richText === '') {
        return;
      } else {
        if (this.richText === this.richData[this.isChoice]) {
          return;
        } else {
          // console.log(this.richText);
          if (this.richText === '') {
            this.richData.push(this.richText);
            // console.log(this.richData);
          } else {
            this.richData.splice(this.isChoice, 1, this.richText);
            // console.log(this.richData);
          }

          // console.log(this.richData);
          // this.$refs.editorRef.clearTxt();
          // this.richText = '';
          // if()
        }
      }
    },
    /**
     * 退出
     */
    quit() {
      this.$router.push('/courseManage');
    },
    /**
     * 获取学习内容列表
     */
    async getStudyList() {
      this.loading = true;
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   // spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)',
      // });
      this.$axios.get('/teach/course/routing').then((res) => {
        let { data } = res;
        if (data.code !== 200) {
          this.loading = false;
          return this.$message.error(data.message);
        }
        if (data.data.length == 0) {
          this.loading = false;
          return this.$message.warning('暂时没有数据');
        }

        this.studyList = data.data;
        // console.log('1111', this.studyList);
        this.studyList.map((item) => {
          if (item.parnetName === '本地资源库') {
            this.localityData.push(item);
          } else {
            this.publicData.push(item);
          }
        });
        // .then(() => {
        /**本地 */
        this.localityData.map((v) => {
          // console.log(v);
          /* 标签存储 */
          this.$set(v, 'tags', []);
          /* 动态添加两个状态 */
          if (v) {
            v.arr.map((v2) => {
              // console.log(v2);
              this.$set(v2, 'checkAll', false);
              this.$set(v2, 'checkStatus', -1);
              this.$set(v2, 'grade', 1);
              v2.arr.map((v3) => {
                this.$set(v3, 'grade', 2);
                this.$set(v3, 'checked', false);
              });
            });
          }
        });
        // console.log(this.localityData);
        this.localityTree.push({
          section: this.isChoice + 1,
          arr: JSON.parse(JSON.stringify(this.localityData)),
        });
        // console.log('this.localityTree', this.localityTree, '313');
        /**公共 */
        this.publicData.map((v) => {
          // console.log(v);
          /* 标签存储 */
          this.$set(v, 'tags', []);
          /* 动态添加两个状态 */
          if (v) {
            v.arr.map((v2) => {
              this.$set(v2, 'checkAll', false);
              this.$set(v2, 'checkStatus', -1);
              this.$set(v2, 'grade', 1);
              if (v2.arr.length)
                v2.arr.map((v3) => {
                  this.$set(v3, 'grade', 2);
                  this.$set(v3, 'checked', false);
                });
            });
          }
        });
        this.publicTree.push({
          section: this.isChoice + 1,
          arr: JSON.parse(JSON.stringify(this.publicData)),
        });
        // console.log(this.localityTree);
        // console.log(this.publicTree);
        // });

        // console.log(this.localityData);
        // console.log(this.publicData);

        // this.studyList.map((v) => {
        //   console.log(v);
        //   /* 标签存储 */
        //   this.$set(v, 'tags', []);
        //   /* 动态添加两个状态 */
        //   if (v) {
        //     v.arr.map((v2) => {
        //       this.$set(v2, 'checkAll', false);
        //       this.$set(v2, 'checkStatus', -1);
        //       this.$set(v2, 'grade', 1);
        //       v2.arr.map((v3) => {
        //         this.$set(v3, 'grade', 2);
        //         this.$set(v3, 'checked', false);
        //       });
        //     });
        //   }
        // }); // console.log(' this.studyList', this.studyList);
        /* 默认第一章 */
        // this.studyTree.push({
        //   section: this.isChoice + 1,
        //   arr: JSON.parse(JSON.stringify(this.studyList)),
        // });
        /* 编辑 数据回显操作 */
        if (this.$route.query.status == 'edit') {
          this.dataEcho();
          this.title = '编辑课程';
        } else {
          this.loading = false;
        }
        /* 结束加载动画 */
        // setTimeout(() => {
        //   this.loading = false;
        // }, 500);
      });
    },

    /**
     * 富文本字数统计
     */
    richTextChang(text) {
      // const regex = /<\/?.+?>/g;
      // this.richTextLength = text.replace(regex, '').length;
      this.richText = text;
      // if (this.richTextLength > 2000) {
      //   this.$message.warning('内容超出');
      // }
    },
    /**
     * 添加章节
     */
    addChapter(i) {
      // console.log(this.$refs.editorRef.editor.txt.html());
      // console.log('---392+++----', this.localityTree);
      // console.log(this.$route.query.status);
      if (i === 1) {
        //
        console.log('111');
        this.richText = '';
        this.$refs.editorRef.clearTxt();
        // console.log('1111');
        this.zhangjieName = '';
        this.richData.push('');
        this.nameData.push('');
      } else {
        // console.log('222');
        // this.richText = '';
        // this.$refs.editorRef.clearTxt();
        // console.log('1111');
        // this.zhangjieName = '';
        // this.richData.push('');
        // this.nameData.push('');
        // console.log(this.richData);
      }

      this.isChoice = this.chapterList.length;
      this.chapterList.push(this.chapterList.length + 1);
      // console.log(this.chapterList);

      // TODO 后台所需数据
      this.capterContent.push({
        courseId: this.courseId,
        section: this.chapterList.length,
        /**实操题 */
        pricate: [],
        /* 软件教程 */
        softwareId: [],
        /* 题目 */
        theoryId: [],
      });
      // TODO 渲染所需数据
      // console.log(this.isChoice);
      // console.log(JSON.parse(JSON.stringify(this.studyList)));

      this.localityTree.push({
        // section: this.isChoice + 1
        section: this.isChoice,
        arr: JSON.parse(JSON.stringify(this.localityData)),
      });
      // console.log(this.localityTree);

      this.publicTree.push({
        // section: this.isChoice + 1
        section: this.isChoice,
        arr: JSON.parse(JSON.stringify(this.publicData)),
      });
      // console.log(this.publicTree);
    },
    delChapter() {
      if (this.chapterList.length > 1) {
        this.chapterList.splice(this.chapterList.length - 1, 1);
        this.capterContent.splice(this.capterContent.length - 1, 1);
        this.richData.splice(this.richData.length - 1, 1);
        this.nameData.splice(this.nameData.length - 1, 1);
        this.isChoice = this.chapterList.length - 1;
        this.zhangjieName = this.nameData[this.nameData.length - 1];
        this.richText = this.richData[this.richData.length - 1];
        // console.log(this.isChoice);
        // console.log(this.richText);
        this.richText = this.$refs.editorRef.editor.txt.html(this.richText);
        // this.choiceChapter(this.isChoice);
      } else {
        this.$message({
          type: 'warning',
          message: '不能删除所有章节',
        });
      }
    },
    /**
     * 章节选择
     */
    choiceChapter(i) {
      // console.log('1111111');
      // this.loading = true;
      // console.log(i);
      if (i === this.isChoice) {
        return;
      }
      // this.loading = true;
      // this.zhangjieName = '';
      // this.richText = '';
      this.zhangjieName = this.nameData[i];
      this.richText = this.richData[i];
      // console.log(this.richText);
      // console.log(this.richData);
      // console.log(this.richData);
      // console.log(this.richText);
      this.$refs.editorRef.editor.txt.html(this.richData[i]);
      // console.log(this.richText);
      console.log(this.richData);
      console.log(this.nameData);
      this.isChoice = i;
      // console.log('------');
      // console.log('this.publicData[i]', this.publicTree);
      if (!this.localityTree[i]) {
        this.localityTree.push({
          section: this.isChoice + 1,
          arr: JSON.parse(JSON.stringify(this.localityData)),
        });
        // this.loading = false;
      }
      if (!this.publicTree[i]) {
        this.publicTree.push({
          section: this.isChoice + 1,
          arr: JSON.parse(JSON.stringify(this.publicData)),
        });
        // this.loading = false;
      }
      // console.log(this.studyTree, 'this.studyTree');
      // this.loading = false;
    },
    /**
     * 创建课程
     */
    async createCourse() {
      /* 上传 课程名称 和 富文本内容 获取课程id */

      if (this.$route.query.status == 'edit') {
        /* 编辑 */
        /* 编辑标题和富文本内容 */
        // {
        //     name: this.className,
        //     content: this.richText,
        //     courseId: ~~this.$route.query.courseId,
        //   }

        // 为何不get？
        let flags = true;
        let i;
        this.nameData.forEach((v, s) => {
          // console.log(v);
          // console.log(i);
          if (v.length > 20) {
            i = s;
            return (flags = false);
          }
        });
        if (flags === false) {
          return this.$message.warning(`第${i + 1}个章节名称最多输入20个字符`);
        }
        // for (let i = 0; i < this.nameData.length; i++) {
        //   if (this.nameData[i].length > 20) {
        //     return this.$message.warning(
        //       `第${i + 1}+个章节名称最多输入20个字符`
        //     );
        //   }
        // }
        // const loading = this.$loading({
        //   lock: true,
        //   text: 'Loading',
        //   // spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)',
        // });
        this.loading = true;
        let data = {
          content: this.classContent,
          id: this.$route.query.courseId,
          name: this.className,
        };
        this.$axios.post('/teach/course/updateCourse', data).then((res) => {
          let { data } = res;
          if (data.code !== 200) {
            return this.$message.error('编辑失败');
          }

          // console.log(data, '修改');
          /* 删除章节重新船舰章节 */
          // console.log(this.capterContent, 'capterContent');
          // this.deleteCapter(this.$route.query.courseId);
          this.createCapter();

          this.capterContent.map((v) => {
            v.courseId = this.$route.query.courseId;
          });
          this.loading = false;

          // this.$router.push({
          //   path: '/courseManage',
          // });
        });
      } else {
        this.nameData.forEach((v, i) => {
          // console.log(v);
          // console.log(i);
          if (v.length > 20) {
            this.$message({
              type: 'warning',
              message: `第${i + 1}个章节名称最多输入20个字符`,
            });
            return;
          }
        });

        /* 新增 */
        if (this.className === '' || this.classContent === '') {
          this.$message({
            type: 'error',
            message: '请将信息补充完整！',
          });
          return;
        }
        this.loading = true;

        this.$axios
          .post('/teach/course/addCourse', {
            name: this.className,
            content: this.classContent,
          })
          .then((res) => {
            let { data } = res;
            // console.log(data, '新增---');
            if (data.code !== 200 || data.data == -1) {
              return this.$message.error('创建课程失败');
            }
            this.courseId = data.data;
            this.capterContent.map((v) => (v.courseId = this.courseId));
            // console.log('this.capterContent: ', this.capterContent);
            this.createCapter();
            //
          });
      }

      /* 通过课程id 去添加章节 */
    },
    /* 删除章节 */
    deleteCapter(courseId) {
      this.$axios
        .delete(`/teach/course/deleteSection?courseId=${courseId}`)
        .then((res) => {
          let { data } = res;
          // console.log(data, '删除---');
          if (data.code !== 200) {
            return this.$message.error('编辑失败');
          }
          /* 重新创建章节 */
          this.createCapter();
        });
    },
    /**
     * 添加章节
     */
    async createCapter() {
      this.loading = true;
      if (this.className.trim().length == 0) {
        return this.$message.error('请填写课程名称');
      } else if (this.richTextChang < 0) {
        return this.$message.error('请填写课程简介');
      }
      /* 重新给定课程id */
      // console.log(this.capterContent);
      await this.postRich();
      await this.$axios
        .post('/teach/course/chapter', this.capterContent)
        .then((res) => {
          // console.log(res);
          let { data } = res;
          if (data.code !== 200) {
            if (this.$route.query.status == 'edit') {
              this.loading = false;
              return this.$message.error('编辑失败');
            }
            this.loading = false;
            return this.$message.error('创建课程失败');
          }
          if (this.$route.query.status == 'edit') {
            this.loading = false;
            this.$message.success('编辑成功');
            this.$router.push({
              path: '/courseManage',
            });
          } else {
            this.loading = false;
            this.$message.success('创建课程成功,即将跳转到课程管理页');
            this.$router.push({
              path: '/courseManage',
            });
            /* 清除数据 */
            this.className = '';
            this.richText = '';
            // this.$nextTick(() => {
            //   this.$refs.rText.updateEditorData();
            // });
            this.chapterList = [1];
            this.isChoice = 0;
            this.capterContent = [
              /* 章节内容 */
              {
                courseId: 0 /* 课程id */,
                section: 1 /* 第几章节 */,
                softwareId: [
                  /* 软件教程 */
                  // {
                  //   /* 二级菜单 */
                  //   // softwareId2: 25,
                  //   /* 三级菜单 */
                  //   // softwareId3: [97],
                  // },
                ],
                /* 题目 */
                theoryId: [],
              },
            ];
            this.studyTree = [
              {
                courseId: '',
                section: 1,
                arr: JSON.parse(JSON.stringify(this.studyList)),
              },
            ];
            /* 跳转到课程列表页 */
            // setTimeout(() => {
            // this.$router.push({
            //   path: '/courseManage',
            // });
            // }, 2000);
          }
        });
    },
    /**
     * 获取选择数据进行整理
     */
    courseTreeChange(data) {
      // console.log(data, 'data----');
      /* 二级菜单id */
      let id = data.softwareId2.id;
      // console.log(data.softwareId2);
      /* 资源进行判断 1 为题目 0 为课件 */
      const arr = data.softwareId2.arr;
      // console.log('arr: ', arr);
      if (data.softwareId2.type == 1) {
        /* 校验是否存在题目id */
        // if (this.capterContent[this.isChoice].theoryId.indexOf(id) == -1) {
        //   /* 不存在将 id 插入到 theoryId 中*/
        //   this.capterContent[this.isChoice].theoryId.push(id);
        // }

        if (data.softwareId2.checkAll == true) {
          // console.log('data.softwareId2', data.softwareId2);
          arr.map((v) => {
            this.capterContent[this.isChoice].theoryId.push(v.id);
            this.capterContent[this.isChoice].theoryId = [
              ...new Set(this.capterContent[this.isChoice].theoryId),
            ];
          });
        } else {
          // console.log('00000');
          arr.map((soft3) => {
            if (!soft3.checked) {
              let s3index = this.capterContent[
                this.isChoice
              ].theoryId.findIndex((s3) => s3 == soft3.id);
              this.capterContent[this.isChoice].theoryId.splice(s3index, 1);
            }
          });
          let th = this.capterContent[this.isChoice].theoryId;
          this.capterContent[this.isChoice].theoryId = [
            ...new Set([...th, ...data.softwareId3]),
          ];
          // let theo = this.capterContent[this.isChoice].theoryId.findIndex(
          //   (sitem) => sitem.softwareId2 === id
          // );
          // console.log('theo: ', theo);
          // if (theo !== -1) {
          //   this.capterContent[this.isChoice].theoryId.splice(theo, 1);
          // }
          // if (data.softwareId3.length > 0) {
          //   let th = this.capterContent[this.isChoice].theoryId;
          //   this.capterContent[this.isChoice].theoryId = [
          //     ...new Set([...th, ...data.softwareId3]),
          //   ];
          // }
        }
      } else if (data.softwareId2.type == 0) {
        /* kejianziyuan */
        if (data.softwareId2.checkAll == true) {
          // console.log('data.softwareId2', data.softwareId2);
          let soft = this.capterContent[this.isChoice].softwareId.findIndex(
            (sitem) => sitem.softwareId2 === id
          );
          // exit'
          if (soft !== -1) {
            const softwareId3 =
              this.capterContent[this.isChoice].softwareId[soft].softwareId3;
            let arr = [...softwareId3, ...data.softwareId3];
            //  data.softwareId3
            this.capterContent[this.isChoice].softwareId[soft].softwareId3 = [
              ...new Set(arr),
            ];
          } else {
            this.capterContent[this.isChoice].softwareId.push({
              softwareId2: id,
              softwareId3: data.softwareId3,
            });
          }
        } else {
          let soft = this.capterContent[this.isChoice].softwareId.findIndex(
            (sitem) => sitem.softwareId2 === id
          );
          // shang
          if (soft !== -1) {
            this.capterContent[this.isChoice].softwareId.splice(soft, 1);
          }
          if (data.softwareId3.length > 0) {
            this.capterContent[this.isChoice].softwareId.push({
              softwareId2: id,
              softwareId3: [...new Set(data.softwareId3)],
            });
          }
        }
      } else {
        /* 校验是否存在题目id------------------实操题库选择--------------*/
        /* kejianziyuan */
        if (data.softwareId2.checkAll == true) {
          // console.log('data.softwareId2', data.softwareId2);
          let soft = this.capterContent[this.isChoice].pricate.findIndex(
            (sitem) => sitem.softwareId2 === id
          );
          // exit'
          if (soft !== -1) {
            const softwareId3 =
              this.capterContent[this.isChoice].pricate[soft].softwareId3;
            let arr = [...softwareId3, ...data.softwareId3];
            //  data.softwareId3
            this.capterContent[this.isChoice].pricate[soft].softwareId3 = [
              ...new Set(arr),
            ];
          } else {
            this.capterContent[this.isChoice].pricate.push({
              softwareId2: id,
              softwareId3: data.softwareId3,
            });
          }
        } else {
          let soft = this.capterContent[this.isChoice].pricate.findIndex(
            (sitem) => sitem.softwareId2 === id
          );
          // shang
          if (soft !== -1) {
            this.capterContent[this.isChoice].pricate.splice(soft, 1);
          }
          if (data.softwareId3.length > 0) {
            this.capterContent[this.isChoice].pricate.push({
              softwareId2: id,
              softwareId3: [...new Set(data.softwareId3)],
            });
          }
          // }
        }
      }
      // console.log(this.localityTree, 'this.localityTree');
      // console.log(this.capterContent, 'this.capterContent');
    },
    /**
     * 递归回显富文本内容
     */
    richContent(num) {
      // console.log(this.huixianData.section.length);
      // console.log(num);
      if (num < this.huixianData.section.length) {
        // this.loading = true;
        // console.log(num);
        // console.log(this.huixianData.section[0]);
        let data = this.huixianData.section[num];
        // console.log(data);
        if (
          this.huixianData.section[0].sectionName !== '' ||
          this.huixianData.section[0].annxUrl !== 'null'
        ) {
          this.addzhangjie = true;
        }
        if (data.annxUrl !== 'null') {
          // 富文本循环渲染
          if (data.sectionName === '') {
            this.nameData.push('');
            this.$axios.get(data.annxUrl).then((res) => {
              // console.log(res);
              if (res.status === 200) {
                this.richData.push(res.data);
                // console.log(this.richData);
                this.zhangjieName = this.nameData[0];
                this.richText = this.richData[0];
                this.$nextTick(() => {
                  this.$refs.editorRef.updateEditorData();
                });
                // this.loading = false;
                this.richContent(num + 1);
              }
            });
            // this.richData.push('');
          } else {
            this.nameData.push(data.sectionName);
            // console.log(data.annxUrl);
            this.$axios.get(data.annxUrl).then((res) => {
              // console.log(res);
              if (res.status === 200) {
                this.richData.push(res.data);
                // console.log(this.richData);
                this.zhangjieName = this.nameData[0];
                this.richText = this.richData[0];
                this.$nextTick(() => {
                  this.$refs.editorRef.updateEditorData();
                });
                // this.loading = false;
                this.richContent(num + 1);
              }
            });
            // this.choiceChapter(0);
          }
        } else {
          this.richData.push('');
          this.nameData.push(data.sectionName);
          // this.richText = '';
          // this.nameData.push('');

          this.richContent(num + 1);
        }
      } else {
        this.loading = false;
      }
    },
    /* 数据回显 */
    dataEcho() {
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   // spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)',
      // });
      let id = this.$route.query.courseId;
      this.$axios.get(`/teach/course/showCourse?courseId=${id}`).then((res) => {
        let { data } = res;
        // console.log(data, '回显数据');
        if (data.code !== 200) {
          return this.$message.error('获取数据失败');
        }
        this.className = data.data.name;
        this.classContent = data.data.content;
        // console.log(data.data);
        this.huixianData = data.data;
        this.richContent(0);
        // loading.close();
        // data.data.section.map((v) => {
        //   // console.log(v, '-----------------');
        //   if (v.name !== '') {
        //     this.addzhangjie = true;
        //   }
        //   // 富文本循环渲染
        //   if (v.sectionName === '') {
        //     this.nameData.push('');
        //     this.richData.push('');
        //   } else {
        //     this.nameData.push(v.sectionName);
        //     this.$axios.get(v.annxUrl).then((res) => {
        //       console.log(res);
        //       if (res.status === 200) {
        //         this.richData.push(res.data);
        //         // console.log(this.richData);
        //         this.zhangjieName = this.nameData[0];
        //         this.richText = this.richData[0];
        //         this.$nextTick(() => {
        //           this.$refs.editorRef.updateEditorData();
        //         });
        //       }
        //     });
        //     // this.choiceChapter(0);
        //   }
        // });
        // console.log(data.data);
        // let zhangjie = data.data.section;
        // console.log(zhangjie);
        // for (let i = 0; i < zhangjie.length; i++) {
        // console.log(zhangjie[i]);
        // }
        // this.$axios.get(data.data.content).then((res) => {
        //   if (res.status !== 200) return this.$message.error('获取数据失败');
        //   this.richText = res.data;
        //   /* 富文本回显 */
        //   // this.$nextTick(() => {
        //   //   this.$refs.rText.updateEditorData();
        //   // });
        // });
        /* 树回显 */
        this.treeEcho(data.data.section);
        // console.log(data.data.section, '670');

        /* 标签回显 */
        this.tagsEcho();
      });

      /* 根据data 去重新渲染studyTree */
    },
    /* 树回显 BUG 找我，千万不要动，一动就死 */
    async treeEcho(treeData) {
      // console.log(treeData);
      // console.log('treeData---', treeData);
      /* 章节编号回显 */
      // this.studyTree = [];
      this.localityTree = [];
      this.publicTree = [];
      // this.nameData = [];
      // this.richData = [];
      // console.log(this.nameData);
      // console.log('---392+++----', this.localityTree);
      for (let i = 0; i < treeData.length; i++) {
        this.addChapter();
        if (i > 0) this.isChoice++;
      }
      // console.log(this.localityTree, '-----692----'); //bendi
      /* 删除最后一个 */
      this.chapterList.pop();
      // console.log('this.chapterList: ', this.chapterList);
      this.capterContent.pop();
      // console.log('this.capterContent: ', this.capterContent);
      this.isChoice = 0;
      // console.log(treeData, '694');
      /* 最底层数据v4 确定 v3 状态 */
      treeData.map((v) => {
        // console.log(v, '-----------------');
        // if (v.name !== '') {
        //   this.addzhangjie = true;
        // }
        // // 富文本循环渲染
        // if (v.sectionName === '') {
        //   this.nameData.push('');
        //   this.richData.push('');
        // } else {
        //   this.nameData.push(v.sectionName);
        //   this.$axios.get(v.annxUrl).then((res) => {
        //     console.log(res);
        //     if (res.status === 200) {
        //       this.richData.push(res.data);
        //     }
        //   });
        //   console.log(this.nameData);
        // }
        this.publicTree.map((v1, i) => {
          // console.log(v1);
          if (v.sectionId == v1.section) {
            // console.log(v, '---++');
            // v1.theoryId.map(v2=>{
            v1.arr.map((v2) => {
              // console.log(v2);
              v2.arr.map((v3) => {
                v3.arr.map((v4) => {
                  v.theoryId.map((tid) => {
                    // console.log(v4, 'vvvvvvvvvvvvvvvvv4444444444');
                    // console.log(v3.name);
                    if (tid == v4.id) {
                      if (v3.name === '实操题库' || v3.name === '理论题库') {
                        v4.checked = true;
                      }
                    }
                  });
                  v.softwareId.map((tid) => {
                    if (tid == v4.id) {
                      if (v3.name !== '实操题库' || v3.name !== '理论题库') {
                        v4.checked = true;
                      }
                      // v4.checked = true;
                    }
                  });
                  v.priceId.map((tid) => {
                    if (tid == v4.id) {
                      if (v3.name !== '实操题库' || v3.name !== '理论题库') {
                        v4.checked = true;
                      }
                      // v4.checked = true;
                    }
                  });

                  // console.log(v4, 'v3');
                  for (let i = 0; i < treeData.length; i++) {
                    this.capterContent[i].theoryId = treeData[i].theoryId;
                  }
                  // treeData[i].theoryId.map((treeIndex) => {
                  //   // console.log('treeIndex',treeIndex);
                  //   // console.log(v3.id,'v3');
                  //   if (v3.id == treeIndex) {
                  //     v3.arr.map((v3th) => {
                  //       console.log(v3th.id,'v3th');
                  //       this.capterContent[i].theoryId.push(v3th.id);
                  //       this.capterContent[i].theoryId = [
                  //         ...new Set(this.capterContent[i].theoryId),
                  //       ];
                  //     });
                  //   }
                  // });

                  /* 回显后台所需的 课件数据 */
                  let flag = true;
                  if (v4.checked == true && v3.type == 0) {
                    /* 判断this.capterContent中是否存在softwareId2  */
                    this.capterContent[i].softwareId.map((cap) => {
                      if (cap.softwareId2 == v3.id) {
                        cap.softwareId3.push(v4.id);
                        /* 去重 */
                        cap.softwareId3 = [...new Set(cap.softwareId3)];
                        flag = false;
                      }
                    });
                    /* 如果没有匹配到id 插入 */
                    if (flag) {
                      let obj = {
                        softwareId2: v3.id,
                        softwareId3: [v4.id],
                      };
                      this.capterContent[i].softwareId.push(obj);
                    }
                  }
                  /**实操题库回显数据保存 */
                  if (v4.checked == true && v3.type == 4) {
                    /* 判断this.capterContent中是否存在softwareId2  */
                    this.capterContent[i].pricate.map((cap) => {
                      if (cap.softwareId2 == v3.id) {
                        cap.softwareId3.push(v4.id);
                        /* 去重 */
                        cap.softwareId3 = [...new Set(cap.softwareId3)];
                        flag = false;
                      }
                    });
                    /* 如果没有匹配到id 插入 */
                    if (flag) {
                      let obj = {
                        softwareId2: v3.id,
                        softwareId3: [v4.id],
                      };
                      this.capterContent[i].pricate.push(obj);
                    }
                  }
                });
                const len = v3.arr.filter((v) => v.checked == true).length;
                if (len == v3.arr.length) {
                  v3.checkAll = true;
                  v3.checkStatus = 1;
                } else if (len == 0) {
                  v3.checkAll = false;
                  v3.checkStatus = -1;
                } else {
                  v3.checkStatus = 0;
                }
                // console.log(v3,'v3');
              });
            });
            // })
          }
        });
        this.localityTree.map((v1, i) => {
          // console.log(v1, 'v1');
          if (v.sectionId == v1.section) {
            // console.log(v, '---++');
            // v1.theoryId.map(v2=>{
            v1.arr.map((v2) => {
              // console.log(v2, 'v2');
              v2.arr.map((v3) => {
                // console.log(v3, 'v3');
                v3.arr.map((v4) => {
                  // console.log(v4, 'v4');
                  // v是后端传过来的要选中的数据，theoryId是题库，softwareId是课件
                  v.theoryId.map((tid) => {
                    // console.log(v4, 'vvvvvvvvvvvvvvvvv4444444444');
                    // console.log(v3.name);
                    if (tid == v4.id) {
                      if (v3.name === '实操题库' || v3.name === '理论题库') {
                        v4.checked = true;
                      }
                    }
                  });
                  v.softwareId.map((tid) => {
                    if (tid == v4.id) {
                      if (v3.name !== '实操题库' || v3.name !== '理论题库') {
                        v4.checked = true;
                      }
                      // v4.checked = true;
                    }
                  });
                  v.priceId.map((tid) => {
                    if (tid == v4.id) {
                      if (v3.name !== '实操题库' || v3.name !== '理论题库') {
                        v4.checked = true;
                      }
                      // v4.checked = true;
                    }
                  });
                  // console.log(v4, 'v3');
                  for (let i = 0; i < treeData.length; i++) {
                    this.capterContent[i].theoryId = treeData[i].theoryId;
                  }
                  treeData[i].theoryId.map((treeIndex) => {
                    // console.log('treeIndex',treeIndex);
                    // console.log(v3.id,'v3');
                    if (v3.id == treeIndex) {
                      v3.arr.map((v3th) => {
                        // console.log(v3th.id, 'v3th');
                        this.capterContent[i].theoryId.push(v3th.id);
                        this.capterContent[i].theoryId = [
                          ...new Set(this.capterContent[i].theoryId),
                        ];
                      });
                    }
                  });
                  /* 回显后台所需的 课件数据 */
                  let flag = true;
                  if (v4.checked == true && v3.type == 0) {
                    /* 判断this.capterContent中是否存在softwareId2  */
                    // console.log(this.capterContent);
                    this.capterContent[i].softwareId.map((cap) => {
                      if (cap.softwareId2 == v3.id) {
                        cap.softwareId3.push(v4.id);
                        /* 去重 */
                        cap.softwareId3 = [...new Set(cap.softwareId3)];
                        flag = false;
                      }
                    });
                    /* 如果没有匹配到id 插入 */
                    if (flag) {
                      let obj = {
                        softwareId2: v3.id,
                        softwareId3: [v4.id],
                      };
                      this.capterContent[i].softwareId.push(obj);
                    }
                  }
                  // console.log(v4);
                  // console.log(v3);
                  /**实操题库回显数据保存 */
                  if (v4.checked == true && v3.type == 4) {
                    /* 判断this.capterContent中是否存在softwareId2  */
                    this.capterContent[i].pricate.map((cap) => {
                      if (cap.softwareId2 == v3.id) {
                        cap.softwareId3.push(v4.id);
                        /* 去重 */
                        cap.softwareId3 = [...new Set(cap.softwareId3)];
                        flag = false;
                      }
                    });
                    /* 如果没有匹配到id 插入 */
                    if (flag) {
                      let obj = {
                        softwareId2: v3.id,
                        softwareId3: [v4.id],
                      };
                      this.capterContent[i].pricate.push(obj);
                    }
                  }
                });
                const len = v3.arr.filter((v) => v.checked == true).length;
                // console.log(len);
                // console.log(v3.arr.length);
                if (len > 0 && len == v3.arr.length) {
                  v3.checkAll = true;
                } else if (len == 0) {
                  v3.checkAll = false;
                  v3.checkStatus = -1;
                } else {
                  v3.checkStatus = 0;
                }
                // console.log(v3, 'v3');
              });
            });
            // })
          }
        });
      });
      // treeData.map((item, index) => {
      //   this.localityTree.map((v, i) => {
      //     // console.log(v,'v');
      //     v.arr.map((v2) => {
      //       v2.arr.map((v3) => {
      //         v3.arr.map((v4) => {
      //           /* 数据展示回显 */
      //           /* 到最底层判断 章节  */
      //           if (index == i) {
      //             /* id相等,checke 为true */
      //             /* 课件回显 */
      //             if (item.softwareId.length > 0) {
      //               item.softwareId.map((idx) => {
      //                 if (idx == v4.id) v4.checked = true;
      //               });

      //               let v3arr = v3.arr.filter((v33) => v33.checked == true);
      //               // console.log('v3arr', v3arr);
      //               // 样式全选控制
      //               if (v3arr.length == v3.arr.length) {
      //                 v3.checkAll = true;
      //                 /* 半选控制 */
      //               }
      //               if (v3.arr.length - v3arr.length > 0) {
      //                 v3.checkStatus = 1;
      //               } else {
      //                 v3.checkStatus = -1;
      //               }
      //             }

      //             /* 题库回显 */
      //             item.theoryId.map((idx) => {
      //               if (idx == v4.id) v4.checked = true;
      //             });
      //             // console.log(v4, '--------');
      //             let v3arr1 = v3.arr.filter((v33) => v33.checked == true);
      //             // 样式全选控制
      //             if (v3arr1.length == v3.arr.length) {
      //               v3.checkAll = true;
      //               /* 半选控制 */
      //             } else if (v3.arr.length - v3arr1.length > 0) {
      //               v3.checkStatus = 1;
      //             } else {
      //               v3.checkStatus = -1;
      //               // v3.checkAll = false;
      //             }

      //             // item.theoryId.map((index) => {
      //             //   if (index == v3.id) {
      //             //     /* 样式控制 */
      //             //     v3.checkAll = true;
      //             //     v3.checkStatus = 1;
      //             //     /* 下级状态控制 */
      //             //     v3.arr.map((v333) => {
      //             //       v333.checked = true;
      //             //     });
      //             //   }
      //             // });
      //             /* 后台所需数据回显 */
      //             /* 直接赋值即可 */
      //             for (let i = 0; i < treeData.length; i++) {
      //               this.capterContent[i].theoryId = treeData[i].theoryId;
      //             }
      //             // treeData[i].theoryId.map((treeIndex) => {
      //             //   // console.log('treeIndex',treeIndex);
      //             //   // console.log(v3.id,'v3');
      //             //   if (v3.id == treeIndex) {
      //             //     v3.arr.map((v3th) => {
      //             //       console.log(v3th.id,'v3th');
      //             //       this.capterContent[i].theoryId.push(v3th.id);
      //             //       this.capterContent[i].theoryId = [
      //             //         ...new Set(this.capterContent[i].theoryId),
      //             //       ];
      //             //     });
      //             //   }
      //             // });

      //             /* 回显后台所需的 课件数据 */
      //             let flag = true;
      //             if (v4.checked == true && v3.type == 0) {
      //               /* 判断this.capterContent中是否存在softwareId2  */
      //               this.capterContent[i].softwareId.map((cap) => {
      //                 if (cap.softwareId2 == v3.id) {
      //                   cap.softwareId3.push(v4.id);
      //                   /* 去重 */
      //                   cap.softwareId3 = [...new Set(cap.softwareId3)];
      //                   flag = false;
      //                 }
      //               });
      //               /* 如果没有匹配到id 插入 */
      //               if (flag) {
      //                 let obj = {
      //                   softwareId2: v3.id,
      //                   softwareId3: [v4.id],
      //                 };
      //                 this.capterContent[i].softwareId.push(obj);
      //               }
      //             }
      //           }
      //         });
      //       });
      //     });
      //   });
      //   this.publicTree.map((v, i) => {
      //     // console.log(v,'v');
      //     v.arr.map((v2) => {
      //       v2.arr.map((v3) => {
      //         v3.arr.map((v4) => {
      //           /* 数据展示回显 */
      //           /* 到最底层判断 章节  */
      //           if (index == i) {
      //             /* id相等,checke 为true */
      //             /* 课件回显 */
      //             item.softwareId.map((idx) => {
      //               if (idx == v4.id) v4.checked = true;
      //             });

      //             // console.log(v4, '--------');
      //             let v3arr = v3.arr.filter((v33) => v33.checked == true);
      //             // 样式全选控制
      //             if (v3arr.length == v3.arr.length) {
      //               v3.checkAll = true;
      //               /* 半选控制 */
      //             } else if (v3.arr.length - v3.arr.length > 0) {
      //               v3.checkStatus = 1;
      //             } else {
      //               v3.checkStatus = -1;
      //             }
      //             // console.log('v3805', v3);
      //             // console.log('item.theoryId 806', item.theoryId);
      //             /* 题库回显 */
      //             item.theoryId.map((idx) => {
      //               if (idx == v4.id) v4.checked = true;
      //             });

      //             // console.log(v4, '--------');
      //             let v3arr1 = v3.arr.filter((v33) => v33.checked == true);
      //             // 样式全选控制
      //             if (v3arr1.length == v3.arr.length) {
      //               v3.checkAll = true;
      //               /* 半选控制 */
      //             } else if (v3.arr.length - v3.arr.length > 0) {
      //               v3.checkStatus = 1;
      //             } else {
      //               v3.checkStatus = -1;
      //             }
      //             // item.theoryId.map((index) => {
      //             //   if (index == v3.id) {
      //             //     /* 样式控制 */
      //             //     v3.checkAll = true;
      //             //     v3.checkStatus = 1;
      //             //     /* 下级状态控制 */
      //             //     v3.arr.map((v333) => {
      //             //       v333.checked = true;
      //             //     });
      //             //   }
      //             // });
      //             /* 后台所需数据回显 */
      //             /* 直接赋值即可 */
      //             for (let i = 0; i < treeData.length; i++) {
      //               this.capterContent[i].theoryId = treeData[i].theoryId;
      //             }
      //             // treeData[i].theoryId.map((treeIndex) => {
      //             //   // console.log('treeIndex',treeIndex);
      //             //   // console.log(v3.id,'v3');
      //             //   if (v3.id == treeIndex) {
      //             //     v3.arr.map((v3th) => {
      //             //       console.log(v3th.id,'v3th');
      //             //       this.capterContent[i].theoryId.push(v3th.id);
      //             //       this.capterContent[i].theoryId = [
      //             //         ...new Set(this.capterContent[i].theoryId),
      //             //       ];
      //             //     });
      //             //   }
      //             // });

      //             /* 回显后台所需的 课件数据 */
      //             let flag = true;
      //             if (v4.checked == true && v3.type == 0) {
      //               /* 判断this.capterContent中是否存在softwareId2  */
      //               this.capterContent[i].softwareId.map((cap) => {
      //                 if (cap.softwareId2 == v3.id) {
      //                   cap.softwareId3.push(v4.id);
      //                   /* 去重 */
      //                   cap.softwareId3 = [...new Set(cap.softwareId3)];
      //                   flag = false;
      //                 }
      //               });
      //               /* 如果没有匹配到id 插入 */
      //               if (flag) {
      //                 let obj = {
      //                   softwareId2: v3.id,
      //                   softwareId3: [v4.id],
      //                 };
      //                 this.capterContent[i].softwareId.push(obj);
      //               }
      //             }
      //           }
      //         });
      //       });
      //     });
      //   });
      // });

      // console.log('this.localityTree901', this.localityTree);
      // console.log('treeData',);
      // console.log(this.studyTree, 'this.studyTree');
      // console.log(this.capterContent, 'this.capterContent');
    },
    /* 右侧标签回显 BUG 找我，千万不要动，一动就死 */
    tagsEcho() {
      this.localityTree.map((v) => {
        v.arr.map((v1) => {
          v1.arr.map((v2) => {
            if (v2.checkAll == true) {
              v1.tags.push(v2);
            } else {
              v2.arr.map((v3) => {
                if (v3.checked == true) {
                  v1.tags.push(v3);
                }
              });
              const arrs = v2.arr.filter((v) => v.checked == true);
              if (arrs.length == v2.arr.length) {
                v2.checkAll = true;
                v2.checkStatus = 1;
              } else if (arrs.length > 0) {
                v2.checkAll = false;
                v2.checkStatus = 0;
              }
            }
          });
        });
      });
      this.publicTree.map((v) => {
        v.arr.map((v1) => {
          v1.arr.map((v2) => {
            if (v2.checkAll == true) {
              v1.tags.push(v2);
            } else {
              v2.arr.map((v3) => {
                if (v3.checked == true) {
                  v1.tags.push(v3);
                }
              });
              const arrs = v2.arr.filter((v) => v.checked == true);
              if (arrs.length == v2.arr.length) {
                v2.checkAll = true;
                v2.checkStatus = 1;
              } else if (arrs.length > 0) {
                v2.checkAll = false;
                v2.checkStatus = 0;
              }
            }
          });
        });
      });
      // console.log('this.studyTree', this.studyTree);
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-courseDesign {
  width: 100%;
  min-height: 100vh;
  background: #f0f2f5;
  overflow: hidden;
  .zksc-newCourse {
    box-sizing: border-box;
    width: 1080px;
    margin: 16px auto;
    background-color: #fff;
    border-radius: 4px;
    padding: 11px 16px;
    color: #333333;
  }
  .zksc-msgBox {
    box-sizing: border-box;
    width: 1080px;
    margin: 0 auto 16px;
    // background-color: #fff;

    .zksc-msgBox-title {
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 4px 4px 0 0;
      padding: 11px 16px;
      color: #333333;
      border-bottom: 1px solid #cccccc;
    }
    .zksc-input-box {
      display: flex;
      align-items: center;
      // box-sizing: border-box;
      margin-left: 16px;
    }
    .el-form-item {
      width: 1080px;
      background-color: #fff;
      margin: 0;
    }
    .zksc-base {
      width: 1030px;
      margin: 11px auto;
      background-color: #fff;
      display: flex;
      justify-content: center;
    }
    .zksc-richbox {
      position: relative;
      border-radius: 4px;
      span {
        position: absolute;
        bottom: 10px;
        right: 30px;
        z-index: 9999;
      }
    }
    .zksc-richText {
      width: 1030px;
      margin: 0 auto 11px;
    }
    .el-form-item__content {
      .addzhangjieBtn {
        display: inline-block;
        background: #409eff;
        padding: 0 12px;
        // margin: 0 auto;
        margin-left: 50%;
        transform: translate(-50%);
        margin-bottom: 10px;
        color: #fff;
        border-radius: 2px;
      }
      .addzhangjieBtn:hover {
        cursor: pointer;
      }
    }
    .zksc-chapter {
      // width: 100%;
      margin-top: 11px;
      border-radius: 4px;

      .chapter-box {
        display: flex;
        flex-wrap: wrap;
        // transition: all ease 0.3s;
        /deep/.chapter-item {
          transition: all 1s;
          box-sizing: border-box;
          width: 32px;
          height: 32px;
          color: #000;
          line-height: 32px;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #666666;
          // background: #409eff;
          margin: 4px 6px;
          cursor: pointer;
          .svg-icon {
            vertical-align: 0;
          }
        }

        .add {
          background-color: #fff;
          border: 1px solid #666666;
          color: #666666;
          font-size: 12px;
        }
        .active {
          color: #fff;
          background: #409eff;
          border-color: transparent;
        }
      }
    }
    .zksc-btn-group {
      margin-top: 30px;
      background-color: transparent;
      .el-button {
        width: 140px;
        margin-right: 20px;
      }
    }
    .zksc-learning-content {
      margin-top: 11px;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}

.list-complete-item {
  transition: all 1s;
}
.list-complete-enter,
.list-complete-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.list-complete-leave-active {
  position: absolute;
}

/* 测试样式 */
.check-content {
  display: flex;
  ul {
    width: 320px;
    height: 300px;
    border: 1px solid #ccc;
  }
}
.title_h1 {
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  background: white;
}
// .addzhangjieBtn {
//   display: inline-block;
//   background: #409eff;
//   padding: 0 12px;
//   // margin: 0 auto;
//   text-align: center;
//   color: #fff;
//   border-radius: 2px;
// }
// .addzhangjieBtn:hover {
//   cursor: pointer;
// }
.delzhangjie {
  display: inline-block;
  background: #f56c6c;
  font-size: 16px;
  padding: 10px 24px;
  // margin: 0 auto;
  margin-bottom: 16px;
  text-align: center;
  color: #fff;
  border-radius: 2px;
  position: relative;
  left: 88%;
}
.delzhangjie:hover {
  cursor: pointer;
}
</style>
