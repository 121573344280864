<template>
  <div class="list">
    <div class="courseware_title">
      <p>
        <span>证书列表</span>
      </p>
      <div>
        <div class="btn_box">
          <el-button type="primary" @click="addCertificate = true"
            >新增</el-button
          >
          <el-button type="info" @click="down">下载模板</el-button>
          <el-button type="info" @click="toLeadFlag = true">导入</el-button>
        </div>
      </div>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        :header-cell-style="{ color: '#333333' }"
      >
        <el-table-column prop="name1" label="序列" width="150">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="证书名称" width="250">
        </el-table-column>
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="realname" label="真实姓名"> </el-table-column>
        <el-table-column prop="role" label="角色"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column prop="name7" label="操作">
          <template slot-scope="scope">
            <span class="btn_alter" @click="alter(scope.row)">编辑</span
            ><span class="btn_del" @click="del(scope.row)">删除</span>
          </template>
        </el-table-column>
        <!-- v-if="tabbar === 0" -->
        <!-- <el-table-column v-else prop="address" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.isUp === 1" effect="plain">已通过</el-tag>
            <el-tag v-else type="warning" effect="dark">未通过</el-tag>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 新增对话框 -->
    <el-dialog
      title="新增证书管理员"
      :visible.sync="addCertificate"
      center
      @close="closeAdd"
    >
      <div class="addbox">
        <el-form label-position="right" label-width="80px">
          <div class="item1">
            <em class="hongdian"></em
            ><el-form-item label="证书名称">
              <el-input v-model="addList.certificateName"></el-input>
            </el-form-item>
          </div>
          <div class="item2">
            <em class="hongdian"></em
            ><el-form-item label="管理员用户名">
              <el-input
                type="number"
                @input="
                  addList.adminName = addList.adminName.replace(/[^\d]/g, '')
                "
                v-model="addList.adminName"
              ></el-input>
            </el-form-item>
          </div>
          <div class="item3">
            <em class="hongdian"></em>
            <el-form-item label="真实姓名">
              <el-input v-model="addList.trueName"></el-input>
            </el-form-item>
          </div>
          <div class="item4">
            <em class="hongdian"></em
            ><el-form-item label="角色">
              <el-input v-model="addList.role" :disabled="true"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addCertificates">确 认</el-button>
        <el-button @click="addCertificate = false">取 消</el-button>
      </span>
    </el-dialog>
    <!--  编辑 -->
    <el-dialog title="编辑证书管理员" :visible.sync="alterCertificate" center>
      <div class="addbox">
        <el-form label-position="right" label-width="80px">
          <div class="item1">
            <em class="hongdian"></em
            ><el-form-item label="证书名称">
              <el-input v-model="alterList.certificateName"></el-input>
            </el-form-item>
          </div>
          <div class="item2">
            <em class="hongdian"></em
            ><el-form-item label="管理员用户名">
              <el-input v-model="alterList.adminName"></el-input>
            </el-form-item>
          </div>
          <div class="item3">
            <el-form-item label="真实姓名">
              <el-input v-model="alterList.trueName"></el-input>
            </el-form-item>
          </div>
          <div class="item4">
            <em class="hongdian"></em
            ><el-form-item label="角色">
              <el-input v-model="alterList.role" :disabled="true"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="alterCertificates">确 认</el-button>
        <el-button @click="alterCertificate = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog
      title="删除证书管理员"
      :visible.sync="delCertificate"
      center
      @close="closeDel"
    >
      <div class="delBox">
        <el-form label-position="right" label-width="80px">
          <div class="item">
            <el-form-item label="证书名称">
              <el-input v-model="delName"></el-input>
            </el-form-item>
          </div>
          <div class="item-two">
            <el-form-item label="用户名">
              <el-input v-model="delUsername"></el-input>
            </el-form-item>
          </div>
          <div class="item-last">
            <el-form-item label="真实姓名">
              <el-input v-model="delOkUserame"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <p class="deltitle">
        成功删除后，证书管理员所添加的班级，老师，学生以及老师添加的资源会一并被删除，请谨慎操作！
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="delCertificates">确 认</el-button>
        <el-button @click="delCertificate = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 模板上传 -->
    <el-dialog title="模板上传" :visible.sync="toLeadFlag" center>
      <el-upload
        accept=".xls"
        class="upload-demo"
        drag
        ref="uploadLilun"
        action="https://jsonplaceholder.typicode.com/posts/"
        multiple
        :auto-upload="false"
        :limit="1"
        :on-change="handleChansge"
      >
        <icon-svg class="shangchaun" icon-class="shangchuan1"></icon-svg>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">请按照模板上传</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toLead">确 认</el-button>
        <el-button @click="toLeadFlag = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      currentPage3: 1,
      pageSize: 10,
      total: 0,
      addCertificate: false,
      alterCertificate: false,
      delCertificate: false,
      toLeadFlag: false,
      addList: {
        certificateName: '',
        trueName: '',
        adminName: '',
        role: '证书管理员',
      },
      alterList: {
        certificateName: '',
        trueName: '',
        adminName: '',
        role: '证书管理员',
      },
      delId: null,
      alterId: null,
      alterUserId: null,
      file: '',
      // 删除证书
      delData: null,
      delOkUserame: '',
      delUsername: '',
      delName: '',
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    closeDel() {
      this.delOkUserame = '';
      this.delUsername = '';
      this.delName = '';
    },
    closeAdd() {
      this.addList.certificateName = '';
      this.addList.trueName = '';
      this.addList.adminName = '';
    },
    toLead() {
      if (this.file === '') {
        this.$message({
          type: 'warning',
          message: '请选择上传文件',
        });
      } else {
        // const that = this;
        let formData = new FormData();
        formData.append('file', this.file);
        let config = {
          headers: { 'Content-Type': 'multipart/form-data', x: 'y' },
        };
        this.$axios
          .post('/teach/supadmin/batchcerificate', formData, config)
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '上传成功',
              });
              this.getTableData();
              this.toLeadFlag = false;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message,
              });
            }
          });
      }
    },
    // 下载模板
    down() {
      window.open('api2/templete/certificate.xls');
    },
    //  确定删除
    delCertificates() {
      if (
        this.delOkUserame === '' ||
        this.delUsername === '' ||
        this.delName === ''
      ) {
        this.$message({
          type: 'warning',
          message: '请将内容补全！',
        });
        return;
      }
      if (
        this.delOkUserame === this.delData.realname &&
        this.delUsername === this.delData.username &&
        this.delName === this.delData.name
      ) {
        this.$axios
          .delete(`/teach/supadmin/delcerificate?cerificateId=${this.delId}`)
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功',
              });
              this.getTableData();
              this.delCertificate = false;
            } else {
              this.$message({
                type: 'error',
                message: '删除失败',
              });
            }
          });
      } else {
        this.$message({
          type: 'error',
          message: '您输入的内容有误！',
        });
      }
    },
    // 确认编辑
    alterCertificates() {
      if (
        this.alterList.certificateName === '' ||
        this.alterList.adminName === '' ||
        this.alterList.role === ''
      ) {
        this.$message({
          type: 'warning',
          message: '噢！我亲爱的超级管理员先生，请您把带红点的信息补全，好嘛？',
        });
      } else {
        let data = {
          name: this.alterList.certificateName,
          realname: this.alterList.trueName,
          role: this.alterList.role,
          username: this.alterList.adminName,
          id: this.alterId,
          userId: this.alterUserId,
        };
        this.$axios.post('/teach/supadmin/deitcerificate', data).then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.$message({
              type: 'success',
              message: '编辑成功',
            });
            this.getTableData();
            this.alterCertificate = false;
          }
        });
      }
    },
    //  新增
    addCertificates() {
      if (
        this.addList.certificateName === '' ||
        this.addList.adminName === '' ||
        this.addList.trueName === '' ||
        this.addList.role === ''
      ) {
        this.$message({
          type: 'warning',
          message: '噢！我亲爱的超级管理员先生，请您把带红点的信息补全，好嘛？',
        });
      } else {
        let data = {
          name: this.addList.certificateName,
          realname: this.addList.trueName,
          role: this.addList.role,
          username: this.addList.adminName,
        };
        this.$axios.post('/teach/supadmin/addcerificate', data).then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            //
            this.$message({
              type: 'success',
              message: '添加成功',
            });
            this.getTableData();
            this.addList.certificateName = '';
            this.addList.trueName = '';
            this.addList.adminName = '';
            this.addCertificate = false;
          }
        });
      }
    },
    // 获取表格数据
    getTableData() {
      this.$axios
        .get(
          `/teach/supadmin/findcerificate?page=${this.currentPage3}&rows=${this.pageSize}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code === 200) {
            this.tableData = res.data.data.items;
            this.total = res.data.data.total;
          }
        });
    },
    // 修改
    alter(item) {
      this.alterList.certificateName = item.name;
      this.alterList.trueName = item.realname;
      this.alterList.adminName = item.username;
      this.alterCertificate = true;
      this.alterId = item.id;
      this.alterUserId = item.userId;
    },
    // 删除
    del(item) {
      this.delData = item;
      // console.log(this.delData);
      this.delId = item.id;
      this.delCertificate = true;
    },
    handleSizeChange() {},
    handleCurrentChange(val) {
      this.currentPage3 = val;
      this.getTableData();
    },
    handleChansge(file) {
      // console.log(file, fileList);
      if (file !== '') {
        this.file = file.raw;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  height: calc(100vh - 100px);

  // display: flex;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
}
.courseware_title {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    text-indent: 15px;
    span {
      font-weight: 600;
      color: #333333;
    }
  }
  .btn_box {
    margin-right: 16px;
    .el-button {
      padding: 10px 56px;
    }
    .el-button:nth-child(2) {
      padding: 10px 42px;
    }
  }
}
.tableBox {
  width: 100%;
  .btn_alter {
    border: 1px solid #666666;
    padding: 5px 14px;
    // margin-right: 16px;
    line-height: 40px;
    border-radius: 4px;
    margin: 5px 8px;
  }
  .btn_del {
    border: 1px solid #e6a23c;
    padding: 5px 14px;
    background: #e6a23c;
    border-radius: 4px;
    color: #fff;
  }
  span:hover {
    cursor: pointer;
  }
}
.block {
  text-align: center;
  margin-top: 32px;
  // position: absolute;
  bottom: 16px;
  left: 50%;
  // transform: translate(-50%, -50%);
  /deep/.btn-prev,
  /deep/.btn-next {
    width: 30px;
    height: 28px;
    padding: 0;
    border: 1px solid #666666;
    border-radius: 4px;
    font-weight: 600;
  }
  /deep/li {
    margin: 0 4px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  /deep/ .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
.addbox {
  width: 579px;
  margin: 0 auto;
  /deep/.el-form-item__label {
    width: 100px !important;
  }
  .el-input {
    width: 468px;
  }
  .item1 {
    position: relative;
    .hongdian {
      position: absolute;
      bottom: 15px;
      left: 20px;
    }
  }
  .item2 {
    position: relative;
    .hongdian {
      position: absolute;
      bottom: 15px;
      left: -10px;
    }
  }
  .item3 {
    position: relative;
    .hongdian {
      position: absolute;
      bottom: 15px;
      left: 15px;
    }
  }
  .item4 {
    position: relative;
    .hongdian {
      position: absolute;
      bottom: 15px;
      left: 48px;
    }
  }
}
.deltitle {
  width: 480px;
  margin: 0 auto;
  color: #ed6a0c;
}

.lead_box_input {
  width: 552px;
  margin: 0 auto;
  div {
    margin: 8px 0;
    .upload-demo {
      width: 468px;
      height: 225px;
      position: relative;
      top: -40px;
      left: 95px;
    }
    /deep/.el-upload-dragger {
      width: 438px;
      height: 225px;
    }
  }
  span {
    width: 100px;
    display: inline-block;
    margin-right: 10px;
  }
  .jeixi {
    // margin-left: 1px;
  }
}
.upload-demo {
  text-align: center;
  margin: 8px 0;
}
// /deep/.el-upload-dragger {
//   width: 300px !important;
//   height: 255px !important;
//   margin: 0 auto;
// }
.title_p {
  text-align: center;
  font-size: 14px;
  color: #333;
}
.title_span {
  display: block;
  text-align: center;
  color: #000000;
  opacity: 0.25;
  font-size: 12px;
}
// .el-input {
//   width: 438px;
//   margin-top: 16px;
// }
.shangchaun {
  color: #409eff;
  margin: 80px 0 20px 0;
  font-size: 44px;
}
.delBox {
  margin: 0 auto;
  width: 560px;
}
</style>
