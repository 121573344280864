<template>
  <div class="part">
    <div class="part-title">
      <p>{{ title }}</p>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: ['title'],
};
</script>
<style lang="less" scoped>
.part {
  width: 33.33%;
  //   min-width: 350px;
  max-height: 710px;

  border-radius: 4px;
  background: #ffffff;
  overflow: auto;
  .part-title {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    height: 50px;
    border-bottom: 1px solid #000;
    p {
      vertical-align: middle;
      margin: 0;
      padding-left: 10px;
      border-left: 4px solid #409eff;
    }
  }
}
</style>
