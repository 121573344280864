<template>
  <div class="zksc-plan" :class="{ 'is-student': role == 'student' }">
    <ul class="side-menu" v-if="role != 'student'">
      <li
        v-for="(v, i) in classMenu"
        :key="v.id"
        :class="{ 'li-active': i == choice }"
        @click="choieClass(i, v)"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="v.name"
          placement="top"
          :open-delay="time"
        >
          <span>
            {{ v.name }}
          </span>
        </el-tooltip>
      </li>
    </ul>
    <div class="zksc-plan-c">
      <div class="zksc-plan-title">
        <h5>{{ className }}</h5>
        <el-button
          v-if="role == 'teacher'"
          type="primary"
          @click="openPlanDialog('new')"
          >新建班级通知</el-button
        >
      </div>
      <!-- <el-empty
        description="暂无通知"
        v-if="planContent.length == 0"
      ></el-empty> -->
      <!-- <div class="zksc-plan-content" v-if="planContent.length == 0">
        暂无通知~~>
      </div> -->

      <div class="zksc-plan-content no-border" v-if="planContent">
        <!-- {{planContent}} -->
        <div class="zksc-plan-item" v-for="v in planContent" :key="v.id">
          <!-- v-html="v.map.richtext" -->
          <div class="rich-text">
            <h2>{{ v.name }}</h2>
            <div v-html="v.map.richtext" class="richtext-content"></div>
          </div>
          <div class="item-bottom">
            <!-- 编辑删除 -->
            <div class="item-btn">
              <!-- 发布 -->
              <el-tooltip
                v-if="role == 'teacher' && v.isUp == 0"
                effect="dark"
                content="发布通知"
                placement="bottom"
              >
                <div class="btn" @click="sendNotice(v.id)">
                  <icon-svg
                    icon-class="daohang_2"
                    style="font-size: 10px; color: #333333"
                  ></icon-svg>
                </div>
              </el-tooltip>
              <!-- 撤回 -->
              <el-tooltip
                v-if="role == 'teacher' && v.isUp == 1"
                effect="dark"
                content="撤回通知"
                placement="bottom"
              >
                <div class="btn" @click="withdrawNotice(v.id)">
                  <icon-svg
                    icon-class="chehui"
                    style="font-size: 10px; color: #333333"
                  ></icon-svg>
                </div>
              </el-tooltip>
              <!-- {{role == 'teacher'&& v.isUp == 0}} -->
              <el-tooltip
                v-if="role == 'teacher' && v.isUp == 0"
                effect="dark"
                content="编辑通知"
                placement="bottom"
              >
                <div class="btn" @click="openPlanDialog('edit', v)">
                  <icon-svg
                    icon-class="bianji_2"
                    style="font-size: 10px; color: #333333"
                  ></icon-svg>
                </div>
              </el-tooltip>

              <el-tooltip
                v-if="role == 'teacher'"
                class="item"
                effect="dark"
                content="删除通知"
                placement="bottom"
              >
                <div class="btn" @click="deletePlan(v.id)">
                  <icon-svg
                    icon-class="shanchu"
                    style="font-size: 10px; color: #d86d86"
                  ></icon-svg>
                </div>
              </el-tooltip>
              <!-- <el-button
                v-if="role == 'teacher'"
                icon="el-icon-edit"
                @click="openPlanDialog('edit', v)"
              ></el-button>-->
              <!-- <el-button v-if="role == 'teacher'" icon="el-icon-delete" @click="deletePlan(v.id)"></el-button> -->
            </div>
            <div class="item-author-msg">
              <img
                :src="
                  v.map.img === 'null' ? '/img/avatar.4e4aa2c3.jpg' : v.map.img
                "
                alt="头像"
              />
              <!-- <img v-else src="/img/avatar.4e4aa2c3.jpg" alt="头像" /> -->
              <span>{{ v.map.realname }}</span>
              <span>{{ v.createTime }}</span>
            </div>
          </div>
        </div>

        <el-pagination
          class="zksc-pagination"
          layout="prev, pager, next, jumper"
          :total="totalPage"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleChangePage"
        ></el-pagination>
        <!--  :current-page="currentPage" -->
      </div>
    </div>

    <!-- 新建通知 -->
    <el-dialog
      class="zksc-dialog"
      :title="title"
      :visible.sync="planDialog"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form :model="uploadPlan" :rules="rules" ref="planRef">
        <el-form-item prop="name">
          <div class="reuqire">
            <em class="hongdian"></em>
            <el-input
              placeholder="请输入标题"
              v-model="uploadPlan.name"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="text">
          <div class="reuqire">
            <em class="hongdian"></em>
            <el-input v-if="false" v-model="uploadPlan.text"></el-input>
            <RichText
              @change="richTextChang"
              :detail="richText"
              ref="richRef"
              style="width: 100%"
            ></RichText>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="planDialog = false">取 消</el-button>
        <el-button type="primary" @click="createPlan">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import RichText from '../../common/richText.vue';
import fileUtil from '../../../utils/index';
import IconSvg from '../../icon/iconSvg.vue';
export default {
  components: {
    RichText,
    IconSvg,
  },
  data() {
    return {
      title: '',
      classMenu: [],
      className: '',
      choice: 0,
      planContent: [],
      planDialog: false,
      richText: '',
      currentPage: 1,
      pageSize: 4,
      totalPage: 0,
      uploadPlan: {
        annexDTO: {
          fileType: 'plan',
          fileName: 'planRichText.txt',
          fileSuffix: '.txt',
          fileSize: '',
          objectname: '',
          resourceType: 'class',
          id: '',
        },
        text: '',
        classId: '',
        name: '',
      },
      rules: {
        name: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        text: [{ required: true, message: '请输入通知内容', trigger: 'blur' }],
      },
      isEdit: false,
      role: '',
      time: 800,
    };
  },
  mounted() {
    this.classId = sessionStorage.getItem('classId');
    this.choice = sessionStorage.getItem('menuIndex');
    this.className = sessionStorage.getItem('className');
    this.role = sessionStorage.getItem('role');
    if (this.role == 'teacher') {
      this.getClassManageMenu();
    } else if (this.role == 'admin') {
      this.getClassListMenu();
    } else if (this.role == 'student') {
      this.getPlan(this.classId);
      this.$store.commit('delNotice', 0);
    }
  },
  methods: {
    /**
     * 获取班级菜单数据
     */
    async getClassManageMenu() {
      const { data } = await this.$axios.get('/teach/class/findClass');
      if (data.code !== 200) {
        return this.$message.error('暂时没有数据呀');
      }
      this.classMenu = data.data;
      this.className = this.classMenu[0].name;
      // this.classId = this.classMenu[0].id;
      if (sessionStorage.getItem('classId') == 'null') {
        sessionStorage.setItem('classId', data.data[0].id);
        sessionStorage.setItem('menuIndex', 0);
        sessionStorage.setItem('className', data.data[0].name);
        this.classId = data.data[0].id;
        this.className = this.classMenu[0].name;
        this.choice = 0;
      }
      this.getPlan(this.classId);
    },
    /**
     * 菜单选择
     */
    choieClass(i, v) {
      this.choice = i;
      this.classId = v.id;
      this.className = v.name;
      this.currentPage = 1;
      /* 右侧状态保存 */
      sessionStorage.setItem('classId', v.id);
      sessionStorage.setItem('menuIndex', i);
      sessionStorage.setItem('className', v.name);
      this.getPlan(v.id);
    },
    /**
     * 获取教学计划
     */
    async getPlan(id) {
      let url = '/teach/class/findPlan';
      if (this.role == 'admin' || this.role == 'student') {
        url = '/teach/class/findPlaning';
      }
      this.planContent = [];
      /* 加载动画 */
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const { data } = await this.$axios.get(url, {
        params: {
          classId: id,
          page: this.currentPage,
          rows: this.pageSize,
        },
      });
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      } else {
        if (data.data.items.length == 0) {
          setTimeout(() => {
            loading.close();
          }, 500);
          return this.$message.warning('暂时没有通知');
        }
      }

      this.totalPage = data.data.total;

      data.data.items.map(async (v) => {
        let res = await this.$axios.get(v.map.text);
        if (res.status !== 200) {
          setTimeout(() => {
            loading.close();
          }, 500);
          return this.$message.error('获取内容失败');
        } else {
          v.map.text = res.data;
          this.$set(v.map, 'richtext', res.data);
        }
      });
      this.planContent = data.data.items;
      setTimeout(() => {
        loading.close();
      }, 500);
    },
    /**
     * 打开教学计划对话框
     */
    openPlanDialog(str, v) {
      if (str == 'new') {
        this.title = '新建班级通知';
        this.isEdit = false;
      } else {
        this.title = '修改通知';
        this.isEdit = true;
        this.uploadPlan.id = v.id;

        this.getRichTextID(v.id);
      }
      if (v) {
        this.uploadPlan.name = v.name;
        this.richText = v.map.richtext;
        /* 进行富文本回显 */
        this.$nextTick(() => {
          this.$refs.richRef.updateEditorData();
        });
      }

      this.planDialog = true;
    },
    /**
     * 关闭教学计划
     */
    handleClose() {
      this.planDialog = false;
      this.uploadPlan.name = '';
      this.uploadPlan.text = '';
      this.richText = '';
      /* 调用富文本的方法进行清除 */
      this.$refs.richRef.clearTxt();
      this.$refs.planRef.resetFields();
    },
    /**
     * 创建教学计划
     */
    createPlan() {
      /* 1.上传富文本到阿里云 */
      /* 获取上传所需参数 */
      this.$refs.planRef.validate(async (valid) => {
        if (!valid) return;
        const { data } = await this.$axios.post(
          '/teach/courseware/ossSignature'
        );
        if (data.code !== 200) {
          return this.$message.error('获取签名失败');
        }
        /* 2.获取响应数据 */
        this.uploadRichText(data.data);
      });

      /* 3.发送数据给后台 */
    },
    /* 上传阿里云，返回数据给服务器 */
    async uploadRichText(list) {
      let txt = this.richText;
      let file = new Blob([txt], { type: 'text/plain;charset=utf-8' });
      let fileName = 'planRichText.txt';
      fileName = fileUtil.getFileName(fileName, list.dir);
      let fd = new FormData();
      fd.append('key', fileName);
      fd.append('policy', list.policy);
      fd.append('OSSAccessKeyId', list.accessId);
      fd.append('success_action_status', '200');
      fd.append('signature', list.signature);
      fd.append('file', file, `planRichText.txt`);
      let config = {
        headers: {
          'Content-Type': 'multipart/from-data',
          x: 'y',
        },
      };
      const res = await this.$axios.post(list.host, fd, config);
      if (res.status !== 200) {
        return this.$message.error('新建通知失败');
      }

      this.uploadPlan.annexDTO.fileSize = file.size;
      this.uploadPlan.annexDTO.objectname = fileName;
      this.uploadPlan.classId = this.classId;
      if (this.isEdit) {
        /* 编辑教学计划 */
        // console.log(this.uploadPlan, '计划');
        const { data } = await this.$axios.post(
          '/teach/class/updatePlan',
          this.uploadPlan
        );

        if (data.code !== 200) {
          return this.$message.error('编辑失败');
        }
        this.$message.success(data.message);
        /* 清除数据 */
        this.uploadPlan.annexDTO.fileSize = 0;
        this.uploadPlan.annexDTO.objectname = '';
        this.uploadPlan.classId = 0;
        this.uploadPlan.name = '';
        this.richText = '';
        /* 重新请求数据 */
        this.getPlan(this.classId);
      } else {
        /* 新建教学计划 */
        const { data } = await this.$axios.post(
          '/teach/class/savePlan',
          this.uploadPlan
        );
        if (data.code !== 200) {
          return this.$message.error('上传失败');
        }
        this.$message.success(data.message);
        /* 清除数据 */
        this.uploadPlan.annexDTO.fileSize = 0;
        this.uploadPlan.annexDTO.objectname = '';
        this.uploadPlan.classId = 0;
        this.uploadPlan.name = '';
        this.richText = '';
        /* 重新请求数据 */
        this.getPlan(this.classId);
      }
      /* 关闭对话框 */
      this.handleClose();
    },
    /**
     * 富文本内容
     */
    richTextChang(content) {
      this.richText = content;
      this.uploadPlan.text = content;
      // console.log(content);
    },
    /**
     * 删除计划
     */
    deletePlan(planId) {
      this.$confirm('此操作将永久删除该计划, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          const { data } = await this.$axios.delete('/teach/class/delPlan', {
            params: {
              planId,
            },
          });
          if (data.code !== 200) {
            return this.$message.error('删除失败');
          }
          this.$message.success(data.message);
          this.getPlan(this.classId);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    /**
     * 获取富文本回显id
     */
    async getRichTextID(planId) {
      const { data } = await this.$axios.get(
        `/teach/class/huiplan?planId=${planId}`
      );
      if (data.code !== 200) {
        return this.$message.error(data.message);
      }
      this.uploadPlan.annexDTO.id = data.data.annexDTO.id;
    },
    handleChangePage(current) {
      this.currentPage = current;
      this.getPlan(this.classId);
    },
    /**
     * 撤回通知
     */
    withdrawNotice(id) {
      this.$confirm('是否撤回通知？', '提示')
        .then(async () => {
          let { data } = await this.$axios.post(
            `/teach/class/revertPlan?planId=${id}`
          );
          if (data.code !== 200) {
            return this.$message.error(data.message);
          }
          this.getPlan(this.classId);
          return this.$message.success(data.message);
        })
        .catch(() => {
          this.$message.info('取消撤回');
        });
    },
    /**
     * 发布通知
     */
    sendNotice(id) {
      this.$confirm('是否发布该通知？', '提示')
        .then(async () => {
          let { data } = await this.$axios.post(
            `/teach/class/pushPlan?planId=${id}`
          );
          if (data.code !== 200) {
            return this.$message.error(data.message);
          }
          this.getPlan(this.classId);
          return this.$message.success(data.message);
        })
        .catch(() => {
          this.$message.info('取消发布');
        });
    },

    /**
     * 证书管理员菜单
     */
    async getClassListMenu() {
      this.tableData = [];
      /* 加载动画 */
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      /* 获取证书id  saveSilderId*/
      let id = localStorage.getItem('silderId');

      let { data } = await this.$axios.get(
        `/teach/cerificate/findCerClass?cerificateId=${id}&page=${1}&rows=${100}`
      );
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.warning('暂时没有班级信息');
      }
      this.classMenu = data.data.items;
      /* 初始默认是第一个 */
      // this.classId = data.data.items[0].id;
      this.className = data.data.items[0].name;
      if (sessionStorage.getItem('classId') == 'null') {
        sessionStorage.setItem('classId', data.data.items[0].id);
        sessionStorage.setItem('menuIndex', 0);
        sessionStorage.setItem('className', data.data.items[0].name);

        this.classId = data.data.items[0].id;
        this.choice = 0;
      }
      this.getPlan(this.classId);
      setTimeout(() => {
        loading.close();
      }, 500);
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-plan {
  padding: 16px 22px;
  display: flex;
  justify-content: space-between;
  .side-menu {
    width: 300px;
    margin: 0 16px 0 0;
    background-color: #fff;
    border-radius: 4px;
    li {
      padding: 0 20px;
      height: 40px;
      margin: 6px;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    li:hover {
      border-radius: 4px;
      background: #f1f3f5;
    }
    .li-active {
      border-radius: 4px;
      background: #f1f3f5;
    }
  }
}
.is-student {
  display: block;
  .zksc-plan-c {
    width: 100%;
  }
}
.zksc-plan-c {
  width: calc(100% - 300px - 22px);
  max-width: 100vw;
  min-height: calc(100vh - 30px - 56px - 56px - 32px);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  // padding: 0 16px 16px 16px;
  .zksc-plan-title {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 14px;
      font-weight: normal;
      padding-left: 16px;
    }
    .el-button {
      margin-right: 16px;
    }
  }
  .zksc-plan-content {
    box-sizing: border-box;
    width: calc(100% - 38px);
    // min-height: 890px - 72px;
    border-radius: 4px;
    border: 1px solid #666666;
    margin: 0 16px 16px;
    padding: 16px;
    p {
      margin: 0;
      color: #000000;
      font-size: 14px;
    }
    .zksc-plan-item {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid #000;
      &:last-child {
        border-bottom: none;
      }
      .rich-text {
        padding: 16px;
        border: 1px solid #000;
        border-radius: 4px;
        color: #333333;
        font-family: PingFang SC;
        font-weight: heavy;
        font-size: 18px;
        > div {
          font-size: 14px;
        }
        .richtext-content {
          white-space: normal;
          word-break: break-all;
          overflow: hidden;
        }
      }
      .item-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        .item-btn {
          display: flex;
          .btn {
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 4px;
            border: 1px solid #666666;
            cursor: pointer;
          }
          .btn:hover {
            border-color: #409eff;
            background-color: #d9ecff;
          }
        }
        .item-author-msg {
          display: flex;
          align-items: center;
          font-size: 14px;
          img {
            width: 28px;
            height: 28px;
            border-radius: 28px;
          }
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .no-border {
    border: none;
  }
}
/deep/.zksc-dialog {
  .el-dialog__header {
    height: 70px !important;
    background: #409eff !important;
    padding: 0 !important;

    line-height: 70px;
    text-align: center;
    .el-dialog__title {
      color: #ffffff !important;
      font-size: 24px !important;
    }
    .el-dialog__headerbtn {
      top: 30px;
      right: 20px;
    }
    .el-dialog__close {
      color: #fff !important;
    }
  }
}

.zksc-edit-content {
  width: 100%;
  min-height: 400px;
  border: 1px solid #666666;
  border-radius: 4px;
}
.reuqire {
  display: flex;
  align-items: center;
  em {
    margin-right: 10px;
  }
}
.el-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
