<template>
  <div>
    <div class="zksc-btn-group">
      <el-button
        v-for="(v2, i2) in soft"
        :key="i2"
        :class="{ 'is-active': i2 == btnIndex }"
        @click="getChapterPartDetail(cid, v2, i2)"
        >{{ v2.name }}</el-button
      >
    </div>

    <!-- type = 0 课件类型 -->
    <div class="zksc-study-content" v-if="type == 0">
      <div class="study-list">
        <item
          v-for="(item, i) in studyList"
          :data="item"
          :key="i"
          :menuId="soft[btnIndex].id"
        ></item>
        <!-- <item></item>
        <item></item>
        <item></item>-->
      </div>
      <el-pagination
        class="zksc-pagination"
        border
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <!-- type = 1 题目 -->
    <template v-else>
      <div class="zksc-btn-classify" v-if="types == '理论题库'">
        <span>题目类型</span>
        <el-button-group>
          <el-button
            @click="classify('choice')"
            :class="[isClass == 'choice' ? 'active' : '']"
            >单选题</el-button
          >
          <el-button
            @click="classify('judge')"
            :class="[isClass == 'judge' ? 'active' : '']"
            >判断题</el-button
          >
        </el-button-group>
      </div>

      <!-- 理论题 -->
      <div v-if="types == '理论题库'">
        <el-table :data="studyList" style="width: 100%" stripe>
          <el-table-column
            type="index"
            label="序号"
            width="50px"
            align="center"
          ></el-table-column>
          <el-table-column prop="name" label="试题名称"></el-table-column>
          <el-table-column prop="class" label="题型" width="80">
            <template slot-scope="scope">
              <div v-if="scope.row.type == 'choice'">选择题</div>
              <div v-if="scope.row.type == 'judge'">判断题</div>
              <div v-if="scope.row.type == 'pricate'">实操题</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="fraction"
            label="分值"
            width="50"
          ></el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button type="info" size="mini" @click="lookChoice(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          border
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <!-- 实操题 -->
      <div v-else>
        <el-table :data="studyList" style="width: 100%" stripe>
          <el-table-column
            type="index"
            label="序号"
            width="50px"
            align="center"
          ></el-table-column>
          <el-table-column prop="name" label="试题名称"></el-table-column>
          <el-table-column prop="required" label="实操题要求"></el-table-column>
          <el-table-column prop="time" label="发布时间"></el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button
                type="info"
                size="mini"
                @click="lookPracticeDialog(scope.row)"
                >预览</el-button
              >
              <el-link class="link" :href="scope.row.url">
                <el-button type="primary" size="mini">下载</el-button>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="zksc-pagination"
          border
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </template>

    <!-- 查看单选 -->
    <!-- <el-dialog class="zksc-dialog" title="题目详情" :visible.sync="isChoiceDialog">
      <el-form :model="choiceForm">
        <el-form-item label="题目名称">
          <el-input v-model="choiceForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="A:">
          <el-input v-model="choiceForm.optionsA" disabled></el-input>
        </el-form-item>
        <el-form-item label="B:">
          <el-input v-model="choiceForm.optionsB" disabled></el-input>
        </el-form-item>
        <el-form-item label="C:">
          <el-input v-model="choiceForm.optionsC" disabled></el-input>
        </el-form-item>
        <el-form-item label="D:">
          <el-input v-model="choiceForm.optionsD" disabled></el-input>
        </el-form-item>
        <el-form-item label="答案">
          <el-input v-model="choiceForm.answer" disabled></el-input>
        </el-form-item>
        <el-form-item label="分数">
          <el-input v-model="choiceForm.fraction" disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isChoiceDialog = false">取 消</el-button>
        <el-button type="primary" @click="isChoiceDialog = false">确 定</el-button>
      </div>
    </el-dialog>-->
    <!-- 理论题查看 -->
    <el-dialog
      :title="menuFlag === false ? '题目详情' : previewData.name"
      :visible.sync="preview"
      class="preview"
      center
    >
      <div class="topicContent" v-if="menuFlag === false">
        <div class="header">
          <span class="spans">
            题目类型：{{ previewData.type === 'choice' ? '单选题' : '判断题' }}
          </span>
          <span class="spans">分值：{{ previewData.fraction }}</span>
          <el-button
            @click="showAnswer = !showAnswer"
            :type="showAnswer === false ? 'primary' : 'null'"
            >{{ showAnswer === false ? '显示答案' : '隐藏答案' }}</el-button
          >
          <span class="daan" v-show="showAnswer"
            >正确答案：{{ previewData.answer }}</span
          >
        </div>
        <div class="centers">
          <p>题目：{{ previewData.name }}</p>
          <ul>
            <li>A：{{ previewData.optionsA }}</li>
            <li>B：{{ previewData.optionsB }}</li>
            <li v-show="previewData.type !== 'judge'">
              C：{{ previewData.optionsC }}
            </li>
            <li v-show="previewData.type !== 'judge'">
              D：{{ previewData.optionsD }}
            </li>
          </ul>
          <span>解析：{{ previewData.parsing }}</span>
          <br />
          <button @click="switchoverTopic(previewData)">上一题</button>
          <button @click="switchoverTopics(previewData)">下一题</button>
        </div>
      </div>
      <div class="previewPractical" v-else>
        <div class="content">{{ previewData.required }}</div>
        <a :href="previewData.quesionurl">
          <el-button type="primary">
            <icon-svg icon-class="download"></icon-svg>下载
          </el-button>
        </a>
      </div>
    </el-dialog>
    <!-- 实操 -->
    <el-dialog
      class="zksc-dialog"
      :title="practiceName"
      :visible.sync="isPracticeDialog"
    >
      <div class="zksc-dialog-content">
        <p>{{ practiceRequired }}</p>
        <el-link :href="href" class="zksc-dialog-link">
          <el-button icon="el-icon-download" type="primary">下载</el-button>
        </el-link>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Item from '../Item/Item.vue';
export default {
  props: ['soft', 'type', 'cid', 'types', 'menuId'],
  components: {
    Item,
  },

  data() {
    return {
      btnIndex: 0,
      isClass: 'choice',
      total: 0,
      pageSize: 8,
      currentPage: 1,
      studyList: [],
      con: {},
      ctypes: 'choice',
      theoryquestion: [],
      actualQuestion: [],
      newCid: '',
      /* 单选对话框 */
      menuFlag: false,
      preview: false,
      showAnswer: false,
      clickItem: {},
      previewData: {
        name: '',
        optionsA: '',
        optionsB: '',
        optionsC: '',
        optionsD: '',
        answer: '',
        parsing: '',
        fraction: '',
        type: '',
      },

      /* 实操 */
      isPracticeDialog: false,
      practiceName: '',
      practiceRequired: '',
      href: '',
    };
  },
  /* 监听父组件切换章节 */
  watch: {
    cid: {
      handler(newV) {
        this.newCid = newV;
      },
    },
    soft: {
      handler(newv) {
        console.log(newv);
        this.getChapterPartDetail(this.newCid, newv[0], this.btnIndex);
      },
      deep: true,
    },
  },
  mounted() {
    console.log(this.cid);
    console.log(this.soft);
    this.getChapterPartDetail(this.cid, this.soft[0], 0);
  },
  methods: {
    /* 切换题目类型 */
    classify(str) {
      this.isClass = str;
      this.ctypes = str;
      this.currentPage = 1;

      this.getChapterPartDetail(this.cid, this.soft[0], this.btnIndex);
    },
    /* 获取资源具体某部分内容  */
    getChapterPartDetail(cid, item, i2) {
      this.btnIndex = i2;
      this.con = item;

      if (this.types == '实操题库') {
        this.ctypes = 'pricate';
      }
      let params = {
        sectionId: cid,
        menuId: item.id,
        num: this.currentPage,
        sum: this.pageSize,
        type: item.type, // 0软件课件   1题目
        types: this.ctypes, // choice 选择 judge判断 pricate实操题库 没有为null
      };
      this.$axios.get('/teach/course/sectionSoft', { params }).then((res) => {
        let { data } = res;
        if (data.code !== 200) {
          return this.$message.error('获取课程详细信息失败');
        }
        console.log(data, 'data');
        this.total = data.data.total;

        this.studyList = data.data.records;
      });
    },

    /* 分页 */
    handleCurrentChange(current) {
      this.currentPage = current;
      this.getChapterPartDetail(this.cid, this.con, this.btnIndex);
    },
    download(url) {
      const a = document.createElement(a);
      a.setAttribute('href', url);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    /* 查看单选 */
    lookChoice(item) {
      this.previewData = {};
      // console.log(item, 'row');
      this.preview = true;
      this.showAnswer = false;
      // console.log(item);
      this.clickItem = item;
      this.$axios
        .get(`/teach/exam/findQuestion?questionId=${item.id}`)
        .then((res) => {
          // console.log(res.data.data);
          if (res.data.code === 200) {
            this.previewData = res.data.data;
          }
        });
    },
    // 上一题、下一题
    switchoverTopic(data) {
      if (data.status !== '0') {
        this.$axios
          .get(
            `/teach/exam/findlastExam?questionId=${
              data.id
            }&status=${0}&menuId=${this.soft[this.btnIndex].id}`
          )
          .then((res) => {
            // console.log(res.data);
            if (res.data.code === 200) {
              this.previewData = res.data.data;
            }
          });
      } else {
        this.$message({
          type: 'error',
          message: '已经是第一题',
        });
      }
    },
    switchoverTopics(data) {
      // console.log(data, 'data---');
      if (data.status !== '1') {
        this.$axios
          .get(
            `/teach/exam/findlastExam?questionId=${
              data.id
            }&status=${1}&menuId=${this.soft[this.btnIndex].id}`
          )
          .then((res) => {
            // console.log(res.data, 'res.data');
            if (res.data.code === 200) {
              this.previewData = res.data.data;
            }
          });
      } else {
        // console.log(data.status, 'status');
        this.$message({
          type: 'error',
          message: '已经是最后一题',
        });
      }
    },
    /* 查看实操 */
    lookPracticeDialog(row) {
      this.isPracticeDialog = true;
      this.practiceName = row.name;
      this.practiceRequired = row.required;
      this.href = row.url;
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-btn-group {
  // padding: 16px;
  .el-button {
    border-color: transparent !important;
    margin: 8px;
  }
  .is-active {
    color: #3a8ee6;
    background-color: #ecf5ff;
  }
}
.zksc-study-content {
  margin-top: 20px;

  .study-list {
    display: flex;
    flex-wrap: wrap;
  }
  .el-pagination {
    margin: 16px 0;
    display: flex;
    justify-content: center;
  }
}
.zksc-btn-classify {
  display: flex;
  align-items: center;
  .el-button:focus,
  .el-button:hover {
    background-color: #409eff;
    color: #fff;
  }
  span {
    font-size: 14px;
    margin: 0 20px;
  }
  .active {
    background-color: #409eff;
    color: #fff;
  }
}
.el-pagination {
  margin: 16px 0;
  display: flex;
  justify-content: center;
}
.el-table {
  padding: 10px;
}
.link {
  margin-left: 10px;
}

/deep/.zksc-dialog {
  .zksc-dialog-content {
    // width: 660px;
    // text-align: center;
    margin: 20px auto !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #333333;
    font-size: 14px;
  }
  .zksc-dialog-link {
    width: 70px;
    margin-top: 30px;
  }
  .el-link {
    &::after {
      content: '' !important;
    }
  }
  .el-dialog {
    .el-dialog__header {
      height: 70px !important;
      background: #409eff !important;
      padding: 0 !important;

      line-height: 70px;
      text-align: center;
      .el-dialog__title {
        color: #ffffff !important;
        font-size: 24px !important;
      }
      .el-dialog__headerbtn {
        top: 30px;
        right: 20px;
      }
      .el-dialog__close {
        color: #fff !important;
      }
    }
  }
}
.preview {
  /deep/.el-dialog__body {
    margin: 0;
    padding: 0;
    .topicContent {
      width: 100%;
      min-height: 300px;
      margin: 0;
      padding: 0;
      .header {
        width: 100%;
        height: 72px;
        background: #f1f3f5;
        .spans {
          display: inline-block;
          font-size: 14px;
          line-height: 72px;
          margin: 0 16px;
        }
        .el-button {
          width: 100px;
        }
        .daan {
          margin-left: 16px;
          color: #f56c6c;
        }
      }
      .centers {
        p {
          font-size: 18px;
          margin-left: 16px;
        }
        ul {
          margin-left: 16px;
          li {
            font-size: 14px;
            margin-bottom: 8px;
          }
        }
        span {
          display: inline-block;
          margin-left: 16px;
          margin-bottom: 32px;
        }
        button {
          display: inline-block;
          width: 140px;
          height: 40px;
          border: 1px solid #c6e2ff;
          border-radius: 4px;
          margin: 0 0 16px 16px;
          background: #ecf5ff;
          text-align: center;
          color: #409eff;
        }
      }
    }
    .previewPractical {
      width: 664px;
      margin: 32px auto;
      .el-button {
        margin-top: 32px;
        margin-bottom: 32px;
        margin-left: 43%;
      }
    }
  }
}
</style>
