<template>
  <div class="zksc-class">
    <nav class="zksc-nav" @click="handleLink($event)">
      <router-link
        to="/classList"
        class="zksc-nav-btn"
        id="classList"
        :class="[choose == 'classList' ? 'active' : '']"
        v-if="role == 'admin'"
        >班级列表</router-link
      >
      <router-link
        to="/member"
        class="zksc-nav-btn"
        id="member"
        :class="[choose == 'member' ? 'active' : '']"
        >班级成员</router-link
      >
      <!-- <router-link
        to="/plan"
        class="zksc-nav-btn"
        id="plan"
        :class="[choose == 'plan' ? 'active' : '']"
        >教学计划</router-link
      >-->
      <router-link
        to="/notice"
        class="zksc-nav-btn"
        id="notice"
        :class="[choose == 'notice' ? 'active' : '']"
        >班级通知
        <em
          v-show="$store.state.noticeNum > 0 && role === 'student'"
          class="hongdian"
          >{{ $store.state.noticeNum }}</em
        >
      </router-link>
      <router-link
        to="/ClassCourse"
        class="zksc-nav-btn"
        id="classCourse"
        :class="[choose == 'classCourse' ? 'active' : '']"
        >班级课程
        <em
          v-show="$store.state.messageNum > 0 && role === 'student'"
          class="hongdian"
          >{{ $store.state.messageNum }}</em
        >
      </router-link>
      <router-link
        to="/homework"
        class="zksc-nav-btn"
        id="homework"
        :class="[choose == 'homework' ? 'active' : '']"
        >班级作业
        <em
          v-show="$store.state.workNum > 0 && role === 'student'"
          class="hongdian"
          >{{ $store.state.workNum }}</em
        >
      </router-link>
      <router-link
        to="/exam"
        class="zksc-nav-btn"
        id="exam"
        :class="[choose == 'exam' ? 'active' : '']"
        >班级模拟考
        <em
          v-show="$store.state.examNum > 0 && role === 'student'"
          class="hongdian"
          >{{ $store.state.examNum }}</em
        >
      </router-link>
    </nav>
    <transition name="zksc-fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
// import SideMenu from '../sideMenu.vue';
export default {
  components: {
    // SideMenu,
  },
  data() {
    return {
      role: '',
      choose: '',
      // hongdian: this.$store.state.messageNum,
    };
  },
  computed: {
    hong() {
      return this.hongdian;
    },
  },
  methods: {
    /* 路由切换，按钮样式控制 */
    handleLink(e) {
      if (e.target.id === '') return;
      this.choose = e.target.id;
      sessionStorage.setItem('choose', e.target.id);

      /* 考试禁止控制路由样式 禁止切换路由 */
      let t =
        45 * 60 -
        (new Date() - new Date(sessionStorage.getItem('startTime'))) / 1000;
      if (t > 0) {
        this.choose = 'exam';
        sessionStorage.setItem('choose', 'exam');
      }
    },
  },
  watch: {
    '$route.path': function () {
      // console.log(this.choose);
      this.choose = sessionStorage.getItem('choose');
    },
    hongdian: function (news, olds) {
      console.log(news, olds);
    },
    deep: true,
  },
  mounted() {
    // this.hongdian = this.$store.state.messageNum;
  },
  created() {
    /* 获取路由 */
    this.choose = sessionStorage.getItem('choose');
    this.role = sessionStorage.getItem('role');
  },
};
</script>

<style lang="less" scoped>
.zksc-class {
  width: 100%;
  min-height: calc(100vh - 30px - 56px);
  background: #f0f2f5;
}
.zksc-nav {
  height: 56px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-left: 320px;
  .zksc-nav-btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0 16px 0 0;
    transition: 0.1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    position: relative;
    .hongdian {
      font-size: 12px;
      text-align: center;
      line-height: 18px;
      font-style: normal;
      position: absolute;
      top: -7px;
      right: -7px;
      width: 18px;
      height: 18px;
      z-index: 99999999;
      color: #fff;
    }
  }
}
.active {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

/* 路由切换动画 */
// .zksc-fade-enter-active {
//   transition: all 0.3s ease;
// }
// .zksc-fade-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
// }
// .zksc-fade-enter, .zksc-fade-leave-to
// /* .slide-fade-leave-active for below version 2.1.8 */ {
//   transform: translateX(10px);
//   opacity: 0;
// }
</style>
