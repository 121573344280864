<template>
  <div class="zksc-course">
    <nav class="zksc-nav" @click="handleLink($event)" v-if="role == 'teacher'">
      <router-link
        to="/courseManage"
        class="zksc-nav-btn"
        id="courseManage"
        :class="[choose == 'courseManage' ? 'active' : '']"
        >课程管理</router-link
      >
      <router-link
        to="/courseDesign"
        class="zksc-nav-btn"
        id="courseDesign"
        :class="[choose == 'courseDesign' ? 'active' : '']"
        >课程设计</router-link
      >
    </nav>

    <!-- <transition name="zksc-fade" mode="out-in"> -->
    <!-- 防止文本编辑器被销毁 -->
    <!-- <keep-alive>
        
      </keep-alive>-->
    <router-view></router-view>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      choose: '',
      role: '',
    };
  },
  watch: {
    '$route.path': function () {
      // console.log(this.choose, 'course');
      this.choose = sessionStorage.getItem('choose');
    },
  },
  methods: {
    handleLink(e) {
      if (e.target.id === '') return;
      this.choose = e.target.id;
      sessionStorage.setItem('choose', e.target.id);
    },
  },
  mounted() {
    /* 获取路由 */
    this.choose = sessionStorage.getItem('choose');
    this.role = sessionStorage.getItem('role');
  },
};
</script>

<style lang="less" scoped>
.zksc-course {
  width: 100%;
}
.zksc-nav {
  width: 100%;
  height: 56px;
  overflow: hidden;
  display: flex;
  align-items: center;
  .zksc-nav-btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: 0;
    margin: 0 16px 0 0;
    transition: 0.1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    &:nth-child(1) {
      margin-left: 170px;
    }
  }
}
.active {
  color: #fff !important;
  background-color: #409eff !important;
  border-color: #409eff !important;
}

/* 路由切换动画 */
.zksc-fade-enter-active {
  transition: all 0.3s ease;
}
.zksc-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.zksc-fade-enter, .zksc-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.el-pagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
