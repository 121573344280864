<template>
  <div class="zksc-classList">
    <div class="zksc-plan-title">
      <h5>班级列表</h5>
      <el-button type="primary" @click="openCreateDialog()">添加班级</el-button>
    </div>
    <!-- 班级列表 -->
    <el-empty
      description="暂时没有班级"
      v-if="tableData.length == 0"
    ></el-empty>
    <template v-else>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="cente"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="班级名称"
          width="180"
        ></el-table-column>
        <el-table-column prop="usernmae" label="老师用户名">
          <template slot-scope="scope">
            <div>
              {{ scope.row.usernmae == null ? '暂无老师' : scope.row.usernmae }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="realname" label="老师真实姓名">
          <template slot-scope="scope">
            <div>
              {{ scope.row.realname == null ? '暂无老师' : scope.row.realname }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="count"
          label="学生数量"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="操作"
          align="center"
          width="240px"
        >
          <template slot-scope="scoped">
            <div>
              <el-button
                size="mini"
                @click="openCreateDialog(scoped.row, 'edit')"
                >编辑</el-button
              >
              <el-button size="mini" type="info" @click="lookClass(scoped.row)"
                >查看</el-button
              >
              <el-button
                size="mini"
                type="warning"
                @click="deleteClass(scoped.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        class="zksc-pagination"
        layout="prev, pager, next, jumper"
        :total="totalPage"
        :page-size="pageSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </template>

    <!-- 添加班级 -->
    <el-dialog
      class="zksc-dialog"
      :title="title"
      :visible.sync="isCreateDialog"
      @close="closeCreateDialog"
    >
      <el-form
        :model="createClassform"
        label-width="80px"
        :rules="createClassRules"
        ref="createClassRef"
      >
        <el-form-item label="班级名称" prop="name">
          <el-input
            v-model="createClassform.name"
            placeholder="请输入班级名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeCreateDialog">取 消</el-button>
        <el-button type="primary" @click="createClass">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isCreateDialog: false,
      tableData: [],

      createClassform: {
        name: '',
        id: '',
      },
      createClassRules: {
        name: [{ required: true, message: '请输入班级名称', trigger: 'blur' }],
      },
      totalPage: 0,
      pageSize: 10,
      currentPage: 1,
      /* 编辑 */
      isEdit: '',
      title: '添加班级',
    };
  },
  mounted() {
    this.getClassList();
  },
  methods: {
    /* 打开创建班级 */
    openCreateDialog(row, str) {
      this.isCreateDialog = true;
      if (str == 'edit') {
        this.createClassform.name = row.name;
        this.title = '编辑班级信息';
        this.createClassform.id = row.id;
        this.isEdit = str;
      }
    },
    /* 关闭创建班级 */
    closeCreateDialog() {
      this.isCreateDialog = false;
      this.$refs.createClassRef.resetFields();
    },
    /* 创建班级 */
    createClass() {
      let url = '/teach/cerificate/addClass';
      if (this.isEdit == 'edit') {
        url = '/teach/cerificate/updateClass';
      }
      this.$refs.createClassRef.validate(async (valid) => {
        if (!valid) return;
        let { data } = await this.$axios.post(url, this.createClassform);
        if (data.code != 200) {
          return this.$message.error(data.message);
        }
        this.closeCreateDialog();
        this.getClassList();
        return this.$message.success(data.message);
      });
    },
    /* 获取班级列表 */
    async getClassList() {
      this.tableData = [];
      /* 加载动画 */
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });

      /* 获取证书id  saveSilderId*/
      let id = localStorage.getItem('silderId');

      let { data } = await this.$axios.get(
        `/teach/cerificate/findCerClass?cerificateId=${id}&page=${this.currentPage}&rows=${this.pageSize}`
      );
      if (data.code !== 200) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.error(data.message);
      }
      if (data.data.items.length == 0) {
        setTimeout(() => {
          loading.close();
        }, 500);
        return this.$message.warning('暂时没有班级信息');
      }

      this.tableData = data.data.items;
      this.totalPage = data.data.total;
      setTimeout(() => {
        loading.close();
      }, 500);
    },
    /* 班级列表分页 */
    handleChangePage(current) {
      this.currentPage = current;
      this.getClassList();
    },
    /* 删除班级 */
    deleteClass({ id }) {
      this.$confirm('是否删除该班级?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          let { data } = await this.$axios.delete(
            `/teach/cerificate/delClass?classId=${id}`
          );
          if (data.code !== 200) return this.$message.error(data.message);
          this.getClassList();
          return this.$message.success(data.message);
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    lookClass({ id, name }) {
      this.$router.push({
        path: '/member',
        query: {
          classId: id,
        },
      });
      sessionStorage.setItem('className', name);
      sessionStorage.setItem('choose', 'member');
      sessionStorage.setItem('classId', id);
    },
  },
};
</script>
<style lang="less" scoped>
.zksc-classList {
  margin: 16px 32px;
  border-radius: 4px;
  background: #ffffff;
  min-height: calc(100vh - 180px);
  .zksc-plan-title {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 14px;
      font-weight: normal;
      padding-left: 16px;
    }
    .el-button {
      margin-right: 16px;
    }
  }
  .el-table {
    padding: 0 10px;
  }
}
/deep/.zksc-dialog {
  .el-dialog__header {
    height: 70px !important;
    background: #409eff !important;
    padding: 0 !important;

    line-height: 70px !important;
    text-align: center !important;
    .el-dialog__title {
      color: #ffffff !important;
      font-size: 24px !important;
    }
    .el-dialog__headerbtn {
      top: 30px;
      right: 20px;
    }
    .el-dialog__close {
      color: #fff !important;
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      display: flex !important;
      justify-content: center !important;
    }
  }
}

.el-pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
/deep/.zksc-pagination {
  .btn-prev {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-right: 6px;
  }
  .btn-next {
    border-radius: 4px;
    border: 1px solid #666666;
    min-width: 28px;
    padding-left: 6px;
  }
  li {
    margin: 0 4px;
    min-width: 28px;
    border-radius: 4px;
    border: 1px solid #666666;
    border-left: 1px solid #666666 !important;
    font-weight: 400;
  }
  .active {
    background: #409eff;
    color: #fff;
    border-radius: 4px;
    border: #409eff 1px solid;
    border-left: 1px solid #409eff !important;
  }
}
</style>
